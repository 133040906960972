import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Markdown from './Markdown';

const useStyles = makeStyles(theme => ({
    markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
    },
}));

export default function Main(props) {
    const classes = useStyles();
    const { posts, title } = props;

    return (
        <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            <Divider />
            <Typography variant="subtitle1" color="textSecondary" component="p">
                Somos una empresa dedicada al adiestramiento canino, ofreciendo
                servicios de calidad en el adiestramiento y proporcionando
                clases efectivas de tal forma que el dueño pueda tener las
                herramientas básicas para un buen control de obediencia en su
                perro, así como crear conciencia social en la responsabilidad
                que conlleva la adquisición de una mascota. Así mismo también
                ofrecemos servicio de venta de alimentos, cuidando la salud y
                nutrición de los perros, facilitar a los clientes los
                aditamentos y accesorios para poder tener efectividad en el
                proceso de enseñanza aprendizaje durante los adiestramientos.
            </Typography>
        </Grid>
    );
}

Main.propTypes = {
    posts: PropTypes.array,
    title: PropTypes.string,
};
