import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getUserInfo,
    getUserRoles,
    createUser,
    updateUser,
    assignRoleToUser,
    updateAddress,
    updateBankInfo,
    uploadEmployeeImage,
    getLandingUserInfo,
    updateEmployee,
} from '../../../api/user';
import { getRolesList } from '../../../api/roles';
import { LOADING_STATUS } from '../../../helpers/constants';
import { initialValues } from './mainForm';
import { userMapper, allRolesMapper, userRolesMapper } from './utils';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';

const initialState = {
    user: initialValues,
    userAdded: false,
    fetchUserInfoStatus: LOADING_STATUS.IDLE,
    fetchUserRolesStatus: LOADING_STATUS.IDLE,
    fetchAllRolesStatus: LOADING_STATUS.IDLE,
    insertStatus: LOADING_STATUS.IDLE,
    insertRoles: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchUserInfo = createAsyncThunk(
    'users/fetchUserInfo',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getUserInfo(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar el usuario con el id ${id}.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchLandingUserInfo = createAsyncThunk(
    'users/fetchLandingUserInfo',
    async (name, { dispatch, rejectWithValue }) => {
        try {
            const data = await getLandingUserInfo(name);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar el Empleado.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

// export const fetchUserRoles = createAsyncThunk(
//     'users/fetchUserRoles',
//     async (id, { dispatch, rejectWithValue }) => {
//         try {
//             const data = await getUserRoles(id);
//             return data;
//         } catch (err) {
//             dispatch(
//                 enqueueSnackbar({
//                     message: `No se pudo cargar los roles del usuario con el id ${id}.`,
//                     options: {
//                         key: new Date().getTime() + Math.random(),
//                         variant: 'error',
//                     },
//                 })
//             );
//             return rejectWithValue(err.message);
//         }
//     }
// );

// export const fetchAllRoles = createAsyncThunk(
//     'users/fetchAllRoles',
//     async (_, { dispatch, rejectWithValue }) => {
//         try {
//             const data = await getRolesList();
//             return data;
//         } catch (err) {
//             dispatch(
//                 enqueueSnackbar({
//                     message: `No se pudo cargar la lista de roles.`,
//                     options: {
//                         key: new Date().getTime() + Math.random(),
//                         variant: 'error',
//                     },
//                 })
//             );
//             return rejectWithValue(err.message);
//         }
//     }
// );

// export const assignRolesToUser = createAsyncThunk(
//     'users/assignRolesToUser',
//     async ({ idUser, roles }, { dispatch, rejectWithValue }) => {
//         try {
//             const data = await assignRoleToUser(idUser, roles);
//             dispatch(
//                 enqueueSnackbar({
//                     message: `Roles asignados con éxito.`,
//                     options: {
//                         key: new Date().getTime() + Math.random(),
//                         variant: 'success',
//                     },
//                 })
//             );
//             return data;
//         } catch (err) {
//             dispatch(
//                 enqueueSnackbar({
//                     message: `No se pudo asignar roles al usuario, intente más tarde.`,
//                     options: {
//                         key: new Date().getTime() + Math.random(),
//                         variant: 'error',
//                     },
//                 })
//             );
//             return rejectWithValue(err.message);
//         }
//     }
// );

export const createSingleUser = createAsyncThunk(
    'users/createSingleUser',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createUser(body);
            dispatch(
                enqueueSnackbar({
                    message: `Usuario creado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo crear el usuario, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleUser = createAsyncThunk(
    'users/updateSingleUser',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateUser(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Usuario actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el usuario, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleEmployee = createAsyncThunk(
    'users/updateSingleEmployee',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateEmployee(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Empleado actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el usuario, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleAddress = createAsyncThunk(
    'users/updateSingleAddress',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateAddress(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Dirección actualizada con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar la dirección, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleBankInfo = createAsyncThunk(
    'users/updateSingleBankInfo',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateBankInfo(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Información bancaria actualizada con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar la información bancaria, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const uploadEmployeePicture = createAsyncThunk(
    'users/updateEmployeePicture',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await uploadEmployeeImage(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Imagen actualizada con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar la Imagen, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const singleUserSlice = createSlice({
    name: 'singleUser',
    initialState,
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUserInfo.pending, state => {
                state.fetchUserInfoStatus = LOADING_STATUS.LOADING;
            })
            .addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
                state.fetchUserInfoStatus = LOADING_STATUS.SUCCEEDED;
                state.user.userInfo = userMapper(payload);
            })
            .addCase(fetchUserInfo.rejected, (state, { error }) => {
                state.fetchUserInfoStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchLandingUserInfo.pending, state => {
                state.fetchUserInfoStatus = LOADING_STATUS.LOADING;
            })
            .addCase(fetchLandingUserInfo.fulfilled, (state, { payload }) => {
                state.fetchUserInfoStatus = LOADING_STATUS.SUCCEEDED;
                state.user.userInfo = userMapper(payload);
            })
            .addCase(fetchLandingUserInfo.rejected, (state, { error }) => {
                state.fetchUserInfoStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            // .addCase(fetchUserRoles.pending, state => {
            //     state.fetchUserRolesStatus = LOADING_STATUS.LOADING;
            // })
            // .addCase(fetchUserRoles.fulfilled, (state, { payload }) => {
            //     state.fetchUserRolesStatus = LOADING_STATUS.SUCCEEDED;
            //     state.user.userRoles = userRolesMapper(payload);
            // })
            // .addCase(fetchUserRoles.rejected, (state, { error }) => {
            //     state.fetchUserRolesStatus = LOADING_STATUS.FAILED;
            //     state.error = error.message;
            // })
            // .addCase(fetchAllRoles.pending, state => {
            //     state.fetchAllRolesStatus = LOADING_STATUS.LOADING;
            // })
            // .addCase(fetchAllRoles.fulfilled, (state, { payload }) => {
            //     state.fetchAllRolesStatus = LOADING_STATUS.SUCCEEDED;
            //     state.user.allRoles = allRolesMapper(payload);
            // })
            // .addCase(fetchAllRoles.rejected, (state, { error }) => {
            //     state.fetchAllRolesStatus = LOADING_STATUS.FAILED;
            //     state.error = error.message;
            // })
            .addCase(createSingleUser.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(createSingleUser.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(createSingleUser.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSingleUser.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSingleUser.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSingleUser.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSingleEmployee.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSingleEmployee.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSingleEmployee.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSingleAddress.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSingleAddress.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSingleAddress.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSingleBankInfo.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSingleBankInfo.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSingleBankInfo.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(uploadEmployeePicture.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(uploadEmployeePicture.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(uploadEmployeePicture.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
        // .addCase(assignRolesToUser.pending, state => {
        //     state.insertRoles = LOADING_STATUS.LOADING;
        // })
        // .addCase(assignRolesToUser.fulfilled, state => {
        //     state.insertRoles = LOADING_STATUS.SUCCEEDED;
        // })
        // .addCase(assignRolesToUser.rejected, (state, { error }) => {
        //     state.insertRoles = LOADING_STATUS.FAILED;
        //     state.error = error.message;
        // });
    },
});

export const { resetValues } = singleUserSlice.actions;

export default singleUserSlice.reducer;
