import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getCarouselInfo,
    createCarousel,
    updateCarousel,
    uploadImageCarousel,
} from '../../../api/carousel';
import { getRolesList } from '../../../api/roles';
import { LOADING_STATUS } from '../../../helpers/constants';
import { initialValues } from './mainForm';
import { carouselMapper } from './utils';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';

const initialState = {
    carousel: initialValues,
    carouselAdded: false,
    fetchCarouselInfoStatus: LOADING_STATUS.IDLE,
    fetchCarouselRolesStatus: LOADING_STATUS.IDLE,
    fetchAllRolesStatus: LOADING_STATUS.IDLE,
    insertStatus: LOADING_STATUS.IDLE,
    insertRoles: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchCarouselInfo = createAsyncThunk(
    'carousel/fetchCarouselInfo',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getCarouselInfo(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar el carrusel con el id ${id}.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createSingleCarousel = createAsyncThunk(
    'carousel/createSingleCarousel',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createCarousel(body);
            dispatch(
                enqueueSnackbar({
                    message: `Carrusel creado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo crear el carrusel, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleCarousel = createAsyncThunk(
    'carousel/updateSingleCarousel',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateCarousel(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Carrusel actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el carrusel, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const uploadCarouselPicture = createAsyncThunk(
    'carousel/updateCarouselPicture',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await uploadImageCarousel(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Imagen actualizada con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar la Imagen, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const singleCarouselSlice = createSlice({
    name: 'singleCarousel',
    initialState,
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchCarouselInfo.pending, state => {
                state.fetchCarouselInfoStatus = LOADING_STATUS.LOADING;
            })
            .addCase(fetchCarouselInfo.fulfilled, (state, { payload }) => {
                state.fetchCarouselInfoStatus = LOADING_STATUS.SUCCEEDED;
                state.carousel.carouselInfo = carouselMapper(payload);
            })
            .addCase(fetchCarouselInfo.rejected, (state, { error }) => {
                state.fetchCarouselInfoStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(createSingleCarousel.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(createSingleCarousel.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(createSingleCarousel.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSingleCarousel.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSingleCarousel.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSingleCarousel.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(uploadCarouselPicture.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(uploadCarouselPicture.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(uploadCarouselPicture.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = singleCarouselSlice.actions;

export default singleCarouselSlice.reducer;
