import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Item from './Item';

export default function CarouselComponent({ data }) {
    return (
        <Carousel>
            {data?.map((item, i) => (
                <Item key={i} item={item} />
            ))}
        </Carousel>
    );
}
