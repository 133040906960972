import * as yup from 'yup';
import {
    emailErrorMessage,
    maxCharMessage,
    phoneErrorMessage,
    phoneRegExp,
    requiredMessage,
} from '../../../helpers/validationSchemaHelpers';

export const initialFormInfo = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    body: '',
};

export const validationSchema = yup.object({
    firstName: yup.string().max(50, maxCharMessage).required(requiredMessage),
    lastName: yup.string().max(50, maxCharMessage).required(requiredMessage),
    phone: yup
        .string()
        .max(15, maxCharMessage)
        .matches(phoneRegExp, phoneErrorMessage),
    email: yup
        .string()
        .max(50, maxCharMessage)
        .email(emailErrorMessage)
        .required(requiredMessage),
    body: yup.string().max(200, maxCharMessage).required(requiredMessage),
});
