export const formatUserPayments = SubjectPaymentsArray =>
    SubjectPaymentsArray.map(subjectPayment => {
        return {
            subjectPaymentName: subjectPayment?.subjectName ?? '',
            idCourseAssignment: subjectPayment?.idCourseAssignment,
            payments: subjectPayment.coursePayments.map(payment => ({
                id: payment.idCoursePaymentMovement,
                idCourseAssignment: payment.idCourseAssignment,
                paymentName: payment?.idMovementConcept ?? '',
                expirationDate: payment.date?.slice(0, 10) ?? '',
                uploadDate: payment.uploadDate?.slice(0, 10) ?? '',
                discount: payment?.discount === true ? 'Aplicado' : 'No aplica',
                cost: payment?.amount ?? '',
                total: payment?.totalAmount ?? '',
                interbankKey: payment?.interbankKey ?? null,
                paymentStatus: payment?.status,
                validation: payment?.validation === true ? true : false,
                comment: payment?.comment ?? null,
                document: {
                    file: payment?.document?.file ?? null,
                    fileName: payment?.document?.fileName ?? null,
                },
            })),
        };
    });

export const formatPaymentInfo = paymentInfo => ({
    base64File: paymentInfo?.base64File,
    idPayment: paymentInfo?.idPayment,
    idCourseAssignment: paymentInfo?.idCourseAssignment,
    idCoursePaymentMovement: paymentInfo?.idCoursePaymentMovement,
    idPaymentType: paymentInfo?.idPaymentType,
    amountPaid: paymentInfo?.amountPaid,
    expiredDate: paymentInfo?.expiredDate,
    paymentDate: paymentInfo?.paymentDate,
    reference: paymentInfo?.reference,
    file: paymentInfo?.file,
    authorizedName: paymentInfo?.authorizedName,
    authorizedBy: paymentInfo?.authorizedBy,
    authorizedAt: paymentInfo?.authorizedAt,
    authorizationStatus: paymentInfo?.authorizationStatus,
    authorizationComment: paymentInfo?.authorizationComment,
    status: paymentInfo?.paymentInfo,
});

export const formatInsertPayment = values => ({
    idCoursePaymentMovement: values.idCoursePaymentMovement,
    authorizedBy: values.user,
    authorizationStatus: values.authorizationStatus,
    AuthorizationComment: values.authorizationComment,
});
