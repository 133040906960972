import * as yup from 'yup';
import {
    emailErrorMessage,
    maxCharMessage,
    minCharMessage,
    phoneErrorMessage,
    phoneRegExp,
    requiredMessage,
} from '../../../helpers/validationSchemaHelpers';

export const initialFormInfo = {
    title: '',
    startDate: '',
    endDate: '',
    allDay: false,
    clientId: null,
    deliveryService: null,
    notes: '',
    serviceId: null,
    trainers: [],
};

export const validationSchema = yup.object({
    title: yup
        .string()
        .min(3, minCharMessage)
        .max(50, maxCharMessage)
        .required(requiredMessage),
    startDate: yup.date().required(requiredMessage),
    endDate: yup.date().required(requiredMessage),
    notes: yup.string().max(250, maxCharMessage),
    // headerContacts: yup.object({
    //     client: yup.string().max(50, maxCharMessage).required(requiredMessage),
    //     contact: yup.string().max(50, maxCharMessage).required(requiredMessage),
    //     //internalContact: yup.string().max(50, maxCharMessage),
    // }),
    // contactClientInfo: yup.object({
    //     firstName: yup
    //         .string()
    //         .max(50, maxCharMessage)
    //         .required(requiredMessage),
    //     middleName: yup.string().max(50, maxCharMessage),
    //     lastName: yup
    //         .string()
    //         .max(50, maxCharMessage)
    //         .required(requiredMessage),
    //     officePhoneNumber: yup
    //         .string()
    //         .max(15, maxCharMessage)
    //         .matches(phoneRegExp, phoneErrorMessage),
    //     cellPhoneNumber: yup
    //         .string()
    //         .max(15, maxCharMessage)
    //         .matches(phoneRegExp, phoneErrorMessage),
    //     email: yup
    //         .string()
    //         .max(50, maxCharMessage)
    //         .email(emailErrorMessage)
    //         .required(requiredMessage),
    //     goesBy: yup.string().max(200, maxCharMessage).required(requiredMessage),
    //     contactNotes: yup.string().max(255, maxCharMessage),
    //     role: yup.string(),
    //     getInTouchWith: yup.string(),
    // }),
});
