import * as yup from 'yup';
import {
    maxCharMessage,
    requiredMessage,
    emailErrorMessage,
    phoneErrorMessage,
    phoneRegExp,
} from '../../helpers/validationSchemaHelpers';

export const initialValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    goesBy: '',
    contactNotes: '',
    contactRole: '',
    influencyId: '',
};

export const validationSchema = yup.object({
    firstName: yup.string().max(50, maxCharMessage).required(requiredMessage),
    middleName: yup.string().max(50, maxCharMessage),
    lastName: yup.string().max(50, maxCharMessage).required(requiredMessage),
    phoneNumber: yup
        .string()
        .max(15, maxCharMessage)
        .matches(phoneRegExp, phoneErrorMessage)
        .required(requiredMessage),
    mobileNumber: yup
        .string()
        .max(15, maxCharMessage)
        .matches(phoneRegExp, phoneErrorMessage)
        .required(requiredMessage),
    email: yup
        .string()
        .max(50, maxCharMessage)
        .email(emailErrorMessage)
        .required(requiredMessage),
    goesBy: yup.string().max(200, maxCharMessage).required(requiredMessage),
    contactNotes: yup
        .string()
        .max(255, maxCharMessage)
        .required(requiredMessage),
    contactRole: yup.string().max(50, maxCharMessage).required(requiredMessage),
    influencyId: yup.number().required(requiredMessage),
});
