import PageHeader from '../../../common/PageHeader/PageHeader';
import { Button } from '@material-ui/core';
import ExportButton from '../../../common/ExportButton/ExportButton';
import { Link } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { fetchUserPayments } from './userPaymentsSlice';
import PaymentTable from './paymentTable/PaymentTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';
import { useParams, useHistory } from 'react-router';
import { set } from 'lodash';
import { User } from '@auth0/auth0-react';
import PaymentTabs from './paymentTabs/PaymentTabs';
import {
    fetchPaymentStatus,
    fetchPaymentTypes,
} from '../../catalogs/catalogSlice';
import { LOADING_STATUS } from '../../../helpers/constants';
import { resetValues } from './userPaymentInfoSlice';

const useStyles = makeStyles(theme => ({
    downloadButton: {
        backgroundColor: green[600],
        color: 'white',
        '&:hover': {
            backgroundColor: green[800],
        },
    },
    topButton: {
        '&:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
}));

const csvHeaders = [
    { label: 'Materia', key: 'paymentName' },
    { label: 'Tipo', key: 'type' },
    { label: 'fecha de inicio', key: 'startDate' },
    { label: 'Fecha de termino', key: 'endDate' },
    { label: 'Costo', key: 'cost' },
    { label: 'Status', key: 'paymentStatus' },
];

const UserPayments = () => {
    const { id, userName } = useParams();
    const classes = useStyles();
    const [isData, setIsData] = useState(false);
    const [edit, setEdit] = useState(false);
    const [newRows, setNewRows] = useState([]);
    const rows = useSelector(state => state.userPayments.data.rows);
    const status = useSelector(state => state.userPayments.status);
    const validationStatus = useSelector(
        state => state.paymentInfo.insertValidation
    );
    const dispatch = useDispatch();
    console.log(validationStatus);
    useEffect(() => {
        if (id) {
            dispatch(fetchUserPayments(id));
            dispatch(fetchPaymentStatus());
            dispatch(fetchPaymentTypes());
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (validationStatus === LOADING_STATUS.SUCCEEDED) {
            dispatch(fetchUserPayments(id))
                .unwrap()
                .then(() => dispatch(resetValues()));
        }
    }, [dispatch, id, validationStatus]);

    const handleButtonClick = e => {
        e.preventDefault();
        if (!edit) {
            setEdit(true);
        }
    };

    const capitalLetter = string => {
        let lowStr = string.toLowerCase();
        let capStr = lowStr.charAt(0).toUpperCase() + lowStr.slice(1);
        return capStr;
    };

    useEffect(() => {
        if (newRows.length !== rows.length) setNewRows([...rows]);
    }, [rows, newRows, setNewRows]);

    return (
        <div>
            <PageHeader title={`Pagos de ${userName}`}>
                <Button
                    color="primary"
                    variant="contained"
                    type={edit ? 'submit' : 'button'}
                    onClick={!edit ? handleButtonClick : undefined}
                    disabled={edit && !isData}
                >
                    {edit ? 'Aplicar cambios' : 'Editar'}
                </Button>
            </PageHeader>
            {/* <PaymentFilters {...filters} /> */}
            <PaymentTabs
                rows={newRows}
                status={status}
                edit={edit}
                data={e => {
                    setIsData(true);
                    let modified = [...newRows];
                    modified[e[0]] = e[1];
                    setNewRows(modified);
                    console.log(e);
                }}
            />
        </div>
    );
};

export default UserPayments;
