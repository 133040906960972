export const assignedSubjectMapper = assignedSubjectsArray =>
    assignedSubjectsArray.map(subject => {
        return {
            id: subject.id,
            subjectName: subject?.name ?? '',
            modality: subject?.modality ?? '',
            link: subject?.link ?? null,
            subjectStatus: subject?.status === 1 ? 'Activo' : 'Inactivo',
            nextClass: {
                day: subject?.nextClass?.day ?? '',
                startTime: subject?.nextClass?.startTime ?? '',
                endTime: subject?.nextClass?.endTime ?? '',
                classroom: subject?.nextClass?.classroom ?? '',
            },
            schedule: subject.schedule.map(schedule => ({
                day: schedule?.day ?? '',
                startTime: schedule?.startTime ?? '',
                endTime: schedule?.endTime ?? '',
                classroom: schedule?.classroom ?? '',
            })),
        };
    });
