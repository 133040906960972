import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    createSubject,
    getSingleSubject,
    updateSubject,
    getUsersById,
    getSingleSubjectLanding,
    uploadSubjectDocuments,
} from '../../../api/subjects';
import {
    assignCourse,
    createCourse,
    createSchedule,
    updateCourse,
} from '../../../api/course';
import { LOADING_STATUS } from '../../../helpers/constants';
import { initialValues } from './mainForm';
import { subjectMapper } from './utils';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { createStudent } from '../../../api/user';

const initialState = {
    subject: initialValues,
    subjectAdded: false,
    fetchStatus: LOADING_STATUS.IDLE,
    insertSubject: LOADING_STATUS.IDLE,
    insertSubjectCourse: LOADING_STATUS.IDLE,
    insertDocument: LOADING_STATUS.IDLE,
    courseAssigned: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchSingleSubject = createAsyncThunk(
    'subjects/fetchSingleSubject',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getSingleSubject(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar la materia con el id ${id}.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchSubjectLanding = createAsyncThunk(
    'subjects/fetchSingleSubject',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getSingleSubjectLanding(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar la materia.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createSingleSubject = createAsyncThunk(
    'subjects/createSingleSubject',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createSubject(body);
            dispatch(
                enqueueSnackbar({
                    message: `Materia creada con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo crear la Materia, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createSubjectCourse = createAsyncThunk(
    'subjects/createSubjectCourse',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createCourse(body);
            dispatch(
                enqueueSnackbar({
                    message: `Curso creado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo crear el curso, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSubjectCourse = createAsyncThunk(
    'subjects/updateSubjectCourse',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateCourse(body);
            dispatch(
                enqueueSnackbar({
                    message: `Curso actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el curso, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleSubject = createAsyncThunk(
    'subjects/updateSingleSubject',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateSubject(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Materia actualizada con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar la materia, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchUserInfo = createAsyncThunk(
    'subjects/fetchUserInfo',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getUsersById(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar la informacion del contacto con el id ${id}.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createCourseSchedule = createAsyncThunk(
    'subjects/createSchedule',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createSchedule(body);
            dispatch(
                enqueueSnackbar({
                    message: `Horario creado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo crear el horario, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const uploadDocument = createAsyncThunk(
    'subjects/uploadDocument',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await uploadSubjectDocuments(body);
            dispatch(
                enqueueSnackbar({
                    message: `Documento subido con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo subir el documento, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const courseAssignment = createAsyncThunk(
    'subjects/courseAssignment',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await assignCourse(body);
            dispatch(
                enqueueSnackbar({
                    message: `Curso asignado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo asignar el curso, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const newStudent = createAsyncThunk(
    'subjects/courseAssignment',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createStudent(body);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo asignar el curso, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const singleSubjectSlice = createSlice({
    name: 'singleSubject',
    initialState,
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSingleSubject.pending, state => {
                state.fetchStatus = LOADING_STATUS.LOADING;
            })
            .addCase(
                fetchSingleSubject.fulfilled,
                (state, { payload: [subject] }) => {
                    state.fetchStatus = LOADING_STATUS.SUCCEEDED;
                    state.subject = subjectMapper(subject);
                }
            )
            .addCase(fetchSingleSubject.rejected, (state, { error }) => {
                state.fetchStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(createSingleSubject.pending, state => {
                state.insertSubject = LOADING_STATUS.LOADING;
            })
            .addCase(
                createSingleSubject.fulfilled,
                (state, { payload: newSubject }) => {
                    state.insertSubject = LOADING_STATUS.SUCCEEDED;
                    state.subjectAdded = Boolean(newSubject.subjectId);
                }
            )
            .addCase(createSingleSubject.rejected, (state, { error }) => {
                state.insertSubject = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(createSubjectCourse.pending, state => {
                state.insertSubjectCourse = LOADING_STATUS.LOADING;
            })
            .addCase(createSubjectCourse.fulfilled, state => {
                state.insertSubjectCourse = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(createSubjectCourse.rejected, (state, { error }) => {
                state.insertSubjectCourse = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSubjectCourse.pending, state => {
                state.insertSubjectCourse = LOADING_STATUS.LOADING;
            })
            .addCase(updateSubjectCourse.fulfilled, state => {
                state.insertSubjectCourse = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSubjectCourse.rejected, (state, { error }) => {
                state.insertSubjectCourse = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            // .addCase(updateSingleSubject.pending, state => {
            //     state.insertStatus = LOADING_STATUS.LOADING;
            // })
            // .addCase(updateSingleSubject.fulfilled, state => {
            //     state.insertStatus = LOADING_STATUS.SUCCEEDED;
            // })
            // .addCase(updateSingleSubject.rejected, (state, { error }) => {
            //     state.insertStatus = LOADING_STATUS.FAILED;
            //     state.error = error.message;
            // });
            .addCase(fetchUserInfo.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(fetchUserInfo.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(uploadDocument.pending, state => {
                state.insertDocument = LOADING_STATUS.LOADING;
            })
            .addCase(uploadDocument.fulfilled, state => {
                state.insertDocument = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(uploadDocument.rejected, (state, { error }) => {
                state.insertDocument = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(courseAssignment.pending, state => {
                state.courseAssigned = LOADING_STATUS.LOADING;
            })
            .addCase(courseAssignment.fulfilled, state => {
                state.courseAssigned = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(courseAssignment.rejected, (state, { error }) => {
                state.courseAssigned = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = singleSubjectSlice.actions;

export default singleSubjectSlice.reducer;
