import {
    Avatar,
    Button,
    CardMedia,
    Container,
    Grid,
    IconButton,
    MenuItem,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FastField, FieldArray, Form, Formik, getIn } from 'formik';
import { useEffect, useRef, useState } from 'react';
import {
    AddNewElement,
    LabelledCheckbox,
    OutlinedTextField,
} from '../../../common/FormComponents/FormComponents';
import PageHeader from '../../../common/PageHeader/PageHeader';
import DeleteIcon from '@material-ui/icons/Delete';
import { validationSchema } from './mainForm';
import { red } from '@material-ui/core/colors';
import UploadDialog from '../../../common/DocumentComponents/UploadDialog/UploadDialog';
import DocumentationList from '../../../common/DocumentComponents/DocumentationList/DocumentationList';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchUserInfo,
    updateSingleUser,
    resetValues,
    updateSingleAddress,
    updateSingleBankInfo,
    uploadEmployeePicture,
    createSingleUser,
    updateSingleEmployee,
} from './SingleUserSlice';
import { fetchAllCatalogs, fetchRoles } from '../../catalogs/catalogSlice';
import {
    formatUpdateBankInfo,
    formatUpdateAddressInfo,
    formatUpdateEmployeeInfo,
    formatUpdateUser,
    formatInsertImage,
} from './utils';
import { LOADING_STATUS } from '../../../helpers/constants';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { renderDateValue } from '../../../helpers/formHelpers';
import CardMediaComponent from '../../../common/cardMediaComponent/cardMediaComponent';

const useStyles = makeStyles(theme => ({
    arraySubtitle: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: 700,
        marginTop: theme.spacing(-1.5),
        marginBottom: theme.spacing(-1.5),
        '$formSubSection + $formSubSection &': {
            marginTop: theme.spacing(2),
        },
        '& > span': {
            marginRight: theme.spacing(1),
        },
    },
    deleteIcon: {
        color: red[400],
        marginTop: theme.spacing(0.2),
    },
    formSection: {
        backgroundColor: theme.palette.grey[50],
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(4),
        },
    },
    formSectionHeading: {
        color: theme.palette.primary.main,
        marginBottom: 0,
        marginTop: 0,
    },
    formSubSection: {},
    noData: {
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 1,
        textAlign: 'center',
    },
    subtitleWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
    uploadGrid: {
        marginBottom: theme.spacing(1),
    },
    container: {
        display: 'flex',
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        maxWidth: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    description: {
        whiteSpace: 'pre-wrap',
    },
}));

const SingleUser = ({ create = false }) => {
    const classes = useStyles();

    const prevSubmitCount = useRef(0);

    const [edit, setEdit] = useState(create);
    const [files, setFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [profileImage, setProfileImage] = useState('');
    const [deletableContent, setDeletableContent] = useState({
        contactInfo: [],
        fiscalInfo: [],
        address: [],
    });

    const { id } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();
    const userRole = useSelector(state => state.user.userData.role);
    const user = useSelector(state => state.singleUser.user);
    // const catalogs = useSelector(state => state.catalogs.catalogs);
    const insertStatus = useSelector(state => state.singleUser.insertStatus);
    const fetchStatus = useSelector(
        state => state.singleUser.fetchUserInfoStatus
    );
    const catalogs = useSelector(state => state.catalogs.catalogs);
    const catalogStatus = useSelector(state => state.catalogs.catalogs.status);

    const roles = catalogs?.roles
        ?.map(role => {
            if (role.text === 'Administrador' || role.text === 'Entrenador') {
                return role;
            } else {
                return null;
            }
        })
        .filter(item => item !== null);

    const loading =
        fetchStatus === LOADING_STATUS.LOADING ||
        catalogStatus === LOADING_STATUS.LOADING;

    const insertingUser = insertStatus === LOADING_STATUS.LOADING;

    useEffect(() => {
        dispatch(fetchRoles());
        return () => dispatch(resetValues());
    }, [dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(fetchUserInfo(id)).then(({ payload }) =>
                setProfileImage(payload.image)
            );
        }
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(fetchAllCatalogs());
    }, [dispatch]);

    // useEffect(() => {
    //     if (fetchStatus === LOADING_STATUS.SUCCEEDED) {
    //         setFiles(user.documents);
    //     }
    // }, [user.documents, fetchStatus]);

    useEffect(() => {
        if (insertStatus === LOADING_STATUS.SUCCEEDED) {
            history.push('/users');
        }
    }, [dispatch, history, insertStatus]);

    const handleFileChange = file => {
        setFiles(prev => [...prev, file]);
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleButtonClick = e => {
        e.preventDefault();
        if (!edit) {
            setEdit(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteDoc = fileName => {
        setFiles(prev => prev.filter(file => file.fileName !== fileName));
    };

    //form data init

    // function buildFormData(formData, data, parentKey) {
    //     if (data && typeof data === 'object') {
    //         Object.keys(data).forEach(key => {
    //             buildFormData(
    //                 formData,
    //                 data[key],
    //                 parentKey ? `${parentKey}[${key}]` : key
    //             );
    //         });
    //     } else {
    //         const value = data == null ? '' : data;

    //         formData.append(parentKey, value);
    //     }
    // }

    // function jsonToFormData(data) {
    //     const formData = new FormData();

    //     buildFormData(formData, data);

    //     return formData;
    // }

    // function imageToFormData(data) {
    //     const formData = new FormData();
    //     buildFormData(formData, data);
    //     formData.append('Image', data);
    //     return formData;
    // }

    // const handleFileChange = file => {
    //     const imageFile = formatInsertImage(file);
    //     let body = jsonToFormData(objectFile);
    //     dispatch(uploadPayment(body))
    //         .unwrap()
    //         .then(() => {
    //             handleClose();
    //         });
    // };

    // form data end

    const handleSubmit = values => {
        console.log(values.userInfo.bankInfo === user.userInfo.bankInfo);
        // const formattedGeneralInfo = formatUpdateData(values);
        console.log(profileImage);
        if (id) {
            if (profileImage !== user.userInfo.employee.image) {
                const formData = new FormData();
                formData.append(
                    'image',
                    new File([profileImage], `user_${id}.jpg`)
                );
                dispatch(
                    uploadEmployeePicture({
                        id,
                        body: formData,
                    })
                );
            }
            if (
                values.userInfo.email !== user.userInfo.email ||
                values.userInfo.role !== user.userInfo.role ||
                values.userInfo.userStatus !== user.userInfo.userStatus
            ) {
                let formattedUser = formatUpdateUser(values);
                dispatch(
                    updateSingleUser({
                        id: values.userInfo.userId,
                        body: formattedUser,
                    })
                );
            }
            if (values.userInfo.employee !== user.userInfo.employee) {
                let formattedEmployee = formatUpdateEmployeeInfo(values);
                dispatch(
                    updateSingleEmployee({
                        id: id,
                        body: formattedEmployee,
                    })
                );
            }
            if (values.userInfo.address !== user.userInfo.address) {
                let formattedAddress = formatUpdateAddressInfo(values);
                dispatch(
                    updateSingleAddress({
                        id: values.userInfo.address.addressId,
                        body: formattedAddress,
                    })
                );
            }
            if (values.userInfo.bankInfo !== user.userInfo.bankInfo) {
                let formattedBankInfo = formatUpdateBankInfo(values);
                dispatch(
                    updateSingleBankInfo({
                        id: values.userInfo.bankInfo.bankInfoId,
                        body: formattedBankInfo,
                    })
                );
            } else {
                setEdit(false);
            }
        } else {
            let body = {
                ...formatUpdateEmployeeInfo(values),
                user: {
                    password: values.userInfo.email,
                    ...formatUpdateUser(values),
                    address: {
                        ...formatUpdateAddressInfo(values),
                    },
                },
                account: {
                    ...formatUpdateBankInfo(values),
                },
            };
            dispatch(createSingleUser(body)).then(e => {
                const formData = new FormData();
                formData.append(
                    'image',
                    new File([profileImage], `user_${e.payload.id}.jpg`)
                );
                dispatch(
                    uploadEmployeePicture({
                        id: e.payload.id,
                        body: formData,
                    })
                );
            });
        }
    };

    const handleFieldUpdate = (nextProps, currentProps) => {
        if (
            nextProps.edit !== currentProps.edit ||
            nextProps.disabled !== currentProps.disabled ||
            nextProps.loading !== currentProps.loading ||
            nextProps.catalog !== currentProps.catalog ||
            nextProps.name !== currentProps.name ||
            getIn(nextProps.formik.values, currentProps.name) !==
                getIn(currentProps.formik.values, currentProps.name) ||
            getIn(nextProps.formik.errors, currentProps.name) !==
                getIn(currentProps.formik.errors, currentProps.name) ||
            getIn(nextProps.formik.touched, currentProps.name) !==
                getIn(currentProps.formik.touched, currentProps.name) ||
            Object.keys(currentProps).length !==
                Object.keys(nextProps).length ||
            nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <main>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={user}
                onSubmit={handleSubmit}
            >
                {props => {
                    const { errors, submitCount, values } = props;
                    if (submitCount !== prevSubmitCount.current) {
                        prevSubmitCount.current = submitCount;
                        if (Object.keys(errors).length > 0) {
                            console.log(errors);
                            dispatch(
                                enqueueSnackbar({
                                    message:
                                        'Por favor llene todos los campos correctamente.',
                                    options: {
                                        key:
                                            new Date().getTime() +
                                            Math.random(),
                                        variant: 'error',
                                    },
                                })
                            );
                        }
                    }
                    return (
                        <Form>
                            <PageHeader
                                title={
                                    create
                                        ? 'Nuevo usuario'
                                        : edit
                                        ? 'Editar usuario'
                                        : 'Consulta de usuario'
                                }
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type={edit ? 'submit' : 'button'}
                                    onClick={
                                        !edit ? handleButtonClick : undefined
                                    }
                                    disabled={loading || insertingUser}
                                >
                                    {create
                                        ? 'Crear'
                                        : edit
                                        ? 'Aplicar cambios'
                                        : 'Editar'}
                                </Button>
                            </PageHeader>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Información General
                                    </h2>
                                </div>
                                <Container className={classes.container}>
                                    <CardMediaComponent
                                        edit={edit}
                                        handleFileChange={e => {
                                            setProfileImage(e);
                                        }}
                                        onClick={e => null}
                                        title={values.userInfo.employee.name}
                                        loading={loading}
                                        image={
                                            profileImage === null
                                                ? ''
                                                : profileImage
                                        }
                                        alt={'imagen de usuario'}
                                    />
                                    <Grid container spacing={3}>
                                        <FastField
                                            component={OutlinedTextField}
                                            name="userInfo.employee.name"
                                            type="text"
                                            label="Nombre completo*"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingUser}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name="userInfo.employee.nickname"
                                            type="text"
                                            label="Apodo"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingUser}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name="userInfo.employee.curp"
                                            type="text"
                                            label="CURP*"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingUser}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name="userInfo.employee.birthday"
                                            type="date"
                                            label="Fecha de nacimiento"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            renderValue={renderDateValue}
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingUser}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name="userInfo.employee.nss"
                                            type="number"
                                            label="Numero de seguro social"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingUser}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            select
                                            name="userInfo.role"
                                            type="text"
                                            label="Rol*"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingUser}
                                            shouldUpdate={handleFieldUpdate}
                                            catalog={roles}
                                        >
                                            <MenuItem value="">&nbsp;</MenuItem>
                                            {roles?.length > 0 &&
                                                roles.map(({ id, text }) => (
                                                    <MenuItem
                                                        value={id}
                                                        key={id}
                                                    >
                                                        {text}
                                                    </MenuItem>
                                                ))}
                                        </FastField>
                                        <FastField
                                            component={OutlinedTextField}
                                            select
                                            name="userInfo.userStatus"
                                            type="text"
                                            label="Estatus*"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingUser}
                                            shouldUpdate={handleFieldUpdate}
                                            catalog={catalogs.user_status}
                                        >
                                            <MenuItem value="">&nbsp;</MenuItem>
                                            {catalogs.user_status?.length > 0 &&
                                                catalogs.user_status.map(
                                                    ({ id, text }) => (
                                                        <MenuItem
                                                            value={id}
                                                            key={id}
                                                        >
                                                            {text}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </FastField>
                                        <FastField
                                            component={OutlinedTextField}
                                            name="userInfo.employee.telephone1"
                                            type="number"
                                            label="Teléfono"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingUser}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name="userInfo.email"
                                            type="text"
                                            label="Email*"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingUser}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            multiline
                                            maxRows={4}
                                            className={classes.description}
                                            name="userInfo.employee.description"
                                            type="text"
                                            label="Descripción"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingUser}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                    </Grid>
                                </Container>
                            </section>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Datos Demográficos
                                    </h2>
                                </div>
                                <Grid container spacing={3}>
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.address.address"
                                        type="text"
                                        label="Dirección*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingUser}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.address.city"
                                        type="text"
                                        label="Ciudad*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingUser}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.address.state"
                                        type="text"
                                        label="Estado*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingUser}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.address.suburb"
                                        type="text"
                                        label="Colonia"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingUser}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.address.cp"
                                        type="text"
                                        label="Código postal*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingUser}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                </Grid>
                            </section>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Información Bancaria
                                    </h2>
                                </div>
                                <Grid container spacing={3}>
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.bankInfo.bank"
                                        type="text"
                                        label="Banco*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingUser}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.bankInfo.accountNumber"
                                        type="number"
                                        label="Numero de cuenta"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingUser}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.bankInfo.interbankKey"
                                        type="number"
                                        label="CLABE interbancaria*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingUser}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.bankInfo.cardNumber"
                                        type="number"
                                        label="Numero de tarjeta"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingUser}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                </Grid>
                            </section>

                            {/* <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Documentación
                                    </h2>
                                    {edit && (
                                        <AddNewElement
                                            disabled={loading || insertingUser}
                                            onAddElement={handleOpen}
                                        >
                                            Cargar archivo
                                        </AddNewElement>
                                    )}
                                </div>
                                {files.length > 0 ? (
                                    <DocumentationList
                                        className={classes.uploadGrid}
                                        documentCatalog={catalogs.documenttype}
                                        files={files}
                                        onDeleteDoc={handleDeleteDoc}
                                        edit={edit}
                                    />
                                ) : (
                                    <Typography className={classes.noData}>
                                        Aún no hay documentos cargados.
                                    </Typography>
                                )}
                                <UploadDialog
                                    documentCatalog={catalogs.documenttype}
                                    files={files}
                                    handleClose={handleClose}
                                    handleFileChange={handleFileChange}
                                    open={open}
                                />
                            </section> */}
                            {/* <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Pagos
                                    </h2>
                                    {edit && (
                                        <AddNewElement
                                            disabled={loading || insertingUser}
                                            onAddElement={handleOpen}
                                        >
                                            Cargar archivo
                                        </AddNewElement>
                                    )}
                                </div>
                                <PaymentTable status={status} rows={rows} edit={edit}/>
                            </section> */}
                        </Form>
                    );
                }}
            </Formik>
        </main>
    );
};

export default SingleUser;
