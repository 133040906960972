import axios from 'axios';
import { axiosConfig, axiosConfigForm } from './axiosConfig';

export const getPayments = async id => {
    const { data } = await axios.get(
        `courses/GetStudentCourses/${id}`,
        axiosConfig('api')
    );
    return data;
};

export const getSubjectPayments = async id => {
    const { data } = await axios.get(
        `CoursePaymentMovements/GetCoursePaymentsMovements/${id}`,
        axiosConfig('api')
    );
    return data;
};

export const createPaymentsMovements = async id => {
    const { data } = await axios.post(
        `CoursePaymentMovements/PostCoursePaymentsMovements/${id}`,
        axiosConfig('api')
    );
    return data;
};

export const uploadPaymentDocument = async body => {
    const { data } = await axios({
        method: 'post',
        url: axiosConfigForm('api/payments/UploadFile'),
        data: body,
        headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data;
};

export const getStudents = async () => {
    const { data } = await axios.get(
        `payments/GetUsersPaymentsSimpleList`,
        axiosConfig('api')
    );
    return data;
};

export const getUserPayments = async id => {
    const { data } = await axios.get(
        `courses/GetStudentCoursesPayments/${id}`,
        axiosConfig('api')
    );
    return data;
};

export const getPaymentInfo = async id => {
    const { data } = await axios.get(
        `payments/GetPaymentInfo/${id}`,
        axiosConfig('api')
    );
    return data;
};

export const updatePaymentStatus = async body => {
    const { data } = await axios.post(
        `payments/UpdatePaymentStatus`,
        body,
        axiosConfig('api')
    );
    return data;
};
