import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSingleContact } from '../../api/clients';
import { LOADING_STATUS } from '../../helpers/constants';
import { initialValues } from './contactForm';
import { enqueueSnackbar } from '../pushNotifications/pushNotificationsSlice';

const initialState = {
    contact: initialValues,
    contactAdded: false,
    fetchStatus: LOADING_STATUS.IDLE,
    insertStatus: LOADING_STATUS.IDLE,
    error: null,
};

export const createClientContact = createAsyncThunk(
    'contacts/createClientContact',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createSingleContact(body);
            dispatch(
                enqueueSnackbar({
                    message: `Contacto creado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo crear el contacto, intente mas tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const clientContactSlice = createSlice({
    name: 'clientContact',
    initialState,
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(createClientContact.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(
                createClientContact.fulfilled,
                (state, { payload: newOpportunity }) => {
                    state.insertStatus = LOADING_STATUS.SUCCEEDED;
                    state.opportunityAdded = Boolean(
                        newOpportunity.opportunityId
                    );
                }
            )
            .addCase(createClientContact.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = clientContactSlice.actions;

export default clientContactSlice.reducer;
