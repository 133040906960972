import PageHeader from '../../../common/PageHeader/PageHeader';
import { Button } from '@material-ui/core';
import ExportButton from '../../../common/ExportButton/ExportButton';
import { Link } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { fetchSubjects } from './subjectsSlice';
import SubjectFilters from './subjectFilters/SubjectFilters';
import SubjectTable from './subjectTable/SubjectTable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';
import {
    fetchAllCatalogs,
    fetchAppointmentsCatalog,
    fetchAppointmentsStatusCatalog,
    fetchSubjectTypes,
} from '../../catalogs/catalogSlice';
import SchedulerComponent from '../../Scheduler/SchedulerComponent';
import Demo from '../../Scheduler/SchedulerRedux';
import { fetchAppointments } from '../../Scheduler/schedulerSlice';
import { LOADING_STATUS } from '../../../helpers/constants';
import ReduxScheduler from '../../Scheduler/SchedulerRedux';

const useStyles = makeStyles(theme => ({
    downloadButton: {
        backgroundColor: green[600],
        color: 'white',
        '&:hover': {
            backgroundColor: green[800],
        },
    },
    topButton: {
        '&:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
}));

const csvHeaders = [
    { label: 'Materia', key: 'subjectName' },
    { label: 'Tipo', key: 'type' },
    { label: 'fecha de inicio', key: 'startDate' },
    { label: 'Fecha de termino', key: 'endDate' },
    { label: 'Costo', key: 'cost' },
    { label: 'Status', key: 'subjectStatus' },
];

const Subjects = () => {
    const classes = useStyles();
    const filters = useSelector(state => state.appointments.data.filters);
    // const rows = useSelector(state => state.subjects.data.rows);
    const rows = useSelector(state => state.appointments.data.rows);
    const catalogs = useSelector(state => state.catalogs.catalogs);
    const status = useSelector(state => state.appointments.status);
    const insertStatus = useSelector(state => state.appointments.insertStatus);
    const role = useSelector(state => state.user.userData.userInfo.role);
    const subjectType = useSelector(
        state => state.catalogs.catalogs.subjectType
    );

    const dispatch = useDispatch();

    const filtersActive = Object.values(filters).some(
        ({ selected }) => selected
    );

    const filteredRows = ((filtersActive, rows) => {
        if (!filtersActive) return rows;
        let newRows = [];
        // if (filters.subjectNameOptions.selected) {
        //     const filterItems = query => {
        //         return rows.filter(
        //             row =>
        //                 row.subjectName
        //                     .toLowerCase()
        //                     .indexOf(query.toLowerCase()) > -1
        //         );
        //     };

        //     newRows = filterItems(filters.subjectNameOptions.selected);
        // }
        if (filters.nicknameFilter.selected) {
            newRows = (newRows.length > 0 ? newRows : rows).filter(
                row =>
                    row.trainers &&
                    row.trainers.some(
                        trainer =>
                            trainer.nickname === filters.nicknameFilter.selected
                    )
            );
        }
        if (filters.clientFilter.selected) {
            newRows = (newRows.length > 0 ? newRows : rows).filter(
                row => row.clientName === filters.clientFilter.selected
            );
        }

        return newRows;
    })(filtersActive, rows);

    console.log(filteredRows, rows);

    useEffect(() => {
        if (insertStatus === LOADING_STATUS.SUCCEEDED) {
            dispatch(fetchAppointments());
        }
    }, [dispatch, insertStatus]);

    useEffect(() => {
        dispatch(fetchAppointmentsCatalog()).then(() =>
            dispatch(fetchAppointments()).then(() =>
                dispatch(fetchAppointmentsStatusCatalog())
            )
        );
    }, [dispatch]);

    return (
        <div>
            {/* <PageHeader title="Materias">
                {userRole === 'Administrador' && (
                    <>
                        <Button
                            className={classes.topButton}
                            color="primary"
                            component={Link}
                            to="/subjects/create"
                            variant="contained"
                        >
                            Adicionar
                        </Button>
                        <ExportButton
                            headers={csvHeaders}
                            inputData={filteredRows}
                            fileName="Materias.csv"
                            className={`${classes.topButton} ${classes.downloadButton}`}
                        >
                            Exportar a Excel
                        </ExportButton>
                    </>
                )}
            </PageHeader> */}
            {(role === 'Developer' || role === 'Administrador') && (
                <SubjectFilters {...filters} />
            )}

            {status === LOADING_STATUS.SUCCEEDED && (
                <Demo data={filteredRows} catalogs={catalogs} />
            )}
            {/* <SubjectTable
                status={status}
                rows={filteredRows}
                subjectType={subjectType}
            /> */}
        </div>
    );
};

export default Subjects;
