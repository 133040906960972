import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import img1 from '../../assets/images/img1.jpg';
import img2 from '../../assets/images/img2.jpg';
import img3 from '../../assets/images/img3.jpg';
import img4 from '../../assets/images/img4.jpg';
import Alert from '@material-ui/lab/Alert';
import logo from '../../assets/images/holistica_logo.png';
import { Link } from 'react-router-dom';

//Importaciones para la top bar
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';

//menus
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import {
    fetchAllCatalogs,
    fetchMenusForHome,
} from '../../features/catalogs/catalogSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LOADING_STATUS } from '../../helpers/constants';
import { CancelPresentationOutlined } from '@material-ui/icons';

//styles menu
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const imgRandom = () => {
    let imgOptions = [img1, img2, img3, img4];
    let numRandom = Math.floor(Math.random() * 3);
    return imgOptions[numRandom];
};

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {`Copyright © Aptude México ${new Date().getFullYear()}.`}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    rootTopBar: {
        backgroundColor: 'white !important',
        color: '#181818',
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    root: {
        flexGrow: 1,
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    image: {
        backgroundImage: `url(${imgRandom()})`,
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    login: {
        backgroundColor: 'rgba(255,255,255,0.6)',
    },
    menus: {
        display: 'flex',
        marginRight: '30px',
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    logo: {
        height: '50px',
        margin: '0 10px 0 10px',
    },
    item: {
        textDecoration: 'none',
        color: 'rgb(0,0,0)',
        margin: 0,
        padding: 0,
        width: '100%',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            marginRight: '20px',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    aButton: {
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'none',
    },
    aButtonLink: {
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'none',
        fontWeight: 'bold',
    },
    forScroll: {
        width: '10px',
        flexGrow: 1,
    },
}));

export default function TopBarHome() {
    const [menuActive, setMenuActive] = React.useState(null);
    const catalogs = useSelector(state => state.catalogs.catalogs);
    const catalogStatus = useSelector(state => state.catalogs.status);
    const dispatch = useDispatch();

    const handleClose = () => {
        setMenuActive(null);
    };

    const { loginWithRedirect, error, logout } = useAuth0();
    const classes = useStyles();

    // Create state
    const [form, setForm] = useState({
        email: '',
        password: '',
        rememberPassword: false,
    });

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleRememberMeChange = e => {
        setForm({
            ...form,
            rememberPassword: !form.rememberPassword,
        });
    };

    if (error) {
        logout();
        alert(`${error.message}`);
    }

    useEffect(() => {
        dispatch(fetchMenusForHome());
    }, [dispatch]);

    return (
        <>
            <div className={classes.body}>
                <div className={classes.root}>
                    <AppBar className={classes.rootTopBar} position="fixed">
                        <Toolbar>
                            <div className={classes.sectionMobile}>
                                <IconButton
                                    edge="start"
                                    className={classes.menuButton}
                                    color="inherit"
                                    aria-label="menu"
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                            <Link to={'/'}>
                                <img
                                    src={logo}
                                    alt=""
                                    className={classes.logo}
                                />
                            </Link>
                            <div className={classes.title}>
                                <Typography variant="h5">
                                    Universidad Holística
                                </Typography>
                                <Typography variant="h7">
                                    Campus Tijuana y Hermosillo
                                </Typography>
                            </div>
                            <div className={classes.sectionDesktop}>
                                <Button color="inherit">
                                    <Link
                                        className={classes.aButton}
                                        to={`/cu`}
                                        onClick={handleClose}
                                    >
                                        Inicio
                                    </Link>
                                </Button>
                                {catalogStatus === LOADING_STATUS.SUCCEEDED &&
                                    catalogs.menusForHome.map(menu =>
                                        menu.options.length > 0 ? (
                                            <>
                                                <Button
                                                    aria-controls="customized-menu"
                                                    aria-haspopup="true"
                                                    color="inherit"
                                                    onClick={e => {
                                                        setMenuActive({
                                                            text: menu.text,
                                                            state:
                                                                e.currentTarget,
                                                        });
                                                    }}
                                                >
                                                    {menu.text}
                                                </Button>

                                                <StyledMenu
                                                    id="customized-menu"
                                                    anchorEl={
                                                        menu.text ===
                                                        menuActive?.text
                                                            ? menuActive.state
                                                            : null
                                                    }
                                                    keepMounted
                                                    open={
                                                        menu.text ===
                                                        menuActive?.text
                                                            ? menuActive.state
                                                            : null
                                                    }
                                                    onClose={handleClose}
                                                >
                                                    {menu.options.map(
                                                        option => (
                                                            <StyledMenuItem>
                                                                <Link
                                                                    className={
                                                                        classes.item
                                                                    }
                                                                    to={`/cu/${option.text}/${option.id}`}
                                                                    onClick={
                                                                        handleClose
                                                                    }
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            option.text
                                                                        }
                                                                    />
                                                                </Link>
                                                            </StyledMenuItem>
                                                        )
                                                    )}
                                                </StyledMenu>
                                            </>
                                        ) : null
                                    )}
                                <Button color="inherit">
                                    <Link
                                        className={classes.aButton}
                                        to={`/cu/terapias`}
                                        onClick={handleClose}
                                    >
                                        Terapias
                                    </Link>
                                </Button>
                                <Button color="inherit">
                                    <Link
                                        className={classes.aButton}
                                        to={`/cu/universidad`}
                                        onClick={handleClose}
                                    >
                                        Universidad
                                    </Link>
                                </Button>
                            </div>
                            <div className={classes.sectionDesktop}>
                                <Button
                                    color="inherit"
                                    variant="contained"
                                    onClick={() => loginWithRedirect()}
                                >
                                    Iniciar sesión
                                </Button>
                            </div>
                            <div className={classes.sectionMobile}>
                                <Button
                                    color="inherit"
                                    variant="contained"
                                    onClick={() => loginWithRedirect()}
                                >
                                    <PersonIcon />
                                </Button>
                            </div>
                        </Toolbar>
                    </AppBar>
                </div>
            </div>
        </>
    );
}
