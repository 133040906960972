import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getPaymentInfo,
    getUserPayments,
    updatePaymentStatus,
} from '../../../api/payments';
import { LOADING_STATUS } from '../../../helpers/constants';
import { formatPaymentInfo, formatUserPayments } from './utils';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';

const initialState = {
    data: {
        paymentInfo: {
            idPayment: null,
            idCourseAssignment: null,
            idCoursePaymentMovement: null,
            idPaymentType: null,
            amountPaid: null,
            expiredDate: null,
            paymentDate: null,
            reference: null,
            file: null,
            base64File: null,
            authorizedName: null,
            authorizedBy: null,
            authorizedAt: null,
            authorizationStatus: null,
            authorizationComment: null,
            status: null,
        },
    },
    insertValidation: LOADING_STATUS.IDLE,
    status: LOADING_STATUS.IDLE,
    error: null,
};
export const fetchPaymentInfo = createAsyncThunk(
    'SubjectPayments/fetchPaymentInfo',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getPaymentInfo(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar la información del pago.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updatePaymentInfo = createAsyncThunk(
    'SubjectPayments/updatePaymentInfo',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await updatePaymentStatus(body);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el status del pago.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const paymentInfoSlice = createSlice({
    name: 'paymentInfo',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchPaymentInfo.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchPaymentInfo.fulfilled, (state, { payload }) => {
                const cleanPayment = formatPaymentInfo(payload);

                state.status = LOADING_STATUS.SUCCEEDED;
                state.data.paymentInfo = cleanPayment;
            })
            .addCase(fetchPaymentInfo.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updatePaymentInfo.pending, state => {
                state.insertValidation = LOADING_STATUS.LOADING;
            })
            .addCase(updatePaymentInfo.fulfilled, state => {
                state.insertValidation = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updatePaymentInfo.rejected, (state, { error }) => {
                state.insertValidation = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = paymentInfoSlice.actions;

export default paymentInfoSlice.reducer;
