import * as yup from 'yup';
import {
    maxCharMessage,
    minCharMessage,
    requiredMessage,
    onlyLetters,
    onlyNumbers,
    onlyLettersMessage,
} from '../../../helpers/validationSchemaHelpers';

export const initialValues = {
    carouselInfo: {
        name: '',
        image: '',
        description: '',
        expirationDate: '',
        link: '',
    },
};

export const validationSchema = yup.object({
    carouselInfo: yup.object({
        name: yup
            .string()
            .matches(onlyLetters, onlyLettersMessage)
            .max(50, maxCharMessage),
        description: yup
            .string()
            .min(100, minCharMessage)
            .max(500, maxCharMessage),
        expirationDate: yup
            .date()
            .min(new Date(), 'la fecha no puede ser antes a la actual'),
        link: yup.string(),
    }),
});
