import { configureStore } from '@reduxjs/toolkit';
import catalogsReducer from '../features/catalogs/catalogSlice';
import dropdownReducer from '../features/subjects/singleSubject/DropdownTeachers/dropdownSlice';
import checklistReducer from '../features/Scheduler/form/checklist/checklistSlice';
import userReducer from '../auth/authSlice';
import profileReducer from '../features/profile/profileSlice';
import pushNotificationsReducer from '../features/pushNotifications/pushNotificationsSlice';
import clientContactReducer from '../features/clientContact/clientContactSlice';
import usersReducer from '../features/users/main/UsersSlice';
import singleUserReducer from '../features/users/singleUser/SingleUserSlice';
import appointmentsReducer from '../features/Scheduler/schedulerSlice';
import subjectsReducer from '../features/subjects/main/subjectsSlice';
import singleSubjectReducer from '../features/subjects/singleSubject/singleSubjectSlice';
import paymentsReducer from '../features/paymentUser/main/paymentsSlice';
import subjectPaymentsReducer from '../features/paymentUser/subjectPayment/subjectPaymentsSlice';
import adminPaymentsReducer from '../features/paymentAdmin/main/paymentsAdminSlice';
import userPaymentsReducer from '../features/paymentAdmin/userPayment/userPaymentsSlice';
import paymentInfoReducer from '../features/paymentAdmin/userPayment/userPaymentInfoSlice';
import registerReducer from '../features/register/registerSlice';
import requestInfoFormReducer from '../features/login/requestInfoForm/requestInfoFormSlice';
import contactUsReducer from '../features/blogPage/contactUs/contactUsSlice';
import clientsReducer from '../features/clients/mainPage/clientsSlice';
import singleClientReducer from '../features/clients/singleClient/singleClientSlice';
import servicesReducer from '../features/services/main/ServicesSlice';
import singleServiceReducer from '../features/services/singleService/SingleServiceSlice';
import carouselReducer from '../features/carousel/main/CarouselSlice';
import singleCarouselReducer from '../features/carousel/singleData/SingleCarouselSlice';
import videosReducer from '../features/videos/main/VideosSlice';
import singleVideoReducer from '../features/videos/singleVideo/SingleVideoSlice';

export const store = configureStore({
    reducer: {
        clients: clientsReducer,
        checklist: checklistReducer,
        appointments: appointmentsReducer,
        singleClient: singleClientReducer,
        catalogs: catalogsReducer,
        dropdown: dropdownReducer,
        user: userReducer,
        services: servicesReducer,
        singleService: singleServiceReducer,
        register: registerReducer,
        users: usersReducer,
        singleUser: singleUserReducer,
        profile: profileReducer,
        pushNotifications: pushNotificationsReducer,
        clientContact: clientContactReducer,
        subjects: subjectsReducer,
        singleSubject: singleSubjectReducer,
        payments: paymentsReducer,
        subjectPayments: subjectPaymentsReducer,
        adminPayments: adminPaymentsReducer,
        userPayments: userPaymentsReducer,
        paymentInfo: paymentInfoReducer,
        requestForm: requestInfoFormReducer,
        contactMail: contactUsReducer,
        carousel: carouselReducer,
        singleCarousel: singleCarouselReducer,
        videos: videosReducer,
        singleVideo: singleVideoReducer,
    },
});
