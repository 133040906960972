export const formatCourses = coursesArray =>
    coursesArray.map(course => {
        return {
            id: course.id_materia,
            name: course.nombre,
            coursesStatus:
                course?.estatus === 1
                    ? 'Activo'
                    : course?.estatus === 2
                    ? 'Inactivo'
                    : 'Desconocido',
        };
    });

export const getFilterData = courses => {
    const coursesOptions = {
        selected: '',
        options: Array.from(
            courses.reduce(
                (acc, cur) => (cur.name ? acc.add(cur.name) : acc),
                new Set()
            )
        ),
    };

    const coursesStatusOptions = {
        selected: '',
        options: Array.from(
            courses.reduce(
                (acc, cur) =>
                    cur.coursesStatus ? acc.add(cur.coursesStatus) : acc,
                new Set()
            )
        ),
    };

    return {
        coursesOptions,
        coursesStatusOptions,
    };
};
