import * as yup from 'yup';
import {
    maxCharMessage,
    minCharMessage,
    requiredMessage,
    onlyLetters,
    onlyNumbers,
    onlyLettersMessage,
} from '../../../helpers/validationSchemaHelpers';

export const initialVideoInfo = {
    title: '',
    image: '',
    video: '',
};

export const initialValues = {
    videoInfo: {
        ...initialVideoInfo,
    },
};

export const validationSchema = yup.object({
    videoInfo: yup.object({
        title: yup
            .string()
            .matches(onlyLetters, onlyLettersMessage)
            .max(50, maxCharMessage),
    }),
});
