export const userMapper = user => {
    return {
        userInfo: {
            id: user.id,
            email: user.email ?? '',
            role: user?.role?.text ?? 'Desconocido',
            userStatus: user?.userStatus ?? '',
            image: user.employee?.image ?? null,
            name:
                user.employee?.fullName ?? user.client?.fullName ?? user.email,
            phoneNumber:
                user.employee?.phoneNumber ?? user.client?.phoneNumber ?? null,
            birthday: user.employee?.birthday ?? user.client?.birthday ?? null,
        },
        employee: {
            employeeId: user?.employee?.id ?? '',
            nickname: user?.employee?.nickname ?? '',
        },
        client: {
            clientId: user.client?.id ?? null,
            paidClasses: user.client?.paidClasses ?? null,
        },
        trainers: user.client?.trainers.map(trainer => ({
            id: trainer?.id,
            image: trainer?.image,
            nickname: trainer?.nickname,
            fullName: trainer?.fullName,
            description: trainer?.description,
            phoneNumber: trainer?.phoneNumber,
        })),
        pets: user.client?.pets.map(pet => ({
            id: pet?.id,
            image: pet?.image ?? '',
            name: pet?.name ?? '',
            breed: pet?.breed ?? '',
            gender: pet?.gender?.text ?? '',
            ethogram: {
                id: pet?.id,
                birthday: pet?.birthday?.slice(0, 10) ?? '',
                adoptionDate: pet?.adoptionDate?.slice(0, 10) ?? '',
                living: pet?.living ?? '',
                socialization: pet?.socialization ?? '',
                weeklyWalks: pet?.weeklyWalks ?? '',
                feeding: pet?.feeding ?? '',
                vaccinationComplete: pet?.vaccinationComplete ?? false,
                diseases: pet?.diseases ?? '',
                targets: pet?.target ?? '',
                adoption: pet?.adoption?.text ?? '',
            },
        })),
        address: {
            address: user?.address?.address ?? '',
            city: user?.address?.city ?? '',
            state: user?.address?.state ?? '',
            suburb: user?.address?.suburb ?? '',
            cp: user?.address?.postalCode ?? '',
        },
        appointments: user.client?.appointments.map(appointment => ({
            id: appointment?.id,
            title: appointment?.title ?? '',
            notes: appointment?.notes ?? '',
            startDate: appointment?.startDate ?? '',
            endDate: appointment?.endDate ?? '',
            appointmentStatus: appointment?.appointmentStatus?.text ?? '',
            individualClass: {
                id: appointment?.individualClass?.id,
                description: appointment?.individualClass?.description ?? '',
                trainerRating:
                    appointment?.individualClass?.trainerRating ?? '',
            },
            trainers: appointment?.trainers?.map(trainer => ({
                fullName: trainer?.fullName ?? '',
                image: trainer?.image ?? '',
            })),
        })),
    };
};
