export const formatAppointments = appointmentsArray =>
    appointmentsArray.map(appointment => {
        return {
            id: appointment.id,
            title: appointment?.title ?? '',
            startDate: appointment?.startDate ?? '',
            endDate: appointment?.endDate ?? '',
            clientId: appointment?.clientId ?? null,
            clientName: appointment?.client?.fullName ?? null,
            mapLink: appointment.client?.user?.address?.mapLink,
            address: appointment.client?.user?.address?.address,
            appointmentStatusId: appointment?.appointmentStatusId,
            trainerReview: appointment?.individualClass?.description ?? '',
            trainerRating: appointment?.individualClass?.trainerRating ?? null,
            trainers: appointment?.trainers.map(trainer => ({
                nickname: trainer.nickname ?? null,
                fullName: trainer.fullName,
                assigned: {
                    ...trainer.AppointmentEmployee,
                },
            })),
            notes: appointment?.notes ?? '',
        };
    });

const includeProp = (key, value, type) => {
    if (type === 'string') {
        return value ? { [key]: value.toString() } : {};
    } else {
        return value ? { [key]: value } : {};
    }
};

export const formatAppointment = values => {
    const formatted = {
        title: values.title,
        startDate: values.startDate,
        endDate: values.endDate,
        ...includeProp('clientId', values?.clientId),
        ...includeProp('notes', values?.notes),
        ...includeProp('appointmentStatusId', values?.appointmentStatusId),
    };
    return formatted;
};

export const formatOrder = values => {
    const formatted = {
        clientId: values.clientId,
        serviceId: values.serviceId,
        ...includeProp('deliveryService', values?.deliveryService),
    };
    return formatted;
};

export const formatIndividualClass = values => {
    const formatted = {
        appointmentId: values.id,
        description: values.trainerReview,
        ...includeProp('trainerRating', values?.trainerRating),
    };
    return formatted;
};

export const getFilterData = appointments => {
    const nicknameFilter = {
        selected: '',
        options: Array.from(
            appointments.reduce((acc, appointment) => {
                appointment.trainers.forEach(trainer => {
                    if (trainer.nickname) {
                        acc.add(trainer.nickname);
                    }
                });
                return acc;
            }, new Set())
        ),
    };

    const clientFilter = {
        selected: '',
        options: Array.from(
            appointments.reduce(
                (acc, cur) => (cur.clientName ? acc.add(cur.clientName) : acc),
                new Set()
            )
        ),
    };

    return {
        nicknameFilter,
        clientFilter,
    };
};
