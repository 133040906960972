import axios from 'axios';
import { axiosConfig, axiosConfigForm } from './axiosConfig';

export const getSubjects = async () => {
    const { data } = await axios.get('subjects', axiosConfig('api'));
    return data;
};

export const getSingleSubject = async id => {
    const { data } = await axios.get(
        `subjects/GetSubject/${id}`,
        axiosConfig('api')
    );
    const newData = [{ ...data }];
    return newData;
};

export const getSingleSubjectLanding = async id => {
    const { data } = await axios.get(
        `subjects/GetLandingSubject/${id}`,
        axiosConfig('api')
    );
    const newData = [{ ...data }];
    return newData;
};

export const createSubject = async body => {
    const { data } = await axios.post(
        'subjects/PostSubject',
        body,
        axiosConfig('api')
    );
    return data;
};

export const updateSubject = async (id, body) => {
    const { data } = await axios.put(
        `subjects/${id}`,
        body,
        axiosConfig('api')
    );
    return data;
};

export const getAssignedSubjects = async id => {
    const { data } = await axios.get(`assignedSubjects/${id}`, axiosConfig(''));
    return data;
};

export const getUsersByTeachersId = async () => {
    const { data } = await axios.get(
        `teachers/GetTeacherList`,
        axiosConfig('api')
    );
    return data;
};

export const getUsersById = async id => {
    const { data } = await axios.get(`users/${id}`, axiosConfig('api'));
    return data;
};

export const uploadSubjectDocuments = async body => {
    const { data } = await axios({
        method: 'post',
        url: axiosConfigForm('api/subjectDocument/UploadFile'),
        data: body,
        headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data;
};

export const sendInfo = async body => {
    const { data } = await axios.post(
        'leads/SendLeadEmail',
        body,
        axiosConfig('api')
    );
    return data;
};
