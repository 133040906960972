import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    createPaymentsMovements,
    getSubjectPayments,
    uploadPaymentDocument,
} from '../../../api/payments';
import { LOADING_STATUS } from '../../../helpers/constants';
import { formatSubjectPayments } from './utils';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';

const initialState = {
    data: {
        rows: [],
    },
    uploadStatus: LOADING_STATUS.IDLE,
    status: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchSubjectPayments = createAsyncThunk(
    'SubjectPayments/fetchSubjectPayments',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getSubjectPayments(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar lista de pagos.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createPayments = createAsyncThunk(
    'SubjectPayments/createPayments',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await createPaymentsMovements(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar lista de pagos.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const uploadPayment = createAsyncThunk(
    'SubjectPayments/uploadPayment',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await uploadPaymentDocument(body);
            dispatch(
                enqueueSnackbar({
                    message: `Documento subido con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo subir el documento, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const subjectPaymentSlice = createSlice({
    name: 'subjectPayments',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSubjectPayments.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchSubjectPayments.fulfilled, (state, { payload }) => {
                const cleanPayments = formatSubjectPayments(payload);

                state.status = LOADING_STATUS.SUCCEEDED;
                state.data.rows = cleanPayments;
            })
            .addCase(fetchSubjectPayments.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(uploadPayment.pending, state => {
                state.uploadStatus = LOADING_STATUS.LOADING;
            })
            .addCase(uploadPayment.fulfilled, state => {
                state.uploadStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(uploadPayment.rejected, (state, { error }) => {
                state.uploadStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = subjectPaymentSlice.actions;

export default subjectPaymentSlice.reducer;
