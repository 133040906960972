import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPaymentInfo, getUserPayments } from '../../../api/payments';
import { LOADING_STATUS } from '../../../helpers/constants';
import { formatPaymentInfo, formatUserPayments } from './utils';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';

const initialState = {
    data: {
        rows: [],
    },
    insertValidation: LOADING_STATUS.IDLE,
    status: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchUserPayments = createAsyncThunk(
    'SubjectPayments/fetchUserPayments',
    async (ids, { dispatch, rejectWithValue }) => {
        try {
            const data = await getUserPayments(ids[0], ids[1]);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar lista de pagos.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const userPaymentSlice = createSlice({
    name: 'userPayments',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUserPayments.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchUserPayments.fulfilled, (state, { payload }) => {
                const cleanPayments = formatUserPayments(payload);

                state.status = LOADING_STATUS.SUCCEEDED;
                state.data.rows = cleanPayments;
            })
            .addCase(fetchUserPayments.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = userPaymentSlice.actions;

export default userPaymentSlice.reducer;
