import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { LOADING_STATUS } from '../../../helpers/constants';
import { deleteVideo, getVideoList } from '../../../api/video';
import { formatVideos, getFilterData } from './utils';

const initialState = {
    data: {
        filters: {
            videosNameOptions: {
                selected: '',
                options: [],
            },
            videosStatusOptions: {
                selected: '',
                options: [],
            },
        },
        rows: [],
    },
    status: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchVideos = createAsyncThunk(
    'videos/fetchVideoList',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getVideoList();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudieron cargar los videos, por favor refresque la página. Si el problema persiste contacte a soporte.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const deleteSingleVideo = createAsyncThunk(
    'videos/deleteSingleVideo',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await deleteVideo(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudo eliminar el video, por favor refresque la página. Si el problema persiste contacte a soporte.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const videosSlice = createSlice({
    name: 'videos',
    initialState,
    reducers: {
        changeOption: (state, { payload }) => {
            state.data.filters[payload.name].selected = payload.value;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchVideos.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchVideos.fulfilled, (state, { payload }) => {
                const cleanVideos = formatVideos(payload);

                state.status = LOADING_STATUS.SUCCEEDED;
                state.data.rows = cleanVideos;
                state.data.filters = getFilterData(cleanVideos);
            })
            .addCase(fetchVideos.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(deleteSingleVideo.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(deleteSingleVideo.fulfilled, state => {
                state.status = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(deleteSingleVideo.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { changeOption } = videosSlice.actions;

export default videosSlice.reducer;
