export const appointments = [
    {
        title: 'Clase grupal',
        id: 0,
        startDate: '2018-06-27T16:30:00.000Z',
        endDate: '2018-06-27T20:00:00.000Z',
        notes: 'grupal',
        statusId: 1,
        trainers: [
            {
                trainerId: 1,
                fullName: 'Oscar Daniel Frausto Rojas',
                nickname: 'Byron',
            },
            {
                trainerId: 4,
                fullName: 'Mariel Adriana Ortega Buenfil',
                nickname: 'Hopita',
            },
        ],
        location: 'Beto',
        rRule:
            'RRULE:INTERVAL=2;FREQ=WEEKLY;BYDAY=SU,TU,WE;UNTIL=20180627T170000Z',
    },
    {
        title: 'Clase individual Maya husky',
        startDate: new Date(2018, 5, 25, 9, 30),
        endDate: new Date(2018, 5, 25, 10, 30),
        id: 0,
        location: 'Mariel',
    },
    {
        title: 'Book Flights to San Fran for Sales Trip',
        startDate: new Date(2018, 5, 25, 12, 11),
        endDate: new Date(2018, 5, 25, 13, 0),
        id: 1,
        location: 'Mariel',
    },
    {
        title: 'Install New Router in Dev Room',
        startDate: new Date(2018, 5, 25, 14, 30),
        endDate: new Date(2018, 5, 25, 15, 35),
        id: 2,
        location: 'Beto',
    },
    {
        title: 'Approve Personal Computer Upgrade Plan',
        startDate: new Date(2018, 5, 26, 10, 0),
        endDate: new Date(2018, 5, 26, 11, 0),
        id: 3,
        location: 'Beto',
    },
    {
        title: 'Final Budget Review',
        startDate: new Date(2018, 5, 26, 12, 0),
        endDate: new Date(2018, 5, 26, 13, 35),
        id: 4,
        location: 'Beto',
    },
    {
        title: 'New Brochures',
        startDate: new Date(2018, 5, 26, 14, 30),
        endDate: new Date(2018, 5, 26, 15, 45),
        id: 5,
        location: 'Beto',
    },
    {
        title: 'Install New Database',
        startDate: new Date(2018, 5, 27, 9, 45),
        endDate: new Date(2018, 5, 27, 11, 15),
        id: 6,
        location: 'Mariel',
    },
    {
        title: 'Approve New Online Marketing Strategy',
        startDate: new Date(2018, 5, 27, 12, 0),
        endDate: new Date(2018, 5, 27, 14, 0),
        id: 7,
        location: 'Daniel',
    },
    {
        title: 'Upgrade Personal Computers',
        startDate: new Date(2018, 5, 27, 15, 15),
        endDate: new Date(2018, 5, 27, 16, 30),
        id: 8,
        location: 'Daniel',
    },
    {
        title: 'Customer Workshop',
        startDate: new Date(2018, 5, 28, 11, 0),
        endDate: new Date(2018, 5, 28, 12, 0),
        id: 9,
        location: 'Daniel',
    },
    {
        title: 'Prepare 2015 Marketing Plan',
        startDate: new Date(2018, 5, 28, 11, 0),
        endDate: new Date(2018, 5, 28, 13, 30),
        id: 10,
        location: 'Mariel',
    },
    {
        title: 'Brochure Design Review',
        startDate: new Date(2018, 5, 28, 14, 0),
        endDate: new Date(2018, 5, 28, 15, 30),
        id: 11,
        location: 'Beto',
    },
    {
        title: 'Create Icons for Website',
        startDate: new Date(2018, 5, 29, 10, 0),
        endDate: new Date(2018, 5, 29, 11, 30),
        id: 12,
        location: 'Beto',
    },
    {
        title: 'Upgrade Server Hardware',
        startDate: new Date(2018, 5, 29, 14, 30),
        endDate: new Date(2018, 5, 29, 16, 0),
        id: 13,
        location: 'Daniel',
    },
    {
        title: 'Submit New Website Design',
        startDate: new Date(2018, 5, 29, 16, 30),
        endDate: new Date(2018, 5, 29, 18, 0),
        id: 14,
        location: 'Daniel',
    },
    {
        title: 'Launch New Website',
        startDate: new Date(2018, 5, 29, 12, 20),
        endDate: new Date(2018, 5, 29, 14, 0),
        id: 15,
        location: 'Beto',
    },
    {
        title: 'Website Re-Design Plan',
        startDate: new Date(2018, 6, 2, 9, 30),
        endDate: new Date(2018, 6, 2, 15, 30),
        id: 16,
        location: 'Mariel',
    },
    {
        title: 'Book Flights to San Fran for Sales Trip',
        startDate: new Date(2018, 6, 2, 12, 0),
        endDate: new Date(2018, 6, 2, 13, 0),
        id: 17,
        location: 'Daniel',
    },
    {
        title: 'Install New Router in Dev Room',
        startDate: new Date(2018, 6, 2, 14, 30),
        endDate: new Date(2018, 6, 2, 17, 30),
        id: 18,
        location: 'Beto',
    },
    {
        title: 'Approve Personal Computer Upgrade Plan',
        startDate: new Date(2018, 6, 2, 16, 0),
        endDate: new Date(2018, 6, 3, 9, 0),
        id: 19,
        location: 'Beto',
    },
    {
        title: 'Final Budget Review',
        startDate: new Date(2018, 6, 3, 10, 15),
        endDate: new Date(2018, 6, 3, 13, 35),
        id: 20,
        location: 'Mariel',
    },
    {
        title: 'New Brochures',
        startDate: new Date(2018, 6, 3, 14, 30),
        endDate: new Date(2018, 6, 3, 15, 45),
        id: 21,
        location: 'Daniel',
    },
    {
        title: 'Install New Database',
        startDate: new Date(2018, 6, 3, 15, 45),
        endDate: new Date(2018, 6, 4, 12, 15),
        id: 22,
        location: 'Daniel',
    },
    {
        title: 'Approve New Online Marketing Strategy',
        startDate: new Date(2018, 6, 4, 12, 35),
        endDate: new Date(2018, 6, 4, 14, 15),
        id: 23,
        location: 'Daniel',
    },
    {
        title: 'Upgrade Personal Computers',
        startDate: new Date(2018, 6, 4, 15, 15),
        endDate: new Date(2018, 6, 4, 20, 30),
        id: 24,
        location: 'Beto',
    },
    {
        title: 'Customer Workshop',
        startDate: new Date(2018, 6, 5, 6, 0),
        endDate: new Date(2018, 6, 5, 14, 20),
        id: 25,
        location: 'Mariel',
    },
    {
        title: 'Customer Workshop',
        startDate: new Date(2018, 6, 5, 14, 35),
        endDate: new Date(2018, 6, 5, 16, 20),
        id: 26,
        location: 'Mariel',
    },
    {
        title: 'Customer Workshop 2',
        startDate: new Date(2018, 6, 5, 10, 0),
        endDate: new Date(2018, 6, 5, 11, 20),
        id: 27,
        location: 'Beto',
    },
    {
        title: 'Prepare 2015 Marketing Plan',
        startDate: new Date(2018, 6, 5, 20, 0),
        endDate: new Date(2018, 6, 6, 13, 30),
        id: 28,
        location: 'Daniel',
    },
    {
        title: 'Brochure Design Review',
        startDate: new Date(2018, 6, 6, 14, 10),
        endDate: new Date(2018, 6, 6, 15, 30),
        id: 29,
        location: 'Daniel',
    },
    {
        title: 'Create Icons for Website',
        startDate: new Date(2018, 6, 6, 10, 0),
        endDate: new Date(2018, 6, 7, 14, 30),
        id: 30,
        location: 'Mariel',
    },
    {
        title: 'Upgrade Server Hardware',
        startDate: new Date(2018, 6, 3, 9, 30),
        endDate: new Date(2018, 6, 3, 12, 25),
        id: 31,
        location: 'Beto',
    },
    {
        title: 'Submit New Website Design',
        startDate: new Date(2018, 6, 3, 12, 30),
        endDate: new Date(2018, 6, 3, 18, 0),
        id: 32,
        location: 'Beto',
    },
    {
        title: 'Launch New Website',
        startDate: new Date(2018, 6, 3, 12, 20),
        endDate: new Date(2018, 6, 3, 14, 10),
        id: 33,
        location: 'Beto',
    },
    {
        title: 'Book Flights to San Fran for Sales Trip',
        startDate: new Date(2018, 5, 26, 0, 0),
        endDate: new Date(2018, 5, 27, 0, 0),
        id: 34,
        location: 'Mariel',
    },
    {
        title: 'Customer Workshop',
        startDate: new Date(2018, 5, 29, 10, 0),
        endDate: new Date(2018, 5, 30, 14, 30),
        id: 35,
        location: 'Mariel',
    },
    {
        title: 'Google AdWords Strategy',
        startDate: new Date(2018, 6, 3, 0, 0),
        endDate: new Date(2018, 6, 4, 10, 30),
        id: 36,
        location: 'Daniel',
    },
    {
        title: 'Rollout of New Website and Marketing Brochures',
        startDate: new Date(2018, 6, 5, 10, 0),
        endDate: new Date(2018, 6, 9, 14, 30),
        id: 37,
        location: 'Daniel',
    },
    {
        title: 'Update NDA Agreement',
        startDate: new Date(2018, 6, 1, 10, 0),
        endDate: new Date(2018, 6, 3, 14, 30),
        id: 38,
        location: 'Beto',
    },
    {
        title: 'Customer Workshop',
        startDate: new Date(2018, 6, 1),
        endDate: new Date(2018, 6, 2),
        allDay: true,
        id: 39,
        location: 'Mariel',
    },
];
