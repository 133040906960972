import axios from 'axios';
//import { axiosConfig } from './axiosConfig';

// const axiosConfigClient =
//     process.env.REACT_APP_ENV === 'development'
//         ? axiosConfig('clients-api')
//         : axiosConfig('client.sigrha.com');

export const getRolesList = async () => {
    const { data } = await axios.get('http://192.168.56.126:8000/rols/');
    return data;
};

export const getPermissionsList = async () => {
    const { data } = await axios.get('http://192.168.56.126:8000/permissions/');
    return data;
};

export const createRole = async body => {
    const { data } = await axios.post(`http://192.168.56.126:8000/rols/`, body);
    return data;
};

export const getRoleInfo = async id => {
    const { data } = await axios.get(`http://192.168.56.126:8000/rols/${id}/`);
    return data;
};

export const updateRoleInfo = async (id, body) => {
    const { data } = await axios.put(
        `http://192.168.56.126:8000/rols/${id}/`,
        body
    );
    return data;
};

export const updateRole = async (id, body) => {
    const { data } = await axios.post(
        `http://192.168.56.126:8000/rols/${id}/permissions/`,
        body
    );
    return data;
};

export const getPermissionsOfRole = async id => {
    const { data } = await axios.get(
        `http://192.168.56.126:8000/rols/${id}/permissions/`
    );
    return data;
};

export const assignPermissionsToRole = async (id, body) => {
    const { data } = await axios.post(
        `http://192.168.56.126:8000/rols/${id}/permissions/`,
        body
    );
    return data;
};
