import { Button } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchClients } from './clientsSlice';
import ExportButton from '../../../common/ExportButton/ExportButton';
import ClientFilters from './ClientFilters/ClientFilters';
import ClientTable from './ClientTable/ClientTable';
import PageHeader from '../../../common/PageHeader/PageHeader';

const useStyles = makeStyles(theme => ({
    downloadButton: {
        backgroundColor: green[600],
        color: 'white',
        '&:hover': {
            backgroundColor: green[800],
        },
    },
    topButton: {
        '&:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
}));

const csvHeaders = [
    { label: 'Cliente', key: 'clientName' },
    { label: 'Encargado', key: 'goesBy' },
    { label: 'Contacto a cargo', key: 'clientContactName' },
    { label: 'Teléfono de contacto', key: 'clientContactPhone' },
    { label: 'Última actualización', key: 'modifiedDate' },
    { label: 'Status del cliente', key: 'clientStatus' },
];

const Clients = () => {
    const classes = useStyles();
    const filters = useSelector(state => state.clients.data.filters);
    const rows = useSelector(state => state.clients.data.rows);
    const status = useSelector(state => state.clients.status);
    const dispatch = useDispatch();

    const filtersActive = Object.values(filters).some(
        ({ selected }) => selected
    );

    const filteredRows = ((filtersActive, rows) => {
        if (!filtersActive) return rows;
        let newRows = [];
        if (filters.clientNameOptions.selected) {
            const filterItems = query => {
                return rows.filter(
                    row =>
                        row.clientName
                            .toLowerCase()
                            .indexOf(query.toLowerCase()) > -1
                );
            };
            newRows = filterItems(filters.clientNameOptions.selected);
        }
        if (filters.clientContactNameOptions.selected) {
            newRows = (newRows.length > 0 ? newRows : rows).filter(
                row =>
                    row.clientContactName ===
                    filters.clientContactNameOptions.selected
            );
        }
        if (filters.clientStatusOptions.selected) {
            newRows = (newRows.length > 0 ? newRows : rows).filter(
                row => row.clientStatus === filters.clientStatusOptions.selected
            );
        }
        if (filters.emailOptions.selected) {
            const filterItems = query => {
                return rows.filter(
                    row =>
                        row.email.toLowerCase().indexOf(query.toLowerCase()) >
                        -1
                );
            };
            newRows = filterItems(filters.emailOptions.selected);
        }

        return newRows;
    })(filtersActive, rows);

    const cltName = clientsNames => clientsNames.clientName;
    const cltsNames = filteredRows.map(cltName);
    const cltGoesBy = clientsGoesBy => clientsGoesBy.goesBy;
    const cltsGoesBy = filteredRows.map(cltGoesBy);
    const cltContactName = clientsContactsNames =>
        clientsContactsNames.clientContactName;
    const cltsContactsNames = filteredRows.map(cltContactName);
    const cltStatus = clientsStatus => clientsStatus.clientStatus;
    const cltsStatus = filteredRows.map(cltStatus);

    const newFilters = {
        ...filters,
        goesByOptions: {
            ...filters.goesByOptions,
            options: new Set(cltsGoesBy),
        },
        clientContactNameOptions: {
            ...filters.clientContactNameOptions,
            options: new Set(cltsContactsNames),
        },
        clientNameOptions: {
            ...filters.clientNameOptions,
            options: new Set(cltsNames),
        },
        clientStatusOptions: {
            ...filters.clientStatusOptions,
            options: new Set(cltsStatus),
        },
    };

    useEffect(() => {
        dispatch(fetchClients());
    }, [dispatch]);

    const sortFilteredRows = [...filteredRows];
    sortFilteredRows.sort(function (a, b) {
        let nameA = a.clientName.toUpperCase();
        let nameB = b.clientName.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    return (
        <section>
            <PageHeader title="Clientes">
                <Button
                    className={classes.topButton}
                    color="primary"
                    component={Link}
                    to="/clients/create"
                    variant="contained"
                >
                    Adicionar
                </Button>
                <ExportButton
                    headers={csvHeaders}
                    inputData={sortFilteredRows}
                    fileName="Clientes.csv"
                    className={`${classes.topButton} ${classes.downloadButton}`}
                >
                    Exportar a Excel
                </ExportButton>
            </PageHeader>

            <ClientFilters {...newFilters} />
            <ClientTable status={status} rows={sortFilteredRows} />
        </section>
    );
};

export default Clients;
