import axios from 'axios';
import { axiosConfig, axiosConfigForm } from './axiosConfig';

const axiosConfigClient =
    process.env.REACT_APP_ENV === 'development'
        ? axiosConfig('/api/v1')
        : axiosConfig('client.sigrha.com');

// export const getAppointments = async id => {
//     const { data } = await axios.get(
//         'appointments/',
//         { params: { ...(id ? { userId: id } : {}) } },
//         axiosConfig('api/v1')
//     );
//     return data;
// };

export const getAppointments = async id => {
    const { data } = await axios({
        method: 'get',
        url: axiosConfigForm(`api/v1/appointments`),
        params: { ...(id ? { userId: id } : {}) },
    });

    return data;
};

export const getSingleAppointment = async id => {
    const { data } = await axios.get(
        `appointments/${id}`,
        axiosConfig('api/v1')
    );
    return data;
};

export const createAppointment = async body => {
    const { data } = await axios.post(
        'appointments',
        body,
        axiosConfig('api/v1')
    );

    return data;
};

export const updateAppointment = async (id, body) => {
    const { data } = await axios.patch(
        `appointments/${id}`,
        body,
        axiosConfig('api/v1')
    );

    return data;
};

export const createAppointmentEmployee = async body => {
    const { data } = await axios.post(
        'appointmentsEmployees',
        body,
        axiosConfig('api/v1')
    );

    return data;
};

export const deleteAppointmentEmployee = async id => {
    const { data } = await axios.delete(
        `appointmentsEmployees/${id}`,
        axiosConfig('api/v1')
    );

    return data;
};

export const createOrder = async body => {
    const { data } = await axios.post('orders', body, axiosConfig('api/v1'));

    return data;
};

export const createIndividualClass = async body => {
    const { data } = await axios.post(
        'individualClass',
        body,
        axiosConfig('api/v1')
    );

    return data;
};
