import { MenuItem, TextField } from '@material-ui/core';
import { camelToDashCase } from '../../helpers/strings';
import PropTypes from 'prop-types';

const SelectField = ({ label, name, onChange, options }) => {
    return (
        <TextField
            id={camelToDashCase(name)}
            name={name}
            select
            label={label}
            value={options?.selected}
            variant="outlined"
            onChange={onChange}
        >
            <MenuItem value="">&nbsp;</MenuItem>
            {options.options?.map(option => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField>
    );
};

SelectField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.string),
        selected: PropTypes.string,
    }),
};

export default SelectField;
