import PageHeader from '../../../common/PageHeader/PageHeader';
import { Button } from '@material-ui/core';
import ExportButton from '../../../common/ExportButton/ExportButton';
import { Link } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { fetchAdminPayments } from './paymentsAdminSlice';
import PaymentFilters from './paymentFilters/PaymentFilters';
import PaymentTable from './paymentTable/PaymentTable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';

const useStyles = makeStyles(theme => ({
    downloadButton: {
        backgroundColor: green[600],
        color: 'white',
        '&:hover': {
            backgroundColor: green[800],
        },
    },
    topButton: {
        '&:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
}));

const csvHeaders = [
    { label: 'Materia', key: 'paymentName' },
    { label: 'Tipo', key: 'type' },
    { label: 'Modalidad', key: 'modality' },
    { label: 'Status', key: 'paymentStatus' },
];

const PaymentsAdmin = () => {
    const classes = useStyles();
    const filters = useSelector(state => state.adminPayments.data.filters);
    const rows = useSelector(state => state.adminPayments.data.rows);
    const status = useSelector(state => state.adminPayments.status);
    const userRole = useSelector(state => state.user.userData.role);
    const userId = useSelector(state => state.user.userData.id);
    const dispatch = useDispatch();

    const filtersActive = Object.values(filters).some(
        ({ selected }) => selected
    );

    const filteredRows = ((filtersActive, rows) => {
        if (!filtersActive) return rows;
        let newRows = [];
        if (filters.paymentNameOptions.selected) {
            const filterItems = query => {
                return rows.filter(
                    row =>
                        row.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
            };

            newRows = filterItems(filters.paymentNameOptions.selected);
        }
        if (filters.paymentEmailOptions.selected) {
            const filterItems = query => {
                return rows.filter(
                    row =>
                        row.email.toLowerCase().indexOf(query.toLowerCase()) >
                        -1
                );
            };

            newRows = filterItems(filters.paymentEmailOptions.selected);
        }
        if (filters.paymentRoleOptions.selected) {
            newRows = (newRows.length > 0 ? newRows : rows).filter(
                row => row.role === filters.paymentRoleOptions.selected
            );
        }
        if (filters.paymentStatusOptions.selected) {
            newRows = (newRows.length > 0 ? newRows : rows).filter(
                row =>
                    row.paymentStatus === filters.paymentStatusOptions.selected
            );
        }

        return newRows;
    })(filtersActive, rows);

    useEffect(() => {
        if (userId) {
            dispatch(fetchAdminPayments(userId));
        }
    }, [dispatch, userId]);

    return (
        <div>
            <PageHeader title="Pagos">
                {userRole === 'Administrador' && (
                    <>
                        <ExportButton
                            headers={csvHeaders}
                            inputData={filteredRows}
                            fileName="Materias.csv"
                            className={`${classes.topButton} ${classes.downloadButton}`}
                        >
                            Exportar a Excel
                        </ExportButton>
                    </>
                )}
            </PageHeader>

            <PaymentFilters {...filters} />
            <PaymentTable status={status} rows={filteredRows} userId={userId} />
        </div>
    );
};

export default PaymentsAdmin;
