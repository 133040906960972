import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { deleteSingleVideo, fetchVideos } from '../VideosSlice';
import { useHistory } from 'react-router';

export default function DeleteDialog({ open = false, handleClose, data }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {`Deseas eliminar el video de ${data?.title}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Estas por eliminar el video {data?.title} de la base de
                        datos. Deseas continuar?.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={() =>
                            dispatch(deleteSingleVideo(data.id)).then(() => {
                                dispatch(fetchVideos());
                                handleClose();
                            })
                        }
                        color="secondary"
                        autoFocus
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
