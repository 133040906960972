import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getClientsforDropdown } from '../../../../api/clients';
import { getUsersByTeachersId } from '../../../../api/subjects';
import { getAllOpportunityType } from '../../../../api/opportunities';
import { LOADING_STATUS } from '../../../../helpers/constants';
import { enqueueSnackbar } from '../../../pushNotifications/pushNotificationsSlice';

const initialState = {
    opportunityType: [],
    dropdownClients: [],
    dropdownContacts: [],
    status: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchAllOpportunityType = createAsyncThunk(
    'clients/fetchAllOpportunityType',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getAllOpportunityType();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: 'No se pudieron cargar los tipos de oportunidad',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchDropdownClients = createAsyncThunk(
    'clients/fetchDropdownClients',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getClientsforDropdown();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudieron cargar los clientes para las oportunidades',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchDropdownContacts = createAsyncThunk(
    'clients/fetchDropdownContacts',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getUsersByTeachersId(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar el usuario con el id ${id}.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

const dropdownSlice = createSlice({
    name: 'dropdown',
    initialState,
    reducers: {
        addClientContact: {
            reducer: (state, { payload }) => {
                state.dropdownContacts.push({ ...payload });
            },
            prepare: (id, value, text) => {
                return { payload: { id, value, text } };
            },
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllOpportunityType.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(
                fetchAllOpportunityType.fulfilled,
                (state, { payload }) => {
                    state.status = LOADING_STATUS.SUCCEEDED;
                    state.opportunityType = payload;
                }
            )
            .addCase(fetchAllOpportunityType.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchDropdownClients.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchDropdownClients.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.dropdownClients = payload;
            })
            .addCase(fetchDropdownClients.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchDropdownContacts.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchDropdownContacts.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.dropdownContacts = payload;
            })
            .addCase(fetchDropdownContacts.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { addClientContact } = dropdownSlice.actions;

export default dropdownSlice.reducer;
