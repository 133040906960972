import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LOADING_STATUS } from '../../../../helpers/constants';
import { initialFormInfo } from './mainForm';
import { enqueueSnackbar } from '../../../pushNotifications/pushNotificationsSlice';
import { contactMail } from '../../../../api/email';

const initialState = {
    form: initialFormInfo,
    sendStatus: LOADING_STATUS.IDLE,
    error: null,
};

export const contactUsMail = createAsyncThunk(
    'university/contactUsMail',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await contactMail(body);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo enviar el correo.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const contactUsSlice = createSlice({
    name: 'contactUs',
    initialState,
    reducers: {
        resetValues: () => {
            return initialFormInfo;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(contactUsMail.pending, state => {
                state.sendStatus = LOADING_STATUS.LOADING;
            })
            .addCase(contactUsMail.fulfilled, state => {
                state.sendStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(contactUsMail.rejected, (state, { error }) => {
                state.sendStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = contactUsSlice.actions;

export default contactUsSlice.reducer;
