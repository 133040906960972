export const formatCarousel = carouselArray =>
    carouselArray.map(carousel => {
        return {
            id: carousel.id,
            image: carousel.image ?? null,
            title: carousel.title ?? '',
            description: carousel.description ?? '',
            link: carousel.link ?? null,
        };
    });

export const getFilterData = carousel => {
    const carouselOptions = {
        selected: '',
        options: Array.from(
            carousel.reduce(
                (acc, cur) => (cur.service ? acc.add(cur.service) : acc),
                new Set()
            )
        ),
    };

    const carouselStatusOptions = {
        selected: '',
        options: Array.from(
            carousel.reduce(
                (acc, cur) =>
                    cur.carouselStatus ? acc.add(cur.carouselStatus) : acc,
                new Set()
            )
        ),
    };

    const carouselRoleOptions = {
        selected: '',
        options: Array.from(
            carousel.reduce(
                (acc, cur) => (cur.role ? acc.add(cur.role) : acc),
                new Set()
            )
        ),
    };

    return {
        carouselOptions,
        carouselStatusOptions,
        carouselRoleOptions,
    };
};
