import React, { useEffect } from 'react';
import {
    ThemeProvider,
    makeStyles,
    createTheme,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from './Footer';
import post1 from './blog-post.1.md';
import post2 from './blog-post.2.md';
import post3 from './blog-post.3.md';
import dog from '../../assets/images/dog.jpg';
import septiembre from '../../assets/images/septiembre.jpg';
import grupal from '../../assets/images/grupal.jpg';
import playa from '../../assets/images/playa.jpeg';
import productos from '../../assets/images/productos.png';
import { WhatsApp } from '@material-ui/icons';
import CarouselComponent from '../../common/carousel/Carousel';
import SvgIcon from '@mui/material/SvgIcon';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCarousel } from '../carousel/main/CarouselSlice';

function TikTokIcon() {
    return (
        <SvgIcon>
            {/* credit: plus icon from https://heroicons.com/ */}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                filter="invert(1)"
            >
                <path d="M16.708 0.027c1.745-0.027 3.48-0.011 5.213-0.027 0.105 2.041 0.839 4.12 2.333 5.563 1.491 1.479 3.6 2.156 5.652 2.385v5.369c-1.923-0.063-3.855-0.463-5.6-1.291-0.76-0.344-1.468-0.787-2.161-1.24-0.009 3.896 0.016 7.787-0.025 11.667-0.104 1.864-0.719 3.719-1.803 5.255-1.744 2.557-4.771 4.224-7.88 4.276-1.907 0.109-3.812-0.411-5.437-1.369-2.693-1.588-4.588-4.495-4.864-7.615-0.032-0.667-0.043-1.333-0.016-1.984 0.24-2.537 1.495-4.964 3.443-6.615 2.208-1.923 5.301-2.839 8.197-2.297 0.027 1.975-0.052 3.948-0.052 5.923-1.323-0.428-2.869-0.308-4.025 0.495-0.844 0.547-1.485 1.385-1.819 2.333-0.276 0.676-0.197 1.427-0.181 2.145 0.317 2.188 2.421 4.027 4.667 3.828 1.489-0.016 2.916-0.88 3.692-2.145 0.251-0.443 0.532-0.896 0.547-1.417 0.131-2.385 0.079-4.76 0.095-7.145 0.011-5.375-0.016-10.735 0.025-16.093z" />
            </svg>
        </SvgIcon>
    );
}

const darkTheme = createTheme({
    palette: {
        type: 'dark',
    },
});

const useStyles = makeStyles(theme => ({
    mainGrid: {
        marginTop: theme.spacing(3),
    },
}));

const mainFeaturedPost = [
    {
        title: 'Expertos en adiestramiento canino',
        description:
            'Contamos con rehabilitación de conductas agresivas, malos hábitos y les enseñamos a respetar las reglas del hogar',
        image: dog,
        imgText: 'main image description',
    },
    {
        title: 'Promociones de Septiembre',
        description:
            'Conoce todas nuestras promociones del mes de Septiembre, descuentos en clases, productos y mas...',
        image: septiembre,
        imgText: 'main image description',
        linkText: 'ver promociones',
    },
    {
        title: 'Nuevos productos',
        description:
            'Visita la sección de productos donde encontraras juguetes, premios, correas y muchas cosas más que a el le encantaran!',
        image: productos,
        imgText: 'main image description',
        linkText: 'ver productos',
    },
];

const featuredPosts = [
    {
        title: 'Clases individuales',
        description:
            'Enfocado al buen comportamiento tanto dentro como fuera de casa y corrección de malos hábitos.',
        image: grupal,
        imageText: 'Image Text',
        link: '#',
    },
    {
        title: 'Clases grupales',
        description:
            'Enfocado a la socialización con perros y personas, realizando juegos y dinámicas al aire libre.',
        image: playa,
        link: '/cu/terapias',
    },
];

const posts = [post1, post2, post3];

const sidebar = {
    title: 'Nuestros cursos',
    description:
        '¿Tu perro tiene malos hábitos en casa? ¿Quieres mejorar su obediencia y convivencia? ¡Estamos aquí para ayudarte!',
    archives: [
        { title: 'March 2020', url: '#' },
        { title: 'February 2020', url: '#' },
        { title: 'January 2020', url: '#' },
        { title: 'November 1999', url: '#' },
        { title: 'October 1999', url: '#' },
        { title: 'September 1999', url: '#' },
        { title: 'August 1999', url: '#' },
        { title: 'July 1999', url: '#' },
        { title: 'June 1999', url: '#' },
        { title: 'May 1999', url: '#' },
        { title: 'April 1999', url: '#' },
    ],
    social: [
        {
            name: 'Instagram',
            icon: InstagramIcon,
            webSite:
                'https://www.instagram.com/acfaithfuldog?igsh=MWVtdGZ4eHBwcjdxeA==',
        },
        {
            name: 'Facebook',
            icon: FacebookIcon,
            webSite: 'https://www.facebook.com/ACFaithfulDog ',
        },
        {
            name: 'TikTok',
            icon: TikTokIcon,
            webSite:
                'https://www.tiktok.com/@faithfuldogmid?_t=8kPxwlZzEgv&_r=1',
        },
    ],
};

export default function Blog() {
    const classes = useStyles();
    const carouselData = useSelector(state => state.carousel.data.rows);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCarousel());
    }, [dispatch]);

    return (
        <React.Fragment>
            <ThemeProvider theme={darkTheme}>
                <main>
                    <CarouselComponent data={carouselData} />
                    {/* <Grid container spacing={4}>
                        {featuredPosts.map(post => (
                            <FeaturedPost key={post.title} post={post} />
                        ))}
                    </Grid> */}
                    <Grid container spacing={5} className={classes.mainGrid}>
                        <Main title="¿Quienes somos?" posts={posts} />
                        <Sidebar
                            title={sidebar.title}
                            description={sidebar.description}
                            archives={sidebar.archives}
                            social={sidebar.social}
                        />
                    </Grid>
                </main>
            </ThemeProvider>
        </React.Fragment>
    );
}
