import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    pageTitle: {
        color: theme.palette.primary.main,
        flexGrow: 1,
        fontSize: theme.typography.h4.fontSize,
        margin: 0,
    },
    topSection: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
}));

const PageHeader = ({ title, children, ...props }) => {
    const classes = useStyles();
    return (
        <div className={classes.topSection} {...props}>
            <h1 className={classes.pageTitle}>
                {process.env.REACT_APP_ENV === 'development'
                    ? `${title}`
                    : title}
            </h1>
            {children}
        </div>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
};

export default PageHeader;
