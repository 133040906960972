import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(0.5),
    },
}));

const Footer = ({ className, ...props }) => {
    const classes = useStyles();

    return (
        <footer className={`${className} ${classes.footer}`} {...props}>
            Faithful Dog&reg; {new Date().getFullYear()}
        </footer>
    );
};

export default Footer;
