import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRolesList } from '../../../api/roles';
import { LOADING_STATUS } from '../../../helpers/constants';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { formatRoles, getFilteredData } from './utils';
// import * as api from '../../../api/opportunity';

/* Mock Data START */
// const createData = (role, description) => ({
//     role,    
//     description
// });

// const exampleData = [
//     createData('Administrador', 'Puede modificar seguridad y usuarios'),
//     createData('Gestión General (creación)', 'Creación de clientes y oportunidades'),
//     createData('Gestión General', 'Crear, actualizar y consultar clientes y oportunidades'),
//     createData('Gestión General (consulta)', 'Puede consultar clientes y oportunidades'),
//     createData('Empleado', 'Actividades para un líder'),
//     createData('Administrador de oportunidades', 'Crear, actualizar y consultar oportunidades y consulta de clientes')
// ];

// const rows = [...exampleData].map(row => ({ ...row, id: Math.floor(Math.random() * 100) }));

// const rolesNameOptions = {
//     selected: '',
//     options: Array.from(
//         rows.reduce((acc, cur) => acc.add(cur.role), new Set())
//     ),
// };

// const rolesDescriptionOptions = {
//     selected: '',
//     options: Array.from(
//         rows.reduce((acc, cur) => acc.add(cur.description), new Set())
//     ),
// };
/* Mock Data END */

const initialState = {
    data: {
        filters: {
            rolesNameOptions: {
                selected: '',
                options: [],
            },
            rolesDescriptionOptions: {
                selected: '',
                options: [],
            },
        },
        rows: [],
    },
    pending: true,
    error: null,
};

// API requests
// export const fetchRoless = createAsyncThunk(
//     'roles/fetchRoless', 
//     async () => {    
//     const response = await getRolesList();
//     return response.data;
//     //return true;
// });

export const fetchRoles = createAsyncThunk(
    'roles/fetchRoles',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getRolesList();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudo cargar la lista de roles, por favor refresque la página. Si el problema persiste contacte a soporte.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);


export const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        changeOption: (state, { payload }) => {
            state.data.filters[payload.name].selected = payload.value;
        },
        setRolesList: (state, action) => {
            state.list = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            // .addCase(fetchRoles.fulfilled, state => {
            //     // TEMPORARY, WILL UPDATE WHEN API IS READY
            //     state.data.rows = rows;
            //     state.data.filters = {
            //         rolesNameOptions: rolesNameOptions,
            //         rolesDescriptionOptions: rolesDescriptionOptions,
            //     };
            // })
            .addCase(fetchRoles.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchRoles.fulfilled, (state, { payload }) => {
                const cleanRoles = formatRoles(payload);

                state.status = LOADING_STATUS.SUCCEEDED;
                state.data.rows = cleanRoles;
                state.data.filters = getFilteredData(cleanRoles);
            })
            .addCase(fetchRoles.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
        
    },
});

export const { changeOption } = rolesSlice.actions;

export default rolesSlice.reducer;
