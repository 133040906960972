import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    deleteBtn: {
        color: red[400],
    },
    removeDocCell: {
        width: 50,
    },
    tableHead: {
        fontWeight: theme.typography.fontWeightBold,
    },
}));

const DocumentationList = ({
    documentCatalog,
    edit,
    files,
    onDeleteDoc,
    ...props
}) => {
    const classes = useStyles();

    return (
        <TableContainer component={Paper} {...props}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead}>
                            Tipo
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                            Nombre de archivo
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                            Notas
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                            Número
                        </TableCell>
                        {edit && (
                            <TableCell
                                align="center"
                                className={
                                    classes.removeDocCell +
                                    ' ' +
                                    classes.tableHead
                                }
                            >
                                Borrar
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {files.map(
                        ({
                            file,
                            fileName,
                            idDocumentType,
                            documentNotes,
                            docNumber,
                        }) => (
                            <TableRow key={fileName}>
                                <TableCell>
                                    {documentCatalog.length &&
                                        (documentCatalog.find(
                                            doc => doc.id === idDocumentType
                                        )?.text ??
                                            'N/A')}
                                </TableCell>
                                <TableCell>
                                    <a href={file} download={fileName}>
                                        {fileName}
                                    </a>
                                </TableCell>
                                <TableCell>{documentNotes || '-'}</TableCell>
                                <TableCell>{docNumber || '-'}</TableCell>
                                {edit && (
                                    <TableCell align="center">
                                        <IconButton
                                            onClick={() =>
                                                onDeleteDoc(fileName)
                                            }
                                            size="small"
                                        >
                                            <DeleteIcon
                                                className={classes.deleteBtn}
                                            />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DocumentationList;
