import * as React from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import { blue, orange, red } from '@mui/material/colors';
import classNames from 'clsx';
import { EditingState, ViewState } from '@devexpress/dx-react-scheduler';
import { connectProps } from '@devexpress/dx-react-core';

import {
    Scheduler,
    WeekView,
    Toolbar,
    DateNavigator,
    Appointments,
    DayView,
    ViewSwitcher,
    Resources,
    AppointmentTooltip,
    EditRecurrenceMenu,
    AppointmentForm,
} from '@devexpress/dx-react-scheduler-material-ui';

import { appointments } from './appointments';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppointments } from './schedulerSlice';
import { LOADING_STATUS } from '../../helpers/constants';
import { CircularProgress } from '@material-ui/core';
import Loading from '../../common/Loading/Loading';
import SchedulerFilters from './filters/schedulerFilters';
import CustomForm from './form/CustomForm';
import { fetchAppointmentsCatalog } from '../catalogs/catalogSlice';
import { resetValues } from './form/checklist/checklistSlice';

const LOCATIONS = ['Mariel', 'Beto', 'Daniel'];
const LOCATIONS_SHORT = [1, 2, 3];
const resources = [
    {
        fieldName: 'location',
        title: 'Location',
        instances: [
            { id: LOCATIONS[0], text: LOCATIONS[0], color: blue },
            { id: LOCATIONS[1], text: LOCATIONS[1], color: orange },
            { id: LOCATIONS[2], text: LOCATIONS[2], color: red },
        ],
    },
];

const PREFIX = 'Demo';
// #FOLD_BLOCK
const classes = {
    flexibleSpace: `${PREFIX}-flexibleSpace`,
    textField: `${PREFIX}-textField`,
    locationSelector: `${PREFIX}-locationSelector`,
    button: `${PREFIX}-button`,
    selectedButton: `${PREFIX}-selectedButton`,
    longButtonText: `${PREFIX}-longButtonText`,
    shortButtonText: `${PREFIX}-shortButtonText`,
    title: `${PREFIX}-title`,
    textContainer: `${PREFIX}-textContainer`,
    time: `${PREFIX}-time`,
    text: `${PREFIX}-text`,
    container: `${PREFIX}-container`,
    weekendCell: `${PREFIX}-weekendCell`,
    weekEnd: `${PREFIX}-weekEnd`,
};
// #FOLD_BLOCK
const StyledAppointmentsAppointmentContent = styled(
    Appointments.AppointmentContent
)(() => ({
    [`& .${classes.title}`]: {
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    [`& .${classes.textContainer}`]: {
        lineHeight: 1,
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
    },
    [`& .${classes.time}`]: {
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    [`& .${classes.text}`]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    [`& .${classes.container}`]: {
        width: '100%',
    },
}));
// #FOLD_BLOCK
const StyledWeekViewTimeTableCell = styled(WeekView.TimeTableCell)(
    ({ theme: { palette } }) => ({
        [`&.${classes.weekendCell}`]: {
            backgroundColor: alpha(palette.action.disabledBackground, 0.04),
            '&:hover': {
                backgroundColor: alpha(palette.action.disabledBackground, 0.04),
            },
            '&:focus': {
                backgroundColor: alpha(palette.action.disabledBackground, 0.04),
            },
        },
    })
);
// #FOLD_BLOCK
const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)(
    ({ theme: { palette } }) => ({
        [`&.${classes.weekEnd}`]: {
            backgroundColor: alpha(palette.action.disabledBackground, 0.06),
        },
    })
);

function clientFind(value, array) {
    if (value && array) {
        return array.find(item => item.id === value).text;
    } else {
        return '';
    }
}

const isRestTime = date =>
    date.getDay() === 0 ||
    date.getDay() === 6 ||
    date.getHours() < 7 ||
    date.getHours() >= 22;

const TimeTableCell = ({ ...restProps }) => {
    const { startDate } = restProps;
    if (isRestTime(startDate)) {
        return (
            <StyledWeekViewTimeTableCell
                {...restProps}
                className={classes.weekendCell}
            />
        );
    }
    return <StyledWeekViewTimeTableCell {...restProps} />;
};

const DayScaleCell = ({ ...restProps }) => {
    const { startDate } = restProps;
    if (startDate.getDay() === 0 || startDate.getDay() === 6) {
        return (
            <StyledWeekViewDayScaleCell
                {...restProps}
                className={classes.weekEnd}
            />
        );
    }
    return <StyledWeekViewDayScaleCell {...restProps} />;
};

const SCHEDULER_STATE_CHANGE_ACTION = 'SCHEDULER_STATE_CHANGE';

const SchedulerContainer = ({
    currentDate,
    onCurrentDateChange,
    currentViewName,
    onCurrentViewNameChange,
}) => {
    //data from store
    const filters = useSelector(state => state.appointments.data.filters);
    const catalogs = useSelector(state => state.catalogs.catalogs);
    const data = useSelector(state => state.appointments.data.rows);
    const status = useSelector(state => state.appointments.status);

    const [state, setState] = useState({
        data: [],
        currentDate: new Date(),
        currentViewName: 'Week',
        editingFormVisible: false,
        addedAppointment: {},
        appointmentChanges: {},
        editingAppointment: undefined,
        previousAppointment: undefined,
        isNewAppointment: false,
    });
    const [show, setShow] = useState(false);

    const filtersActive =
        filters.generalFilter.selected.length > 0 ||
        filters.locationFilter.selected.length > 0;

    const filteredData = ((filtersActive, data) => {
        if (!filtersActive) return data;
        let newData = [];
        if (filters.generalFilter.selected) {
            const filterItems = query => {
                return data.filter(
                    row =>
                        row.location
                            .toLowerCase()
                            .indexOf(query.toLowerCase()) > -1
                );
            };
            newData = filterItems(filters.generalFilter.selected);
        }
        if (filters.locationFilter.selected.length > 0) {
            function checkAvailability(arr, val) {
                return arr.some(arrVal => val === arrVal);
            }
            data.map(appointment => {
                if (
                    checkAvailability(
                        filters.locationFilter.selected,
                        appointment.location
                    )
                ) {
                    return newData.push(appointment);
                }
                return null;
            });
        }
        // if (filters.usersStatusOptions.selected) {
        //     newData = (newData.length > 0 ? newData : data).filter(
        //         row => row.usersStatus === filters.usersStatusOptions.selected
        //     );
        // }
        return newData;
    })(filtersActive, data);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAppointmentsCatalog());
    }, [dispatch]);

    const toggleEditingFormVisibility = () => {
        if (!show) {
            setShow(true);
        } else {
            setShow(false);
            setState({
                ...state,
                editingAppointment: undefined,
                addedAppointment: {},
                appointmentChanges: {},
            });
            dispatch(resetValues());
        }
    };

    const onEditingAppointmentChange = editingAppointment => {
        setState({ ...state, editingAppointment });
    };

    useEffect(() => {
        dispatch(fetchAppointments());
    }, [dispatch]);

    const changeAddedAppointment = addedAppointment => {
        toggleEditingFormVisibility();
        setState({ ...state, addedAppointment });
        if (state.editingAppointment !== undefined) {
            setState({
                previousAppointment: state.editingAppointment,
            });
        }
        setState({
            editingAppointment: undefined,
            isNewAppointment: true,
        });
    };

    const changeAppointmentChanges = appointmentChanges => {
        setState({ ...state, appointmentChanges });
    };

    const changeEditingAppointment = editingAppointment => {
        toggleEditingFormVisibility();
        setState({ ...state, editingAppointment });
    };

    const commitChanges = ({ added, changed, deleted }) => {
        setState(() => {
            if (added) {
                const startingAddedId =
                    data.length > 0 ? data[data.length - 1].id + 1 : 0;
                data = [...data, { id: startingAddedId, ...added }];
            }
            if (changed) {
                data = data.map(appointment =>
                    changed[appointment.id]
                        ? { ...appointment, ...changed[appointment.id] }
                        : appointment
                );
            }
            if (deleted !== undefined) {
                data = data.filter(appointment => appointment.id !== deleted);
            }
            return { ...state, data: data };
        });
    };

    const formatDate = value => {
        if (value) {
            const UTCValue = new Date(value).toUTCString();
            const formatDateValue = new Date(UTCValue + '+0600').toISOString();
            const newDate = formatDateValue
                .split(':')
                .slice(0, 2)
                .toString()
                .replace(/,/g, ':');
            return newDate;
        } else {
            return null;
        }
    };

    const AppointmentContent = ({ data, formatDate, ...restProps }) => (
        <StyledAppointmentsAppointmentContent
            {...restProps}
            formatDate={formatDate}
            data={data}
        >
            <div className={classes.container}>
                <div className={classes.title}>
                    {data.title +
                        ' ' +
                        clientFind(data.clientId, catalogs?.clients)}
                </div>
                {data.trainers?.map(trainer => {
                    return (
                        <div className={classes.text}>{trainer.fullName}</div>
                    );
                })}
                <div className={classes.textContainer}>
                    <div className={classes.time}>
                        {formatDate(data.startDate, {
                            hour: 'numeric',
                            minute: 'numeric',
                        })}
                    </div>
                    <div className={classes.time}>{' - '}</div>
                    <div className={classes.time}>
                        {formatDate(data.endDate, {
                            hour: 'numeric',
                            minute: 'numeric',
                        })}
                    </div>
                </div>
            </div>
        </StyledAppointmentsAppointmentContent>
    );

    const appointmentForm = connectProps(CustomForm, () => {
        const currentAppointment =
            data.filter(
                appointment =>
                    state.editingAppointment &&
                    appointment.id === state.editingAppointment.id
            )[0] || state.addedAppointment;
        const cancelAppointment = () => {
            if (state.isNewAppointment) {
                setState({
                    editingAppointment: state.previousAppointment,
                    isNewAppointment: false,
                });
            }
        };

        let formattedData = {
            ...currentAppointment,
            startDate: formatDate(currentAppointment?.startDate),
            endDate: formatDate(currentAppointment?.endDate),
        };

        return {
            visible: show,
            catalogs: catalogs,
            appointmentData: formattedData,
            commitChanges: commitChanges,
            visibleChange: toggleEditingFormVisibility,
            onEditingAppointmentChange: onEditingAppointmentChange,
            cancelAppointment,
        };
    });
    return (
        <Paper>
            {status === LOADING_STATUS.LOADING ? (
                <Loading />
            ) : (
                <>
                    <Scheduler
                        data={filteredData}
                        height={660}
                        catalogs={catalogs}
                    >
                        <EditingState
                            onCommitChanges={e => commitChanges(e)}
                            addedAppointment={state.addedAppointment}
                            onAddedAppointmentChange={e => {
                                changeAddedAppointment(e);
                            }}
                            appointmentChanges={state.appointmentChanges}
                            onAppointmentChangesChange={e =>
                                changeAppointmentChanges(e)
                            }
                            editingAppointment={state.editingAppointment}
                            onEditingAppointmentChange={e => {
                                changeEditingAppointment(e);
                            }}
                        />
                        <ViewState
                            currentDate={state.currentDate}
                            onCurrentDateChange={e =>
                                setState({ ...state, currentDate: e })
                            }
                            currentViewName={state.currentViewName}
                            onCurrentViewNameChange={e =>
                                setState({ ...state, currentViewName: e })
                            }
                        />
                        <DayView startDayHour={9} endDayHour={19} />
                        <WeekView
                            startDayHour={6}
                            endDayHour={23}
                            timeTableCellComponent={TimeTableCell}
                            dayScaleCellComponent={DayScaleCell}
                        />
                        <EditRecurrenceMenu />

                        <Appointments
                            appointmentContentComponent={AppointmentContent}
                        />
                        <Resources data={resources} />
                        <AppointmentTooltip showOpenButton showDeleteButton />

                        <SchedulerFilters {...filters} />
                        <DateNavigator />
                        <ViewSwitcher />
                        <AppointmentForm overlayComponent={appointmentForm} />
                    </Scheduler>
                </>
            )}
        </Paper>
    );
};

export default SchedulerContainer;
