import * as yup from 'yup';
import {
    emailErrorMessage,
    maxCharMessage,
    phoneErrorMessage,
    phoneRegExp,
    requiredMessage,
} from '../../../helpers/validationSchemaHelpers';

export const initialPermissionsOfRole = {
    id: '',
    name: '',
    codeName: '',
    description: '',
    essential: false,
    state: true,
    url: '',
};

export const initialAllPermissions = {
    id: '',
    name: '',
    codeName: '',
    description: '',
    essential: false,
    state: false,
    url: '',
};

export const initialValues = {
    roleInfo: {
        id: '',
        name: '',
        description: '',
    },
    permissionsOfRole: [{ ...initialPermissionsOfRole }],
    allPermissions: [{ ...initialAllPermissions }],
};

export const validationSchema = yup.object({
    roleInfo: yup.object({
        name: yup.string().max(50, maxCharMessage).required(requiredMessage),
        description: yup
            .string()
            .max(250, maxCharMessage)
            .required(requiredMessage),
    }),
});
