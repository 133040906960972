const envOption = process.env.REACT_APP_ENV === 'development' ? '/' : '/';

export const axiosConfig = apiModule => {
    return {
        baseURL: `${process.env.REACT_APP_API}${envOption}${apiModule}`,
    };
};

export const axiosConfigForm = apiModule => {
    return `${process.env.REACT_APP_API}${envOption}${apiModule}`;
};
