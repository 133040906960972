import PageHeader from '../../../common/PageHeader/PageHeader';
import { Button } from '@material-ui/core';
import ExportButton from '../../../common/ExportButton/ExportButton';
import { Link } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import {
    fetchSubjectPayments,
    createPayments,
    resetValues,
} from './subjectPaymentsSlice';
import PaymentTable from './paymentTable/PaymentTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';
import { useParams, useHistory } from 'react-router';
import { set } from 'lodash';
import { LOADING_STATUS } from '../../../helpers/constants';
import {
    fetchPaymentStatus,
    fetchPaymentTypes,
} from '../../catalogs/catalogSlice';

const useStyles = makeStyles(theme => ({
    downloadButton: {
        backgroundColor: green[600],
        color: 'white',
        '&:hover': {
            backgroundColor: green[800],
        },
    },
    topButton: {
        '&:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
}));

const csvHeaders = [
    { label: 'Materia', key: 'paymentName' },
    { label: 'Tipo', key: 'type' },
    { label: 'fecha de inicio', key: 'startDate' },
    { label: 'Fecha de termino', key: 'endDate' },
    { label: 'Costo', key: 'cost' },
    { label: 'Status', key: 'paymentStatus' },
];

const SubjectPayments = () => {
    const { id, subject } = useParams();
    const classes = useStyles();
    const [isData, setIsData] = useState(false);
    const [newRows, setNewRows] = useState();
    const rows = useSelector(state => state.subjectPayments.data.rows);
    const status = useSelector(state => state.subjectPayments.status);
    const uploadStatus = useSelector(
        state => state.subjectPayments.uploadStatus
    );
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (uploadStatus === LOADING_STATUS.SUCCEEDED) {
            dispatch(fetchSubjectPayments(id))
                .unwrap()
                .then(() => resetValues());
        }
    }, [dispatch, uploadStatus, id]);

    useEffect(() => {
        if (id) {
            dispatch(fetchSubjectPayments(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(fetchPaymentTypes())
            .unwrap()
            .then(() => dispatch(fetchPaymentStatus()));
    }, [dispatch]);

    const capitalLetter = string => {
        let lowStr = string.toLowerCase();
        let capStr = lowStr.charAt(0).toUpperCase() + lowStr.slice(1);
        return capStr;
    };

    return (
        <div>
            <PageHeader
                title={`Pagos de ${capitalLetter(subject)}`}
            ></PageHeader>

            {/* <PaymentFilters {...filters} /> */}
            <PaymentTable
                status={status}
                rows={rows}
                data={e => {
                    setIsData(true);
                    console.log(e);
                }}
                idCourse={id}
            />
        </div>
    );
};

export default SubjectPayments;
