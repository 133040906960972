import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAdminPayments, getStudents } from '../../../api/payments';
import { LOADING_STATUS } from '../../../helpers/constants';
import { formatPayments, getFilterData } from './utils';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';

const initialState = {
    data: {
        filters: {
            paymentNameOptions: {
                selected: '',
                options: [],
            },
            paymentEmailOptions: {
                selected: '',
                options: [],
            },
            paymentRoleOptions: {
                selected: '',
                options: [],
            },
            paymentStatusOptions: {
                selected: '',
                options: [],
            },
        },
        rows: [],
    },
    status: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchAdminPayments = createAsyncThunk(
    'payments/fetchAdminPayments',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getStudents();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar lista de usuarios.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const adminPaymentSlice = createSlice({
    name: 'adminPayments',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        changeOption: (state, { payload }) => {
            state.data.filters[payload.name].selected = payload.value;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAdminPayments.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchAdminPayments.fulfilled, (state, { payload }) => {
                const cleanPayments = formatPayments(payload);

                state.status = LOADING_STATUS.SUCCEEDED;
                state.data.rows = cleanPayments;
                state.data.filters = getFilterData(cleanPayments);
            })
            .addCase(fetchAdminPayments.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { changeOption } = adminPaymentSlice.actions;

export default adminPaymentSlice.reducer;
