import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    Toolbar,
    MonthView,
    DayView,
    WeekView,
    DateNavigator,
    ViewSwitcher,
    Appointments,
    AppointmentTooltip,
    AppointmentForm,
    DragDropProvider,
    EditRecurrenceMenu,
    AllDayPanel,
} from '@devexpress/dx-react-scheduler-material-ui';
import { connectProps } from '@devexpress/dx-react-core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import CustomForm from './form/CustomForm';
import AppointmentContent from './AppointmentContent';

const PREFIX = 'Demo';
const classes = {
    content: `${PREFIX}-content`,
    header: `${PREFIX}-header`,
    closeButton: `${PREFIX}-closeButton`,
    buttonGroup: `${PREFIX}-buttonGroup`,
    button: `${PREFIX}-button`,
    picker: `${PREFIX}-picker`,
    wrapper: `${PREFIX}-wrapper`,
    icon: `${PREFIX}-icon`,
    textField: `${PREFIX}-textField`,
    addButton: `${PREFIX}-addButton`,
};

const StyledDiv = styled('div')(({ theme }) => ({
    [`& .${classes.icon}`]: {
        margin: theme.spacing(2, 0),
        marginRight: theme.spacing(2),
    },
    [`& .${classes.header}`]: {
        overflow: 'hidden',
        paddingTop: theme.spacing(0.5),
    },
    [`& .${classes.textField}`]: {
        width: '100%',
    },
    [`& .${classes.content}`]: {
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    [`& .${classes.closeButton}`]: {
        float: 'right',
    },
    [`& .${classes.picker}`]: {
        marginRight: theme.spacing(2),
        '&:last-child': {
            marginRight: 0,
        },
        width: '50%',
    },
    [`& .${classes.wrapper}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 0),
    },
    [`& .${classes.buttonGroup}`]: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 2),
    },
    [`& .${classes.button}`]: {
        marginLeft: theme.spacing(2),
    },
}));

const StyledFab = styled(Fab)(({ theme }) => ({
    [`&.${classes.addButton}`]: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(4),
    },
}));

const Demo = props => {
    const [data, setData] = useState(props.data);
    const [catalogs, setCatalogs] = useState(props.catalogs);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [editingFormVisible, setEditingFormVisible] = useState(false);
    const [deletedAppointmentId, setDeletedAppointmentId] = useState();
    const [editingAppointment, setEditingAppointment] = useState();
    const [previousAppointment, setPreviousAppointment] = useState();
    const [addedAppointment, setAddedAppointment] = useState({});
    const [isNewAppointment, setIsNewAppointment] = useState(false);
    const startDayHour = 9;
    const endDayHour = 23;

    const formatDate = value => {
        if (value) {
            const UTCValue = new Date(value).toUTCString();
            const formatDateValue = new Date(UTCValue + '+0600').toISOString();
            const newDate = formatDateValue
                .split(':')
                .slice(0, 2)
                .toString()
                .replace(/,/g, ':');
            return newDate;
        }
        return null;
    };

    const toggleEditingFormVisibility = () => {
        setEditingFormVisible(!editingFormVisible);
    };

    const toggleConfirmationVisible = () => {
        setConfirmationVisible(!confirmationVisible);
    };

    const commitChanges = ({ added, changed, deleted }) => {
        let updatedData = data;
        if (added) {
            const startingAddedId =
                updatedData.length > 0
                    ? updatedData[updatedData.length - 1].id + 1
                    : 0;
            updatedData = [...updatedData, { id: startingAddedId, ...added }];
        }
        if (changed) {
            updatedData = updatedData.map(appointment =>
                changed[appointment.id]
                    ? { ...appointment, ...changed[appointment.id] }
                    : appointment
            );
        }
        if (deleted !== undefined) {
            setDeletedAppointmentId(deleted);
            toggleConfirmationVisible();
        }
        setData(updatedData);
        setAddedAppointment({});
    };

    const appointmentForm = connectProps(CustomForm, () => {
        const currentAppointment =
            data.find(
                appointment =>
                    editingAppointment &&
                    appointment.id === editingAppointment.id
            ) || addedAppointment;

        const cancelAppointment = () => {
            if (isNewAppointment) {
                setEditingAppointment(previousAppointment);
                setIsNewAppointment(false);
            }
        };

        let formattedData = {
            ...currentAppointment,
            startDate: formatDate(currentAppointment?.startDate),
            endDate: formatDate(currentAppointment?.endDate),
        };

        return {
            visible: editingFormVisible,
            appointmentData: formattedData,
            catalogs,
            commitChanges,
            visibleChange: toggleEditingFormVisibility,
            onEditingAppointmentChange: setEditingAppointment,
            cancelAppointment,
        };
    });

    const appointmentContent = connectProps(AppointmentContent, () => ({
        data,
        catalogs,
    }));

    useEffect(() => {
        appointmentForm.update();
    }, [appointmentForm]);

    const onAddedAppointmentChange = addedAppointment => {
        setAddedAppointment(addedAppointment);
        if (editingAppointment) {
            setPreviousAppointment({ ...editingAppointment });
        }
        setEditingAppointment(undefined);
        setIsNewAppointment(true);
    };

    const commitDeletedAppointment = () => {
        setData(
            data.filter(appointment => appointment.id !== deletedAppointmentId)
        );
        toggleConfirmationVisible();
    };

    return (
        <Paper>
            <Scheduler data={props.data} height={660}>
                <ViewState
                    currentDate={currentDate}
                    onCurrentDateChange={setCurrentDate}
                />
                <EditingState
                    onCommitChanges={commitChanges}
                    onEditingAppointmentChange={setEditingAppointment}
                    onAddedAppointmentChange={onAddedAppointmentChange}
                />
                <WeekView
                    startDayHour={startDayHour}
                    endDayHour={endDayHour}
                    cellDuration={60}
                    name="SEMANA"
                />
                <DayView
                    startDayHour={startDayHour}
                    endDayHour={endDayHour}
                    cellDuration={60}
                    name="DÍA"
                />
                <MonthView name="MES" />
                <AllDayPanel />
                <EditRecurrenceMenu />
                <Appointments
                    appointmentContentComponent={AppointmentContent}
                />
                <AppointmentTooltip
                    showOpenButton
                    showCloseButton
                    showDeleteButton
                />
                <Toolbar />
                <DateNavigator />
                <ViewSwitcher />
                <AppointmentForm
                    overlayComponent={appointmentForm}
                    visible={editingFormVisible}
                    onVisibilityChange={toggleEditingFormVisibility}
                />
                <DragDropProvider />
            </Scheduler>

            <Dialog
                open={confirmationVisible}
                onClose={toggleConfirmationVisible}
            >
                <DialogTitle>Delete Appointment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this appointment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={toggleConfirmationVisible}
                        color="primary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={commitDeletedAppointment}
                        color="secondary"
                        variant="outlined"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <StyledFab
                color="secondary"
                className={classes.addButton}
                onClick={() => {
                    setEditingFormVisible(true);
                    setEditingAppointment(undefined);
                    onAddedAppointmentChange({
                        startDate: new Date(currentDate).setHours(startDayHour),
                        endDate: new Date(currentDate).setHours(
                            startDayHour + 1
                        ),
                    });
                }}
            >
                <AddIcon />
            </StyledFab>
        </Paper>
    );
};

export default Demo;
