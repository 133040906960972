export const formatModalities = modalitiesArray =>
    modalitiesArray.map(modality => {
        return {
            id: modality.idCourseModality,
            text: modality?.name ?? '',
        };
    });

export const formatBranches = branchesArray =>
    branchesArray.map(branch => {
        return {
            id: branch.idBranch,
            text: branch?.name ?? '',
        };
    });

export const formatSubjectDocumentType = subjectDocumentTypeArray =>
    subjectDocumentTypeArray.map(subjectDocumentType => {
        return {
            id: subjectDocumentType.idDocumentType,
            text: subjectDocumentType?.description ?? '',
        };
    });

export const formatPaymentStatus = paymentStatusArray =>
    paymentStatusArray.map(paymentStatus => {
        return {
            id: paymentStatus.idPaymentStatus,
            text: paymentStatus?.description ?? '',
        };
    });

export const formatPaymentTypes = paymentTypesArray =>
    paymentTypesArray.map(paymentType => {
        return {
            id: paymentType.idPaymentType,
            text: paymentType?.name ?? '',
        };
    });

export const formatMenusForHome = menusForHomeArray =>
    menusForHomeArray.map(menuForHome => {
        return {
            id: menuForHome.idSubjectType,
            text: menuForHome?.description ?? '',
            status: menuForHome?.status ?? null,
            options: menuForHome.options.map(option => ({
                id: option?.idSubject,
                text: option?.name ?? '',
            })),
        };
    });
