export const formatOpportunities = opportunitiesArray =>
    opportunitiesArray.map(opportunity => {
        return {
            id: opportunity.opportunityId,
            opportunityName: opportunity?.opportunityName ?? '',
            goesBy: opportunity?.opportunityGoesBy ?? '',
            opportunityContactName: opportunity?.contactGoesBy ?? '',
            opportunityContactPhone: opportunity?.contactPhoneNumber ?? '',
            modifiedDate: opportunity.opportunityUpdatedDate ?? null,
            opportunityStatus:
                opportunity?.opportunityStatusId === 1
                    ? 'Activo'
                    : opportunity?.opportunityStatusId === 2
                    ? 'Cerrado'
                    : opportunity?.opportunityStatusId === 3
                    ? 'En proceso'
                    : 'Pendiente',
        };
    });

export const getFilterData = opportunities => {
    const opportunityNameOptions = {
        selected: '',
        options: Array.from(
            opportunities.reduce(
                (acc, cur) =>
                    cur.opportunityName ? acc.add(cur.opportunityName) : acc,
                new Set()
            )
        ),
    };

    const opportunityContactNameOptions = {
        selected: '',
        options: Array.from(
            opportunities.reduce(
                (acc, cur) =>
                    cur.opportunityContactName
                        ? acc.add(cur.opportunityContactName)
                        : acc,
                new Set()
            )
        ),
    };

    const opportunityStatusOptions = {
        selected: '',
        options: Array.from(
            opportunities.reduce(
                (acc, cur) =>
                    cur.opportunityStatus
                        ? acc.add(cur.opportunityStatus)
                        : acc,
                new Set()
            )
        ),
    };

    const goesByOptions = {
        selected: '',
        options: Array.from(
            opportunities.reduce(
                (acc, cur) => (cur.goesBy ? acc.add(cur.goesBy) : acc),
                new Set()
            )
        ),
    };

    return {
        opportunityContactNameOptions,
        opportunityNameOptions,
        opportunityStatusOptions,
        goesByOptions,
    };
};
