const hexToRgbObject = hexCode => {
    let hex = hexCode.replace('#', '');
    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return { r, g, b };
};

export const hexToRgba = (hexCode, opacity = 1) => {
    if (opacity < 0 || opacity > 1) {
        throw new Error('Opacity should be between 0 and 1 (inclusive).');
    }
    const { r, g, b } = hexToRgbObject(hexCode);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const hexToHslObject = hexCode => {
    const { r: r255, g: g255, b: b255 } = hexToRgbObject(hexCode);
    // Make r, g, and b fractions of 1
    const [r, g, b] = [r255, g255, b255].map(color => color / 255);
    // Find greatest and smallest channel values
    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;
    // Calculate hue
    // No difference
    if (delta === 0) h = 0;
    // Red is max
    else if (cmax === r) h = ((g - b) / delta) % 6;
    // Green is max
    else if (cmax === g) h = (b - r) / delta + 2;
    // Blue is max
    else h = (r - g) / delta + 4;
    h = Math.round(h * 60);
    // Make negative hues positive behind 360°
    if (h < 0) h += 360;
    // Calculate lightness
    l = (cmax + cmin) / 2;
    // Calculate saturation
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    // Multiply l and s by 100
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);
    return { h, s, l };
};

const hslToRgb = ({ h, s: saturation, l: lightness }) => {
    let s = saturation > 100 ? 1 : saturation < 0 ? 0 : saturation / 100,
        l = lightness > 100 ? 1 : lightness < 0 ? 0 : lightness / 100;
    let c = (1 - Math.abs(2 * l - 1)) * s,
        x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
        m = l - c / 2,
        r = 0,
        g = 0,
        b = 0;
    if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
    }
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
};

export const adjustLightness = (hexColor, lightness, saturation = 0) => {
    const hslColor = hexToHslObject(hexColor);
    hslColor.l += lightness;
    hslColor.s += saturation;
    return hslToRgb(hslColor);
};
