import axios from 'axios';
import { axiosConfig, axiosConfigForm } from './axiosConfig';

const axiosConfigClient =
    process.env.REACT_APP_ENV === 'development'
        ? axiosConfig('/api/v1')
        : axiosConfig('client.sigrha.com');

export const getClients = async id => {
    const { data } = await axios({
        method: 'get',
        url: axiosConfigForm(`api/v1/clients`),
        params: { ...(id ? { userId: id } : {}) },
    });

    return data;
};

export const getSingleClient = async id => {
    const { data } = await axios.get(`/clients/${id}`, axiosConfig('api/v1'));
    return data;
};

export const createClient = async body => {
    const { data } = await axios.post(`clients`, body, axiosConfig('api/v1'));
    return data;
};

export const updateClient = async (id, body) => {
    const { data } = await axios.patch(
        `/clients/${id}`,
        body,
        axiosConfig('api/v1')
    );

    return data;
};

export const createPet = async body => {
    const { data } = await axios.post(`pets`, body, axiosConfig('api/v1'));
    return data;
};

export const updatePet = async (id, body) => {
    const { data } = await axios.patch(
        `pets/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const updateEthogram = async (id, body) => {
    const { data } = await axios.patch(
        `ethograms/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const getClientsforDropdown = async () => {
    const { data } = await axios.get(
        '/clients/getclientsdropdown',
        axiosConfigClient
    );
    return data;
};

export const createSingleContact = async body => {
    const { data } = await axios.post(
        '/Contacts/create',
        body,
        axiosConfigClient
    );

    return data;
};

export const createTrainerAssign = async body => {
    const { data } = await axios.post(
        `employeesClients`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const updateTrainerAssign = async (id, body) => {
    const { data } = await axios.patch(
        `employeesClients/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const uploadPetImage = async (id, body) => {
    const { data } = await axios({
        method: 'patch',
        url: axiosConfigForm(`api/v1/pets/images/${id}`),
        data: body,
        headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data;
};
