import axios from 'axios';
import { axiosConfig } from './axiosConfig';

export const getCourseList = async () => {
    const { data } = await axios.get(
        'https://centro-universitario-dev.herokuapp.com/api/materias/get/list'
    );
    return data.data;
};

export const createCourse = async body => {
    const { data } = await axios.post(
        'courses/PostCourses',
        body,
        axiosConfig('api')
    );
    return data;
};

export const updateCourse = async body => {
    const { data } = await axios.post(
        'courses/PutCourses',
        body,
        axiosConfig('api')
    );
    return data;
};

export const createSchedule = async body => {
    const { data } = await axios.post(
        'CourseSchedule/PostSchedule',
        body,
        axiosConfig('api')
    );
    return data;
};

export const assignCourse = async body => {
    const { data } = await axios.post(
        'CourseAssignments/PostCourseAssignment',
        body,
        axiosConfig('api')
    );
    return data;
};
