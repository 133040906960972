import axios from 'axios';
import { axiosConfig } from './axiosConfig';

const axiosConfigOpportunities =
    process.env.REACT_APP_ENV === 'development'
        ? axiosConfig('opportunities-api')
        : axiosConfig('opportunities.sigrha.com');

export const getOpportunities = async () => {
    const { data } = await axios.get(
        '/Opportunities/getall',
        axiosConfigOpportunities
    );
    return data;
};

export const getSingleOpportunity = async id => {
    const { data } = await axios.get(
        `/Opportunities/getfull/${id}`,
        axiosConfigOpportunities
    );
    const newData = [{ ...data }];
    return newData;
};

export const createOpportunity = async body => {
    const { data } = await axios.post(
        '/Opportunities/create',
        body,
        axiosConfigOpportunities
    );

    return data;
};

export const updateOpportunity = async (id, body) => {
    const { data } = await axios.put(
        `/Opportunities/update/${id}`,
        body,
        axiosConfigOpportunities
    );

    return data;
};

export const getAllOpportunityType = async () => {
    const { data } = await axios.get(
        '/OpportunityType/getall',
        axiosConfigOpportunities
    );
    return data;
};
