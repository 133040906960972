import * as yup from 'yup';
import {
    maxCharMessage,
    requiredMessage,
} from '../../../helpers/validationSchemaHelpers';

export const initialValues = {
    fileName: '',
    idDocumentType: '',
    documentNotes: '',
    docNumber: '',
};

export const validationSchema = yup.object({
    fileName: yup.string().max(50, maxCharMessage).required(requiredMessage),
    idDocumentType: yup.number().required(requiredMessage),
    documentNotes: yup.string().max(255, maxCharMessage),
    docNumber: yup.string().max(25, maxCharMessage),
});
