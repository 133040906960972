export const roleInfoMapper = role => {
    return {
        id: role.pk ?? '',
        name: role.name ?? '',
        description:
            role.description === '' ? 'Sin descripcion' : role.description,
    };
};

export const permissionsMapper = permissionsArray =>
    permissionsArray.map(permit => {
        return {
            id: permit.pk ?? '',
            name: permit.name ?? '',
            codeName: permit.codename ?? '',
            description: permit.description ?? '',
            essential: permit.essential ?? false,
            state: false,
            url: permit.url ?? '',
        };
    });

export const permissionsRoleMapper = permissionsArray =>
    permissionsArray.map(permit => {
        return {
            id: permit.pk ?? '',
            name: permit.name ?? '',
            codeName: permit.codename ?? '',
            description: permit.description ?? '',
            essential: permit.essential ?? false,
            state: true,
            url: permit.url ?? '',
        };
    });

export const formatInsertData = values => {
    const formatted = {
        name: values.roleInfo.name,
        description: values.roleInfo.description,
    };
    return formatted;
};

export const formatInsertDataPermissions = values => {
    const formatted = values.permissionsOfRole.map(permit =>
        permit.state
            ? {
                  ...(permit.state ? { pk: permit.id } : null),
              }
            : null
    );
    return formatted;
};
