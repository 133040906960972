import * as yup from 'yup';
import {
    emailErrorMessage,
    maxCharMessage,
    minCharMessage,
    onlyLetters,
    onlyLettersMessage,
    phoneErrorMessage,
    phoneRegExp,
    requiredMessage,
} from '../../../helpers/validationSchemaHelpers';

export const initialEthogramInfo = {
    birthday: '',
    adoptionDate: '',
    adoptionId: null,
    living: '',
    socialization: '',
    weeklyWalks: '',
    feeding: '',
    targets: '',
    vaccinationComplete: '',
    diseases: '',
    petId: null,
};

export const initialPetInfo = {
    image: null,
    name: '',
    breed: '',
    genderId: null,
    ethogram: { ...initialEthogramInfo },
};

export const initialUserInfo = {
    email: '',
};

export const initialAddressInfo = {
    address: '',
    city: '',
    state: '',
    postalCode: '',
    suburb: '',
    mapLink: '',
};

export const initialValues = {
    generalInfo: {
        fullName: '',
        membership: false,
        birthday: '',
        phoneNumber: '',
        paidClasses: 0,
    },
    userInfo: { ...initialUserInfo },
    address: { ...initialAddressInfo },
};

export const validationSchema = yup.object({
    generalInfo: yup.object({
        fullName: yup
            .string()
            .min(3, minCharMessage)
            .max(30, maxCharMessage)
            .required(requiredMessage),
        phoneNumber: yup
            .string()
            .min(10, minCharMessage)
            .max(15, maxCharMessage),
    }),
    userInfo: yup.object({
        email: yup
            .string()
            .max(50, maxCharMessage)
            .email(emailErrorMessage)
            .required(requiredMessage),
    }),
    address: yup.object({
        address: yup.string().max(50, maxCharMessage).required(requiredMessage),
        city: yup
            .string()
            .matches(onlyLetters, onlyLettersMessage)
            .min(3, minCharMessage)
            .max(10, maxCharMessage)
            .required(requiredMessage),
        state: yup
            .string()
            .matches(onlyLetters, onlyLettersMessage)
            .max(15, maxCharMessage)
            .required(requiredMessage),
        suburb: yup
            .string()
            .matches(onlyLetters, onlyLettersMessage)
            .max(20, maxCharMessage),
        postalCode: yup
            .string()
            .min(5, 'Mínimo 5 dígitos')
            .max(5, 'Máximo 5 dígitos')
            .required(requiredMessage),
    }),
});

export const validationPetsSchema = yup.object({
    name: yup
        .string()
        .min(3, minCharMessage)
        .max(30, maxCharMessage)
        .required(requiredMessage),
    breed: yup.string().matches(onlyLetters, onlyLettersMessage),
    genderId: yup.number().required(requiredMessage),
    ethogram: yup.object().shape({
        birthday: yup.date().required(requiredMessage),
        living: yup.string().min(3, minCharMessage).max(30, maxCharMessage),
        socialization: yup.string().matches(onlyLetters, onlyLettersMessage),
        vaccinationComplete: yup.boolean().required(requiredMessage),
        sextupleVaccine: yup.date().when('vaccinationComplete', {
            is: true,
            then: yup
                .date()
                .max(new Date(), 'La fecha no puede ser en el futuro')
                .min(
                    new Date(
                        new Date().setFullYear(new Date().getFullYear() - 1)
                    ),
                    'La fecha debe estar dentro del último año'
                )
                .required('La fecha es obligatoria'),
        }),
        diseases: yup.string().max(250, maxCharMessage),
        targets: yup.string().max(250, maxCharMessage),
    }),
});
