// TODO full slice, there's only initialState for now
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LOADING_STATUS } from '../../helpers/constants';
import { getAssignedSubjects } from '../../api/subjects';
import { getUsersById } from '../../api/clients';
import { enqueueSnackbar } from '../pushNotifications/pushNotificationsSlice';
import { assignedSubjectMapper } from './utils';

const initialState = {
    data: {
        rows: [],
    },
    status: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchAssignedSubjects = createAsyncThunk(
    'assignedSubjects/fetchAssignedSubjects',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getAssignedSubjects(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar la lista de materias.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAssignedSubjects.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchAssignedSubjects.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.data.rows = assignedSubjectMapper(payload);
            })
            .addCase(fetchAssignedSubjects.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = profileSlice.actions;

export default profileSlice.reducer;
