import PageHeader from '../../../common/PageHeader/PageHeader';
import { Button } from '@material-ui/core';
import ExportButton from '../../../common/ExportButton/ExportButton';
import { Link } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { fetchCourses } from './CoursesSlice';
import CoursesFilters from './coursesFilters/CoursesFilters';
import CoursesTable from './coursesTable/CoursesTable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    downloadButton: {
        backgroundColor: green[600],
        color: 'white',
        '&:hover': {
            backgroundColor: green[800],
        },
    },
    topButton: {
        '&:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
}));

const csvHeaders = [
    { label: 'Materias', key: 'course' },
    { label: 'Status de la materia', key: 'coursesStatus' },
];

const Courses = () => {
    const classes = useStyles();
    const status = useSelector(state => state.courses.status);
    const filters = useSelector(state => state.courses.data.filters);
    const rows = useSelector(state => state.courses.data.rows);

    const filtersActive = Object.values(filters).some(
        ({ selected }) => selected
    );

    const filteredRows = ((filtersActive, rows) => {
        if (!filtersActive) return rows;
        let newRows = [];
        if (filters.coursesOptions.selected) {
            const filterItems = query => {
                return rows.filter(
                    row =>
                        row.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
            };

            newRows = filterItems(filters.coursesOptions.selected);
        }
        if (filters.coursesStatusOptions.selected) {
            newRows = (newRows.length > 0 ? newRows : rows).filter(
                row =>
                    row.coursesStatus === filters.coursesStatusOptions.selected
            );
        }

        return newRows;
    })(filtersActive, rows);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCourses());
    }, [dispatch]);

    return (
        <div>
            <PageHeader title="Materias">
                <Button
                    className={classes.topButton}
                    color="primary"
                    component={Link}
                    to="/subjects/create"
                    variant="contained"
                >
                    Adicionar
                </Button>
                <ExportButton
                    headers={csvHeaders}
                    inputData={rows}
                    fileName="Materias.csv"
                    className={`${classes.topButton} ${classes.downloadButton}`}
                >
                    Exportar a Excel
                </ExportButton>
            </PageHeader>

            <CoursesFilters {...filters}></CoursesFilters>
            <CoursesTable rows={filteredRows} status={status}></CoursesTable>
        </div>
    );
};

export default Courses;
