import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ReactWhatsapp from 'react-whatsapp';

const useStyles = makeStyles(theme => ({
    whatsApp: {
        border: 'none',
        background: 'none',
        color: 'inherit',
    },
    socialContent: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            marginRight: '20px',
        },
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        // marginTop: theme.spacing(8),
        padding: theme.spacing(6, 0),
    },
}));

export default function Footer(props) {
    const classes = useStyles();
    const { description, title, social = [] } = props;

    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    justifyContent="center"
                    alignContent="center"
                >
                    <Grid item>
                        <Typography variant="h6" align="center" gutterBottom>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="subtitle1"
                            align="center"
                            color="textSecondary"
                            component="p"
                        >
                            {description}
                        </Typography>
                    </Grid>
                    <Grid container className={classes.socialContent}>
                        {social.map(network => (
                            <Link
                                color="inherit"
                                display="block"
                                variant="body1"
                                href={network.webSite.toString()}
                                key={network}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <network.icon />
                                    </Grid>
                                    <Grid
                                        className={classes.sectionDesktop}
                                        item
                                    >
                                        {network.name}
                                    </Grid>
                                </Grid>
                            </Link>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
}

Footer.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
};
