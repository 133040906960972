export const clientMapper = client => {
    return {
        generalInfo: {
            fullName: client.fullName ?? '',
            membership: client.membership ?? false,
            birthday: client.birthday?.slice(0, 10) ?? '',
            phoneNumber: client.phoneNumber ?? '',
            paidClasses: client.paidClasses ?? 0,
            createdDate: client.createdAt?.slice(0, 10) ?? '',
        },
        userInfo: {
            id: client.user.id,
            email: client.user?.email ?? '',
            role: client.user?.roleId ?? null,
            userStatus: client.user?.userStatusId ?? null,
        },
        trainers: client.trainers?.map(trainer => ({
            id: trainer.EmployeeClient?.id ?? null,
            trainerId: trainer.EmployeeClient?.employeeId ?? null,
            clientId: trainer.EmployeeClient?.clientId ?? null,
        })),
        pets: client.pets?.map(pet => ({
            petId: pet.id ?? null,
            image: pet.image ?? '',
            name: pet.name ?? '',
            breed: pet.breed ?? '',
            genderId: pet.genderId ?? null,
            clientId: pet.clientId ?? null,
            ethogram: {
                ethogramId: pet.ethogram?.id ?? null,
                birthday: pet.ethogram?.birthday?.slice(0, 10) ?? '',
                adoptionDate: pet.ethogram?.adoptionDate?.slice(0, 10) ?? '',
                adoptionId: pet.ethogram?.adoptionId ?? null,
                living: pet.ethogram?.living ?? '',
                socialization: pet.ethogram?.socialization ?? '',
                weeklyWalks: pet.ethogram?.weeklyWalks ?? null,
                feeding: pet.ethogram?.feeding ?? '',
                targets: pet.ethogram?.targets ?? '',
                vaccinationComplete: pet.ethogram?.vaccinationComplete ?? null,
                sextupleVaccine:
                    pet.ethogram?.sextupleVaccine?.slice(0, 10) ?? '',
                diseases: pet.ethogram?.diseases ?? '',
                petId: pet.ethogram?.petId ?? null,
            },
        })),
        address: {
            addressId: client.user?.address?.id ?? '',
            city: client.user?.address?.city ?? '',
            address: client.user?.address?.address ?? '',
            state: client.user?.address?.state ?? '',
            postalCode: client.user?.address?.postalCode ?? null,
            suburb: client.user?.address?.suburb ?? '',
            userId: client.user?.id ?? null,
            mapLink: client.user?.address?.mapLink ?? null,
        },
        // contactInfo: client.contacts?.map(contact => ({
        //     contactId: contact.id ?? '',
        //     firstName: contact.firstName ?? '',
        //     middleName: contact.middleName ?? '',
        //     lastName: contact.lastName ?? '',
        //     officePhoneNumber: contact.phoneNumber ?? '',
        //     cellPhoneNumber: contact.mobileNumber ?? '',
        //     email: contact.email ?? '',
        //     goesBy: contact.goesBy ?? '',
        //     contactNotes: contact.contactNotes ?? '',
        //     role: contact.contactRole ?? '',
        //     getInTouchWith: contact.firstcontact ?? '',
        //     influence: contact.influencyId ?? 1,
        // })),
        // fiscalInfo: client.fiscalEntities?.map(fiscal => ({
        //     fiscalEntityId: fiscal.id ?? '',
        //     name: fiscal.name ?? '',
        //     fiscalId: fiscal.fiscalNumber ?? '',
        //     contactName: fiscal.contactName ?? '',
        //     phoneNumber: fiscal.phone ?? '',
        //     address: fiscal.address ?? '',
        //     email: fiscal.email ?? '',
        // })),
        // address: client.addresses?.map(address => ({
        //     addressId: address.id ?? '',
        //     addressType: address.addressTypeId ?? 1,
        //     address: address.address ?? '',
        //     idCountry: address.countryId ?? 1,
        //     state: address.stateId ?? '',
        //     municipality: address.municipality ?? '',
        //     township: address.township ?? '',
        //     zipCode: address.postalCodeId ?? '',
        //     suburb: '', // Missing
        // })),
        // documents: client.documents.map(document => ({
        //     documentId: document.id ?? '',
        //     idDocumentType: document.documentTypeId ?? '',
        //     docNumber: document.docNumber ?? '',
        //     documentNotes: document.documentNotes ?? '',
        //     effBeginDate: document.effBeginDate,
        //     effEndDate: document.effEndDate,
        //     fileName: document.fileName ?? '',
        //     file: document.file ?? '',
        // })),
    };
};

const includeProp = (key, value, type) => {
    if (type === 'string') {
        return value ? { [key]: value.toString() } : {};
    } else {
        return value ? { [key]: value } : {};
    }
};

export const formatCreateClient = (values, id) => {
    const formatted = {
        fullName: values.generalInfo.fullName,
        phoneNumber: values.generalInfo.phoneNumber.toString(),
        membership: values.generalInfo.membership,
        ...includeProp('birthday', values.generalInfo.birthday),
        user: {
            email: values.userInfo.email,
            password: values.userInfo.email,
            roleId: values.userInfo.roleId,
            userStatusId: values.userInfo.userStatus,
            address: {
                address: values.address.address,
                state: values.address.state,
                postalCode: values.address.postalCode,
                suburb: values.address.suburb,
                city: values.address.city,
            },
        },
    };
    return formatted;
};

export const formatUpdateClient = values => {
    const formatted = {
        fullName: values.generalInfo.fullName,
        phoneNumber: values.generalInfo.phoneNumber.toString(),
        membership: values.generalInfo.membership,
        ...includeProp('birthday', values.generalInfo.birthday),
    };
    return formatted;
};

export const formatUpdateUser = values => {
    const formatted = {
        email: values.userInfo.email,
        roleId: values.userInfo.roleId,
        userStatusId: values.userInfo.userStatus,
    };
    return formatted;
};
export const formatUpdateAddressInfo = values => {
    const formatted = {
        ...includeProp('city', values.address.city),
        ...includeProp('state', values.address.state),
        ...includeProp('postalCode', values.address.postalCode),
        ...includeProp('address', values.address.address),
        ...includeProp('suburb', values.address.suburb),
    };
    return formatted;
};

export const formatCreatePet = values => {
    const formatted = {
        name: values.name,
        ...includeProp('breed', values.breed),
        genderId: values.genderId,
        clientId: values.clientId,
        ethogram: {
            birthday: values.ethogram.birthday,
            adoptionId: values.ethogram.adoptionId,
            ...includeProp('adoptionDate', values.ethogram.adoptionDate),
            ...includeProp('living', values.ethogram.living),
            ...includeProp('socialization', values.ethogram.socialization),
            ...includeProp('weeklyWalks', values.ethogram.weeklyWalks),
            ...includeProp('feeding', values.ethogram.feeding),
            vaccinationComplete: values.ethogram.vaccinationComplete,
            ...includeProp('sextupleVaccine', values.ethogram.sextupleVaccine),
            ...includeProp('diseases', values.ethogram.diseases),
            ...includeProp('targets', values.ethogram.targets),
        },
    };
    return formatted;
};

export const formatUpdatePet = values => {
    const formatted = {
        name: values.name,
        ...includeProp('breed', values.breed),
        genderId: values.genderId,
        clientId: values.clientId,
    };
    return formatted;
};

export const formatUpdateEthogram = values => {
    const formatted = {
        birthday: values.ethogram.birthday,
        adoptionId: values.ethogram.adoptionId,
        ...includeProp('adoptionDate', values.ethogram.adoptionDate),
        ...includeProp('living', values.ethogram.living),
        ...includeProp('socialization', values.ethogram.socialization),
        ...includeProp('weeklyWalks', values.ethogram.weeklyWalks),
        ...includeProp('feeding', values.ethogram.feeding),
        vaccinationComplete: values.ethogram.vaccinationComplete,
        ...includeProp('sextupleVaccine', values.ethogram.sextupleVaccine),
        ...includeProp('diseases', values.ethogram.diseases),
        ...includeProp('targets', values.ethogram.targets),
    };
    return formatted;
};

export const formatTrainerAssign = (values, id) => {
    const formatted = {
        employeeId: values.trainerId,
        clientId: id,
    };
    return formatted;
};
