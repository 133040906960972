import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PetsIcon from '@material-ui/icons/Pets';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import Trainers from './trainers/Trainers';
import Pets from './petsTable/Pets';
import ClientInfo from './clientInfo/ClientInfo';
import ClassesTable from './classesTable/ClassesTable';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ClientTabs({ info, pets, trainers, appointments }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                    <Tab
                        label="Información"
                        icon={<PersonPinIcon />}
                        {...a11yProps(0)}
                    />
                    <Tab
                        label="Mis mascotas"
                        icon={<PetsIcon />}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Entrenadores"
                        icon={<AssignmentIndIcon />}
                        {...a11yProps(2)}
                    />
                    <Tab
                        label="Clases individuales"
                        icon={<FormatListBulletedIcon />}
                        {...a11yProps(3)}
                    />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <ClientInfo />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Pets rows={pets} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Trainers cards={trainers} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <ClassesTable rows={appointments} />
            </TabPanel>
        </div>
    );
}
