import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar, Container, Grid, ListItemAvatar } from '@material-ui/core';
import { FastField, Form, Formik } from 'formik';
import { OutlinedTextField } from '../../../../common/FormComponents/FormComponents';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const formatDate = value => {
    if (value) {
        const UTCValue = new Date(value).toUTCString();
        const formatDateValue = new Date(UTCValue + '+0600').toISOString();
        const newDate = formatDateValue
            .split(':')
            .slice(0, 2)
            .toString()
            .replace(/,/g, ':');
        return newDate.split('T')[1];
    } else {
        return null;
    }
};

function printTime(startDate, endDate) {
    return `${startDate} - ${endDate}`;
}

function Row(props) {
    const { row, index } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <AvatarGroup>
                        {row.trainers?.map(trainer => (
                            <Avatar
                                alt={trainer.fullName}
                                src={trainer.image}
                            />
                        ))}
                    </AvatarGroup>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.title}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.startDate.slice(0, 10)}
                </TableCell>
                <TableCell>
                    {printTime(
                        formatDate(row.startDate, {
                            hour: 'numeric',
                            minute: 'numeric',
                        }),
                        formatDate(row.endDate, {
                            hour: 'numeric',
                            minute: 'numeric',
                        })
                    )}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.appointmentStatus}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                Resumen de clase
                            </Typography>
                            <Formik
                                enableReinitialize
                                initialValues={row.individualClass}
                            >
                                {() => {
                                    return (
                                        <Form>
                                            <Container
                                                className={classes.container}
                                            >
                                                <Grid container spacing={3}>
                                                    <FastField
                                                        component={
                                                            OutlinedTextField
                                                        }
                                                        name="trainerRating"
                                                        type="number"
                                                        label="Calificación"
                                                        edit={false}
                                                    />
                                                    <FastField
                                                        component={
                                                            OutlinedTextField
                                                        }
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        multiline
                                                        maxRows={4}
                                                        name="description"
                                                        type="text"
                                                        label="Descripción"
                                                        edit={false}
                                                    />
                                                </Grid>
                                            </Container>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function ClassesTable({ rows = [] }) {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Entrenadores</TableCell>
                        <TableCell align="left">Clase</TableCell>
                        <TableCell align="left">Fecha</TableCell>
                        <TableCell align="left">Hora</TableCell>
                        <TableCell align="left">Estatus</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <Row key={row.name} row={row} index={index} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
