import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUsersByTeachersId } from '../../../../api/subjects';
import { LOADING_STATUS } from '../../../../helpers/constants';
import { enqueueSnackbar } from '../../../pushNotifications/pushNotificationsSlice';
import { formatTeachers } from './utils';

const initialState = {
    dropdownTeachers: [],
    status: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchDropdownTeachers = createAsyncThunk(
    'clients/fetchDropdownTeachers',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getUsersByTeachersId();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar el usuario.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

const dropdownSlice = createSlice({
    name: 'dropdown',
    initialState,
    reducers: {
        addClientContact: {
            reducer: (state, { payload }) => {
                state.dropdownTeachers.push({ ...payload });
            },
            prepare: (id, value, text) => {
                return { payload: { id, value, text } };
            },
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchDropdownTeachers.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchDropdownTeachers.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.dropdownTeachers = formatTeachers(payload);
            })
            .addCase(fetchDropdownTeachers.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { addClientContact } = dropdownSlice.actions;

export default dropdownSlice.reducer;
