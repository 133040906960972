import axios from 'axios';
import { axiosConfig } from './axiosConfig';

export const contactMail = async body => {
    const { data } = await axios.post(
        'mails/contactMail',
        body,
        axiosConfig('api/v1')
    );
    return data;
};
