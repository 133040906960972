import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import SelectField from '../../../../common/SelectField/SelectField';
import TextField from '@material-ui/core/TextField';
import { changeOption } from '../ServicesSlice';

const useStyles = makeStyles(theme => ({
    filtersContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(3),
        '& .MuiTextField-root': {
            minWidth: '25ch',
            maxWidth: `calc(25% - (3px * ${theme.spacing(2)} / 4))`,
            flexGrow: 1,
            '&:not(:last-child)': {
                marginRight: theme.spacing(2),
                marginBottom: theme.spacing(1),
            },
        },
    },
}));

const UsersFilters = ({ priceOptions = { options: [] } }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleChange = ({ target: { name, value } }) => {
        dispatch(changeOption({ name, value }));
    };

    return (
        <div className={classes.filtersContainer}>
            <TextField
                variant="outlined"
                label="Usuario"
                name="servicesOptions"
                onChange={handleChange}
            />
            <SelectField
                label="Rol"
                name="priceOptions"
                onChange={handleChange}
                options={priceOptions}
            />
        </div>
    );
};

export default UsersFilters;
