import {
    Avatar,
    Button,
    CardMedia,
    Container,
    Grid,
    IconButton,
    MenuItem,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FastField, FieldArray, Form, Formik, getIn } from 'formik';
import { useEffect, useRef, useState } from 'react';
import {
    AddNewElement,
    LabelledCheckbox,
    OutlinedTextField,
} from '../../../common/FormComponents/FormComponents';
import PageHeader from '../../../common/PageHeader/PageHeader';
import DeleteIcon from '@material-ui/icons/Delete';
import { validationSchema } from './mainForm';
import { red } from '@material-ui/core/colors';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCatalogs } from '../../catalogs/catalogSlice';
import { formatData } from './utils';
import { LOADING_STATUS } from '../../../helpers/constants';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { renderDateValue } from '../../../helpers/formHelpers';
import CardMediaComponent from '../../../common/cardMediaComponent/cardMediaComponent';
import {
    createSingleVideo,
    fetchVideoInfo,
    resetValues,
    updateSingleVideo,
    uploadImage,
    uploadVideo,
    uploadVideoFile,
} from './SingleVideoSlice';

const useStyles = makeStyles(theme => ({
    arraySubtitle: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: 700,
        marginTop: theme.spacing(-1.5),
        marginBottom: theme.spacing(-1.5),
        '$formSubSection + $formSubSection &': {
            marginTop: theme.spacing(2),
        },
        '& > span': {
            marginRight: theme.spacing(1),
        },
    },
    deleteIcon: {
        color: red[400],
        marginTop: theme.spacing(0.2),
    },
    formSection: {
        backgroundColor: theme.palette.grey[50],
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(4),
        },
    },
    formSectionHeading: {
        color: theme.palette.primary.main,
        marginBottom: 0,
        marginTop: 0,
    },
    formSubSection: {},
    noData: {
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 1,
        textAlign: 'center',
    },
    subtitleWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
    uploadGrid: {
        marginBottom: theme.spacing(1),
    },
    container: {
        display: 'flex',
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        maxWidth: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    cover: {
        height: 240,
        minWidth: 240,
    },
    inputFile: {
        width: 0,
        height: 0,
    },
    button: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        border: 'none',
        height: 50,
        maxWidth: 240,
    },
}));

const SingleVideo = ({ create = false }) => {
    const classes = useStyles();

    const prevSubmitCount = useRef(0);

    const [edit, setEdit] = useState(create);
    const [videoFile, setVideoFile] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [videoLoaded, setVideoLoaded] = useState(null);

    const { id } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();
    const video = useSelector(state => state.singleVideo.video);
    // const catalogs = useSelector(state => state.catalogs.catalogs);
    const insertStatus = useSelector(state => state.singleVideo.insertStatus);
    const fetchStatus = useSelector(
        state => state.singleVideo.fetchVideoInfoStatus
    );
    const catalogs = useSelector(state => state.catalogs.catalogs);
    const catalogStatus = useSelector(state => state.catalogs.catalogs.status);

    const loading =
        fetchStatus === LOADING_STATUS.LOADING ||
        catalogStatus === LOADING_STATUS.LOADING;

    const insertingVideo = insertStatus === LOADING_STATUS.LOADING;

    useEffect(() => {
        if (id) {
            dispatch(fetchVideoInfo(id)).then(({ payload }) => {
                setVideoFile(payload.video);
                setImageFile(payload.image);
            });
        }
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(fetchAllCatalogs());
    }, [dispatch]);

    // useEffect(() => {
    //     if (fetchStatus === LOADING_STATUS.SUCCEEDED) {
    //         setFiles(user.documents);
    //     }
    // }, [user.documents, fetchStatus]);

    useEffect(() => {
        if (insertStatus === LOADING_STATUS.SUCCEEDED) {
            history.push('/videos');
            dispatch(resetValues());
        }
    }, [dispatch, history, insertStatus]);

    useEffect(() => {
        if (create) {
            dispatch(resetValues());
        }
    }, [dispatch, history, insertStatus]);

    const handleButtonClick = e => {
        e.preventDefault();
        if (!edit) {
            setEdit(true);
        }
    };

    //form data init

    // function buildFormData(formData, data, parentKey) {
    //     if (data && typeof data === 'object') {
    //         Object.keys(data).forEach(key => {
    //             buildFormData(
    //                 formData,
    //                 data[key],
    //                 parentKey ? `${parentKey}[${key}]` : key
    //             );
    //         });
    //     } else {
    //         const value = data == null ? '' : data;

    //         formData.append(parentKey, value);
    //     }
    // }

    // function jsonToFormData(data) {
    //     const formData = new FormData();

    //     buildFormData(formData, data);

    //     return formData;
    // }

    // function videoToFormData(data) {
    //     const formData = new FormData();
    //     buildFormData(formData, data);
    //     formData.append('Video', data);
    //     return formData;
    // }

    // const handleFileChange = file => {
    //     const videoFile = formatInsertVideo(file);
    //     let body = jsonToFormData(objectFile);
    //     dispatch(uploadPayment(body))
    //         .unwrap()
    //         .then(() => {
    //             handleClose();
    //         });
    // };

    // form data end

    const convertBase64 = file => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = error => {
                reject(error);
            };
        });
    };

    const handleSubmit = values => {
        if (id) {
            if (videoFile !== video.videoInfo.video) {
                const videoFormData = new FormData();
                videoFormData.append(
                    'video',
                    new File([videoFile], `video_${id}.mp4`)
                );
                dispatch(
                    uploadVideo({
                        id,
                        body: videoFormData,
                    })
                );
            }
            if (imageFile !== video.videoInfo.image) {
                const imageFormData = new FormData();
                imageFormData.append(
                    'image',
                    new File([imageFile], `video_${id}.jpg`)
                );
                dispatch(
                    uploadImage({
                        id,
                        body: imageFormData,
                    })
                );
            }
            if (values.videoInfo.title !== video.videoInfo.title) {
                let formattedInfo = formatData(values);
                dispatch(
                    updateSingleVideo({
                        id: id,
                        body: formattedInfo,
                    })
                );
            } else {
                setEdit(false);
            }
        } else {
            let body = {
                ...formatData(values),
            };
            dispatch(createSingleVideo(body)).then(async e => {
                const videoFormData = new FormData();
                const imageFormData = new FormData();

                videoFormData.append(
                    'video',
                    new File([videoFile], `video_${e.payload.id}.mp4`)
                );
                imageFormData.append(
                    'image',
                    new File([imageFile], `video_${e.payload.id}.jpg`)
                );
                await dispatch(
                    uploadVideo({
                        id: e.payload.id,
                        body: videoFormData,
                    })
                );
                await dispatch(
                    uploadImage({
                        id: e.payload.id,
                        body: imageFormData,
                    })
                );
            });
        }
    };

    const handleFieldUpdate = (nextProps, currentProps) => {
        if (
            nextProps.edit !== currentProps.edit ||
            nextProps.disabled !== currentProps.disabled ||
            nextProps.loading !== currentProps.loading ||
            nextProps.catalog !== currentProps.catalog ||
            nextProps.name !== currentProps.name ||
            getIn(nextProps.formik.values, currentProps.name) !==
                getIn(currentProps.formik.values, currentProps.name) ||
            getIn(nextProps.formik.errors, currentProps.name) !==
                getIn(currentProps.formik.errors, currentProps.name) ||
            getIn(nextProps.formik.touched, currentProps.name) !==
                getIn(currentProps.formik.touched, currentProps.name) ||
            Object.keys(currentProps).length !==
                Object.keys(nextProps).length ||
            nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <main>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={video}
                onSubmit={handleSubmit}
            >
                {props => {
                    const { errors, submitCount, values } = props;
                    if (submitCount !== prevSubmitCount.current) {
                        prevSubmitCount.current = submitCount;
                        if (Object.keys(errors).length > 0) {
                            dispatch(
                                enqueueSnackbar({
                                    message:
                                        'Por favor llene todos los campos correctamente.',
                                    options: {
                                        key:
                                            new Date().getTime() +
                                            Math.random(),
                                        variant: 'error',
                                    },
                                })
                            );
                        }
                    }
                    return (
                        <Form>
                            <PageHeader
                                title={
                                    create
                                        ? 'Nuevo video'
                                        : edit
                                        ? 'Editar video'
                                        : 'Consulta de video'
                                }
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type={edit ? 'submit' : 'button'}
                                    onClick={
                                        !edit ? handleButtonClick : undefined
                                    }
                                    disabled={loading || insertingVideo}
                                >
                                    {create
                                        ? 'Crear'
                                        : edit
                                        ? 'Aplicar cambios'
                                        : 'Editar'}
                                </Button>
                            </PageHeader>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <FastField
                                        component={OutlinedTextField}
                                        name="videoInfo.title"
                                        type="text"
                                        label="Titulo*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingVideo}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                </div>
                                <Container className={classes.container}>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <CardMediaComponent
                                                edit={edit}
                                                handleFileChange={e => {
                                                    setImageFile(e);
                                                }}
                                                onClick={e => null}
                                                title={values.videoInfo.title}
                                                loading={loading}
                                                image={
                                                    imageFile === null
                                                        ? ''
                                                        : imageFile
                                                }
                                                alt={values.videoInfo.title}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <CardMedia
                                                controls
                                                className={classes.cover}
                                                src={videoLoaded ?? ''}
                                                component={'video'}
                                                title={values.videoInfo.title}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                component="label"
                                                variant="contained"
                                                className={classes.button}
                                                size="small"
                                                color="primary"
                                            >
                                                Cargar video
                                                <input
                                                    name="video"
                                                    type="file"
                                                    className={
                                                        classes.inputFile
                                                    }
                                                    onChange={async e => {
                                                        const file =
                                                            e.target.files[0];
                                                        if (
                                                            file !== undefined
                                                        ) {
                                                            const split = file.name.split(
                                                                '.'
                                                            );
                                                            const newFileName = values.videoInfo.title
                                                                .replace(
                                                                    / /g,
                                                                    ''
                                                                )
                                                                .toLowerCase();
                                                            const base64 = await convertBase64(
                                                                file
                                                            );
                                                            let copiedFile = new File(
                                                                [file],
                                                                `${newFileName}.jpg`,
                                                                {
                                                                    filename:
                                                                        file.name,
                                                                    type:
                                                                        'image/jpeg',
                                                                    lastModified: new Date(),
                                                                }
                                                            );
                                                            setVideoLoaded(
                                                                base64
                                                            );
                                                            setVideoFile(
                                                                copiedFile
                                                            );
                                                        }
                                                    }}
                                                />
                                            </Button>
                                        </Grid>
                                        {/* <FastField
                                            component={OutlinedTextField}
                                            select
                                            name="videoInfo.userStatus"
                                            type="text"
                                            label="Estatus*"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingVideo}
                                            shouldUpdate={handleFieldUpdate}
                                            catalog={catalogs.user_status}
                                        >
                                            {catalogs.user_status?.length > 0 &&
                                                catalogs.user_status.map(
                                                    ({ id, text }) => (
                                                        <MenuItem
                                                            value={id}
                                                            key={id}
                                                        >
                                                            {text}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </FastField> */}
                                    </Grid>
                                </Container>
                            </section>
                        </Form>
                    );
                }}
            </Formik>
        </main>
    );
};

export default SingleVideo;
