export const carouselMapper = carousel => {
    return {
        id: carousel?.id,
        image: carousel?.image ?? '',
        title: carousel?.title ?? '',
        description: carousel?.description ?? '',
        expirationDate: carousel?.expirationDate?.slice(0, 10) ?? '',
        link: carousel?.link ?? null,
    };
};

const includeProp = (key, value, type) => {
    if (type === 'string') {
        return value ? { [key]: value.toString() } : {};
    } else {
        return value ? { [key]: value } : {};
    }
};

export const formatData = values => {
    const formatted = {
        title: values.carouselInfo.title,
        ...includeProp('description', values.carouselInfo.description),
        ...includeProp('title', values.carouselInfo.title),
        ...includeProp('expirationDate', values.carouselInfo.expirationDate),
        ...includeProp('link', values.carouselInfo.link),
    };
    return formatted;
};

export const formatInsertImage = values => ({
    filename: values.file,
});
