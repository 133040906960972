import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    Toolbar,
    MonthView,
    WeekView,
    ViewSwitcher,
    Appointments,
    AppointmentTooltip,
    AppointmentForm,
    DragDropProvider,
    EditRecurrenceMenu,
    AllDayPanel,
} from '@devexpress/dx-react-scheduler-material-ui';
import { connectProps } from '@devexpress/dx-react-core';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import LocationOn from '@mui/icons-material/LocationOn';
import Notes from '@mui/icons-material/Notes';
import Close from '@mui/icons-material/Close';
import CalendarToday from '@mui/icons-material/CalendarToday';
import Create from '@mui/icons-material/Create';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Grid, Button, ListItemIcon } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { FastField, Form, Formik, getIn } from 'formik';
import { OutlinedTextField } from '../../../common/FormComponents/FormComponents';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { initialFormInfo, validationSchema } from './mainForm';
import { formatInfoForm } from './utils';
import { sendRequestInfoInfo } from './requestInfoFormSlice';
import { LOADING_STATUS } from '../../../helpers/constants';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import { fetchTrainersList, resetValues } from './checklist/checklistSlice';
import { createAppointmentEmployee } from '../../../api/appointment';
import {
    createSingleAppointment,
    createSingleAssociation,
    createSingleClass,
    createSingleOrder,
    deleteSingleAssociation,
    fetchAppointments,
    updateSingleAppointment,
} from '../schedulerSlice';
import {
    formatAppointment,
    formatIndividualClass,
    formatOrder,
    formatUpdateAppointment,
} from '../utils';
import { fetchAppointmentsCatalog } from '../../catalogs/catalogSlice';

//stepper
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { fetchServices } from '../../services/main/ServicesSlice';

const PREFIX = 'Demo';
// #FOLD_BLOCK
const classes = {
    content: `${PREFIX}-content`,
    header: `${PREFIX}-header`,
    closeButton: `${PREFIX}-closeButton`,
    buttonGroup: `${PREFIX}-buttonGroup`,
    button: `${PREFIX}-button`,
    picker: `${PREFIX}-picker`,
    wrapper: `${PREFIX}-wrapper`,
    icon: `${PREFIX}-icon`,
    textField: `${PREFIX}-textField`,
    addButton: `${PREFIX}-addButton`,
};

// #FOLD_BLOCK
// const StyledDiv = styled('div')(({ theme }) => ({
//     [`& .${classes.icon}`]: {
//         margin: theme.spacing(2, 0),
//         marginRight: theme.spacing(2),
//     },
//     [`& .${classes.header}`]: {
//         overflow: 'hidden',
//         paddingTop: theme.spacing(0.5),
//     },
//     [`& .${classes.textField}`]: {
//         width: '100%',
//     },
//     [`& .${classes.content}`]: {
//         padding: theme.spacing(2),
//         paddingTop: 0,
//     },
//     [`& .${classes.closeButton}`]: {
//         float: 'right',
//     },
//     [`& .${classes.picker}`]: {
//         marginRight: theme.spacing(2),
//         '&:last-child': {
//             marginRight: 0,
//         },
//         width: '50%',
//     },
//     [`& .${classes.wrapper}`]: {
//         display: 'flex',
//         justifyContent: 'space-between',
//         padding: theme.spacing(1, 0),
//     },
//     [`& .${classes.buttonGroup}`]: {
//         display: 'flex',
//         justifyContent: 'flex-end',
//         padding: theme.spacing(0, 2),
//     },
//     [`& .${classes.button}`]: {
//         marginLeft: theme.spacing(2),
//     },
// }));
// const StyledFab = styled(Fab)(({ theme }) => ({
//     [`&.${classes.addButton}`]: {
//         position: 'absolute',
//         bottom: theme.spacing(3),
//         right: theme.spacing(4),
//     },
// }));

function getSteps(id, role, clientId) {
    if (!id && (role === 'Administrador' || role === 'Developer')) {
        return ['Orden de pago', 'Fecha y hora'];
    }
    // else if (id && (role === 'Administrador' || role === 'Developer')) {
    //     return ['Fecha y hora'];
    // } else if (!id && role === 'Entrenador') {
    //     return ['Fecha y hora'];
    // }
    else if (id && role === 'Entrenador' && clientId) {
        return ['Clase'];
    } else {
        return ['Fecha y hora'];
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: 'transparent',
    },
    field: {
        padding: theme.spacing(1),
    },
    forEdit: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(4, 0, 0, 0),
    },
    formSection: {
        display: 'flex',
        backgroundColor: 'transparent',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(3),
        },
    },
    checklist: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const CustomForm = ({
    visible,
    visibleChange,
    appointmentData = { initialFormInfo },
    cancelAppointment,
    target,
    onHide,
}) => {
    const [activeStep, setActiveStep] = useState(0);
    const classes = useStyles();
    const prevSubmitCount = useRef(0);
    const [subjectOptions, setSubjectOptions] = useState([]);
    const dispatch = useDispatch();
    const role = useSelector(state => state.user.userData.userInfo.role);
    const employeeId = useSelector(
        state => state.user.userData.employee.employeeId
    );
    const catalogs = useSelector(state => state.catalogs.catalogs);
    const trainers = useSelector(state => state.checklist.trainersList);
    const services = useSelector(state => state.services.data.rows);
    const sendStatus = useSelector(state => state.requestForm.sendStatus);
    const subjectType = useSelector(
        state => state.catalogs.catalogs.menusForHome
    );
    const steps = getSteps(appointmentData.id, role, appointmentData.clientId);
    const [checked, setChecked] = useState([]);
    const [checkedProducts, setCheckedProducts] = useState([0]);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    console.log(appointmentData);

    useEffect(() => {
        if (sendStatus === LOADING_STATUS.SUCCEEDED) {
            dispatch(resetValues());
        }
    }, [dispatch, sendStatus]);

    useEffect(() => {
        if (appointmentData?.clientId) {
            dispatch(fetchTrainersList(appointmentData.clientId)).then(() => {
                let assigned = appointmentData.trainers.map(
                    trainer => trainer.assigned.employeeId
                );
                setChecked([...assigned]);
            });
        } else {
            dispatch(resetValues());
        }
    }, [appointmentData, dispatch, setChecked]);

    useEffect(() => {
        dispatch(fetchServices());
    }, [dispatch]);

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleToggleProducts = value => () => {
        const currentIndex = checkedProducts.indexOf(value);
        const newChecked = [...checkedProducts];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedProducts(newChecked);
    };

    function arrayValidation(oldArray, newArray) {
        const addedValues = newArray.filter(item => !oldArray.includes(item));
        const deletedValues = oldArray.filter(item => !newArray.includes(item));

        return { addedValues, deletedValues };
    }

    function newTab(url) {
        window.open(url, '_blank');
    }

    console.log(catalogs);

    function getStatus(text) {
        let status = catalogs?.appointmentStatus?.find(
            status => status.text === text
        );
        console.log(status);
        return status.id;
    }

    function handleSubmit(values) {
        if (appointmentData.id) {
            let body = formatAppointment(values);
            let assignedArray = appointmentData.trainers.map(
                value => value?.assigned?.employeeId
            );
            let addedArray = arrayValidation(assignedArray, checked)
                .addedValues;
            let deletedArray = arrayValidation(assignedArray, checked)
                .deletedValues;
            let formatAddedValues = addedArray.map(item => ({
                employeeId: item,
                appointmentId: appointmentData.id,
            }));
            let formatDeletedValues = deletedArray.map(item =>
                appointmentData.trainers.find(
                    value => value.assigned.employeeId === item
                )
            );
            if (
                JSON.stringify(body) !==
                JSON.stringify(formatAppointment(appointmentData))
            ) {
                dispatch(
                    updateSingleAppointment({
                        id: appointmentData.id,
                        body,
                    })
                );
            }
            if (values.description) {
                let newBody = {
                    ...body,
                    appointmentStatusId: getStatus('Completado'),
                };
                dispatch(createSingleClass(formatIndividualClass(values))).then(
                    () =>
                        dispatch(
                            updateSingleAppointment({
                                id: appointmentData.id,
                                body: newBody,
                            })
                        )
                );
            }
            if (appointmentData.clientId) {
                formatDeletedValues.map(item =>
                    dispatch(deleteSingleAssociation(item.assigned.id))
                );
                formatAddedValues.map(body =>
                    dispatch(createSingleAssociation(body))
                );
            }
            visibleChange();
            cancelAppointment();
            handleReset();
        } else {
            let addedArray = arrayValidation([], checked).addedValues;
            const newValues = {
                ...values,
                appointmentStatusId: getStatus('Pendiente'),
            };
            let body = formatAppointment(newValues);
            let orderBody = formatOrder(values);
            if (role === 'Administrador' || role === 'Developer') {
                dispatch(createSingleOrder(orderBody));
                dispatch(createSingleAppointment(body)).then(res => {
                    console.log(res.payload);
                    let formatAddedValues = addedArray.map(item => ({
                        employeeId: item,
                        appointmentId: res.payload.data.id,
                    }));
                    formatAddedValues.map(item =>
                        dispatch(createSingleAssociation(item))
                    );
                });
            } else if (role === 'Entrenador') {
                dispatch(createSingleAppointment(body)).then(res => {
                    console.log(res.payload);
                    let formatAddedValues = {
                        employeeId: employeeId,
                        appointmentId: res.payload.data.id,
                    };
                    dispatch(createSingleAssociation(formatAddedValues));
                });
            }
        }
    }

    const handleFieldUpdate = (nextProps, currentProps) => {
        if (
            nextProps.edit !== currentProps.edit ||
            nextProps.disabled !== currentProps.disabled ||
            nextProps.catalog !== currentProps.catalog ||
            nextProps.loading !== currentProps.loading ||
            nextProps.name !== currentProps.name ||
            getIn(nextProps.formik.values, currentProps.name) !==
                getIn(currentProps.formik.values, currentProps.name) ||
            getIn(nextProps.formik.errors, currentProps.name) !==
                getIn(currentProps.formik.errors, currentProps.name) ||
            getIn(nextProps.formik.touched, currentProps.name) !==
                getIn(currentProps.formik.touched, currentProps.name) ||
            Object.keys(currentProps).length !==
                Object.keys(nextProps).length ||
            nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <AppointmentForm.Overlay
            visible={visible}
            target={target}
            fullSize
            onHide={onHide}
        >
            <Formik
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                initialValues={appointmentData}
            >
                {props => {
                    const { errors, submitCount, values, setValues } = props;
                    if (submitCount !== prevSubmitCount.current) {
                        prevSubmitCount.current = submitCount;
                        if (Object.keys(errors).length > 0) {
                            dispatch(
                                enqueueSnackbar({
                                    message:
                                        'Por favor llene todos los campos correctamente.',
                                    options: {
                                        key:
                                            new Date().getTime() +
                                            Math.random(),
                                        variant: 'error',
                                    },
                                })
                            );
                        }
                    }
                    return (
                        <>
                            {console.log(appointmentData)}
                            <div className={classes.root}>
                                <Stepper
                                    activeStep={activeStep}
                                    alternativeLabel
                                >
                                    {steps.map(label => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                    <IconButton
                                        disabled={false}
                                        aria-label="close"
                                        className={classes.closeButton}
                                        onClick={() => {
                                            visibleChange();
                                            cancelAppointment();
                                            handleReset();
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Stepper>
                                <div>
                                    <Form>
                                        {steps.map((step, index) => (
                                            <>
                                                {activeStep === index &&
                                                    step ===
                                                        'Orden de pago' && (
                                                        <>
                                                            <section
                                                                className={
                                                                    classes.formSection
                                                                }
                                                            >
                                                                <Grid
                                                                    container
                                                                    spacing={3}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={6}
                                                                        lg={6}
                                                                    >
                                                                        <FastField
                                                                            component={
                                                                                OutlinedTextField
                                                                            }
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            md={
                                                                                12
                                                                            }
                                                                            lg={
                                                                                12
                                                                            }
                                                                            select
                                                                            name="clientId"
                                                                            type="text"
                                                                            label="Cliente"
                                                                            edit={
                                                                                true
                                                                            }
                                                                            disabled={
                                                                                false
                                                                            }
                                                                            shouldUpdate={
                                                                                handleFieldUpdate
                                                                            }
                                                                            catalog={
                                                                                catalogs?.clients
                                                                            }
                                                                            onChange={e => {
                                                                                let clientId =
                                                                                    e
                                                                                        .target
                                                                                        .value;
                                                                                setChecked(
                                                                                    []
                                                                                );
                                                                                if (
                                                                                    clientId !==
                                                                                    ''
                                                                                ) {
                                                                                    dispatch(
                                                                                        fetchTrainersList(
                                                                                            clientId
                                                                                        )
                                                                                    );
                                                                                } else {
                                                                                    dispatch(
                                                                                        resetValues()
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            <MenuItem value="">
                                                                                &nbsp;
                                                                            </MenuItem>
                                                                            {catalogs
                                                                                ?.clients
                                                                                ?.length >
                                                                                0 &&
                                                                                catalogs?.clients.map(
                                                                                    ({
                                                                                        id,
                                                                                        text,
                                                                                    }) => (
                                                                                        <MenuItem
                                                                                            value={
                                                                                                id
                                                                                            }
                                                                                            key={
                                                                                                id
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                text
                                                                                            }
                                                                                        </MenuItem>
                                                                                    )
                                                                                )}
                                                                        </FastField>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={6}
                                                                        lg={6}
                                                                    >
                                                                        <FastField
                                                                            component={
                                                                                OutlinedTextField
                                                                            }
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            md={
                                                                                12
                                                                            }
                                                                            lg={
                                                                                12
                                                                            }
                                                                            select
                                                                            name="serviceId"
                                                                            type="text"
                                                                            label="Servicio"
                                                                            disabled={
                                                                                false
                                                                            }
                                                                            edit={
                                                                                true
                                                                            }
                                                                            shouldUpdate={
                                                                                handleFieldUpdate
                                                                            }
                                                                            catalog={
                                                                                services
                                                                            }
                                                                        >
                                                                            {services?.length >
                                                                                0 &&
                                                                                services.map(
                                                                                    ({
                                                                                        id,
                                                                                        name,
                                                                                    }) => (
                                                                                        <MenuItem
                                                                                            value={
                                                                                                id
                                                                                            }
                                                                                            key={
                                                                                                id
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                name
                                                                                            }
                                                                                        </MenuItem>
                                                                                    )
                                                                                )}
                                                                        </FastField>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={6}
                                                                        lg={6}
                                                                    >
                                                                        <FastField
                                                                            component={
                                                                                OutlinedTextField
                                                                            }
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            md={
                                                                                12
                                                                            }
                                                                            lg={
                                                                                12
                                                                            }
                                                                            name="deliveryService"
                                                                            type="number"
                                                                            label="Servicio a domicilio"
                                                                            edit={
                                                                                true
                                                                            }
                                                                            disabled={
                                                                                false
                                                                            }
                                                                            shouldUpdate={
                                                                                handleFieldUpdate
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </section>
                                                            <section
                                                                className={
                                                                    classes.formSection
                                                                }
                                                            ></section>
                                                        </>
                                                    )}
                                                {activeStep === index &&
                                                    step === 'Fecha y hora' && (
                                                        <>
                                                            <section
                                                                className={
                                                                    classes.formSection
                                                                }
                                                            >
                                                                <Grid
                                                                    container
                                                                    spacing={3}
                                                                >
                                                                    <FastField
                                                                        component={
                                                                            OutlinedTextField
                                                                        }
                                                                        className={
                                                                            classes.field
                                                                        }
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={12}
                                                                        name="title"
                                                                        type="text"
                                                                        label="titulo"
                                                                        edit={
                                                                            true
                                                                        }
                                                                        disabled={
                                                                            false
                                                                        }
                                                                        shouldUpdate={
                                                                            handleFieldUpdate
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </section>
                                                            {console.log(
                                                                values
                                                            )}
                                                            {values.clientId && (
                                                                <section
                                                                    className={
                                                                        classes.formSection
                                                                    }
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={6}
                                                                        lg={6}
                                                                    >
                                                                        <List
                                                                            dense
                                                                            className={
                                                                                classes.root
                                                                            }
                                                                        >
                                                                            {trainers.map(
                                                                                trainer => {
                                                                                    const labelId =
                                                                                        trainer.id;
                                                                                    return (
                                                                                        <ListItem
                                                                                            key={
                                                                                                trainer.id
                                                                                            }
                                                                                            button
                                                                                        >
                                                                                            <ListItemAvatar>
                                                                                                <Avatar
                                                                                                    alt={`${trainer.text}`}
                                                                                                    src={`${trainer.image}`}
                                                                                                />
                                                                                            </ListItemAvatar>
                                                                                            <ListItemText
                                                                                                id={
                                                                                                    labelId
                                                                                                }
                                                                                                primary={`${trainer.text}`}
                                                                                            />
                                                                                            <ListItemSecondaryAction>
                                                                                                <Checkbox
                                                                                                    edge="end"
                                                                                                    onChange={handleToggle(
                                                                                                        trainer.id
                                                                                                    )}
                                                                                                    checked={
                                                                                                        checked.indexOf(
                                                                                                            trainer.id
                                                                                                        ) !==
                                                                                                        -1
                                                                                                    }
                                                                                                    inputProps={{
                                                                                                        'aria-labelledby': labelId,
                                                                                                    }}
                                                                                                />
                                                                                            </ListItemSecondaryAction>
                                                                                        </ListItem>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </List>
                                                                    </Grid>
                                                                </section>
                                                            )}
                                                            <section
                                                                className={
                                                                    classes.formSection
                                                                }
                                                            >
                                                                <Grid
                                                                    container
                                                                    spacing={3}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={3}
                                                                        lg={3}
                                                                    >
                                                                        <FastField
                                                                            component={
                                                                                OutlinedTextField
                                                                            }
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            md={
                                                                                12
                                                                            }
                                                                            lg={
                                                                                12
                                                                            }
                                                                            name="startDate"
                                                                            type="datetime-local"
                                                                            label="Fecha de inicio*"
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            edit={
                                                                                true
                                                                            }
                                                                            disabled={
                                                                                false
                                                                            }
                                                                            shouldUpdate={
                                                                                handleFieldUpdate
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={3}
                                                                        lg={3}
                                                                    >
                                                                        <FastField
                                                                            component={
                                                                                OutlinedTextField
                                                                            }
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            md={
                                                                                12
                                                                            }
                                                                            lg={
                                                                                12
                                                                            }
                                                                            name="endDate"
                                                                            type="datetime-local"
                                                                            label="Fecha de termino*"
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            edit={
                                                                                true
                                                                            }
                                                                            disabled={
                                                                                false
                                                                            }
                                                                            shouldUpdate={
                                                                                handleFieldUpdate
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </section>
                                                            <section
                                                                className={
                                                                    classes.formSection
                                                                }
                                                            >
                                                                <Grid
                                                                    container
                                                                    spacing={3}
                                                                >
                                                                    <FastField
                                                                        component={
                                                                            OutlinedTextField
                                                                        }
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={12}
                                                                        multiline
                                                                        maxRows={
                                                                            4
                                                                        }
                                                                        name="notes"
                                                                        type="text"
                                                                        label="Notas"
                                                                        edit={
                                                                            true
                                                                        }
                                                                        disabled={
                                                                            false
                                                                        }
                                                                        shouldUpdate={
                                                                            handleFieldUpdate
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </section>
                                                        </>
                                                    )}

                                                {activeStep === index &&
                                                    step === 'Clase' && (
                                                        <>
                                                            <section
                                                                className={
                                                                    classes.formSection
                                                                }
                                                            >
                                                                <Grid
                                                                    container
                                                                    spacing={3}
                                                                >
                                                                    <FastField
                                                                        component={
                                                                            OutlinedTextField
                                                                        }
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={12}
                                                                        multiline
                                                                        maxRows={
                                                                            4
                                                                        }
                                                                        name="trainerReview"
                                                                        type="text"
                                                                        label="Reseña de clase"
                                                                        edit={
                                                                            true
                                                                        }
                                                                        disabled={
                                                                            false
                                                                        }
                                                                        shouldUpdate={
                                                                            handleFieldUpdate
                                                                        }
                                                                    />
                                                                    <FastField
                                                                        component={
                                                                            OutlinedTextField
                                                                        }
                                                                        xs={6}
                                                                        sm={6}
                                                                        md={6}
                                                                        lg={6}
                                                                        name="trainerRating"
                                                                        type="number"
                                                                        label="Calificación"
                                                                        edit={
                                                                            true
                                                                        }
                                                                        disabled={
                                                                            false
                                                                        }
                                                                        shouldUpdate={
                                                                            handleFieldUpdate
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </section>
                                                        </>
                                                    )}
                                            </>
                                        ))}
                                        <section
                                            className={classes.formSection}
                                        >
                                            <Grid
                                                container
                                                spacing={3}
                                                justifyContent="space-around"
                                            >
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    onClick={() => {
                                                        if (activeStep === 0) {
                                                            visibleChange();
                                                            cancelAppointment();
                                                            handleReset();
                                                        } else {
                                                            handleBack();
                                                        }
                                                    }}
                                                >
                                                    {activeStep === 0
                                                        ? 'Cancelar'
                                                        : 'Regresar'}
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    type={
                                                        activeStep ===
                                                        steps.length
                                                            ? 'submit'
                                                            : 'button'
                                                    }
                                                    onClick={() => {
                                                        handleNext();
                                                    }}
                                                >
                                                    {activeStep ===
                                                    steps.length - 1
                                                        ? 'Finalizar'
                                                        : 'Siguiente'}
                                                </Button>
                                                {/* <Button
                                                                    onClick={() => {
                                                                        visibleChange();
                                                                        cancelAppointment();
                                                                    }}
                                                                    color="primary"
                                                                    variant="outlined"
                                                                >
                                                                    Cancel
                                                                </Button>
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    type="submit"
                                                                >
                                                                    aplicar
                                                                </Button> */}
                                            </Grid>
                                        </section>
                                        <div className={classes.forEdit}></div>
                                    </Form>
                                    {/* {activeStep === steps.length ? (
                                        <div>
                                            <Typography
                                                className={classes.instructions}
                                            >
                                                All steps completed
                                            </Typography>
                                            <Button onClick={handleReset}>
                                                Reset
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <div></div>
                                        </div>
                                    )} */}
                                </div>
                            </div>
                        </>
                    );
                }}
            </Formik>
            {/* <StyledDiv>
                <div className={classes.header}>
                    <IconButton
                        className={classes.closeButton}
                        onClick={cancelChanges}
                        size="large"
                    >
                        <Close color="action" />
                    </IconButton>
                </div>
                <div className={classes.content}>
                    <div className={classes.wrapper}>
                        <Create className={classes.icon} color="action" />
                        <TextField {...textEditorProps('title')} />
                    </div>
                    <div className={classes.wrapper}>
                        <CalendarToday
                            className={classes.icon}
                            color="action"
                        />
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                label="Start Date"
                                renderInput={props => (
                                    <TextField
                                        className={classes.picker}
                                        {...props}
                                    />
                                )}
                                {...startDatePickerProps}
                            />
                            <DateTimePicker
                                label="End Date"
                                renderInput={props => (
                                    <TextField
                                        className={classes.picker}
                                        {...props}
                                    />
                                )}
                                {...endDatePickerProps}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className={classes.wrapper}>
                        <LocationOn className={classes.icon} color="action" />
                        <TextField {...textEditorProps('employee')} />
                    </div>
                    <div className={classes.wrapper}>
                        <Notes className={classes.icon} color="action" />
                        <TextField
                            {...textEditorProps('notes')}
                            multiline
                            rows="6"
                        />
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    {!isNewAppointment && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            onClick={() => {
                                visibleChange();
                                this.commitAppointment('deleted');
                            }}
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            visibleChange();
                            applyChanges();
                        }}
                    >
                        {isNewAppointment ? 'Create' : 'Save'}
                    </Button>
                </div>
            </StyledDiv> */}
        </AppointmentForm.Overlay>
    );
};
export default CustomForm;
// export default class CustomFor extends React.PureComponent {
//     constructor(props) {
//         super(props);

//         this.state = {
//             appointmentChanges: {},
//         };

//         this.getAppointmentData = () => {
//             const { appointmentData } = this.props;
//             console.log(appointmentData);
//             return appointmentData;
//         };
//         this.getAppointmentChanges = () => {
//             const { appointmentChanges } = this.state;
//             return appointmentChanges;
//         };

//         this.changeAppointment = this.changeAppointment.bind(this);
//         this.commitAppointment = this.commitAppointment.bind(this);
//     }

//     changeAppointment({ field, changes }) {
//         const nextChanges = {
//             ...this.getAppointmentChanges(),
//             [field]: changes,
//         };
//         this.setState({
//             appointmentChanges: nextChanges,
//         });
//     }

//     commitAppointment(type) {
//         const { commitChanges } = this.props;
//         const appointment = {
//             ...this.getAppointmentData(),
//             ...this.getAppointmentChanges(),
//         };
//         if (type === 'deleted') {
//             commitChanges({ [type]: appointment.id });
//         } else if (type === 'changed') {
//             commitChanges({ [type]: { [appointment.id]: appointment } });
//         } else {
//             commitChanges({ [type]: appointment });
//         }
//         this.setState({
//             appointmentChanges: {},
//         });
//     }

//     render() {
//         const {
//             visible,
//             visibleChange,
//             appointmentData,
//             cancelAppointment,
//             target,
//             onHide,
//         } = this.props;
//         const { appointmentChanges } = this.state;

//         const displayAppointmentData = {
//             ...appointmentData,
//             ...appointmentChanges,
//         };

//         const isNewAppointment = appointmentData?.id === undefined;
//         const applyChanges = isNewAppointment
//             ? () => this.commitAppointment('added')
//             : () => this.commitAppointment('changed');

//         const textEditorProps = field => ({
//             variant: 'outlined',
//             onChange: ({ target: change }) =>
//                 this.changeAppointment({
//                     field: [field],
//                     changes: change.value,
//                 }),
//             value: displayAppointmentData[field] || '',
//             label: field[0].toUpperCase() + field.slice(1),
//             className: classes.textField,
//         });

//         console.log(this.props);

//         const pickerEditorProps = field => ({
//             // keyboard: true,
//             value: displayAppointmentData[field],
//             onChange: date =>
//                 this.changeAppointment({
//                     field: [field],
//                     changes: date
//                         ? date.toDate()
//                         : new Date(displayAppointmentData[field]),
//                 }),
//             ampm: false,
//             inputFormat: 'DD/MM/YYYY HH:mm',
//             onError: () => null,
//         });
//         const startDatePickerProps = pickerEditorProps('startDate');
//         const endDatePickerProps = pickerEditorProps('endDate');
//         const cancelChanges = () => {
//             this.setState({
//                 appointmentData: {},
//                 appointmentChanges: {},
//             });
//             visibleChange();
//             cancelAppointment();
//         };

//         return (
//             <AppointmentForm.Overlay
//                 visible={visible}
//                 target={target}
//                 fullSize
//                 onHide={onHide}
//             >
//                 <StyledDiv>
//                     <div className={classes.header}>
//                         <IconButton
//                             className={classes.closeButton}
//                             onClick={cancelChanges}
//                             size="large"
//                         >
//                             <Close color="action" />
//                         </IconButton>
//                     </div>
//                     {/* <div className={classes.content}>
//                         <div className={classes.wrapper}>
//                             <Create className={classes.icon} color="action" />
//                             <TextField {...textEditorProps('title')} />
//                         </div>
//                         <div className={classes.wrapper}>
//                             <CalendarToday
//                                 className={classes.icon}
//                                 color="action"
//                             />
//                             <LocalizationProvider dateAdapter={AdapterMoment}>
//                                 <DateTimePicker
//                                     label="Start Date"
//                                     renderInput={props => (
//                                         <TextField
//                                             className={classes.picker}
//                                             {...props}
//                                         />
//                                     )}
//                                     {...startDatePickerProps}
//                                 />
//                                 <DateTimePicker
//                                     label="End Date"
//                                     renderInput={props => (
//                                         <TextField
//                                             className={classes.picker}
//                                             {...props}
//                                         />
//                                     )}
//                                     {...endDatePickerProps}
//                                 />
//                             </LocalizationProvider>
//                         </div>
//                         <div className={classes.wrapper}>
//                             <LocationOn
//                                 className={classes.icon}
//                                 color="action"
//                             />
//                             <TextField {...textEditorProps('employee')} />
//                         </div>
//                         <div className={classes.wrapper}>
//                             <Notes className={classes.icon} color="action" />
//                             <TextField
//                                 {...textEditorProps('notes')}
//                                 multiline
//                                 rows="6"
//                             />
//                         </div>
//                     </div> */}
//                     <div className={classes.buttonGroup}>
//                         {!isNewAppointment && (
//                             <Button
//                                 variant="outlined"
//                                 color="secondary"
//                                 className={classes.button}
//                                 onClick={() => {
//                                     visibleChange();
//                                     this.commitAppointment('deleted');
//                                 }}
//                             >
//                                 Delete
//                             </Button>
//                         )}
//                         <Button
//                             variant="outlined"
//                             color="primary"
//                             className={classes.button}
//                             onClick={() => {
//                                 visibleChange();
//                                 applyChanges();
//                             }}
//                         >
//                             {isNewAppointment ? 'Create' : 'Save'}
//                         </Button>
//                     </div>
//                 </StyledDiv>
//             </AppointmentForm.Overlay>
//         );
//     }
// }
