import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getPermissionsList,
    getPermissionsOfRole,
    getRoleInfo,
    updateRole,
    createRole,
    updateRoleInfo,
} from '../../../api/roles';
import { LOADING_STATUS } from '../../../helpers/constants';
import { initialValues } from './mainForm';
import {
    roleInfoMapper,
    permissionsMapper,
    permissionsRoleMapper,
} from './utils';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';

const initialState = {
    role: initialValues,
    roleAdded: false,
    fetchRoleInfoStatus: LOADING_STATUS.IDLE,
    fetchPermissionsOfRoleStatus: LOADING_STATUS.IDLE,
    fetchAllPermissionsStatus: LOADING_STATUS.IDLE,
    insertStatus: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchRoleInfo = createAsyncThunk(
    'users/fetchUserInfo',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getRoleInfo(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar el rol con el id ${id}.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchPermissionsOfRole = createAsyncThunk(
    'users/fetchRolePermission',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getPermissionsOfRole(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar los permisos del rol con el id ${id}.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createSingleRole = createAsyncThunk(
    'users/createSingleRole',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createRole(body);
            dispatch(
                enqueueSnackbar({
                    message: `Rol creado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo crear el rol, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchAllPermissions = createAsyncThunk(
    'roles/fetchAllPermissions',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getPermissionsList();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudo cargar la lista de permisos, por favor refresque la página. Si el problema persiste contacte a soporte.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleRole = createAsyncThunk(
    'users/updateSingleRole',
    async ({ idRole, permissions }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateRole(idRole, permissions);
            dispatch(
                enqueueSnackbar({
                    message: `Rol actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el rol, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleRoleInfo = createAsyncThunk(
    'users/updateSingleRoleInfo',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateRoleInfo(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Información del rol actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar la informacion del rol, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const singleRoleSlice = createSlice({
    name: 'singleRole',
    initialState,
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchRoleInfo.pending, state => {
                state.fetchRoleInfoStatus = LOADING_STATUS.LOADING;
            })
            .addCase(fetchRoleInfo.fulfilled, (state, { payload }) => {
                state.fetchRoleInfoStatus = LOADING_STATUS.SUCCEEDED;
                state.role.roleInfo = roleInfoMapper(payload);
            })
            .addCase(fetchRoleInfo.rejected, (state, { error }) => {
                state.fetchRoleInfoStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchPermissionsOfRole.pending, state => {
                state.fetchPermissionsOfRoleStatus = LOADING_STATUS.LOADING;
            })
            .addCase(fetchPermissionsOfRole.fulfilled, (state, { payload }) => {
                state.fetchPermissionsOfRoleStatus = LOADING_STATUS.SUCCEEDED;
                state.role.permissionsOfRole = permissionsRoleMapper(payload);
            })
            .addCase(fetchPermissionsOfRole.rejected, (state, { error }) => {
                state.fetchPermissionsOfRoleStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchAllPermissions.pending, state => {
                state.fetchAllPermissionsStatus = LOADING_STATUS.LOADING;
            })
            .addCase(fetchAllPermissions.fulfilled, (state, { payload }) => {
                state.fetchAllPermissionsStatus = LOADING_STATUS.SUCCEEDED;
                state.role.allPermissions = permissionsMapper(payload);
            })
            .addCase(fetchAllPermissions.rejected, (state, { error }) => {
                state.fetchAllPermissionsStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(createSingleRole.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(
                createSingleRole.fulfilled,
                (state, { payload: newUser }) => {
                    state.insertStatus = LOADING_STATUS.SUCCEEDED;
                    state.clientAdded = Boolean(newUser.pk);
                }
            )
            .addCase(createSingleRole.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSingleRole.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSingleRole.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSingleRole.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = singleRoleSlice.actions;

export default singleRoleSlice.reducer;
