import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';

const PREFIX = 'Demo';
// #FOLD_BLOCK
const classes = {
    content: `${PREFIX}-content`,
    header: `${PREFIX}-header`,
    closeButton: `${PREFIX}-closeButton`,
    buttonGroup: `${PREFIX}-buttonGroup`,
    button: `${PREFIX}-button`,
    picker: `${PREFIX}-picker`,
    wrapper: `${PREFIX}-wrapper`,
    icon: `${PREFIX}-icon`,
    textField: `${PREFIX}-textField`,
    addButton: `${PREFIX}-addButton`,
};

const StyledAppointmentsAppointmentContent = styled(
    Appointments.AppointmentContent
)(() => ({
    [`& .${classes.title}`]: {
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    [`& .${classes.textContainer}`]: {
        display: 'flex !important',
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
    },
    [`& .${classes.time}`]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    [`& .${classes.text}`]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'wrap',
    },
    [`& .${classes.container}`]: {
        width: '100%',
    },
}));

function clientFind(value, array) {
    if (value && array) {
        return array.find(item => item.id === value).text;
    } else {
        return '';
    }
}

function printTrainers(array) {
    const arrayNames = array.map(item => item.nickname);
    return arrayNames.toString().replace(/,/g, ' y ');
}

function printTime(startDate, endDate) {
    return `${startDate} - ${endDate}`;
}

const AppointmentContent = ({ catalogs, data, formatDate, ...restProps }) => (
    <StyledAppointmentsAppointmentContent
        {...restProps}
        formatDate={formatDate}
        data={data}
    >
        <div className={classes.container}>
            <div className={classes.title}>{data.title}</div>
            <div className={classes.title}>{data.clientName}</div>
            <div className={classes.text}>{printTrainers(data?.trainers)}</div>

            <div style={{ display: 'flex' }} className={classes.textContainer}>
                <div className={classes.time}>
                    {printTime(
                        formatDate(data.startDate, {
                            hour: 'numeric',
                            minute: 'numeric',
                        }),
                        formatDate(data.endDate, {
                            hour: 'numeric',
                            minute: 'numeric',
                        })
                    )}
                </div>
            </div>
        </div>
    </StyledAppointmentsAppointmentContent>
);

export default AppointmentContent;
