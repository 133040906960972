import {
    Button,
    Grid,
    IconButton,
    MenuItem,
    Typography,
    Card,
    Container,
    CardMedia,
    CssBaseline,
    Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FastField, FieldArray, Form, Formik, getIn } from 'formik';
import { useEffect, useRef, useState } from 'react';
// import {
//     AddNewElement,
//     LabelledCheckbox,
//     OutlinedTextField,
// } from '../../../common/FormComponents/FormComponents';
import PageHeader from '../../../common/PageHeader/PageHeader';
// import DeleteIcon from '@material-ui/icons/Delete';
import { red } from '@material-ui/core/colors';
// import UploadDialog from '../../../common/DocumentComponents/UploadDialog/UploadDialog';
// import DocumentationList from '../../../common/DocumentComponents/DocumentationList/DocumentationList';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubjectLanding } from '../../subjects/singleSubject/singleSubjectSlice';
// import { fetchAllCatalogs } from '../../catalogs/catalogSlice';
// import { formatInsertData } from './utils';
import { LOADING_STATUS } from '../../../helpers/constants';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { boolean } from 'yup';
import { renderTimeValue } from '../../../helpers/formHelpers';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    backgroundImagen: {
        width: '100%',
        height: '-webkit-fill-available',
        objectFit: 'cover',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
    },
    heroContent: {
        height: '-webkit-fill-available',
        position: 'absolute',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        top: 0,
        right: 0,
        left: 0,
        padding: theme.spacing(8, 0, 6),
        placeItems: 'center',
    },
    heroContener: {
        paddingTop: '200px',
        position: 'relative',
    },
    cardGrid: {
        position: 'relative',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    arraySubtitle: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: 700,
        marginTop: theme.spacing(-1.5),
        marginBottom: theme.spacing(-1.5),
        '$formSubSection + $formSubSection &': {
            marginTop: theme.spacing(2),
        },
        '& > span': {
            marginRight: theme.spacing(1),
        },
    },
    deleteIcon: {
        color: red[400],
        marginTop: theme.spacing(0.2),
    },
    formSection: {
        backgroundColor: theme.palette.grey[50],
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(4),
        },
    },
    formSectionHeading: {
        color: theme.palette.primary.main,
        marginBottom: 0,
        marginTop: 0,
    },
    formSubSection: {
        width: '100%',
    },
    noData: {
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 1,
        textAlign: 'center',
    },
    gridSection: {
        width: '100%',
        flexDirection: 'row',
        padding: theme.spacing(2, 0),
    },
    subtitleWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
    uploadGrid: {
        marginBottom: theme.spacing(1),
    },
    downloadButton: {
        padding: '20px 0px',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}));

const SubjectInfo = () => {
    const classes = useStyles();
    const subject = useSelector(state => state.singleSubject.subject);
    const fetchStatus = useSelector(state => state.singleSubject.fetchStatus);
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    // const catalogs = useSelector(state => state.catalogs.catalogs);
    const insertStatus = useSelector(state => state.singleUser.insertStatus);
    const catalogStatus = useSelector(
        state => state.singleUser.fetchUserInfoStatus
    );
    const [bgImg, setBgImg] = useState(null);
    const [curriculum, setCurriculum] = useState(null);

    const capitalLetter = string => {
        let lowStr = string.toLowerCase();
        let capStr = lowStr.charAt(0).toUpperCase() + lowStr.slice(1);
        return capStr;
    };

    const loading =
        fetchStatus === LOADING_STATUS.LOADING ||
        catalogStatus === LOADING_STATUS.LOADING;

    const insertingUser = insertStatus === LOADING_STATUS.LOADING;

    // useEffect(() => {
    //     dispatch(fetchAllCatalogs());
    //     return () => dispatch(resetValues());
    // }, [dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(fetchSubjectLanding(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (fetchStatus === LOADING_STATUS.SUCCEEDED) {
            const findCurriculum = subject.documents.find(
                object => object.idDocumentType === 1
            );
            const findImg = subject.documents.find(
                object => object.idDocumentType === 3
            );
            if (findImg) {
                setBgImg(findImg.file);
            } else setBgImg(null);
            if (findCurriculum) {
                setCurriculum(findCurriculum);
            } else setCurriculum(null);
        }
    }, [subject, fetchStatus, bgImg]);

    // useEffect(() => {
    //     if (fetchStatus === LOADING_STATUS.SUCCEEDED) {
    //         setFiles(user.documents);
    //     }
    // }, [user.documents, fetchStatus]);

    const renderDateValue = value => {
        return new Intl.DateTimeFormat('es', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        }).format(Date.parse(value));
    };

    const handleFieldUpdate = (nextProps, currentProps) => {
        if (
            nextProps.edit !== currentProps.edit ||
            nextProps.disabled !== currentProps.disabled ||
            nextProps.loading !== currentProps.loading ||
            nextProps.catalog !== currentProps.catalog ||
            nextProps.name !== currentProps.name ||
            getIn(nextProps.formik.values, currentProps.name) !==
                getIn(currentProps.formik.values, currentProps.name) ||
            getIn(nextProps.formik.errors, currentProps.name) !==
                getIn(currentProps.formik.errors, currentProps.name) ||
            getIn(nextProps.formik.touched, currentProps.name) !==
                getIn(currentProps.formik.touched, currentProps.name) ||
            Object.keys(currentProps).length !==
                Object.keys(nextProps).length ||
            nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <CssBaseline />
            <main>
                {/* Hero unit */}
                <div className={classes.heroContener}>
                    <img
                        src={bgImg}
                        alt=""
                        className={classes.backgroundImagen}
                    />
                    <div className={classes.heroContent}>
                        <Container maxWidth="sm">
                            <Typography
                                component="h1"
                                variant="h2"
                                align="center"
                                color="textPrimary"
                                gutterBottom
                            >
                                {capitalLetter(subject.generalInfo.subjectName)}
                            </Typography>
                        </Container>
                    </div>
                </div>
                <Container className={classes.cardGrid} maxWidth="lg">
                    {/* End hero unit */}
                    <Grid
                        container
                        spacing={4}
                        direction="row"
                        justifyContent="start"
                        alignItems="center"
                    >
                        <Grid className={classes.gridSection}>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                            >
                                Descripción
                            </Typography>
                            <Typography>
                                {capitalLetter(subject.generalInfo.description)}
                            </Typography>
                        </Grid>
                        {subject?.courses.map(course => (
                            <>
                                <Grid className={classes.gridSection}>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="h2"
                                    >
                                        Proximo curso
                                    </Typography>
                                    <Typography>
                                        {course?.startDate
                                            ? renderDateValue(course.startDate)
                                            : null}{' '}
                                        -{' '}
                                        {course?.endDate
                                            ? renderDateValue(course.endDate)
                                            : null}
                                    </Typography>
                                </Grid>
                                <Grid className={classes.gridSection}>
                                    {course?.schedule.length > 0 && (
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="h2"
                                        >
                                            Horario
                                        </Typography>
                                    )}

                                    {course?.schedule.map(schedule =>
                                        schedule.startTime &&
                                        schedule.endTime ? (
                                            <Typography>
                                                <b>
                                                    {schedule?.day === 1
                                                        ? 'Lunes'
                                                        : schedule?.day === 2
                                                        ? 'Martes'
                                                        : schedule?.day === 3
                                                        ? 'Miércoles'
                                                        : schedule?.day === 4
                                                        ? 'Jueves'
                                                        : schedule?.day === 5
                                                        ? 'Viernes'
                                                        : schedule?.day === 6
                                                        ? 'Sábado'
                                                        : null}{' '}
                                                </b>
                                                {schedule?.startTime
                                                    ? renderTimeValue(
                                                          schedule.startTime
                                                      )
                                                    : null}{' '}
                                                -{' '}
                                                {schedule?.endTime
                                                    ? renderTimeValue(
                                                          schedule.endTime
                                                      )
                                                    : null}
                                            </Typography>
                                        ) : null
                                    )}
                                </Grid>
                            </>
                        ))}
                        {/* <Grid
                            className={classes.downloadButton}
                            container
                            spacing={4}
                            direction="column"
                            justifyContent="start"
                            alignItems="center"
                        >
                            <Button
                                download={curriculum}
                                disabled={Boolean(!curriculum)}
                                direction="row"
                                component="label"
                                color="primary"
                                variant="contained"
                            >
                                <a
                                    className={classes.link}
                                    href={curriculum?.file ?? null}
                                    download={curriculum?.fileName ?? null}
                                >
                                    Descargar Plan de estudios
                                </a>
                            </Button>
                        </Grid> */}
                    </Grid>
                </Container>
            </main>
            {/* Footer */}
            <footer className={classes.footer}>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                    component="p"
                >
                    Avenida 13 número 166 Colonia Apolo 83100 Hermosillo,
                    Sonora, México
                    <br />
                    Teléfono: 01 662 284 4500
                    <br />
                    http://universidadholistica.com.mx/
                    <br />
                    Lunes a Viernes: 8:00am - 8:00pm Sábado: 8:00am a 2:00pm
                </Typography>
                <Copyright />
            </footer>
            {/* End footer */}
        </>
    );
};

export default SubjectInfo;
