import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

const AuthProvider = ({ children }) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = `${window.location.origin}`;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
    const envBaseUrl = process.env.REACT_BASE_URL;

    const authConfig = {
        domain,
        clientId,
        redirectUri,
        audience,
        AUTH0_BASE_URL: `${envBaseUrl}`,
    };

    return <Auth0Provider {...authConfig}>{children}</Auth0Provider>;
};

export default AuthProvider;
