import {
    Button,
    capitalize,
    FormControlLabel,
    Grid,
    Typography,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { TextField, Checkbox } from 'formik-material-ui';
import { makeStyles } from '@material-ui/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import PersonAdd from '@material-ui/icons/PersonAdd';
const useStyles = makeStyles(theme => ({
    viewLabel: {
        color: theme.palette.text.secondary,
        fontSize: '0.75rem',
    },
}));
const ViewField = ({ catalog, loading, label, value }) => {
    const classes = useStyles();
    const catalogMatch = catalog?.find(({ id }) => id === value)?.text || null;
    const valueToShow =
        catalog?.length > 0 && catalogMatch ? catalogMatch : value;
    const finalValue =
        typeof valueToShow === 'boolean'
            ? valueToShow
                ? 'Sí'
                : 'No'
            : valueToShow
            ? typeof valueToShow === 'string'
                ? capitalize(valueToShow)
                : valueToShow
            : '-';
    return (
        <>
            <Typography className={classes.viewLabel}>{label}</Typography>
            <Typography>
                {loading ? <Skeleton width="80%" /> : finalValue}
            </Typography>
        </>
    );
};
export const LabelledCheckbox = ({ edit, label, loading, ...props }) => {
    const {
        field: { value },
    } = props;
    return (
        <Grid
            style={edit && !loading ? { marginTop: 8 } : {}}
            item
            xs={12}
            sm={6}
            md={3}
        >
            {edit ? (
                loading ? (
                    <Skeleton
                        width="100%"
                        height={56}
                        style={{ borderRadius: '4px 4px 0 0' }}
                        variant="rect"
                    />
                ) : (
                    <FormControlLabel
                        control={<Checkbox {...props} color="primary" />}
                        label={label}
                    />
                )
            ) : (
                <ViewField
                    loading={loading}
                    label={label}
                    value={Boolean(value)}
                />
            )}
        </Grid>
    );
};
export const OutlinedTextField = ({
    xs = 12,
    sm = 6,
    md = 4,
    lg = 3,
    xl,
    catalog,
    renderValue,
    edit = false,
    loading,
    onChange,
    ...props
}) => {
    const {
        label,
        field: { onChange: formikChange, value },
    } = props;

    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
            {edit ? (
                loading ? (
                    <Skeleton
                        width="100%"
                        height={56}
                        style={{ borderRadius: '4px 4px 0 0' }}
                        variant="rect"
                    />
                ) : (
                    <TextField
                        fullWidth
                        {...props}
                        variant="filled"
                        onChange={e => {
                            formikChange(e);
                            onChange && onChange(e);
                        }}
                    />
                )
            ) : (
                <ViewField
                    catalog={catalog}
                    loading={loading}
                    label={label}
                    value={renderValue ? renderValue(value) : value}
                />
            )}
        </Grid>
    );
};
export const AddNewElement = ({
    children = 'añadir',
    onAddElement,
    icon = <AddCircleOutlineIcon />,
    ...props
}) => {
    return (
        <Button
            color="primary"
            onClick={onAddElement}
            startIcon={icon}
            variant="outlined"
            {...props}
        >
            {children}
        </Button>
    );
};

export const ConsultSchedule = ({
    children = 'ver',
    onConsult,
    icon = <InsertInvitationIcon />,
    ...props
}) => {
    return (
        <Button
            color="primary"
            onClick={onConsult}
            startIcon={icon}
            variant="outlined"
            {...props}
        >
            {children}
        </Button>
    );
};

export const AddNewContact = ({ onAddElement, ...props }) => {
    return (
        <Button
            color="primary"
            onClick={onAddElement}
            startIcon={<PersonAdd />}
            {...props}
        />
    );
};
