import axios from 'axios';
import { axiosConfig } from './axiosConfig';

export const getServiceList = async () => {
    const { data } = await axios.get('services', axiosConfig('api/v1'));
    return data;
};

export const getServiceInfo = async id => {
    const { data } = await axios.get(`services/${id}`, axiosConfig('api/v1'));
    return data;
};

export const createService = async body => {
    const { data } = await axios.post('services', body, axiosConfig('api/v1'));
    return data;
};

export const updateService = async (id, body) => {
    const { data } = await axios.patch(
        `services/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const uploadImageService = async (id, body) => {
    const { data } = await axios.patch(
        `services/images/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};
