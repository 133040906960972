import * as yup from 'yup';
import {
    maxCharMessage,
    minCharMessage,
    requiredMessage,
    onlyLetters,
    onlyNumbers,
    onlyLettersMessage,
} from '../../../helpers/validationSchemaHelpers';

export const initialUserInfo = {
    name: '',
    image: '',
    description: '',
    price: 0,
};

export const initialValues = {
    serviceInfo: {
        ...initialUserInfo,
    },
};

export const validationSchema = yup.object({
    serviceInfo: yup.object({
        name: yup
            .string()
            .matches(onlyLetters, onlyLettersMessage)
            .max(50, maxCharMessage),
        description: yup
            .string()
            .min(50, minCharMessage)
            .max(500, maxCharMessage),
        price: yup.string().required(requiredMessage).matches(onlyNumbers),
    }),
});
