export const formatSubjectPayments = SubjectPaymentsArray =>
    SubjectPaymentsArray.map(payment => {
        return {
            id: payment.idCoursePaymentMovement,
            paymentName: payment?.idMovementConcept ?? '',
            expirationDate: payment.date?.slice(0, 10) ?? '',
            uploadDate: payment.uploadDate?.slice(0, 10) ?? '',
            discount: payment?.discount === true ? 'Aplicado' : 'No aplica',
            cost: payment?.amount ?? '',
            interbankKey: payment?.teacherBankClabe ?? null,
            paymentStatus: payment?.status,
            validation: payment?.validation === true ? true : false,
            comment: payment?.comment ?? null,
            document: {
                file:
                    payment?.base64File === 'Not found!' ||
                    payment?.base64File === null
                        ? null
                        : payment?.base64File,
                fileName: payment?.fileName ?? null,
            },
        };
    });

export const formatInsertDocument = values => ({
    File: values.fileName,
    base64File: values.file,
    IdCourseAssignment: Number(values.idCourse),
    IdPaymentType: values.idPaymentType,
    Reference: values.reference,
    PaymentDate: new Date().toISOString(),
    IdCoursePaymentMovement: values.idPayment,
});
