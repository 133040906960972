import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar, Container, Grid, ListItemAvatar } from '@material-ui/core';
import { FastField, Form, Formik } from 'formik';
import { OutlinedTextField } from '../../../../common/FormComponents/FormComponents';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <ListItemAvatar>
                        <Avatar alt={row.name} src={row.image} />
                        {console.log(row.image)}
                    </ListItemAvatar>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                Etograma
                            </Typography>
                            <Formik enableReinitialize initialValues={row}>
                                {() => {
                                    return (
                                        <Form>
                                            <Container
                                                className={classes.container}
                                            >
                                                <Grid container spacing={3}>
                                                    <FastField
                                                        component={
                                                            OutlinedTextField
                                                        }
                                                        name="name"
                                                        type="text"
                                                        label="Nombre"
                                                        edit={false}
                                                    />
                                                    <FastField
                                                        component={
                                                            OutlinedTextField
                                                        }
                                                        name="gender"
                                                        type="text"
                                                        label="Sexo"
                                                        edit={false}
                                                    />
                                                    <FastField
                                                        component={
                                                            OutlinedTextField
                                                        }
                                                        name="breed"
                                                        type="number"
                                                        label="Raza"
                                                        edit={false}
                                                    />
                                                </Grid>
                                            </Container>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function pets({ rows = [] }) {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableBody>
                    {rows.map(row => (
                        <Row key={row.name} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
