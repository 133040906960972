import axios from 'axios';
import { axiosConfig } from './axiosConfig';

export const getCarouselList = async () => {
    const { data } = await axios.get('carousel', axiosConfig('api/v1'));
    return data;
};

export const getCarouselInfo = async id => {
    const { data } = await axios.get(`carousel/${id}`, axiosConfig('api/v1'));
    return data;
};

export const createCarousel = async body => {
    const { data } = await axios.post('carousel', body, axiosConfig('api/v1'));
    return data;
};

export const updateCarousel = async (id, body) => {
    const { data } = await axios.patch(
        `carousel/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const uploadImageCarousel = async (id, body) => {
    const { data } = await axios.patch(
        `carousel/images/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};
