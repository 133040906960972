import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
 import {getOpportunities} from '../../../api/opportunities';
 import { LOADING_STATUS } from '../../../helpers/constants';
 import { formatOpportunities, getFilterData } from './utils';

const initialState = {
    data: {
        filters: {
            opportunityContactNameOptions: {
                selected: '',
                options: [],
            },
            opportunityNameOptions: {
                selected: '',
                options: [],
            },
            opportunityStatusOptions: {
                selected: '',
                options: [],
            },
            goesByOptions: {
                selected: '',
                options: [],
            },
        },
        rows: [],
    },
    status: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchOpportunities = createAsyncThunk(
    'opportunities/fetchOpportunities',
    async () => {
        const data = await getOpportunities();
        return data;
    }
);

export const opportunitySlice = createSlice({
    name: 'opportunities',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        changeOption: (state, { payload }) => {
            state.data.filters[payload.name].selected = payload.value;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchOpportunities.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchOpportunities.fulfilled, (state, { payload }) => {
                const cleanOpportunities = formatOpportunities(payload);

                state.status = LOADING_STATUS.SUCCEEDED;
                state.data.rows = cleanOpportunities;
                state.data.filters = getFilterData(cleanOpportunities);
            })
            .addCase(fetchOpportunities.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { changeOption } = opportunitySlice.actions;

export default opportunitySlice.reducer;