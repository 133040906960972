export const renderDateValue = value => {
    const parsedDate = Date.parse(value);
    if (isNaN(parsedDate)) return value;

    return new Intl.DateTimeFormat('es', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    }).format(Date.parse(value));
};

export const renderTimeValue = value => {
    if (value == undefined || value == null) return value;
    else {
        if (value >= '12:00') {
            const h = value.split(':');
            if (h[0] == '12') {
                return '12:' + h[1] + ' PM';
            } else {
                return h[0] - 12 + ':' + h[1] + ' PM';
            }
        } else {
            const h = value.split(':');
            if (h[0] == '00') {
                return '12:' + h[1] + ' AM';
            } else {
                return Math.abs(h[0]) + ':' + h[1] + ' AM';
            }
        }
    }
};
