import React from 'react';
import checkEmail from '../../assets/images/check_email.svg';
import Cookies from 'js-cookie';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@material-ui/core';

export default function Error() {
    const { logout } = useAuth0();
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    height: '100vh',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <img
                    src={checkEmail}
                    alt=""
                    style={{ height: '200px', width: '200px' }}
                ></img>
                <p>Se ha enviado un correo de verificación a su cuenta.</p>
                <Button
                    onClick={async () => {
                        await Cookies.remove('token');
                        await document.location.reload();
                    }}
                    color="secondary"
                    variant="contained"
                >
                    Volver a inicio
                </Button>
            </div>
        </>
    );
}
