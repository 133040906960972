import PageHeader from '../../../common/PageHeader/PageHeader';
import { Button } from '@material-ui/core';
import ExportButton from '../../../common/ExportButton/ExportButton';
import { Link } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { fetchOpportunities } from './opportunitiesSlice';
import { fetchSingleOpportunity } from '../singleOpportunity/singleOpportunitySlice';
import OpportunityFilters from './opportunityFilters/OpportunityFilters';
import OpportunityTable from './opportunityTable/OpportunityTable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';

const useStyles = makeStyles(theme => ({
    downloadButton: {
        backgroundColor: green[600],
        color: 'white',
        '&:hover': {
            backgroundColor: green[800],
        },
    },
    topButton: {
        '&:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
}));

const csvHeaders = [
    { label: 'Oportunidad', key: 'opportunityName' },
    { label: 'Alias', key: 'goesBy' },
    { label: 'Alias de contacto', key: 'opportunityContactName' },
    { label: 'Teléfono de contacto', key: 'opportunityContactPhone' },
    { label: 'Última actualización', key: 'modifiedDate' },
    { label: 'Status de la oportunidad', key: 'opportunityStatus' },
];

const Opportunities = () => {
    const classes = useStyles();
    const filters = useSelector(state => state.opportunities.data.filters);
    const rows = useSelector(state => state.opportunities.data.rows);
    const status = useSelector(state => state.opportunities.status);
    const dispatch = useDispatch();

    const filtersActive = Object.values(filters).some(
        ({ selected }) => selected
    );

    const filteredRows = ((filtersActive, rows) => {
        if (!filtersActive) return rows;
        let newRows = [];
        if (filters.opportunityNameOptions.selected) {
            newRows = rows.filter(
                row =>
                    row.opportunityName ===
                    filters.opportunityNameOptions.selected
            );
        }
        if (filters.opportunityContactNameOptions.selected) {
            newRows = (newRows.length > 0 ? newRows : rows).filter(
                row =>
                    row.opportunityContactName ===
                    filters.opportunityContactNameOptions.selected
            );
        }
        if (filters.opportunityStatusOptions.selected) {
            newRows = (newRows.length > 0 ? newRows : rows).filter(
                row =>
                    row.opportunityStatus ===
                    filters.opportunityStatusOptions.selected
            );
        }
        if (filters.goesByOptions.selected) {
            newRows = (newRows.length > 0 ? newRows : rows).filter(
                row => row.goesBy === filters.goesByOptions.selected
            );
        }

        return newRows;
    })(filtersActive, rows);

    const OppName = opportunitiesNames => opportunitiesNames.opportunityName;
    const OppsNames = filteredRows.map(OppName);
    const OppGoesBy = opportunitiesGoesBy => opportunitiesGoesBy.goesBy;
    const OppsGoesBy = filteredRows.map(OppGoesBy);
    const OppContactName = opportunitiesContactsNames =>
        opportunitiesContactsNames.opportunityContactName;
    const OppsContactsNames = filteredRows.map(OppContactName);
    const OppStatus = opportunitiesStatus =>
        opportunitiesStatus.opportunityStatus;
    const OppsStatus = filteredRows.map(OppStatus);

    const newFilters = {
        ...filters,
        goesByOptions: {
            ...filters.goesByOptions,
            options: [...new Set(OppsGoesBy)],
        },
        opportunityContactNameOptions: {
            ...filters.opportunityContactNameOptions,
            options: [...new Set(OppsContactsNames)],
        },
        opportunityNameOptions: {
            ...filters.opportunityNameOptions,
            options: [...new Set(OppsNames)],
        },
        opportunityStatusOptions: {
            ...filters.opportunityStatusOptions,
            options: [...new Set(OppsStatus)],
        },
    };
    useEffect(() => {
        dispatch(fetchOpportunities());
    }, [dispatch]);
    const sortFilteredRows = [...filteredRows];
    sortFilteredRows.sort(function (a, b) {
        let nameA = a.opportunityName.toUpperCase();
        let nameB = b.opportunityName.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    return (
        <div>
            <PageHeader title="Oportunidades">
                <Button
                    className={classes.topButton}
                    color="primary"
                    component={Link}
                    to="/opportunities/create"
                    variant="contained"
                >
                    Adicionar
                </Button>
                <ExportButton
                    headers={csvHeaders}
                    inputData={sortFilteredRows}
                    fileName="Oportunidades.csv"
                    className={`${classes.topButton} ${classes.downloadButton}`}
                >
                    Exportar a Excel
                </ExportButton>
            </PageHeader>

            <OpportunityFilters {...newFilters} />
            <OpportunityTable status={status} rows={sortFilteredRows} />
        </div>
    );
};

export default Opportunities;
