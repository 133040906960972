export const formatPayments = paymentsArray =>
    paymentsArray.map(payment => {
        return {
            id: payment.idCourseAssignment,
            name: payment?.subjectName ?? '',
            branch: payment?.idBranch,
            modality: payment?.idCourseModality,
            paymentStatus: payment?.status,
        };
    });

export const getFilterData = payments => {
    const paymentNameOptions = {
        selected: '',
        options: Array.from(
            payments.reduce(
                (acc, cur) =>
                    cur.paymentName ? acc.add(cur.paymentName) : acc,
                new Set()
            )
        ),
    };

    const paymentBranchesOptions = {
        selected: '',
        options: Array.from(
            payments.reduce(
                (acc, cur) => (cur.branch ? acc.add(cur.branch) : acc),
                new Set()
            )
        ),
    };

    const paymentModalityOptions = {
        selected: '',
        options: Array.from(
            payments.reduce(
                (acc, cur) => (cur.modality ? acc.add(cur.modality) : acc),
                new Set()
            )
        ),
    };

    const paymentStatusOptions = {
        selected: '',
        options: Array.from(
            payments.reduce(
                (acc, cur) =>
                    cur.paymentStatus ? acc.add(cur.paymentStatus) : acc,
                new Set()
            )
        ),
    };

    return {
        paymentNameOptions,
        paymentBranchesOptions,
        paymentStatusOptions,
        paymentModalityOptions,
    };
};
