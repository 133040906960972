import PageHeader from '../../../common/PageHeader/PageHeader';
import {
    Button,
    CardActionArea,
    CardActions,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    withStyles,
} from '@material-ui/core';
import ExportButton from '../../../common/ExportButton/ExportButton';
import { Link } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideos } from './VideosSlice';

// cards
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import VideosFilters from './videosFilters/VideosFilters';
import { LOADING_STATUS } from '../../../helpers/constants';
import Loading from '../../../common/Loading/Loading';
import CardMediaComponent from '../../../common/cardMediaComponent/cardMediaComponent';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DeleteDialog from './videoDialog/DeleteDialog';
import VideoDialog from './videoDialog/VideoDialog';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    avatar: {
        margin: theme.spacing(1),
        height: '250px',
        width: '250px',
    },
    heroContent: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: theme.spacing(8, 0, 6),
        placeItems: 'center',
    },
    backgroundImagen: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        margin: '15px', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    root: {
        maxWidth: 345,
    },
    media: {
        height: 220,
        width: 280,
    },
    actions: {
        justifyContent: 'space-around',
    },
    dialogTitle: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
}));

const csvHeaders = [
    { label: 'Videos', key: 'user' },
    { label: 'Email', key: 'email' },
    { label: 'Nombre', key: 'name' },
    { label: 'Apellido', key: 'lastName' },
    { label: 'Status del usuario', key: 'videosStatus' },
];

const Videos = () => {
    const classes = useStyles();
    const status = useSelector(state => state.videos.status);
    const filters = useSelector(state => state.videos.data.filters);
    const rows = useSelector(state => state.videos.data.rows);
    const role = useSelector(state => state.user.userData.userInfo.role);
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [videoDialog, setVideoDialog] = useState();
    const [videoDelete, setVideoDelete] = useState();
    const [openDelete, setOpenDelete] = useState(false);

    const handleClickOpenDelete = card => {
        setVideoDelete(card);
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
        setVideoDelete(null);
    };

    const handleClickOpen = card => {
        setVideoDialog(card);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setVideoDialog(null);
    };

    const filtersActive = Object.values(filters).some(
        ({ selected }) => selected
    );

    const filteredRows = ((filtersActive, rows) => {
        if (!filtersActive) return rows;
        let newRows = [];
        if (filters.videosOptions.selected) {
            const filterItems = query => {
                return rows.filter(
                    row =>
                        row.title.toLowerCase().indexOf(query.toLowerCase()) >
                        -1
                );
            };
            newRows = filterItems(filters.videosOptions.selected);
        }
        return newRows;
    })(filtersActive, rows);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchVideos());
    }, [dispatch]);

    const DialogTitle = withStyles(useStyles)(props => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle
                disableTypography
                className={classes.root}
                {...other}
            >
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    return status === LOADING_STATUS.LOADING ? (
        <Loading />
    ) : (
        <>
            <CssBaseline />
            <div>
                <PageHeader title="Videos">
                    {(role === 'Administrador' || role === 'Developer') && (
                        <Button
                            className={classes.topButton}
                            color="primary"
                            component={Link}
                            to="/videos/create"
                            variant="contained"
                        >
                            Adicionar
                        </Button>
                    )}
                </PageHeader>
                <VideosFilters {...filters}></VideosFilters>

                <Container className={classes.cardGrid} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container spacing={4}>
                        {filteredRows.map(card => (
                            <Card className={classes.cardMedia}>
                                <CardActionArea
                                    onClick={() => handleClickOpen(card)}
                                >
                                    <CardMedia
                                        className={classes.media}
                                        image={card.image}
                                        title={card.title}
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="h2"
                                        >
                                            {card.title}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                {(role === 'Administrador' ||
                                    role === 'Developer') && (
                                    <CardActions className={classes.actions}>
                                        <Button
                                            size="small"
                                            color="primary"
                                            onClick={() =>
                                                history.push(
                                                    `videos/${card.id}`
                                                )
                                            }
                                        >
                                            <EditIcon />
                                        </Button>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            onClick={() => {
                                                handleClickOpenDelete(card);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    </CardActions>
                                )}
                            </Card>
                        ))}
                    </Grid>
                    <VideoDialog
                        data={videoDialog}
                        open={open}
                        handleClose={() => handleClose()}
                    />
                    <DeleteDialog
                        data={videoDelete}
                        open={openDelete}
                        handleClose={() => handleCloseDelete()}
                    />
                </Container>
                {/* <VideosTable rows={filteredRows} status={status}></VideosTable> */}
            </div>
        </>
    );
};

export default Videos;
