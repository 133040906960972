export const formatUsers = usersArray =>
    usersArray.map(user => {
        return {
            id: user.id,
            image: user.image ?? null,
            user: user?.fullName ?? '',
            email: user?.user?.email ?? '',
            phone: user?.phoneNumber ?? '',
            role: user?.user?.role?.text ?? 'Desconocido',
            usersStatus: user?.user?.userStatus?.text ?? 'Desconocido',
        };
    });

export const getFilterData = users => {
    const usersOptions = {
        selected: '',
        options: Array.from(
            users.reduce(
                (acc, cur) => (cur.user ? acc.add(cur.user) : acc),
                new Set()
            )
        ),
    };

    const usersStatusOptions = {
        selected: '',
        options: Array.from(
            users.reduce(
                (acc, cur) =>
                    cur.usersStatus ? acc.add(cur.usersStatus) : acc,
                new Set()
            )
        ),
    };

    const usersRoleOptions = {
        selected: '',
        options: Array.from(
            users.reduce(
                (acc, cur) => (cur.role ? acc.add(cur.role) : acc),
                new Set()
            )
        ),
    };

    return {
        usersOptions,
        usersStatusOptions,
        usersRoleOptions,
    };
};
