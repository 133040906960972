import { useEffect, useRef, useState } from 'react';
import {
    CircularProgress,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Checkbox,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Link } from 'react-router-dom';
import { LOADING_STATUS } from '../../../../helpers/constants';
import { renderTimeValue } from '../../../../helpers/formHelpers';
import { AddNewElement } from '../../../../common/FormComponents/FormComponents';
import classNames from 'classnames';
import { initialScheduleInfo } from '../mainForm';

const useActionStyles = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const useTableStyles = makeStyles(theme => ({
    noInfo: {
        color: theme.palette.grey[400],
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'center',
    },
    tableFooter: {
        background: `linear-gradient(to top, white 0%, white calc(100% - 1px), ${theme.palette.grey[300]} calc(100% - 1px))`,
        position: 'sticky',
        bottom: 0,
    },
    tableHead: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        position: 'sticky',
        top: 0,
    },
    tableHeadCell: {
        color: 'white',
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase',
    },
    tableLink: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    tableRoot: {
        borderRadius: theme.shape.borderRadius,
        maxHeight: 484,
        '& td, & th': {
            height: 40,
        },
        '& table': {
            borderCollapse: 'separate',
        },
    },
    hideRow: {
        display: 'none',
    },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
    const classes = useActionStyles();

    const handleFirstPageButtonClick = event => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </div>
    );
};

const ScheduleTable = ({ rows = [], status, edit = false, data }) => {
    const [open, setOpen] = useState(false);
    const [rowOpen, setRowOpen] = useState(null);
    const [page, setPage] = useState(0);
    const [files, setFiles] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const classes = useTableStyles();
    const rowsLength = rows.length;
    const prevRows = useRef(rows);

    useEffect(() => {
        if (prevRows.current !== rowsLength) {
            prevRows.current = rowsLength;
            setPage(0);
        }
    }, [rowsLength]);

    // useEffect(() => {
    //     if (files.length !== initialScheduleInfo.length) {
    //         let newArray = initialScheduleInfo.map(schedule => {
    //             let object = rows.find(row => row.day === schedule.day);
    //             return object ? object : schedule;
    //         });
    //         setFiles([...newArray]);
    //     }
    // }, [rows, files, setFiles]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClose = () => {
        setOpen(false);
        setRowOpen(null);
    };

    const capitalLetter = string => {
        let lowStr = string.toLowerCase();
        let capStr = lowStr.charAt(0).toUpperCase() + lowStr.slice(1);
        return capStr;
    };

    const sendData = (index, row) => {
        return {
            indexRow: index,
            row: row,
        };
    };

    return (
        <div>
            <TableContainer
                component={Paper}
                className={classNames(classes.tableRoot, {
                    [classes.fullHeight]:
                        status === LOADING_STATUS.LOADING || rows.length === 0,
                })}
            >
                <Table size="small" aria-label="a dense table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell className={classes.tableHeadCell}>
                                Día
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Hora de Inicio
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Hora de Termino
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Salon
                            </TableCell>
                            {edit && (
                                <TableCell
                                    align="center"
                                    className={classes.tableHeadCell}
                                >
                                    Virtual
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={row.id}
                                className={
                                    (row.startTime && row.endTime) || edit
                                        ? null
                                        : classes.hideRow
                                }
                            >
                                <TableCell component="th" scope="row">
                                    {row.day === 1
                                        ? 'Lunes'
                                        : row.day === 2
                                        ? 'Martes'
                                        : row.day === 3
                                        ? 'Miércoles'
                                        : row.day === 4
                                        ? 'Jueves'
                                        : row.day === 5
                                        ? 'Viernes'
                                        : 'Sábado'}
                                </TableCell>
                                <TableCell align="center">
                                    {edit ? (
                                        <TextField
                                            type="time"
                                            defaultValue={row.startTime}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e => {
                                                let timeValue =
                                                    e.target.value + ':00';
                                                let newRow = {
                                                    ...row,
                                                    startTime: timeValue,
                                                };
                                                data(sendData(index, newRow));
                                            }}
                                        />
                                    ) : (
                                        renderTimeValue(row.startTime)
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {edit ? (
                                        <TextField
                                            type="time"
                                            defaultValue={row.endTime}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e => {
                                                let timeValue =
                                                    e.target.value + ':00';
                                                let newRow = {
                                                    ...row,
                                                    endTime: timeValue,
                                                };
                                                data(sendData(index, newRow));
                                            }}
                                        />
                                    ) : (
                                        renderTimeValue(row.endTime)
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {edit ? (
                                        <TextField
                                            disabled={row.isOnline}
                                            type="text"
                                            defaultValue={row.classroom}
                                            className={classes.textField}
                                            onChange={e => {
                                                let newRow = {
                                                    ...row,
                                                    classroom: e.target.value,
                                                };
                                                data(sendData(index, newRow));
                                            }}
                                        />
                                    ) : row.isOnline ? (
                                        'virtual'
                                    ) : (
                                        row.classroom
                                    )}
                                </TableCell>
                                {edit && (
                                    <TableCell align="center">
                                        <Checkbox
                                            disabled={!edit}
                                            checked={rows[index]?.isOnline}
                                            onChange={e => {
                                                let newRow = {
                                                    ...row,
                                                    isOnline: e.target.checked,
                                                };
                                                data(sendData(index, newRow));
                                            }}
                                            color="primary"
                                            inputProps={{
                                                'aria-label':
                                                    'primary checkbox',
                                            }}
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ScheduleTable;
