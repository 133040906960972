import { useEffect, useRef, useState } from 'react';
import {
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Link } from 'react-router-dom';
import { LOADING_STATUS } from '../../../../helpers/constants';
import classNames from 'classnames';
import { renderDateValue } from '../../../../helpers/formHelpers';
import { AddNewElement } from '../../../../common/FormComponents/FormComponents';
import UploadDialog from '../../../../common/DocumentComponents/UploadDialog/UploadDialogPayment';
import { Publish } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { formatInsertDocument } from '../utils';
import { uploadPayment } from '../subjectPaymentsSlice';

const useActionStyles = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const useTableStyles = makeStyles(theme => ({
    noInfo: {
        color: theme.palette.grey[400],
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'center',
    },
    fullHeight: {
        height: 484,
    },
    tableFooter: {
        background: `linear-gradient(to top, white 0%, white calc(100% - 1px), ${theme.palette.grey[300]} calc(100% - 1px))`,
        position: 'sticky',
        bottom: 0,
    },
    tableHead: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        position: 'sticky',
        top: 0,
    },
    tableHeadCell: {
        color: 'white',
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase',
    },
    tableLink: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    tableRoot: {
        borderRadius: theme.shape.borderRadius,
        maxHeight: 484,
        '& td, & th': {
            height: 40,
        },
        '& table': {
            borderCollapse: 'separate',
        },
    },
    btnUpd: {
        margin: theme.spacing(0, 2),
    },
    forUpdate: {
        display: 'flex',
        flexDirection: 'row-reverse',
        padding: theme.spacing(0, 0, 2, 0),
    },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
    const classes = useActionStyles();

    const handleFirstPageButtonClick = event => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </div>
    );
};

const PaymentTable = ({ rows = [], status, edit, data, idCourse }) => {
    const paymentTypes = useSelector(
        state => state.catalogs.catalogs.paymentTypes
    );
    const paymentStatus = useSelector(
        state => state.catalogs.catalogs.paymentStatus
    );
    const permissions = useSelector(state => state.user.userData.role);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [rowOpen, setRowOpen] = useState();
    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const classes = useTableStyles();
    const rowsLength = rows.length;
    const prevRows = useRef(rows);
    const dispatch = useDispatch();

    useEffect(() => {
        if (prevRows.current !== rowsLength) {
            prevRows.current = rowsLength;
            setPage(0);
        }
    }, [rowsLength]);

    useEffect(() => {
        if (status === LOADING_STATUS.SUCCEEDED) setFiles([...rows]);
    }, [rows, status, setFiles]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const paymentAccepted = paymentStatus => {
        return paymentStatus?.find(payment => payment.text === 'Validado');
    };

    function buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object') {
            Object.keys(data).forEach(key => {
                buildFormData(
                    formData,
                    data[key],
                    parentKey ? `${parentKey}[${key}]` : key
                );
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }

    function jsonToFormData(data) {
        const formData = new FormData();

        buildFormData(formData, data);

        return formData;
    }

    const handleFileChange = file => {
        const objectFile = formatInsertDocument(file);
        let body = jsonToFormData(objectFile);
        dispatch(uploadPayment(body))
            .unwrap()
            .then(() => {
                handleClose();
            });
    };

    return (
        <div>
            <TableContainer
                component={Paper}
                className={classNames(classes.tableRoot, {
                    [classes.fullHeight]:
                        status === LOADING_STATUS.LOADING || rows.length === 0,
                })}
            >
                <Table size="small" aria-label="a dense table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell className={classes.tableHeadCell}>
                                Pago
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Fecha de vencimiento
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Descuento
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                costo
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                CLABE interbancaria
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Status
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Comentario
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Archivo
                            </TableCell>
                            {(permissions === 'Estudiante' ||
                                permissions === 'Interesado') && (
                                <TableCell
                                    align="center"
                                    className={classes.tableHeadCell}
                                >
                                    Cargar Archivo
                                </TableCell>
                            )}
                            {permissions === 'Administrador' && (
                                <TableCell
                                    align="center"
                                    className={classes.tableHeadCell}
                                >
                                    Validación
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {status === LOADING_STATUS.LOADING ||
                        rows.length === 0 ? (
                            <TableRow style={{ height: 378 }}>
                                <TableCell
                                    className={classes.noInfo}
                                    colSpan={10}
                                >
                                    {status === LOADING_STATUS.LOADING ? (
                                        <CircularProgress />
                                    ) : (
                                        'Sin información que mostrar'
                                    )}
                                </TableCell>
                            </TableRow>
                        ) : (
                            (rowsPerPage > 0
                                ? rows.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                  )
                                : rows
                            ).map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {paymentTypes?.map(paymentType =>
                                            paymentType.id === row.paymentName
                                                ? paymentType.text
                                                : null
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {renderDateValue(row.expirationDate)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.discount}
                                    </TableCell>
                                    <TableCell align="center">
                                        ${row.cost}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.interbankKey}
                                    </TableCell>
                                    <TableCell align="center">
                                        {paymentStatus?.map(status =>
                                            status.id === row.paymentStatus
                                                ? status.text
                                                : null
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.comment}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.document.fileName === null ? (
                                            'Sin Archivo'
                                        ) : (
                                            <>
                                                <a
                                                    href={row.document.file}
                                                    download={
                                                        row.document.fileName
                                                    }
                                                >
                                                    {row.document.fileName}
                                                </a>
                                            </>
                                        )}
                                    </TableCell>
                                    {(permissions === 'Estudiante' ||
                                        permissions === 'Interesado') && (
                                        <TableCell align="center">
                                            <AddNewElement
                                                disabled={
                                                    row.paymentStatus ===
                                                    paymentAccepted(
                                                        paymentStatus
                                                    )?.id
                                                }
                                                icon={<Publish />}
                                                onAddElement={() => {
                                                    setOpen(true);
                                                    setRowOpen({
                                                        idPayment: row.id,
                                                        idCourse: idCourse,
                                                        idPaymentType:
                                                            row.paymentName,
                                                    });
                                                }}
                                            >
                                                Cargar
                                            </AddNewElement>
                                            <UploadDialog
                                                files={row.file}
                                                handleClose={handleClose}
                                                handleFileChange={file => {
                                                    let newRows = {
                                                        ...rowOpen,
                                                        file: file.file,
                                                        fileName: file.fileName,
                                                        reference:
                                                            file.reference,
                                                    };
                                                    handleFileChange(newRows);
                                                }}
                                                open={open}
                                            />
                                        </TableCell>
                                    )}
                                    {permissions === 'Administrador' && (
                                        <TableCell align="center">
                                            <Checkbox
                                                disabled={!edit}
                                                checked={false}
                                                onChange={e => {
                                                    let newRows = [...files];
                                                    newRows[files[index]] = {
                                                        ...row,
                                                        paymentStatus: e.target
                                                            .checked
                                                            ? 'Validado'
                                                            : 'Pendiente',
                                                    };
                                                    setFiles(newRows);
                                                }}
                                                color="primary"
                                                inputProps={{
                                                    'aria-label':
                                                        'primary checkbox',
                                                }}
                                            />
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                    <TableFooter className={classes.tableFooter}>
                        <TableRow>
                            <TablePagination
                                align="left"
                                rowsPerPageOptions={[5, 10, 20, 100]}
                                colSpan={10}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default PaymentTable;
