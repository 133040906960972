export const formatPayments = paymentsArray =>
    paymentsArray.map(payment => {
        return {
            id: payment.studentId,
            name: payment?.name ?? '',
            email: payment?.email ?? '',
            subjectsAssigned: payment?.subjectsAssigned ?? '',
            role: payment?.roleDescription ?? '',
            paymentStatus:
                payment?.status === 1
                    ? 'Por Validar'
                    : payment?.status === 2
                    ? 'validado'
                    : payment?.status === 3
                    ? 'Atrasado'
                    : 'Sin asignar',
        };
    });

export const getFilterData = payments => {
    const paymentNameOptions = {
        selected: '',
        options: Array.from(
            payments.reduce(
                (acc, cur) => (cur.name ? acc.add(cur.name) : acc),
                new Set()
            )
        ),
    };

    const paymentEmailOptions = {
        selected: '',
        options: Array.from(
            payments.reduce(
                (acc, cur) => (cur.email ? acc.add(cur.email) : acc),
                new Set()
            )
        ),
    };

    const paymentRoleOptions = {
        selected: '',
        options: Array.from(
            payments.reduce(
                (acc, cur) => (cur.role ? acc.add(cur.role) : acc),
                new Set()
            )
        ),
    };

    const paymentStatusOptions = {
        selected: '',
        options: Array.from(
            payments.reduce(
                (acc, cur) =>
                    cur.paymentStatus ? acc.add(cur.paymentStatus) : acc,
                new Set()
            )
        ),
    };

    return {
        paymentNameOptions,
        paymentEmailOptions,
        paymentRoleOptions,
        paymentStatusOptions,
    };
};
