export const formatVideos = videosArray =>
    videosArray.map(video => {
        return {
            id: video.id,
            video: video.video ?? null,
            title: video.title ?? '',
            image: video.image ?? '',
        };
    });

export const getFilterData = videos => {
    const videosOptions = {
        selected: '',
        options: Array.from(
            videos.reduce(
                (acc, cur) => (cur.video ? acc.add(cur.video) : acc),
                new Set()
            )
        ),
    };

    const videosStatusOptions = {
        selected: '',
        options: Array.from(
            videos.reduce(
                (acc, cur) =>
                    cur.videosStatus ? acc.add(cur.videosStatus) : acc,
                new Set()
            )
        ),
    };

    const videosRoleOptions = {
        selected: '',
        options: Array.from(
            videos.reduce(
                (acc, cur) => (cur.role ? acc.add(cur.role) : acc),
                new Set()
            )
        ),
    };

    return {
        videosOptions,
        videosStatusOptions,
        videosRoleOptions,
    };
};
