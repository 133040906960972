import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import SelectField from '../../../../common/SelectField/SelectField';
import TextField from '@material-ui/core/TextField';
import { changeOption } from '../paymentsAdminSlice';

const useStyles = makeStyles(theme => ({
    filtersContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(3),
        '& .MuiTextField-root': {
            minWidth: '25ch',
            maxWidth: `calc(25% - (3px * ${theme.spacing(2)} / 4))`,
            flexGrow: 1,
            '&:not(:last-child)': {
                marginRight: theme.spacing(2),
                marginBottom: theme.spacing(1),
            },
        },
    },
}));

const PaymentFilters = ({
    paymentRoleOptions = { options: [] },
    paymentStatusOptions = { options: [] },
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleChange = ({ target: { name, value } }) => {
        dispatch(changeOption({ name, value }));
    };

    const sort = forSort => {
        let newOptions = [...forSort.options];
        let sortOptions = { ...forSort, options: newOptions.sort() };
        return sortOptions;
    };

    return (
        <div className={classes.filtersContainer}>
            <TextField
                variant="outlined"
                label="Nombre"
                name="paymentNameOptions"
                onChange={handleChange}
            />
            <TextField
                variant="outlined"
                label="Email"
                name="paymentEmailOptions"
                onChange={handleChange}
            />
            <SelectField
                label="Rol"
                name="paymentRoleOptions"
                onChange={handleChange}
                options={sort(paymentRoleOptions)}
            />
            <SelectField
                label="Status"
                name="paymentStatusOptions"
                onChange={handleChange}
                options={sort(paymentStatusOptions)}
            />
        </div>
    );
};

export default PaymentFilters;
