export const formatRoles = rolesArray =>
    rolesArray.map(role => {
        return {
            id: role.pk ?? '',
            name: role.name ?? '',
            description:
                role.description === '' ? 'sin descripcion' : role.description,
            url: role.url ?? '',
        };
    });

export const getFilteredData = roles => {
    const rolesNameOptions = {
        selected: '',
        options: Array.from(
            roles.reduce(
                (acc, cur) => (cur.name ? acc.add(cur.name) : acc),
                new Set()
            )
        ),
    };

    const rolesDescriptionOptions = {
        selected: '',
        options: Array.from(
            roles.reduce(
                (acc, cur) =>
                    cur.description ? acc.add(cur.description) : acc,
                new Set()
            )
        ),
    };

    return {
        rolesNameOptions,
        rolesDescriptionOptions,
    };
};
