import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLandingUsers } from '../../users/main/UsersSlice';
import CardMediaComponent from '../../../common/cardMediaComponent/cardMediaComponent';
import bgimage from '../../../assets/images/bgEntrenadores.jpeg';
import { useHistory } from 'react-router';
import { LOADING_STATUS } from '../../../helpers/constants';
import Loading from '../../../common/Loading/Loading';
import { ListItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: theme.spacing(8, 0, 6),
        placeItems: 'center',
    },
    backgroundImagen: {
        backgroundImage: `url(${bgimage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Trainers() {
    const dispatch = useDispatch();
    const history = useHistory();
    const trainers = useSelector(state => state.users.data.rows);
    const loading = useSelector(state => state.users.status);
    const classes = useStyles();

    useEffect(() => {
        dispatch(fetchLandingUsers());
    }, [dispatch]);

    return loading === LOADING_STATUS.LOADING ? (
        <Loading />
    ) : (
        <React.Fragment>
            <CssBaseline />
            {/* <AppBar position="relative">
                <Toolbar>
                    <CameraIcon className={classes.icon} />
                    <Typography variant="h6" color="inherit" noWrap>
                        Trainers layout
                    </Typography>
                </Toolbar>
            </AppBar> */}
            <main>
                {/* Hero unit */}
                <div className={classes.backgroundImagen}>
                    <div className={classes.heroContent}>
                        <Container maxWidth="sm">
                            <Typography
                                component="h1"
                                variant="h3"
                                align="center"
                                color="textPrimary"
                                gutterBottom
                            >
                                Entrenadores
                            </Typography>
                        </Container>
                    </div>
                </div>

                <Container className={classes.cardGrid} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container spacing={4}>
                        {trainers.map(card => (
                            <CardMediaComponent
                                onClick={() => {
                                    let name = card.user
                                        .replace(/ /g, '-')
                                        .toLowerCase();
                                    history.push(`/entrenadores/${name}`);
                                }}
                                title={card.user}
                                isLanding={true}
                                image={card.image}
                                alt={'imagen de usuario'}
                            />
                        ))}
                    </Grid>
                </Container>
            </main>
        </React.Fragment>
    );
}
