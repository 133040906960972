import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import WelcomeDialog from './welcomeDialog';
import img1 from '../../assets/images/img1.jpg';
import img2 from '../../assets/images/img2.jpg';
import img3 from '../../assets/images/img3.jpg';

const imgRandom = () => {
    let imgOptions = [img1, img2, img3];
    let numRandom = Math.floor(Math.random() * 3);
    return imgOptions[numRandom];
};

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        width: '100%',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
}));

export default function LandingPage() {
    const classes = useStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <WelcomeDialog />
            <img src={imgRandom()} alt="" className={classes.image} />
        </Grid>
    );
}
