export const opportunityMapper = opportunity => {
    return {
        generalInfo: {
            name: opportunity?.opportunityName ?? '',
            goesBy: opportunity?.opportunityGoesBy ?? '',
            opportunityType: opportunity?.opportunityTypeId ?? '',
            startDate: opportunity?.startDate?.slice(0, 10) ?? '',
            endDate: opportunity?.endDate?.slice(0, 10) ?? '',
            notes: opportunity?.opportunityNotes ?? '',
        },
        headerContacts: {
            client: opportunity?.idClient ?? '',
            contact: opportunity?.clientContact?.id ?? '',
            //            internalContact: opportunity?.internalContact.firstName ?? '',
        },
        contactClientInfo: {
            contactId: opportunity?.clientContact?.id ?? '',
            firstName: opportunity?.clientContact?.firstName ?? '',
            middleName: opportunity?.clientContact?.middleName ?? '',
            lastName: opportunity?.clientContact?.lastName ?? '',
            officePhoneNumber: opportunity?.clientContact?.phoneNumber ?? '',
            cellPhoneNumber: opportunity?.clientContact?.mobileNumber ?? '',
            email: opportunity?.clientContact?.email ?? '',
            goesBy: opportunity?.clientContact?.goesBy ?? '',
            contactNotes: opportunity?.clientContact?.contactNotes ?? '',
            role: opportunity?.clientContact?.contactRole ?? '',
            getInTouchWith: opportunity?.clientContact?.firstcontact ?? '',
            opportunityContactId:
                opportunity?.opportunityContact?.opportunityContactId ?? '',
        },
        documents: opportunity?.opportunityDocuments.map(document => ({
            documentId: document.opportunityDocumentId ?? '',
            idDocumentType: document.documentTypeId ?? '',
            docNumber: document.docNumber ?? '',
            documentNotes: document.documentNotes ?? '',
            effBeginDate: document.effBeginDate,
            effEndDate: document.effEndDate,
            file: document.file ?? '',
            fileName: document.fileName ?? '',
        })),
    };
};

export const formatInsertData = (values, id, opportunityContactId) => {
    const formatted = {
        ...(id ? { opportunityId: Number(id) } : {}),
        idClient: values.headerContacts.client,
        opportunityContact: {
            opportunityContactId,
            idClientContact: values.headerContacts.contact ?? null,
            idInternalContact: values.headerContacts.internalContact ?? null,
        },
        opportunityName: values.generalInfo.name,
        opportunityGoesBy: values.generalInfo.goesBy,
        opportunityTypeId: values.generalInfo.opportunityType,
        opportunityNotes: values.generalInfo.notes,
        startDate: values.generalInfo.startDate,
        endDate: values.generalInfo.endDate,
        opportunityStatusId: 1,
        createdDate:
            id && values.generalInfo.createdDate
                ? values.generalInfo.createdDate
                : new Date().toISOString(),
        updatedDate: new Date().toISOString(),
        updater: 'NOT SET', // CHECK WITHIN THE USER SLICE
    };
    return formatted;
};

export const contactInfoMapper = payload => {
    return {
        firstName: payload.firstName ?? '',
        middleName: payload.middleName ?? '',
        lastName: payload.lastName ?? '',
        officePhoneNumber: payload.phoneNumber ?? '',
        cellPhoneNumber: payload.mobileNumber ?? '',
        email: payload.email ?? '',
        goesBy: payload.goesBy ?? '',
        contactNotes: payload.contactNotes ?? '',
        role: payload.contactRole ?? '',
        getInTouchWith: payload.firstcontact ?? '',
        influence: payload.influencyId ?? '',
    };
};
