export const userMapper = user => {
    return {
        employee: {
            id: user.id ?? '',
            image: user.image ?? '',
            name: user.fullName ?? '',
            nickname: user.nickname ?? '',
            curp: user.curp ?? '',
            birthday: user?.birthday?.slice(0, 10) ?? '',
            nss: user.nss ?? '',
            description: user.description ?? '',
            telephone1: user.phoneNumber ?? '',
        },
        address: {
            addressId: user?.user?.address?.id ?? null,
            city: user?.user?.address?.city ?? '',
            state: user?.user?.address?.state ?? '',
            suburb: user?.user?.address?.suburb ?? '',
            cp: user?.user?.address?.postalCode ?? '',
            address: user?.user?.address?.address ?? '',
        },
        userId: user?.user?.id,
        userStatus: user?.user?.userStatusId ?? null,
        email: user.user.email ?? '',
        role: user?.user?.roleId ?? null,
        bankInfo: {
            bankInfoId: user?.account?.id ?? null,
            bank: user?.account?.bank ?? '',
            accountNumber: user?.account?.account ?? '',
            interbankKey: user?.account?.key ?? '',
            cardNumber: user?.account?.card ?? '',
        },
    };
};

// export const userRolesMapper = rolesArray =>
//     rolesArray.map(role => {
//         return {
//             id: role.pk ?? '',
//             name: role.name ?? '',
//             description: role.description ?? '',
//             url: role.url ?? '',
//             state: true,
//         };
//     });

// export const allRolesMapper = rolesArray =>
//     rolesArray.map(role => {
//         return {
//             id: role.pk ?? '',
//             name: role.name ?? '',
//             description: role.description ?? '',
//             url: role.url ?? '',
//             state: false,
//         };
//     });

const includeProp = (key, value, type) => {
    if (type === 'string') {
        return value ? { [key]: value.toString() } : {};
    } else {
        return value ? { [key]: value } : {};
    }
};

export const formatCreateData = values => {
    const formatted = {
        fullName: values.userInfo.name,
        curp: values.userInfo.curp ? values.userInfo.curp : null,
        description: values.userInfo.description
            ? values.userInfo.description
            : null,
        birthday: values.userInfo.birthday ? values.userInfo.birthday : null,
        nss: values.userInfo.nss ? values.userInfo.nss : null,
        phoneNumber: values.userInfo.telephone1
            ? values.userInfo.telephone1
            : null,
        user: {
            email: values.userInfo.email,
            roleId: values.userInfo.role,
            userStatusId: values.userInfo.userStatus,
            password: values.userInfo.email,
            address: {
                city: values.userInfo.address.city
                    ? values.userInfo.address.city
                    : null,
                state: values.userInfo.address.state
                    ? values.userInfo.address.state
                    : null,
                postalCode: values.userInfo.address.cp
                    ? values.userInfo.address.cp
                    : null,
                address: values.userInfo.address.address
                    ? values.userInfo.address.address
                    : null,
                suburb: values.userInfo.address.suburb
                    ? values.userInfo.address.suburb
                    : null,
            },
        },
        account: {
            bank: values.userInfo.bankInfo.bank
                ? values.userInfo.bankInfo.bank
                : null,
            account: values.userInfo.bankInfo.accountNumber
                ? values.userInfo.bankInfo.accountNumber
                : null,
            key: values.userInfo.bankInfo.interbankKey
                ? values.userInfo.bankInfo.interbankKey
                : null,
            card: values.userInfo.bankInfo.cardNumber
                ? values.userInfo.bankInfo.cardNumber
                : null,
        },
    };
    return formatted;
};

export const formatUpdateEmployeeInfo = values => {
    const formatted = {
        fullName: values.userInfo.employee.name,
        curp: values.userInfo.employee.curp,
        ...includeProp('nickname', values.userInfo.employee.nickname),
        ...includeProp('description', values.userInfo.employee.description),
        ...includeProp('birthday', values.userInfo.employee.birthday),
        ...includeProp('nss', values.userInfo.employee.nss, 'string'),
        ...includeProp(
            'phoneNumber',
            values.userInfo.employee.telephone1,
            'string'
        ),
    };
    return formatted;
};

export const formatUpdateUser = values => {
    const formatted = {
        email: values.userInfo.email,
        roleId: values.userInfo.role,
        userStatusId: values.userInfo.userStatus,
    };
    return formatted;
};
export const formatUpdateAddressInfo = values => {
    const formatted = {
        ...includeProp('city', values.userInfo.address.city),
        ...includeProp('state', values.userInfo.address.state),
        ...includeProp('postalCode', values.userInfo.address.cp),
        ...includeProp('address', values.userInfo.address.address),
        ...includeProp('suburb', values.userInfo.address.suburb),
    };
    return formatted;
};

export const formatUpdateBankInfo = values => {
    const formatted = {
        bank: values.userInfo.bankInfo.bank,
        key: values.userInfo.bankInfo.interbankKey.toString(),
        ...includeProp(
            'account',
            values.userInfo.bankInfo.accountNumber,
            'string'
        ),
        ...includeProp('card', values.userInfo.bankInfo.cardNumber, 'string'),
    };
    return formatted;
};

export const formatInsertImage = values => ({
    filename: values.file,
});

// export const formatInsertDataRoles = values => {
//     const formatted = values.userRoles.map(role =>
//         role.state
//             ? {
//                   ...(role.state ? { pk: role.id } : null),
//               }
//             : null
//     );
//     return formatted;
// };
