import * as yup from 'yup';
import {
    emailErrorMessage,
    maxCharMessage,
    phoneErrorMessage,
    phoneRegExp,
    requiredMessage,
} from '../../../helpers/validationSchemaHelpers';

export const initialScheduleInfo = [
    {
        idCourseSchedule: 0,
        idCourse: null,
        startTime: null,
        endTime: null,
        classroom: null,
        isOnline: false,
        day: 1,
    },
    {
        idCourseSchedule: 0,
        idCourse: null,
        startTime: null,
        endTime: null,
        classroom: null,
        isOnline: false,
        day: 2,
    },
    {
        idCourseSchedule: 0,
        idCourse: null,
        startTime: null,
        endTime: null,
        classroom: null,
        isOnline: false,
        day: 3,
    },
    {
        idCourseSchedule: 0,
        idCourse: null,
        startTime: null,
        endTime: null,
        classroom: null,
        isOnline: false,
        day: 4,
    },
    {
        idCourseSchedule: 0,
        idCourse: null,
        startTime: null,
        endTime: null,
        classroom: null,
        isOnline: false,
        day: 5,
    },
    {
        idCourseSchedule: 0,
        idCourse: null,
        startTime: null,
        endTime: null,
        classroom: null,
        isOnline: false,
        day: 6,
    },
];

export const initialPricesInfo = [
    {
        idCoursePrice: null,
        idCourse: null,
        idPaymentType: 1,
        cost: 0,
        status: 1,
        optiongroup: 1,
    },
    {
        idCoursePrice: null,
        idCourse: null,
        idPaymentType: 2,
        cost: 0,
        status: 1,
        optiongroup: 1,
    },
];

export const initialCourseInfo = {
    idCourse: 0,
    status: 'Activo',
    modality: '',
    startDate: '',
    endDate: '',
    idTeacher: '',
    prices: [],
    schedule: [],
    idBranch: '',
    courseLink: '',
};

export const initialValues = {
    generalInfo: {
        subjectName: '',
        startDate: `${new Date().toISOString().slice(0, 10)}`,
        endDate: null,
        type: 'Sin asignar',
        cost: '',
        subjectStatus: 'Activo',
        startTime: null,
        endTime: null,
        description: '',
    },
    price: {
        inscription: '',
        monthly: '',
        oneExhibit: '',
        total: '',
    },
    headerContacts: {
        contact: '',
    },
    courses: [],
    contactClientInfo: {
        name: '',
        lastName: '',
        secondLastName: '',
        bank: '',
        accountNumber: '',
        interbankKey: '',
        cardNumber: '',
    },
};

export const validationSchema = yup.object({
    // generalInfo: yup.object({
    //     subjectName: yup
    //         .string()
    //         .max(50, maxCharMessage)
    //         .required(requiredMessage),
    //     startDate: yup
    //         .string()
    //         .max(50, maxCharMessage)
    //         .required(requiredMessage),
    //     endDate: yup.string().max(50, maxCharMessage).required(requiredMessage),
    // }),
    // headerContacts: yup.object({
    //     client: yup.string().max(50, maxCharMessage).required(requiredMessage),
    //     contact: yup.string().max(50, maxCharMessage).required(requiredMessage),
    //     //internalContact: yup.string().max(50, maxCharMessage),
    // }),
    // contactClientInfo: yup.object({
    //     firstName: yup
    //         .string()
    //         .max(50, maxCharMessage)
    //         .required(requiredMessage),
    //     middleName: yup.string().max(50, maxCharMessage),
    //     lastName: yup
    //         .string()
    //         .max(50, maxCharMessage)
    //         .required(requiredMessage),
    //     officePhoneNumber: yup
    //         .string()
    //         .max(15, maxCharMessage)
    //         .matches(phoneRegExp, phoneErrorMessage),
    //     cellPhoneNumber: yup
    //         .string()
    //         .max(15, maxCharMessage)
    //         .matches(phoneRegExp, phoneErrorMessage),
    //     email: yup
    //         .string()
    //         .max(50, maxCharMessage)
    //         .email(emailErrorMessage)
    //         .required(requiredMessage),
    //     goesBy: yup.string().max(200, maxCharMessage).required(requiredMessage),
    //     contactNotes: yup.string().max(255, maxCharMessage),
    //     role: yup.string(),
    //     getInTouchWith: yup.string(),
    // }),
});
