import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createCourse } from '../../../api/course';
import { LOADING_STATUS } from '../../../helpers/constants';
import { initialValues } from './mainForm';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';

const initialState = {
    course: initialValues,
    courseAdded: false,
    insertStatus: LOADING_STATUS.IDLE,
    error: null,
};
/*
export const fetchSingleCourse = createAsyncThunk(
    'courses/fetchSingleCourse',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getSingleCourse(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar el usuario con el id ${id}.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);
*/
export const createSingleCourse = createAsyncThunk(
    'courses/createSingleCourse',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createCourse(body);
            dispatch(
                enqueueSnackbar({
                    message: `Curso creado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo crear el curso, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

/*export const updateSingleCourse = createAsyncThunk(
    'courses/updateSingleCourse',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateCourse(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Curso actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el curso, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);*/

export const singleCourseSlice = createSlice({
    name: 'singleCourse',
    initialState,
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            /*.addCase(fetchSingleCourse.pending, state => {
                state.fetchStatus = LOADING_STATUS.LOADING;
            })
            .addCase(
                fetchSingleCourse.fulfilled,
                (state, { payload: [course] }) => {
                    state.fetchStatus = LOADING_STATUS.SUCCEEDED;
                    state.course = courseMapper(course);
                }
            )
            .addCase(fetchSingleCourse.rejected, (state, { error }) => {
                state.fetchStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })*/
            .addCase(createSingleCourse.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(
                createSingleCourse.fulfilled,
                (state, { payload: newCourse }) => {
                    state.insertStatus = LOADING_STATUS.SUCCEEDED;
                    state.courseAdded = Boolean(newCourse.courseId);
                }
            )
            .addCase(createSingleCourse.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
        /*.addCase(updateSingleCourse.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSingleCourse.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSingleCourse.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })*/
    },
});

export const { resetValues } = singleCourseSlice.actions;

export default singleCourseSlice.reducer;
