import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import logo from '../../assets/images/faithful-dog-logo.png';
import { useAuth0 } from '@auth0/auth0-react';
import PersonIcon from '@material-ui/icons/Person';
import ContactUs from './contactUs/ContactUs';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbarTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
    toolbarSecondary: {
        justifyContent: 'space-between',
        overflowX: 'auto',
    },
    toolbarLink: {
        padding: theme.spacing(1),
        flexShrink: 0,
    },
    logoLink: {
        padding: theme.spacing(1),
        flexShrink: 0,
        '&:hover': {
            textDecoration: 'none',
        },
    },
    logo: {
        height: '50px',
        filter: 'invert(1)',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            marginRight: '20px',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function Header(props) {
    const history = useHistory();
    const { menuToggleHandle } = props;
    const classes = useStyles();
    // const { sections, title } = props;
    const { loginWithRedirect, error, logout } = useAuth0();
    const [open, setOpen] = useState(false);

    if (error) {
        logout();
        alert(`${error.message}`);
    }

    const sections = [
        { title: 'Eventos', url: '#' },
        { title: 'Productos', url: '#' },
        { title: 'Servicios', url: '/servicios' },
        { title: 'Entrenadores', url: '/entrenadores' },
        { title: 'Empresa', url: '/empresa' },
    ];

    return (
        <React.Fragment>
            <ContactUs open={open} setOpen={e => setOpen(e)} />

            <Toolbar className={classes.toolbar}>
                <div className={classes.sectionMobile}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={menuToggleHandle ? menuToggleHandle : null}
                    >
                        <MenuIcon />
                    </IconButton>
                </div>
                <Grid container justifyContent="center" alignContent="center">
                    <Link
                        color="inherit"
                        noWrap
                        key="login"
                        variant="body2"
                        href="/"
                        className={classes.logoLink}
                    >
                        <Typography
                            component="h2"
                            variant="h5"
                            color="inherit"
                            align="center"
                            noWrap
                            className={classes.toolbarTitle}
                        >
                            <img
                                className={classes.logo}
                                src={logo}
                                alt="logo"
                            />
                            FaithfulDog
                        </Typography>
                    </Link>
                </Grid>
                {/* <IconButton>
                    <SearchIcon />
                </IconButton> */}
                <div className={classes.sectionMobile}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="open drawer"
                        href="/login"
                    >
                        <PersonIcon />
                    </IconButton>
                </div>
            </Toolbar>
            <Toolbar
                component="nav"
                variant="dense"
                className={classes.toolbarSecondary}
            >
                <div className={classes.sectionDesktop}>
                    <Link
                        color="inherit"
                        noWrap
                        key="login"
                        variant="body2"
                        href="/"
                        className={classes.toolbarLink}
                    >
                        Inicio
                    </Link>
                </div>
                {sections.map(section => (
                    <div className={classes.sectionDesktop}>
                        <Link
                            color="inherit"
                            noWrap
                            key={section.title}
                            variant="body2"
                            href={section.url}
                            className={classes.toolbarLink}
                        >
                            {section.title}
                        </Link>
                    </div>
                ))}
                <div className={classes.sectionDesktop}>
                    <Link
                        color="inherit"
                        noWrap
                        key="contact"
                        variant="body2"
                        href="#"
                        onClick={() => setOpen(true)}
                        className={classes.toolbarLink}
                    >
                        Contacto
                    </Link>
                </div>
                <div className={classes.sectionDesktop}>
                    <Link
                        color="inherit"
                        noWrap
                        key="login"
                        variant="body2"
                        href="/login"
                        className={classes.toolbarLink}
                    >
                        Iniciar sesión
                    </Link>
                </div>
            </Toolbar>
        </React.Fragment>
    );
}

Header.propTypes = {
    sections: PropTypes.array,
    title: PropTypes.string,
};
