import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AuthenticationRoute from './auth/AuthenticationRoute';
import PrivateRoute from './auth/PrivateRoute';
import TopBar from './common/TopBar/TopBar';
import TopBarHome from './common/TopBarHome/TopBarHome';
import SideNavigation from './common/SideNavigation/SideNavigation';
import SideNavigationHome from './common/SideNavigationHome/SideNavigationHome';
import Clients from './features/clients/mainPage/Clients';
import Footer from './common/Footer/Footer';
import SingleClient from './features/clients/singleClient/SingleClient';
import SingleOpportunity from './features/opportunities/singleOpportunity/SingleOpportunity';
import { useState, useEffect } from 'react';
import Opportunities from './features/opportunities/main/Opportunities';
import Profile from './features/profile/Profile';
import Notifier from './features/pushNotifications/Notifier';
import { SnackbarProvider } from 'notistack';
import Loading from './common/Loading/Loading';
import AxiosInterceptor from './api/axiosInterceptor';
import LandingPage from './features/landingPage/landingPage';
import Users from './features/users/main/Users';
import Roles from './features/roles/main/Roles';
import SingleRole from './features/roles/singleRole/SingleRole';
import SingleUser from './features/users/singleUser/SingleUser';
import Courses from './features/courses/main/Courses';
import SingleCourse from './features/courses/singleCourse/SingleCourse';
import Subjects from './features/subjects/main/Subjects';
import SingleSubject from './features/subjects/singleSubject/SingleSubject';
import UniversityPage from './features/login/universityPage/UniversityPage';
import TherapiesPage from './features/login/therapiesPage/TherapiesPage';
import SubjectInfo from './features/login/subjectInfo/SubjectInfo';
import Payments from './features/paymentUser/main/Payments';
import SubjectPayments from './features/paymentUser/subjectPayment/SubjectPayments';
import PaymentsAdmin from './features/paymentAdmin/main/PaymentsAdmin';
import UserPayments from './features/paymentAdmin/userPayment/UserPayments';
import Cookies from 'js-cookie';

import { useDispatch, useSelector } from 'react-redux';
import Blog from './features/blogPage/Blog';
import Header from './features/blogPage/Header';
import {
    Container,
    CssBaseline,
    ThemeProvider,
    createTheme,
} from '@material-ui/core';
import Trainers from './features/blogPage/trainers/Trainers';
import Login from './features/auth/Login';
import Recovery from './features/auth/Recovery';
import ChangePassword from './features/auth/ChangePassword';
import SingleTrainer from './features/blogPage/trainers/SingleTrainer';
import { useAuth0 } from '@auth0/auth0-react';
import AboutUs from './features/blogPage/AboutUs';
import HomeFooter from './features/blogPage/Footer';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import SvgIcon from '@mui/material/SvgIcon';
import ServicesPage from './features/login/ServicesPage/ServicesPage';
import Services from './features/services/main/Services';
import SingleService from './features/services/singleService/SingleService';
import Carousel from './features/carousel/main/Carousel';
import SingleCarousel from './features/carousel/singleData/SingleCarousel';
import Videos from './features/videos/main/Videos';
import SingleVideo from './features/videos/singleVideo/SingleVideo';

function TikTokIcon() {
    return (
        <SvgIcon>
            {/* credit: plus icon from https://heroicons.com/ */}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                filter="invert(1)"
            >
                <path d="M16.708 0.027c1.745-0.027 3.48-0.011 5.213-0.027 0.105 2.041 0.839 4.12 2.333 5.563 1.491 1.479 3.6 2.156 5.652 2.385v5.369c-1.923-0.063-3.855-0.463-5.6-1.291-0.76-0.344-1.468-0.787-2.161-1.24-0.009 3.896 0.016 7.787-0.025 11.667-0.104 1.864-0.719 3.719-1.803 5.255-1.744 2.557-4.771 4.224-7.88 4.276-1.907 0.109-3.812-0.411-5.437-1.369-2.693-1.588-4.588-4.495-4.864-7.615-0.032-0.667-0.043-1.333-0.016-1.984 0.24-2.537 1.495-4.964 3.443-6.615 2.208-1.923 5.301-2.839 8.197-2.297 0.027 1.975-0.052 3.948-0.052 5.923-1.323-0.428-2.869-0.308-4.025 0.495-0.844 0.547-1.485 1.385-1.819 2.333-0.276 0.676-0.197 1.427-0.181 2.145 0.317 2.188 2.421 4.027 4.667 3.828 1.489-0.016 2.916-0.88 3.692-2.145 0.251-0.443 0.532-0.896 0.547-1.417 0.131-2.385 0.079-4.76 0.095-7.145 0.011-5.375-0.016-10.735 0.025-16.093z" />
            </svg>
        </SvgIcon>
    );
}

const darkTheme = createTheme({
    palette: {
        type: 'dark',
    },
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100vh',
    },
    footer: {
        bottom: 0,
        textAlign: 'center',
    },
    leftSide: {
        background: 'white',
        height: '100%',
    },
    mainContent: {
        flexGrow: 1,
        padding: theme.spacing(1, 4, 4),
    },
    rightSide: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'static',
    },
    sideBar: {
        background: 'white',
        boxShadow: '-20px 0 20px 20px rgba(0,0,0,0.14)',
        height: '100%',
        minWidth: 270,
        width: '20%',
        position: 'fixed',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            marginRight: '20px',
        },
    },
    sectionMobile: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

const App = () => {
    const classes = useStyles();
    const [menuToggle, setMenuToggle] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [rol, setRol] = useState();
    const userRole = useSelector(state => state.user?.userData?.userInfo?.role);
    const menuToggleHandle = () => {
        setMenuToggle(!menuToggle);
    };

    const socialOptions = [
        {
            name: 'Instagram',
            icon: InstagramIcon,
            webSite:
                'https://www.instagram.com/acfaithfuldog?igsh=MWVtdGZ4eHBwcjdxeA==',
        },
        {
            name: 'Facebook',
            icon: FacebookIcon,
            webSite: 'https://www.facebook.com/ACFaithfulDog ',
        },
        {
            name: 'TikTok',
            icon: TikTokIcon,
            webSite:
                'https://www.tiktok.com/@faithfuldogmid?_t=8kPxwlZzEgv&_r=1',
        },
    ];

    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            setIsAuthenticated(true);
        }
    }, []);

    useEffect(() => {
        if (userRole !== undefined) {
            setRol(userRole);
        }
    }, [userRole, setRol]);

    const { isLoading } = useAuth0();

    if (isLoading) {
        return <Loading />;
    }

    console.log(rol);

    return (
        <Router basename={process.env.REACT_BASE_URL}>
            <SnackbarProvider
                autoHideDuration={7000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <div className={classes.root}>
                    <Notifier />

                    <Switch>
                        {!isAuthenticated && (
                            <ThemeProvider theme={darkTheme}>
                                <CssBaseline />
                                <Container maxWidth="lg">
                                    <div style={{ display: 'none' }}>
                                        <SideNavigationHome
                                            isAuthenticated={isAuthenticated}
                                            menuToggle={menuToggle}
                                            className={classes.sideBar}
                                            menuToggleHandle={menuToggleHandle}
                                        />
                                    </div>
                                    <div className={classes.rightSide}>
                                        <Header
                                            menuToggleHandle={menuToggleHandle}
                                        />
                                        {/* <TopBarHome /> */}
                                        <main>
                                            <Switch>
                                                {/* <Route exact path="/">
                                                <Redirect
                                                    to={{
                                                        pathname: '/home',
                                                    }}
                                                />
                                            </Route> */}
                                                <AuthenticationRoute
                                                    path="/login"
                                                    isAuthenticated={
                                                        isAuthenticated
                                                    }
                                                >
                                                    <Route exact path="/login">
                                                        <Login
                                                            userData={async e => {
                                                                await Cookies.set(
                                                                    'token',
                                                                    e.token
                                                                );
                                                                await document.location.reload();
                                                            }}
                                                        />
                                                    </Route>
                                                </AuthenticationRoute>
                                                <Route exact path="/recovery">
                                                    <Recovery />
                                                </Route>
                                                <Route path="/cambiar-contraseña/:token">
                                                    <ChangePassword />
                                                </Route>
                                                <Route exact path="/">
                                                    <Blog />
                                                </Route>
                                                <Route
                                                    exact
                                                    path="/entrenadores"
                                                >
                                                    <Trainers />
                                                </Route>
                                                <Route
                                                    exact
                                                    path="/entrenadores/:name"
                                                >
                                                    <SingleTrainer />
                                                </Route>
                                                <Route exact path="/empresa">
                                                    <AboutUs />
                                                </Route>
                                                <Route exact path="/terapias">
                                                    <TherapiesPage />
                                                </Route>
                                                <Route exact path="/servicios">
                                                    <ServicesPage />
                                                </Route>
                                                <Route path="/:nameSubject/:id">
                                                    <SubjectInfo />
                                                </Route>
                                            </Switch>
                                        </main>
                                        <HomeFooter
                                            title="Adiestramiento canino FaithfulDog"
                                            social={socialOptions}
                                            description="Síguenos en nuestras redes sociales"
                                        />
                                    </div>
                                </Container>
                            </ThemeProvider>
                        )}
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                            <div className={classes.leftSide}>
                                <div className={classes.sectionDesktop}>
                                    <SideNavigation
                                        isAuthenticated={isAuthenticated}
                                        menuToggle={menuToggle}
                                        className={classes.sideBar}
                                        menuToggleHandle={menuToggleHandle}
                                    />
                                </div>
                            </div>
                            <div className={classes.rightSide}>
                                <div className={classes.sectionMobile}>
                                    <TopBar
                                        menuToggleHandle={menuToggleHandle}
                                    />
                                </div>
                                <main className={classes.mainContent}>
                                    <Switch>
                                        <Route exact path="/subjects/create">
                                            <SingleSubject create />
                                        </Route>
                                        <Route path="/subjects/:id">
                                            <SingleSubject />
                                        </Route>
                                    </Switch>
                                    <Route exact path="/events">
                                        <TherapiesPage />
                                    </Route>
                                    <Route exact path="/scheduler">
                                        <Subjects />
                                    </Route>
                                    <Switch>
                                        <Route exact path="/clients/create">
                                            <SingleClient create />
                                        </Route>
                                        <Route path="/clients/:id">
                                            <SingleClient />
                                        </Route>
                                    </Switch>
                                    <Route exact path="/clients">
                                        <Clients />
                                    </Route>
                                    <Route exact path="/opportunities">
                                        <Opportunities />
                                    </Route>
                                    <Switch>
                                        <Route
                                            exact
                                            path="/opportunities/create"
                                        >
                                            <SingleOpportunity create />
                                        </Route>
                                        <Route path="/opportunities/:id">
                                            <SingleOpportunity />
                                        </Route>
                                    </Switch>
                                    <Route exact path="/users">
                                        <Users />
                                    </Route>
                                    <Switch>
                                        <Route exact path="/users/create">
                                            <SingleUser create />
                                        </Route>
                                        <Route exact path="/users/:id">
                                            <SingleUser />
                                        </Route>
                                    </Switch>
                                    <Route exact path="/roles">
                                        <Roles />
                                    </Route>
                                    <Switch>
                                        <Route exact path="/roles/create">
                                            <SingleRole create />
                                        </Route>
                                        <Route exact path="/roles/:id">
                                            <SingleRole />
                                        </Route>
                                    </Switch>
                                    <Route exact path="/profile">
                                        <Profile />
                                    </Route>
                                    <Route exact path="/payments">
                                        <Payments />
                                    </Route>
                                    <Route exact path="/payments/:subject/:id">
                                        <SubjectPayments />
                                    </Route>
                                    <Route exact path="/paymentsAdmin">
                                        <PaymentsAdmin />
                                    </Route>
                                    <Route
                                        exact
                                        path="/paymentsUsers/:userName/:id"
                                    >
                                        <UserPayments />
                                    </Route>
                                    <Route exact path="/services">
                                        <Services />
                                    </Route>
                                    <Switch>
                                        <Route exact path="/services/create">
                                            <SingleService create />
                                        </Route>
                                        <Route exact path="/services/:id">
                                            <SingleService />
                                        </Route>
                                    </Switch>
                                    <Route exact path="/carousel">
                                        <Carousel />
                                    </Route>
                                    <Switch>
                                        <Route exact path="/carousel/create">
                                            <SingleCarousel create />
                                        </Route>
                                        <Route exact path="/carousel/:id">
                                            <SingleCarousel />
                                        </Route>
                                    </Switch>
                                    <Route exact path="/videos">
                                        <Videos />
                                    </Route>
                                    <Switch>
                                        <Route exact path="/videos/create">
                                            <SingleVideo create />
                                        </Route>
                                        <Route exact path="/videos/:id">
                                            <SingleVideo />
                                        </Route>
                                    </Switch>
                                    <Route exact path="/login">
                                        {rol === 'Developer' ||
                                        rol === 'Miembro' ||
                                        rol === 'Cliente' ? (
                                            <Redirect
                                                to={{
                                                    pathname: '/profile',
                                                }}
                                            />
                                        ) : rol === 'Administrador' ||
                                          rol === 'Entrenador' ? (
                                            <Redirect
                                                to={{
                                                    pathname: '/scheduler',
                                                }}
                                            />
                                        ) : (
                                            <Loading />
                                        )}
                                    </Route>
                                </main>
                                <Footer className={classes.footer} />
                            </div>
                        </PrivateRoute>
                    </Switch>
                </div>
            </SnackbarProvider>
        </Router>
    );
};

export default App;
