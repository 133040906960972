export const formatClients = clientsArray =>
    clientsArray.map(client => {
        return {
            id: client.id,
            clientName: client?.fullName ?? '',
            email: client?.user?.email ?? '',
            clientContactPhone: client?.phoneNumber ?? '',
            address: client.user?.address?.address ?? '',
            clientStatus: client?.user?.userStatus?.text ?? 'Desconocido',
        };
    });

export const getFilterData = clients => {
    const clientNameOptions = {
        selected: '',
        options: Array.from(
            clients.reduce(
                (acc, cur) => (cur.clientName ? acc.add(cur.clientName) : acc),
                new Set()
            )
        ),
    };

    const clientContactNameOptions = {
        selected: '',
        options: Array.from(
            clients.reduce(
                (acc, cur) =>
                    cur.clientContactName
                        ? acc.add(cur.clientContactName)
                        : acc,
                new Set()
            )
        ),
    };

    const clientStatusOptions = {
        selected: '',
        options: Array.from(
            clients.reduce(
                (acc, cur) =>
                    cur.clientStatus ? acc.add(cur.clientStatus) : acc,
                new Set()
            )
        ),
    };

    const emailOptions = {
        selected: '',
        options: Array.from(
            clients.reduce(
                (acc, cur) => (cur.email ? acc.add(cur.email) : acc),
                new Set()
            )
        ),
    };

    return {
        clientContactNameOptions,
        clientNameOptions,
        clientStatusOptions,
        emailOptions,
    };
};
