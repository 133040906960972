import * as yup from 'yup';
import {
    emailErrorMessage,
    maxCharMessage,
    phoneErrorMessage,
    phoneRegExp,
    requiredMessage,
} from '../../../helpers/validationSchemaHelpers';

export const initialValues = {
    generalInfo: {
        name: '',
        goesBy: '',
        opportunityType: '',
        startDate: '',
        endDate: '',
        notes: '',
    },
    headerContacts: {
        client: '',
        contact: '',
        //    internalContact: '',
    },
    contactClientInfo: {
        firstName: '',
        middleName: '',
        lastName: '',
        officePhoneNumber: '',
        cellPhoneNumber: '',
        email: '',
        goesBy: '',
        contactNotes: '',
        role: '',
        getInTouchWith: '',
        influence: '',
        opportunityContactId: '',
    },
};

export const validationSchema = yup.object({
    generalInfo: yup.object({
        name: yup.string().max(50, maxCharMessage).required(requiredMessage),
        goesBy: yup.string().max(50, maxCharMessage).required(requiredMessage),
        opportunityType: yup
            .string()
            .max(50, maxCharMessage)
            .required(requiredMessage),
        startDate: yup
            .string()
            .max(50, maxCharMessage)
            .required(requiredMessage),
        endDate: yup.string().max(50, maxCharMessage).required(requiredMessage),
        notes: yup.string().max(50, maxCharMessage).required(requiredMessage),
    }),
    headerContacts: yup.object({
        client: yup.string().max(50, maxCharMessage).required(requiredMessage),
        contact: yup.string().max(50, maxCharMessage).required(requiredMessage),
        //internalContact: yup.string().max(50, maxCharMessage),
    }),
    contactClientInfo: yup.object({
        firstName: yup
            .string()
            .max(50, maxCharMessage)
            .required(requiredMessage),
        middleName: yup.string().max(50, maxCharMessage),
        lastName: yup
            .string()
            .max(50, maxCharMessage)
            .required(requiredMessage),
        officePhoneNumber: yup
            .string()
            .max(15, maxCharMessage)
            .matches(phoneRegExp, phoneErrorMessage),
        cellPhoneNumber: yup
            .string()
            .max(15, maxCharMessage)
            .matches(phoneRegExp, phoneErrorMessage),
        email: yup
            .string()
            .max(50, maxCharMessage)
            .email(emailErrorMessage)
            .required(requiredMessage),
        goesBy: yup.string().max(200, maxCharMessage).required(requiredMessage),
        contactNotes: yup.string().max(255, maxCharMessage),
        role: yup.string(),
        getInTouchWith: yup.string(),
    }),
});
