import * as yup from 'yup';
import {
    emailErrorMessage,
    maxCharMessage,
    phoneErrorMessage,
    phoneRegExp,
    requiredMessage,
    onlyLetters,
    onlyNumbers,
    onlyLettersMessage,
} from '../../helpers/validationSchemaHelpers';

export const studentInfo = {
    registrationNumber: '',
};

export const teacherInfo = {
    bank: '',
    accountNumber: '',
    interbankKey: '',
    cardNumber: '',
};

export const initialUserInfo = {
    id: '',
    name: '',
    surname: '',
    curp: '',
    birthday: '',
    nss: '',
    city: '',
    state: '',
    municipality: '',
    cp: '',
    country: '',
    address1: '',
    address2: '',
    userStatus: 1,
    email: '',
    telephone1: '',
    whatsApp1: false,
    telephone2: '',
    whatsApp2: false,
    role: 0,
    private: false,
};

export const initialValues = {
    userInfo: {
        ...initialUserInfo,
        studentInfo: {
            ...studentInfo,
        },
        teacherInfo: {
            ...teacherInfo,
        },
    },
};

export const validationSchema = yup.object({
    userInfo: yup.object({
        name: yup
            .string()
            .matches(onlyLetters, onlyLettersMessage)
            .max(50, maxCharMessage)
            .required(requiredMessage),
        surName: yup
            .string()
            .matches(onlyLetters, onlyLettersMessage)
            .max(50, maxCharMessage)
            .required(requiredMessage),
        telephone1: yup
            .string()
            .required(requiredMessage)
            .matches(onlyNumbers)
            .min(8, 'Mínimo 8 dígitos')
            .max(10, 'Máximo 10 dígitos'),
        telephone2: yup
            .string()
            .matches(onlyNumbers)
            .min(8, 'Mínimo 8 dígitos')
            .max(10, 'Máximo 10 dígitos'),
        private: yup
            .bool()
            .oneOf([true], 'No ha aceptado el aviso de privacidad'),
    }),
});
