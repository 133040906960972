export const maxCharMessage = ({ max }) =>
    `Se permiten máximo ${max} caracteres.`;
export const minCharMessage = ({ min }) =>
    `No se permiten menos de ${min} caracteres.`;
export const emailErrorMessage = 'Formato de correo inválido';
export const requiredMessage = 'Campo obligatorio';
export const confirmPasswordMessage = 'Las contraseñas no coinciden';
export const phoneErrorMessage = 'Formato de teléfono inválido';
export const onlyLettersMessage = 'Solo se permiten letras';
export const onlyNumbers = /^[0-9]+$/;
export const onlyLetters = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/g;
export const phoneRegExp = /^\+?([0-9]{1,3}?(-| )?)?\(?[0-9]{1,6}\)?(-| )?[0-9]{1,6}(-| )?[0-9]+$/;
