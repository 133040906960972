import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    requirePropFactory,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { OutlinedTextField } from '../../FormComponents/FormComponents';
import { initialValues, validationSchema } from './dialogForm';

const useStyles = makeStyles(theme => ({
    dialogActions: {
        marginBottom: theme.spacing(1),
    },
    dialogGrid: {
        marginBottom: theme.spacing(2),
    },
    dialogTitle: {
        '& > *': {
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    inputFile: {
        width: 0,
        height: 0,
    },
}));

const convertBase64 = file => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = error => {
            reject(error);
        };
    });
};

const UploadDialog = ({
    documentCatalog,
    handleClose,
    handleFileChange,
    open,
    files,
}) => {
    const classes = useStyles();
    const [dialogFile, setDialogFile] = useState();
    const [base64File, setBase64File] = useState();

    const handleDialogFile = async e => {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setDialogFile(file);
        setBase64File(base64);
    };

    useEffect(() => {
        if (!open) setDialogFile(undefined);
    }, [open]);

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle className={classes.dialogTitle}>
                Carga de documentos
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={({
                        fileName,
                        idDocumentType,
                        documentNotes,
                        docNumber,
                    }) =>
                        handleFileChange({
                            file: base64File,
                            fileName,
                            idDocumentType,
                            documentNotes,
                            docNumber,
                        })
                    }
                >
                    {props => {
                        const { setFieldValue, values } = props;
                        const { fileName } = values;
                        if (dialogFile && fileName !== dialogFile.name) {
                            setFieldValue('fileName', dialogFile.name);
                        }
                        const validExtensions = () => {
                            if (fileName) {
                                const extensions = [
                                    'pdf',
                                    'jpg',
                                    'png',
                                    'jpeg',
                                ];
                                const split = fileName.split('.');
                                function getExtension(ext) {
                                    return ext === split[split.length - 1];
                                }
                                return !extensions.some(getExtension);
                            } else {
                                return false;
                            }
                        };
                        const maxSize = dialogFile
                            ? dialogFile.size > 5242880
                            : false;
                        const dontRepeat = files.some(
                            fName => fName.fileName === fileName
                        );

                        return (
                            <Form>
                                <Grid
                                    alignItems="center"
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <Field
                                        component={OutlinedTextField}
                                        name="fileName"
                                        type="text"
                                        label="Documento*"
                                        error={
                                            dontRepeat ||
                                            maxSize ||
                                            validExtensions()
                                        }
                                        helperText={
                                            (dontRepeat &&
                                                'Documento existente') ||
                                            (validExtensions() &&
                                                'Formato no valido') ||
                                            (maxSize && 'Archivo mayor a 5MB')
                                        }
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit
                                        disabled={!dialogFile}
                                    />
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            component="label"
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                        >
                                            Buscar
                                            <input
                                                type="file"
                                                className={classes.inputFile}
                                                onChange={handleDialogFile}
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <Field
                                        component={OutlinedTextField}
                                        select
                                        name="idDocumentType"
                                        type="text"
                                        label="Tipo de Documento*"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit
                                        disabled={!dialogFile}
                                    >
                                        <MenuItem value="0">&nbsp;</MenuItem>
                                        {documentCatalog?.length > 0 &&
                                            documentCatalog.map(
                                                ({ id, text }) => (
                                                    <MenuItem
                                                        value={id}
                                                        key={id}
                                                    >
                                                        {text}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Field>
                                </Grid>
                                <Grid
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <Field
                                        component={OutlinedTextField}
                                        name="docNumber"
                                        type="text"
                                        label="Número de documento"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit
                                        disabled={!dialogFile}
                                    />
                                    <Field
                                        component={OutlinedTextField}
                                        name="documentNotes"
                                        type="text"
                                        label="Notas"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit
                                        disabled={!dialogFile}
                                    />
                                </Grid>
                                <Grid
                                    className={classes.dialogActions}
                                    container
                                    spacing={3}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            color="primary"
                                            onClick={handleClose}
                                            variant="outlined"
                                            type="button"
                                            fullWidth
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            disabled={
                                                dontRepeat ||
                                                validExtensions() ||
                                                maxSize
                                            }
                                            fullWidth
                                        >
                                            Cargar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default UploadDialog;
