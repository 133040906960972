import { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarContent } from 'notistack';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { closeSnackbar } from './pushNotificationsSlice';
import { number, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { forwardRef } from 'react';
import { adjustLightness } from '../../helpers/colorManipulation';

const useStyles = makeStyles(theme => ({
    alertRoot: {
        width: 500,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,0.16)',
        borderWidth: 1,
        borderStyle: 'solid',
        color: ({ severity }) =>
            severity === 'success'
                ? adjustLightness(theme.palette.success.dark, -5)
                : severity === 'warning'
                ? adjustLightness(theme.palette.warning.dark, -10)
                : severity === 'error'
                ? adjustLightness(theme.palette.error.dark, -5)
                : theme.palette.info.dark,
        borderColor: ({ severity }) =>
            severity === 'success'
                ? theme.palette.success.dark
                : severity === 'warning'
                ? theme.palette.warning.dark
                : severity === 'error'
                ? theme.palette.error.main
                : theme.palette.info.dark,
    },
    alertAction: {
        marginRight: 0,
    },
}));

export const severityMap = {
    info: 'Información',
    error: 'Error',
    success: 'Éxito',
    warning: 'Advertencia',
};

const Snackbar = forwardRef(({ id, severity = 'info', message }, ref) => {
    const classes = useStyles({ severity });
    const title = severityMap[severity];
    const dispatch = useDispatch();
    const handleDismiss = useCallback(() => {
        dispatch(closeSnackbar(id));
    }, [dispatch, id]);

    return (
        <SnackbarContent ref={ref}>
            <Alert
                severity={severity}
                className={classes.alertRoot}
                classes={{
                    action: classes.alertAction,
                }}
                onClose={handleDismiss}
            >
                <AlertTitle>{title}</AlertTitle>
                {message}
            </Alert>
        </SnackbarContent>
    );
});

Snackbar.propTypes = {
    id: number.isRequired,
    message: string.isRequired,
    severity: string,
};

export default Snackbar;
