import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, MenuItem, Grid, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { FastField, Form, Formik, getIn, FieldArray } from 'formik';
import {
    AddNewContact,
    AddNewElement,
    OutlinedTextField,
} from '../../../../common/FormComponents/FormComponents';
import ScheduleTable from '../ScheduleTable/ScheduleTable';
import {
    initialCourseInfo,
    initialPricesInfo,
    initialScheduleInfo,
    validationSchema,
} from '../mainForm';
import { enqueueSnackbar } from '../../../pushNotifications/pushNotificationsSlice';
import { renderDateValue } from '../../../../helpers/formHelpers';
import { fetchCourseModalities } from '../../../catalogs/catalogSlice';
import EditIcon from '@material-ui/icons/Edit';
import { formatInsertCourses, formatInsertSchedule } from '../utils';
import {
    courseAssignment,
    createCourseSchedule,
    createSubjectCourse,
    fetchSingleSubject,
    newStudent,
    updateSubjectCourse,
} from '../singleSubjectSlice';
import axios from 'axios';
import { LOADING_STATUS } from '../../../../helpers/constants';
import { useHistory, useParams } from 'react-router-dom';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    forMain: {
        width: '100%',
    },
    forEdit: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1, 0, 0, 0),
    },
    scheduleTable: {
        padding: theme.spacing(3, 0),
    },
    deleteIcon: {
        color: 'rgb(255, 0, 0)',
        marginTop: theme.spacing(0.2),
    },
    subtitleWrapper: {
        backgroundColor: theme.palette.grey[50],
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(4),
    },
    formSectionHeading: {
        color: theme.palette.primary.main,
        marginBottom: 0,
        marginTop: 0,
    },
    cancelButton: {
        margin: theme.spacing(0, 2),
    },
    noData: {
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 1,
        textAlign: 'center',
    },
}));

export default function CoursesTabs({
    idSubject,
    courses = [],
    catalogs,
    loading,
    insertingSubject,
}) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [options, setOptions] = useState([]);
    const [schedule, setSchedule] = useState([]);
    const prevSubmitCount = useRef(0);
    const [edit, setEdit] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();

    const history = useHistory();

    const dropdownTeachers = useSelector(
        state => state.dropdown.dropdownTeachers
    );
    const userRole = useSelector(state => state.user.userData.role);
    const idUser = useSelector(state => state.user.userData.id);

    const studentInfo = useSelector(state => state.user.userData.studentInfo);

    const assignmentStatus = useSelector(
        state => state.singleSubject.courseAssignment
    );

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (courses.length > options.length) {
            setOptions([...courses]);
        }
    }, [options, setOptions, courses]);

    useEffect(() => {
        if (assignmentStatus === LOADING_STATUS.SUCCEEDED) {
            dispatch(fetchSingleSubject());
        }
    }, [dispatch, assignmentStatus]);

    useEffect(() => {
        options.map((option, index) => {
            if (option.schedule.length === 0) {
                let newOption = {
                    ...option,
                    schedule: [...initialScheduleInfo],
                };
                let newArrayOptions = [...options];
                newArrayOptions[index] = { ...newOption };
                setOptions([...newArrayOptions]);
            }
        });
    }, [options]);

    // const optionDeleted = (option, index) => {
    //     let newOptions = [...options];
    //     newOptions[index] = { ...option, shouldDelete: true };
    //     onDelete(newOptions);
    // };

    // const handleButtonClick = e => {
    //     e.preventDefault();
    //     if (!edit) {
    //         setEdit(true);
    //     }
    // };

    const handleSubmit = values => {
        let newValues = formatInsertCourses(values, Number(idSubject));
        if (values.idCourse) {
            let newBody = formatInsertSchedule(values, Number(values.idCourse));
            dispatch(updateSubjectCourse(newValues))
                .unwrap()
                .then(() =>
                    dispatch(createCourseSchedule(newBody))
                        .unwrap()
                        .then(() => {
                            dispatch(fetchSingleSubject(id));
                            setEdit(false);
                        })
                );
        } else {
            dispatch(createSubjectCourse(newValues))
                .unwrap()
                .then(result => {
                    let newBody = formatInsertSchedule(
                        values,
                        Number(result.course.idCourse)
                    );
                    dispatch(createCourseSchedule(newBody))
                        .unwrap()
                        .then(() => {
                            dispatch(fetchSingleSubject(id));
                            setEdit(false);
                        })
                        .catch(() => {
                            dispatch(fetchSingleSubject(id));
                            setEdit(false);
                        });
                });
        }
    };

    const handleAssign = course => {
        if (studentInfo) {
            let body = {
                idStudent: studentInfo.idStudent,
                idCourse: course,
                status: 1,
            };
            dispatch(courseAssignment(body))
                .unwrap()
                .then(() => history.push(`/subjects`));
        } else {
            let studentBody = {
                idUser: idUser,
                registrationNumber: '0',
                status: 1,
            };
            dispatch(newStudent(studentBody))
                .unwrap()
                .then(result => {
                    let body = {
                        idStudent: result.idStudent,
                        idCourse: course,
                        status: 1,
                    };
                    dispatch(courseAssignment(body))
                        .unwrap()
                        .then(() => window.location.reload());
                });
        }
    };

    const handleFieldUpdate = (nextProps, currentProps) => {
        if (
            nextProps.edit !== currentProps.edit ||
            nextProps.disabled !== currentProps.disabled ||
            nextProps.catalog !== currentProps.catalog ||
            nextProps.loading !== currentProps.loading ||
            nextProps.name !== currentProps.name ||
            getIn(nextProps.formik.values, currentProps.name) !==
                getIn(currentProps.formik.values, currentProps.name) ||
            getIn(nextProps.formik.errors, currentProps.name) !==
                getIn(currentProps.formik.errors, currentProps.name) ||
            getIn(nextProps.formik.touched, currentProps.name) !==
                getIn(currentProps.formik.touched, currentProps.name) ||
            Object.keys(currentProps).length !==
                Object.keys(nextProps).length ||
            nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.subtitleWrapper}>
                <h2 className={classes.formSectionHeading}>Cursos</h2>
                {edit && (
                    <Grid>
                        <Button
                            color="secondary"
                            variant="contained"
                            className={classes.cancelButton}
                            onClick={() => {
                                setEdit(false);
                                setOptions([...courses]);
                            }}
                            disabled={loading}
                        >
                            Cancelar
                        </Button>
                        <AddNewElement
                            disabled={loading || insertingSubject}
                            onAddElement={() => {
                                let coursesArray = [...options];

                                coursesArray.push({
                                    ...initialCourseInfo,
                                    idSubject: Number(idSubject),
                                    prices: [...initialPricesInfo],
                                    schedule: [...initialScheduleInfo],
                                });
                                setOptions([...coursesArray]);
                            }}
                        />
                    </Grid>
                )}
                {!edit && userRole === 'Administrador' && (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setEdit(true)}
                        disabled={loading}
                    >
                        Editar
                    </Button>
                )}
            </div>
            {options.length > 0 ? (
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {options.map((option, index) => (
                            <Tab
                                label={`Curso ${index + 1}`}
                                {...a11yProps(options.indexOf(option))}
                            />
                        ))}
                    </Tabs>
                </AppBar>
            ) : (
                <Typography className={classes.noData}>
                    Aún no hay cursos.
                </Typography>
            )}

            {options.map((option, index) => (
                <TabPanel value={value} index={options.indexOf(option)}>
                    <Grid key={index} container spacing={3}>
                        <main className={classes.forMain}>
                            <Formik
                                enableReinitialize
                                validationSchema={validationSchema}
                                initialValues={option}
                                onSubmit={handleSubmit}
                            >
                                {props => {
                                    const {
                                        errors,
                                        submitCount,
                                        values,
                                        setValues,
                                    } = props;
                                    if (
                                        submitCount !== prevSubmitCount.current
                                    ) {
                                        prevSubmitCount.current = submitCount;
                                        if (Object.keys(errors).length > 0) {
                                            dispatch(
                                                enqueueSnackbar({
                                                    message:
                                                        'Por favor llene todos los campos correctamente.',
                                                    options: {
                                                        key:
                                                            new Date().getTime() +
                                                            Math.random(),
                                                        variant: 'error',
                                                    },
                                                })
                                            );
                                        }
                                    }
                                    return (
                                        <Form>
                                            <section
                                                className={classes.formSection}
                                            >
                                                <Grid container spacing={3}>
                                                    <>
                                                        {!edit && (
                                                            <FastField
                                                                component={
                                                                    OutlinedTextField
                                                                }
                                                                name="courseIdentifier"
                                                                type="text"
                                                                label="Matricula"
                                                                edit={edit}
                                                                loading={
                                                                    loading
                                                                }
                                                                disabled={true}
                                                                shouldUpdate={
                                                                    handleFieldUpdate
                                                                }
                                                            />
                                                        )}
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            select
                                                            name="idTeacher"
                                                            type="select"
                                                            label="Profesor"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingSubject
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                            catalog={
                                                                dropdownTeachers
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                &nbsp;
                                                            </MenuItem>
                                                            {dropdownTeachers?.length >
                                                                0 &&
                                                                dropdownTeachers.map(
                                                                    ({
                                                                        id,
                                                                        text,
                                                                    }) => (
                                                                        <MenuItem
                                                                            value={
                                                                                id
                                                                            }
                                                                            key={
                                                                                id
                                                                            }
                                                                        >
                                                                            {
                                                                                text
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                        </FastField>
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            select
                                                            name="idBranch"
                                                            type="select"
                                                            label="Sucursal"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingSubject
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                            catalog={
                                                                catalogs.branches
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                &nbsp;
                                                            </MenuItem>
                                                            {catalogs.branches
                                                                ?.length > 0 &&
                                                                catalogs.branches.map(
                                                                    ({
                                                                        id,
                                                                        text,
                                                                    }) => (
                                                                        <MenuItem
                                                                            value={
                                                                                id
                                                                            }
                                                                            key={
                                                                                id
                                                                            }
                                                                        >
                                                                            {
                                                                                text
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                        </FastField>
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            select
                                                            name="status"
                                                            type="text"
                                                            label="estatus"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingSubject
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                            // catalog={catalogs.businessarea}
                                                        >
                                                            <MenuItem value="Activo">
                                                                Activo
                                                            </MenuItem>
                                                            <MenuItem value="Inactivo">
                                                                Inactivo
                                                            </MenuItem>
                                                        </FastField>
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            select
                                                            name="modality"
                                                            type="select"
                                                            label="modalidad"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingSubject
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                            // catalog={
                                                            //     catalogs.courseModalities
                                                            // }
                                                        >
                                                            <MenuItem value="">
                                                                &nbsp;
                                                            </MenuItem>
                                                            <MenuItem value="Online">
                                                                Online
                                                            </MenuItem>
                                                            <MenuItem value="Hibrido">
                                                                Hibrido
                                                            </MenuItem>
                                                            <MenuItem value="Presencial">
                                                                Presencial
                                                            </MenuItem>
                                                            {/* {catalogs
                                                                .courseModalities
                                                                ?.length > 0 &&
                                                                catalogs.courseModalities.map(
                                                                    ({
                                                                        idCourseModality,
                                                                        name,
                                                                    }) => (
                                                                        <MenuItem
                                                                            value={
                                                                                idCourseModality
                                                                            }
                                                                            key={
                                                                                idCourseModality
                                                                            }
                                                                        >
                                                                            {
                                                                                name
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )} */}
                                                        </FastField>
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            name={`startDate`}
                                                            type="date"
                                                            label="Fecha de inicio*"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingSubject
                                                            }
                                                            renderValue={
                                                                renderDateValue
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            name={`endDate`}
                                                            type="date"
                                                            label="Fecha de Termino*"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingSubject
                                                            }
                                                            renderValue={
                                                                renderDateValue
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            name="prices[1].cost"
                                                            type="number"
                                                            label="Inscripción"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingSubject
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            name="prices[0].cost"
                                                            type="number"
                                                            label="mensualidad"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingSubject
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                    </>
                                                </Grid>
                                                {edit && (
                                                    <Grid container spacing={3}>
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            name="courseLink"
                                                            type="text"
                                                            label="Link de clase virtual"
                                                            multiline
                                                            maxRows={5}
                                                            lg={12}
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingSubject
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                    </Grid>
                                                )}

                                                {option.schedule.length > 0 && (
                                                    <div
                                                        className={
                                                            classes.scheduleTable
                                                        }
                                                    >
                                                        <ScheduleTable
                                                            rows={
                                                                option.schedule
                                                            }
                                                            edit={edit}
                                                            data={e => {
                                                                let newArray = [
                                                                    ...option.schedule,
                                                                ];
                                                                const renderObject = ({
                                                                    row,
                                                                    indexRow,
                                                                }) => {
                                                                    newArray[
                                                                        indexRow
                                                                    ] = {
                                                                        ...row,
                                                                    };
                                                                    return newArray;
                                                                };
                                                                let newOptions = [
                                                                    ...options,
                                                                ];
                                                                newOptions[
                                                                    index
                                                                ] = {
                                                                    ...values,
                                                                    schedule: renderObject(
                                                                        e
                                                                    ),
                                                                };
                                                                setOptions(
                                                                    newOptions
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                <div
                                                    className={classes.forEdit}
                                                >
                                                    {edit && (
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            type="submit"
                                                            disabled={loading}
                                                        >
                                                            Aplicar cambios
                                                        </Button>
                                                    )}
                                                </div>
                                                <div
                                                    className={classes.forEdit}
                                                >
                                                    {(userRole ===
                                                        'Estudiante' ||
                                                        userRole ===
                                                            'Interesado') && (
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            type="button"
                                                            onClick={() =>
                                                                handleAssign(
                                                                    option.idCourse
                                                                )
                                                            }
                                                            disabled={loading}
                                                        >
                                                            Asignar Materia
                                                        </Button>
                                                    )}
                                                </div>
                                            </section>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </main>
                    </Grid>
                </TabPanel>
            ))}
        </div>
    );
}
