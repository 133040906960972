export const subjectMapper = subject => {
    return {
        generalInfo: {
            subjectName: subject?.name ?? '',
            subjectType: subject?.idSubjectType ?? null,
            description: subject?.description ?? '',
            subjectStatus: subject?.status === 1 ? 'Activo' : 'Inactivo',
            modules: subject?.modules ?? null,
        },
        courses:
            subject?.courses.length > 0
                ? subject?.courses.map(course => ({
                      idSubject: course?.idSubject,
                      idCourse: course?.idCourse ?? 0,
                      idBranch: course?.idBranch ?? '',
                      courseIdentifier: course?.courseIdentifier ?? '',
                      status: course?.status === 1 ? 'Activo' : 'Inactivo',
                      modality: course?.modality ?? '',
                      startDate: course?.dateStart?.slice(0, 10) ?? '',
                      endDate: course?.dateEnd?.slice(0, 10) ?? '',
                      idTeacher: course?.idTeacher ?? null,
                      courseLink: course?.courseLink ?? '',
                      prices:
                          course?.prices?.length > 0
                              ? course?.prices.map(price => ({
                                    idCoursePrice: price?.idCoursePrice ?? null,
                                    idCourse: price?.idCourse ?? null,
                                    idPaymentType: price?.idPaymentType,
                                    cost: price?.cost ?? 0,
                                    status: 1,
                                    optiongroup: 1,
                                }))
                              : [],
                      schedule:
                          course?.schedule.length > 0
                              ? course.schedule.map(schedule => ({
                                    idCourseSchedule:
                                        schedule?.idCourseSchedule,
                                    idCourse: schedule?.idCourse,
                                    startTime: schedule?.startTime ?? null,
                                    endTime: schedule?.endTime ?? null,
                                    classroom: schedule?.classroom ?? null,
                                    isOnline: schedule?.isOnline ?? false,
                                    day: schedule?.day ?? null,
                                }))
                              : [],

                      // shouldDelete: course?.shouldDelete ?? false,
                  }))
                : [],

        documents: subject?.subjectDocuments.map(document => ({
            idSubjectDocument: document.idSubjectDocument ?? '',
            idDocumentType: document.idDocumentType ?? '',
            docNumber: document.docNumber ?? '',
            documentNotes: document.documentNotes ?? '',
            effBeginDate: document.effBeginDate,
            effEndDate: document.effEndDate,
            file: document.base64File ?? '',
            fileName: document.fileName ?? '',
        })),
    };
};

export const formatInsertData = (values, id) => {
    const formatted = {
        ...(id ? { idSubject: Number(id) } : {}),
        name: values.generalInfo.subjectName
            ? values.generalInfo.subjectName
            : null,
        idSubjectType: values.generalInfo.subjectType,
        Description: values.generalInfo.description
            ? values.generalInfo.description
            : null,
        status: values.generalInfo.subjectStatus ? 1 : 0,
        modules: values.generalInfo.modules ?? null,
        // createdDate:
        //     id && values.generalInfo.createdDate
        //         ? values.generalInfo.createdDate
        //         : new Date().toISOString(),
        // updatedDate: new Date().toISOString(),
        // updater: 'NOT SET', // CHECK WITHIN THE USER SLICE
    };
    return formatted;
};

export const formatInsertCourses = (values, id) => {
    return {
        course: {
            ...(values.idCourse ? { idCourse: Number(values.idCourse) } : {}),
            courseIdentifier: values.courseIdentifier,
            idBranch: values.idBranch,
            idSubject: id,
            idTeacher: values.idTeacher,
            idCourseModality:
                values.modality === 'Online'
                    ? 1
                    : values.modality === 'Hibrido'
                    ? 2
                    : values.modality === 'Presencial'
                    ? 3
                    : null,
            dateStart: values.startDate,
            dateEnd: values.endDate,
            status: values.status === 'Activo' ? 1 : 0,
            courseLink: values.courseLink,
        },
        prices: values.prices.map(price => ({
            ...(values.idCourse
                ? { idCourse: Number(values.idCourse) }
                : { idCourse: '0' }),
            ...(price.idCoursePrice
                ? { idCoursePrice: Number(price.idCoursePrice) }
                : {}),
            idPaymentType: price.idPaymentType,
            cost: price.cost,
            status: price.status,
            optiongroup: price.optiongroup,
        })),
    };
};

export const formatInsertSchedule = (values, idCourse) =>
    values.schedule.map(schedule => ({
        idCourseSchedule: schedule?.idCourseSchedule,
        idCourse: schedule?.idCourse ?? idCourse,
        day: schedule?.day,
        startTime: schedule?.startTime ?? null,
        endTime: schedule?.endTime ?? null,
        classroom: schedule?.classroom ?? null,
        isOnline: schedule?.isOnline,
    }));

export const formatInsertDocument = (values, id) => {
    return {
        idSubject: id,
        docNumber: values.docNumber,
        documentNotes: values.documentNotes,
        idDocumentType: values.idDocumentType,
        beginDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        base64File: values.file,
        FileName: values.fileName,
    };
};
