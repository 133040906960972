import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../assets/images/faithful-dog-logo.png';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    avatar: {
        margin: theme.spacing(1),
        height: '250px',
        width: '250px',
    },
    heroContent: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: theme.spacing(8, 0, 6),
        placeItems: 'center',
    },
    backgroundImagen: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    logo: {
        height: '250px',
        width: '250px',
        filter: 'invert(1)',
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function AboutUs() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <main>
                <Container className={classes.cardGrid} maxWidth="md">
                    {/* End hero unit */}
                    <Grid
                        container
                        spacing={4}
                        justifyContent="center"
                        alignContent="center"
                        alignItems="center"
                        direction="column"
                    >
                        <Grid item>
                            <Avatar
                                className={classes.logo}
                                alt="logo"
                                src={logo}
                            />
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h4"
                                align="center"
                                gutterBottom
                            >
                                Objetivo general
                            </Typography>
                            <Typography variant="h6" align="left" gutterBottom>
                                Consolidarnos como la mejor escuela de
                                adiestramiento canino en el estado, demostrando
                                la capacitación constante de nuestro equipo de
                                trabajo, mantenimiento de instalaciones y
                                actualización en los procesos de enseñanza y
                                aprendizaje sobre el adiestramiento canino con
                                la finalidad de innovar y revolucionar el método
                                educativo del mismo.
                            </Typography>
                        </Grid>
                        <hr style={{ width: '100%' }} />
                        <Grid item>
                            <Typography
                                variant="h4"
                                align="center"
                                gutterBottom
                            >
                                Misión
                            </Typography>
                            <Typography variant="h6" align="left" gutterBottom>
                                Ofrecer servicios de calidad en el
                                adiestramiento canino, proporcionando clases
                                efectivas de tal forma que el dueño pueda tener
                                las herramientas básicas para un buen control de
                                obediencia en su perro, así como crear
                                conciencia social en la responsabilidad que
                                conlleva la adquisición de una mascota. Así
                                mismo también ofrecer servicio de venta de
                                alimentos, cuidando la salud y nutrición de los
                                perros, facilitar a los clientes los aditamentos
                                y accesorios para poder tener efectividad en el
                                proceso de enseñanza aprendizaje durante los
                                adiestramientos.
                            </Typography>
                        </Grid>
                        <hr style={{ width: '100%' }} />
                        <Grid item>
                            <Typography
                                variant="h4"
                                align="center"
                                gutterBottom
                            >
                                Valores
                            </Typography>
                            <Grid
                                container
                                spacing={4}
                                justifyContent="center"
                                alignContent="center"
                                alignItems="center"
                                direction="column"
                            >
                                <Grid item>
                                    <Typography
                                        variant="h5"
                                        align="left"
                                        gutterBottom
                                    >
                                        Empatía:
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        align="left"
                                        gutterBottom
                                    >
                                        La empatía con los clientes, es decir
                                        ponernos en los zapatos de nuestros
                                        clientes, lo cual siempre va a ser un
                                        gesto bien recibido ya que nos contratan
                                        con necesidades diferentes.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="h5"
                                        align="left"
                                        gutterBottom
                                    >
                                        Responsabilidad:
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        align="left"
                                        gutterBottom
                                    >
                                        Comprometernos con el cliente a siempre
                                        finalizar los cursos de nuestra parte
                                        con la finalidad de que el cliente tenga
                                        las herramientas necesarias, enfatizando
                                        la puntualidad tomada de la mano.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="h5"
                                        align="left"
                                        gutterBottom
                                    >
                                        Honestidad laboral:
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        align="left"
                                        gutterBottom
                                    >
                                        Ser honestos con el cliente en lo que si
                                        podemos trabajar en lo que si podemos
                                        trabajar y ser específicos con los
                                        resultados necesarios que atiendan las
                                        problemáticas del cliente.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </React.Fragment>
    );
}
