import {
    Button,
    Container,
    Grid,
    MenuItem,
    Typography,
    IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import { FastField, Form, Formik, getIn, FieldArray } from 'formik';
import { useEffect, useRef, useState } from 'react';
import {
    AddNewContact,
    AddNewElement,
    OutlinedTextField,
} from '../../../common/FormComponents/FormComponents';
import PageHeader from '../../../common/PageHeader/PageHeader';
import { validationSchema, initialCourseInfo } from './mainForm';
import { red } from '@material-ui/core/colors';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubjects } from '../main/subjectsSlice';
import {
    fetchUserInfo,
    createSingleSubject,
    fetchSingleSubject,
    createSubjectCourse,
    updateSingleSubject,
    resetValues,
    uploadDocument,
} from './singleSubjectSlice';
import { fetchDropdownTeachers } from './DropdownTeachers/dropdownSlice';
import {
    fetchBranches,
    fetchCourseModalities,
    fetchSubjectDocumentType,
    fetchSubjectTypes,
} from '../../catalogs/catalogSlice';
import {
    formatInsertCourses,
    formatInsertData,
    formatInsertDocument,
} from './utils';
import { LOADING_STATUS } from '../../../helpers/constants';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { renderDateValue, renderTimeValue } from '../../../helpers/formHelpers';
import axios from 'axios';

// import UploadContact from './UploadContact/UploadContact';
import UploadDialog from '../../../common/DocumentComponents/UploadDialog/UploadDialog';
import DocumentationList from '../../../common/DocumentComponents/DocumentationList/DocumentationList';
import { values } from 'lodash';
import CoursesTabs from './coursesTabs/CoursesTabs';

const useStyles = makeStyles(theme => ({
    arraySubtitle: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: 700,
        marginTop: theme.spacing(-1.5),
        marginBottom: theme.spacing(-1.5),
        '$formSubSection + $formSubSection &': {
            marginTop: theme.spacing(2),
        },
        '& > span': {
            marginRight: theme.spacing(1),
        },
    },
    deleteIcon: {
        color: red[400],
        marginTop: theme.spacing(0.2),
    },
    formSection: {
        backgroundColor: theme.palette.grey[50],
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
    formSectionHeading: {
        color: theme.palette.primary.main,
        marginBottom: 0,
        marginTop: 0,
    },
    formSubSection: {},
    noData: {
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 1,
        textAlign: 'center',
    },
    subtitleWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
    uploadGrid: {
        marginBottom: theme.spacing(1),
    },
    cancelButton: {
        margin: '0px 10px',
    },
    gridSection: {
        width: '100%',
        flexDirection: 'row',
        padding: theme.spacing(2, 2),
    },
    containerStyles: {
        margin: 0,
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 0, 0),
    },
}));

const SingleSubject = ({ create = false }) => {
    const classes = useStyles();

    //states
    const prevSubmitCount = useRef(0);
    const [edit, setEdit] = useState(create);
    const [files, setFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [teacherInfo, setTeacherInfo] = useState({});
    const [openNewContact, setOpenNewContact] = useState(false);
    const [subName, setSubName] = useState({});
    const [idCreated, setIdCreated] = useState();
    const [subjectAdded, setSubjectAdded] = useState(false);
    const [newCourses, setNewCourses] = useState({ courses: [] });
    const [deletableContent, setDeletableContent] = useState({
        courses: [],
    });

    //selectors
    const allSubjects = useSelector(state => state.subjects.data.rows);
    const dropdownClientsStatus = useSelector(state => state.dropdown.status);
    const userRole = useSelector(state => state.user.userData.role);
    const subject = useSelector(state => state.singleSubject.subject);
    const catalogs = useSelector(state => state.catalogs.catalogs);
    const insertSubject = useSelector(
        state => state.singleSubject.insertSubject
    );
    const insertCourse = useSelector(
        state => state.singleSubject.insertSubjectCourse
    );
    const fetchStatus = useSelector(state => state.singleSubject.fetchStatus);
    const documentStatus = useSelector(
        state => state.singleSubject.insertDocument
    );

    const catalogStatus = useSelector(state => state.catalogs.status);
    const dropdownTeachers = useSelector(
        state => state.dropdown.dropdownTeachers
    );
    // const allSubjectType = useSelector(state => state.dropdown.subjectType);
    // const dropdownStatus = useSelector(state => state.dropdown.status);
    // const dropdownClients = useSelector(
    //     state => state.dropdown.dropdownClients
    // );

    const { id } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();

    const loadingClients = dropdownClientsStatus === LOADING_STATUS.LOADING;

    const loading = fetchStatus === LOADING_STATUS.LOADING;
    // || catalogStatus === LOADING_STATUS.LOADING ||
    // dropdownStatus === LOADING_STATUS.LOADING
    const error =
        fetchStatus === LOADING_STATUS.FAILED ||
        catalogStatus === LOADING_STATUS.FAILED;

    const insertingSubject = insertSubject === LOADING_STATUS.LOADING;

    useEffect(() => {
        dispatch(fetchSubjectTypes())
            .unwrap()
            .then(() =>
                dispatch(fetchCourseModalities())
                    .unwrap()
                    .then(() =>
                        dispatch(fetchBranches())
                            .unwrap()
                            .then(() => dispatch(fetchSubjectDocumentType()))
                    )
            );
        return () => dispatch(resetValues());
    }, [dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(fetchSingleSubject(id))
                .unwrap()
                .then(result =>
                    dispatch(fetchDropdownTeachers(result[0].idTeacher))
                );
        } else {
            dispatch(fetchDropdownTeachers());
            return () => dispatch(resetValues());
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (create) {
            dispatch(resetValues());
        }
    }, [dispatch, create]);

    useEffect(() => {
        if (id) {
            dispatch(fetchSingleSubject(id));
            // .unwrap()
            // .then(result =>
            //     dispatch(fetchUserInfo(result[0].courses.idTeacher))
            //         .unwrap()
            //         .then(result => setTeacherInfo(result))
            // );
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (fetchStatus === LOADING_STATUS.SUCCEEDED) {
            setFiles(subject.documents);
        }
    }, [subject.documents, fetchStatus]);

    useEffect(() => {
        if (documentStatus === LOADING_STATUS.SUCCEEDED) {
            dispatch(fetchSubjects())
                .unwrap()
                .then(() => handleClose());
        }
    }, [documentStatus, dispatch]);

    useEffect(() => {
        if (subject.courses.length > newCourses.courses.length) {
            setNewCourses({ courses: [...subject.courses] });
        }
    }, [subject, setNewCourses, newCourses]);

    useEffect(() => {
        if (insertSubject === LOADING_STATUS.SUCCEEDED) {
            allSubjects.find(subject =>
                subject.subjectName === subName
                    ? setIdCreated(subject.id)
                    : null
            );
            if (idCreated) {
                history.push(`/subjects/${idCreated}`);
                setEdit(false);
            }
        }
    }, [
        dispatch,
        history,
        insertSubject,
        allSubjects,
        setIdCreated,
        subName,
        idCreated,
    ]);

    // useEffect(() => {
    //     dispatch(fetchAllOpportunityType());
    //     return () => dispatch(resetValues());
    // }, [dispatch]);

    const handleFileChange = file => {
        const objectFile = formatInsertDocument(file, id);
        let body = jsonToFormData(objectFile);
        dispatch(uploadDocument(body))
            .unwrap()
            .then(() => {
                dispatch(fetchSingleSubject(id));
                handleClose();
            });
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenNewContact = () => {
        setOpenNewContact(true);
    };

    const handleCloseNewContact = () => {
        setOpenNewContact(false);
    };

    const handleButtonClick = e => {
        e.preventDefault();
        if (!edit) {
            setEdit(true);
        }
    };

    const handleDeleteDoc = fileName => {
        setFiles(prev => prev.filter(file => file.fileName !== fileName));
    };

    const handleSubmit = values => {
        // const newValue = {
        //     ...values,
        //     courses: [...values.courses, ...deletableContent.courses],
        // };
        const formattedValues = formatInsertData(values, id);
        const body = {
            ...formattedValues,
        };
        if (id) {
            dispatch(updateSingleSubject({ id, body }))
                .unwrap()
                .then(() => {
                    dispatch(fetchSingleSubject(id))
                        .unwrap()
                        .then(() => setEdit(false));
                    setEdit(false);
                });
        } else {
            dispatch(createSingleSubject(body))
                .unwrap()
                .then(result => {
                    setEdit(false);
                    history.push(`/subjects/${result.idSubject}`);
                });
        }
    };

    function buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object') {
            Object.keys(data).forEach(key => {
                buildFormData(
                    formData,
                    data[key],
                    parentKey ? `${parentKey}[${key}]` : key
                );
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }

    function jsonToFormData(data) {
        const formData = new FormData();

        buildFormData(formData, data);

        return formData;
    }

    const handleFieldUpdate = (nextProps, currentProps) => {
        if (
            nextProps.edit !== currentProps.edit ||
            nextProps.disabled !== currentProps.disabled ||
            nextProps.catalog !== currentProps.catalog ||
            nextProps.loading !== currentProps.loading ||
            nextProps.name !== currentProps.name ||
            getIn(nextProps.formik.values, currentProps.name) !==
                getIn(currentProps.formik.values, currentProps.name) ||
            getIn(nextProps.formik.errors, currentProps.name) !==
                getIn(currentProps.formik.errors, currentProps.name) ||
            getIn(nextProps.formik.touched, currentProps.name) !==
                getIn(currentProps.formik.touched, currentProps.name) ||
            Object.keys(currentProps).length !==
                Object.keys(nextProps).length ||
            nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ) {
            return true;
        } else {
            return false;
        }
    };

    const capitalLetter = string => {
        let lowStr = string.toLowerCase();
        let capStr = lowStr.charAt(0).toUpperCase() + lowStr.slice(1);
        return capStr;
    };

    return (
        <main>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={subject}
                onSubmit={handleSubmit}
            >
                {props => {
                    const { errors, submitCount, values, setValues } = props;
                    if (submitCount !== prevSubmitCount.current) {
                        prevSubmitCount.current = submitCount;
                        if (Object.keys(errors).length > 0) {
                            dispatch(
                                enqueueSnackbar({
                                    message:
                                        'Por favor llene todos los campos correctamente.',
                                    options: {
                                        key:
                                            new Date().getTime() +
                                            Math.random(),
                                        variant: 'error',
                                    },
                                })
                            );
                        }
                    }
                    return (
                        <Form>
                            <PageHeader
                                title={
                                    create
                                        ? 'Nueva materia'
                                        : edit
                                        ? 'Editar materia'
                                        : `${capitalLetter(
                                              values.generalInfo.subjectName
                                          )}`
                                }
                            />
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Información General
                                    </h2>
                                    {userRole === 'Administrador' && (
                                        <Grid>
                                            {edit && (
                                                <Button
                                                    className={
                                                        classes.cancelButton
                                                    }
                                                    color="secondary"
                                                    variant="contained"
                                                    type="button"
                                                    onClick={() => {
                                                        if (create) {
                                                            history.push(
                                                                '/subjects'
                                                            );
                                                        } else {
                                                            setValues(subject);
                                                            setEdit(false);
                                                        }
                                                    }}
                                                    disabled={loading}
                                                >
                                                    Cancelar
                                                </Button>
                                            )}
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                type={
                                                    edit ? 'submit' : 'button'
                                                }
                                                onClick={
                                                    !edit
                                                        ? handleButtonClick
                                                        : undefined
                                                }
                                                disabled={loading}
                                            >
                                                {create
                                                    ? 'Crear'
                                                    : edit
                                                    ? 'Aplicar cambios'
                                                    : 'Editar'}
                                            </Button>
                                        </Grid>
                                    )}
                                </div>
                                <Grid container spacing={3}>
                                    {edit && (
                                        <>
                                            <FastField
                                                component={OutlinedTextField}
                                                name="generalInfo.subjectName"
                                                type="text"
                                                label="Nombre*"
                                                edit={edit}
                                                loading={loading}
                                                disabled={insertingSubject}
                                                shouldUpdate={handleFieldUpdate}
                                            />
                                            <FastField
                                                component={OutlinedTextField}
                                                select
                                                name="generalInfo.subjectType"
                                                type="text"
                                                label="Typo*"
                                                edit={edit}
                                                loading={loading}
                                                disabled={insertingSubject}
                                                shouldUpdate={handleFieldUpdate}
                                                catalog={catalogs.subjectType}
                                            >
                                                <MenuItem value="">
                                                    &nbsp;
                                                </MenuItem>
                                                {catalogs.subjectType?.length >
                                                    0 &&
                                                    catalogs.subjectType.map(
                                                        ({
                                                            idSubjectType,
                                                            description,
                                                        }) => (
                                                            <MenuItem
                                                                value={
                                                                    idSubjectType
                                                                }
                                                                key={
                                                                    idSubjectType
                                                                }
                                                            >
                                                                {description}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </FastField>
                                            <FastField
                                                component={OutlinedTextField}
                                                select
                                                name="generalInfo.subjectStatus"
                                                type="text"
                                                label="estatus"
                                                edit={edit}
                                                loading={loading}
                                                disabled={insertingSubject}
                                                shouldUpdate={handleFieldUpdate}
                                                // catalog={catalogs.businessarea}
                                            >
                                                <MenuItem value="Activo">
                                                    Activo
                                                </MenuItem>
                                                <MenuItem value="Inactivo">
                                                    Inactivo
                                                </MenuItem>
                                            </FastField>
                                            <FastField
                                                component={OutlinedTextField}
                                                name="generalInfo.modules"
                                                type="number"
                                                label="Mensualidades"
                                                edit={edit}
                                                loading={loading}
                                                disabled={insertingSubject}
                                                shouldUpdate={handleFieldUpdate}
                                            />
                                        </>
                                    )}
                                </Grid>
                                <Grid container spacing={3}>
                                    <FastField
                                        component={OutlinedTextField}
                                        name="generalInfo.description"
                                        type="text"
                                        label="Descripción"
                                        multiline
                                        maxRows={5}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingSubject}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                </Grid>
                            </section>
                        </Form>
                    );
                }}
            </Formik>
            {id && (
                <section className={classes.formSection}>
                    <CoursesTabs
                        idSubject={id}
                        courses={subject.courses}
                        catalogs={catalogs}
                        loading={loading}
                        renderDateValue={renderDateValue}
                        insertingSubject={insertingSubject}
                        handleFieldUpdate={handleFieldUpdate}
                        onDelete={e =>
                            setNewCourses({
                                courses: [...e],
                            })
                        }
                    />
                </section>
            )}
            {id && userRole === 'Administrador' && (
                <section className={classes.formSection}>
                    <div className={classes.subtitleWrapper}>
                        <h2 className={classes.formSectionHeading}>
                            Documentación
                        </h2>
                        <AddNewElement
                            disabled={loading || insertingSubject}
                            onAddElement={handleOpen}
                        >
                            Cargar archivo
                        </AddNewElement>
                    </div>
                    {files.length > 0 ? (
                        <DocumentationList
                            className={classes.uploadGrid}
                            documentCatalog={catalogs.subjectDocumentType}
                            files={files}
                            onDeleteDoc={handleDeleteDoc}
                            edit={edit}
                        />
                    ) : (
                        <Typography className={classes.noData}>
                            Aún no hay documentos cargados.
                        </Typography>
                    )}
                    <UploadDialog
                        documentCatalog={catalogs.subjectDocumentType}
                        files={files}
                        handleClose={handleClose}
                        handleFileChange={handleFileChange}
                        open={open}
                    />
                </section>
            )}
        </main>
    );
};

export default SingleSubject;
