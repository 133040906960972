import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getStudentsData,
    getTeachersData,
    getUserData,
    getUserPermissions,
} from '../api/user';
import { LOADING_STATUS } from '../helpers/constants';
import { enqueueSnackbar } from '../features/pushNotifications/pushNotificationsSlice';
import { userMapper } from './utils';

const initialState = {
    userData: [],
    permissions: [],
    statusData: LOADING_STATUS.IDLE,
    statusPermissions: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchAllUserData = createAsyncThunk(
    'clients/fetchAllUserData',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const dataUser = await getUserData();
            const data = {
                ...dataUser,
            };
            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

export const fetchPermissionsOfUser = createAsyncThunk(
    'users/fetchUserPermission',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getUserPermissions();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar los permisos del usuario.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllUserData.pending, state => {
                state.statusData = LOADING_STATUS.LOADING;
            })
            .addCase(fetchAllUserData.fulfilled, (state, { payload }) => {
                state.statusData = LOADING_STATUS.SUCCEEDED;
                state.userData = userMapper(payload);
            })
            .addCase(fetchAllUserData.rejected, (state, { error }) => {
                state.statusData = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchPermissionsOfUser.pending, state => {
                state.statusPermissions = LOADING_STATUS.LOADING;
            })
            .addCase(fetchPermissionsOfUser.fulfilled, (state, { payload }) => {
                state.statusPermissions = LOADING_STATUS.SUCCEEDED;
                state.permissions = payload;
            })
            .addCase(fetchPermissionsOfUser.rejected, (state, { error }) => {
                state.statusPermissions = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
