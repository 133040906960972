import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../pushNotifications/pushNotificationsSlice';
import { LOADING_STATUS } from '../../helpers/constants';
import {
    createAppointment,
    createAppointmentEmployee,
    createIndividualClass,
    createOrder,
    deleteAppointmentEmployee,
    getAppointments,
    updateAppointment,
} from '../../api/appointment';
import { formatAppointments, getFilterData } from './utils';

const initialState = {
    data: {
        filters: {
            generalFilter: {
                selected: '',
                options: [],
            },
            locationFilter: {
                selected: '',
                options: [],
            },
        },
        rows: [],
    },
    insertStatus: LOADING_STATUS.IDLE,
    status: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchAppointments = createAsyncThunk(
    'appointments/fetchAppointmentList',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getAppointments(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudo cargar la lista de usuarios, por favor refresque la página. Si el problema persiste contacte a soporte.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createSingleAppointment = createAsyncThunk(
    'appointments/createSingleAppointment',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createAppointment(body);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudo crear el horario. Si el problema persiste contacte a soporte.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleAppointment = createAsyncThunk(
    'appointments/updateSingleAppointment',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateAppointment(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Horario actualizado.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se actualizar el horario. Si el problema persiste contacte a soporte.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createSingleAssociation = createAsyncThunk(
    'appointments/createSingleAssociation',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createAppointmentEmployee(body);
            dispatch(
                enqueueSnackbar({
                    message: `Entrenador Asignado.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudo asignar el entrenador. contacte a soporte.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const deleteSingleAssociation = createAsyncThunk(
    'appointments/deleteSingleAssociation',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await deleteAppointmentEmployee(id);
            dispatch(
                enqueueSnackbar({
                    message: `Entrenador eliminado del horario.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudo eliminar al entrenador del horario, contacte a soporte.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createSingleOrder = createAsyncThunk(
    'appointments/createSingleOrder',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createOrder(body);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudo crear la orden. Si el problema persiste contacte a soporte.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createSingleClass = createAsyncThunk(
    'appointments/createSingleClass',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createIndividualClass(body);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudo crear la clase. Si el problema persiste contacte a soporte.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const appointmentsSlice = createSlice({
    name: 'appointments',
    initialState,
    reducers: {
        changeOption: (state, { payload }) => {
            state.data.filters[payload.name].selected = payload.value;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAppointments.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchAppointments.fulfilled, (state, { payload }) => {
                const cleanAppointments = formatAppointments(payload);

                state.status = LOADING_STATUS.SUCCEEDED;
                state.insertStatus = LOADING_STATUS.IDLE;
                state.data.rows = cleanAppointments;
                state.data.filters = getFilterData(cleanAppointments);
            })
            .addCase(fetchAppointments.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSingleAppointment.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(
                updateSingleAppointment.fulfilled,
                (state, { payload }) => {
                    state.insertStatus = LOADING_STATUS.SUCCEEDED;
                }
            )
            .addCase(updateSingleAppointment.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(createSingleAssociation.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(
                createSingleAssociation.fulfilled,
                (state, { payload }) => {
                    state.insertStatus = LOADING_STATUS.SUCCEEDED;
                }
            )
            .addCase(createSingleAssociation.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(deleteSingleAssociation.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(
                deleteSingleAssociation.fulfilled,
                (state, { payload }) => {
                    state.insertStatus = LOADING_STATUS.SUCCEEDED;
                }
            )
            .addCase(deleteSingleAssociation.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(createSingleOrder.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(createSingleOrder.fulfilled, (state, { payload }) => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(createSingleOrder.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(createSingleClass.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(createSingleClass.fulfilled, (state, { payload }) => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(createSingleClass.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { changeOption } = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
