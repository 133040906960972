import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LOADING_STATUS } from '../../../helpers/constants';
import { initialFormInfo } from './mainForm';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { sendInfo } from '../../../api/subjects';

const initialState = {
    form: initialFormInfo,
    sendStatus: LOADING_STATUS.IDLE,
    error: null,
};

export const sendRequestInfoInfo = createAsyncThunk(
    'home/sendRequestInfo',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await sendInfo(body);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo enviar el correo.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const requestInfoFormSlice = createSlice({
    name: 'requestInfoForm',
    initialState,
    reducers: {
        resetValues: () => {
            return initialFormInfo;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(sendRequestInfoInfo.pending, state => {
                state.sendRequestInfoInfoStatus = LOADING_STATUS.LOADING;
            })
            .addCase(sendRequestInfoInfo.fulfilled, state => {
                state.sendRequestInfoInfoStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(sendRequestInfoInfo.rejected, (state, { error }) => {
                state.sendRequestInfoInfoStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = requestInfoFormSlice.actions;

export default requestInfoFormSlice.reducer;
