import axios from 'axios';
import { axiosConfig, axiosConfigForm } from './axiosConfig';

export const getUserData = async () => {
    const { data } = await axios.get(`users/me`, axiosConfig('api/v1'));
    return data;
};
export const getStudentsData = async () => {
    const { data } = await axios.get(`students`, axiosConfig('api'));
    return data;
};

export const getTeachersData = async () => {
    const { data } = await axios.get(`teachers`, axiosConfig('api'));
    return data;
};

export const getUserPermissions = async id => {
    const { data } = await axios.get(
        `http://192.168.56.126:8000/me/permissions/`
    );
    return data;
};

export const getUserList = async () => {
    const { data } = await axios.get('employees', axiosConfig('api/v1'));
    return data;
};

export const getUserLandingList = async () => {
    const { data } = await axios.get(
        'employees/landing',
        axiosConfig('api/v1')
    );
    return data;
};

// export const getUserInfo = async id => {
//     const { data } = await axios.get(
//         `https://centro-universitario-dev.herokuapp.com/api/users/${id}`
//     );
//     return data;
// };

export const getUserInfo = async id => {
    const { data } = await axios.get(`employees/${id}`, axiosConfig('api/v1'));
    return data;
};

export const getLandingUserInfo = async name => {
    const { data } = await axios.get(
        `employees/landing/${name}`,
        axiosConfig('api/v1')
    );
    return data;
};

// export const getUserRoles = async id => {
//     const { data } = await axios.get(
//         `http://192.168.56.126:8000/users/${id}/rols/`
//     );
//     return data;
// };

export const createUser = async body => {
    const { data } = await axios.post('employees', body, axiosConfig('api/v1'));
    return data;
};

export const updateUser = async (id, body) => {
    const { data } = await axios.patch(
        `users/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const updateEmployee = async (id, body) => {
    const { data } = await axios.patch(
        `employees/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};
export const updateAddress = async (id, body) => {
    const { data } = await axios.patch(
        `addresses/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};
export const updateBankInfo = async (id, body) => {
    const { data } = await axios.patch(
        `accounts/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const uploadEmployeeImage = async (id, body) => {
    const { data } = await axios({
        method: 'patch',
        url: axiosConfigForm(`api/v1/employees/images/${id}`),
        data: body,
        headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data;
};

export const createStudent = async body => {
    const { data } = await axios.post(
        'students/PostStudent',
        body,
        axiosConfig('api')
    );
    return data;
};

export const registerUser = async body => {
    const { data } = await axios.patch(`users`, body, axiosConfig('api'));
    return data;
};

// export const assignRoleToUser = async (id, body) => {
//     const { data } = await axios.post(
//         `http://192.168.56.126:8000/users/${id}/rols/`,
//         body
//     );
//     return data;
// };
