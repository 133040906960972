import React from 'react';
import {
    Paper,
    makeStyles,
    Typography,
    Grid,
    Link,
    CardActionArea,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    mainFeaturedPost: {
        height: '50vh',
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        [theme.breakpoints.down('md')]: {
            height: '70vh',
        },
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    },
    description: {
        width: '100%',
        fontSize: '25px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
}));

export default function Item({ item }) {
    function filterItems(query, data) {
        const object = data.files.filter(
            file => file.path.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
        return object[0]?.path;
    }
    const classes = useStyles();

    const handleRedirect = link => {
        window.location.href = `${link}`;
    };

    return (
        <CardActionArea
            component="a"
            className={classes.mainFeaturedPost}
            style={{ backgroundImage: `url(${item.image})` }}
            onClick={() => (item.link ? handleRedirect(item.link) : null)}
        >
            {/* Increase the priority of the hero background image */}
            {
                <img
                    style={{ display: 'none' }}
                    src={item.image}
                    alt={item.imageText}
                />
            }
            <div className={classes.overlay} />
            <Grid container>
                <Grid item md={11}>
                    <div className={classes.mainFeaturedPostContent}>
                        <Typography
                            component="h1"
                            variant="h3"
                            color="inherit"
                            gutterBottom
                        >
                            {item.title}
                        </Typography>
                        <Typography
                            className={classes.description}
                            color="inherit"
                            paragraph
                        >
                            {item.description}
                        </Typography>
                        <Link color="inherit" variant="subtitle1" href="#">
                            {item.linkText}
                        </Link>
                    </div>
                </Grid>
            </Grid>
        </CardActionArea>
    );
}
