import { useEffect, useRef, useState } from 'react';
import {
    CircularProgress,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Link } from 'react-router-dom';
import { LOADING_STATUS } from '../../../../helpers/constants';
import classNames from 'classnames';

const useActionStyles = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const useTableStyles = makeStyles(theme => ({
    noInfo: {
        color: theme.palette.grey[400],
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'center',
    },
    fullHeight: {
        height: 484,
    },
    tableFooter: {
        background: `linear-gradient(to top, white 0%, white calc(100% - 1px), ${theme.palette.grey[300]} calc(100% - 1px))`,
        position: 'sticky',
        bottom: 0,
    },
    tableHead: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        position: 'sticky',
        top: 0,
    },
    tableHeadCell: {
        color: 'white',
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase',
    },
    tableLink: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    tableRoot: {
        borderRadius: theme.shape.borderRadius,
        maxHeight: 484,
        '& td, & th': {
            height: 40,
        },
        '& table': {
            borderCollapse: 'separate',
        },
    },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
    const classes = useActionStyles();

    const handleFirstPageButtonClick = event => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </div>
    );
};

const PaymentTable = ({ rows = [], status }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const classes = useTableStyles();
    const rowsLength = rows.length;
    const prevRows = useRef(rows);

    useEffect(() => {
        if (prevRows.current !== rowsLength) {
            prevRows.current = rowsLength;
            setPage(0);
        }
    }, [rowsLength]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const capitalLetter = string => {
        let lowStr = string.toLowerCase();
        let capStr = lowStr.charAt(0).toUpperCase() + lowStr.slice(1);
        return capStr;
    };

    return (
        <div>
            <TableContainer
                component={Paper}
                className={classNames(classes.tableRoot, {
                    [classes.fullHeight]:
                        status === LOADING_STATUS.LOADING || rows.length === 0,
                })}
            >
                <Table size="small" aria-label="a dense table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell className={classes.tableHeadCell}>
                                Nombre
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Email
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Rol
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Materias asignadas
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Status
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {status === LOADING_STATUS.LOADING ||
                        rows.length === 0 ? (
                            <TableRow style={{ height: 378 }}>
                                <TableCell
                                    className={classes.noInfo}
                                    colSpan={6}
                                >
                                    {status === LOADING_STATUS.LOADING ? (
                                        <CircularProgress />
                                    ) : (
                                        'Sin información que mostrar'
                                    )}
                                </TableCell>
                            </TableRow>
                        ) : (
                            (rowsPerPage > 0
                                ? rows.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                  )
                                : rows
                            ).map(row => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        <Link
                                            className={classes.tableLink}
                                            to={`/paymentsUsers/${row.name}/${row.id}`}
                                        >
                                            {row.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.email}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.role}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.subjectsAssigned}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.paymentStatus}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                    <TableFooter className={classes.tableFooter}>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 20, 100]}
                                colSpan={6}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default PaymentTable;
