export const formatServices = servicesArray =>
    servicesArray.map(service => {
        return {
            id: service.id,
            image: service.image ?? null,
            name: service.name ?? '',
            description: service.description ?? '',
            price: service.price ?? null,
        };
    });

export const getFilterData = services => {
    const servicesOptions = {
        selected: '',
        options: Array.from(
            services.reduce(
                (acc, cur) => (cur.service ? acc.add(cur.service) : acc),
                new Set()
            )
        ),
    };

    const servicesStatusOptions = {
        selected: '',
        options: Array.from(
            services.reduce(
                (acc, cur) =>
                    cur.servicesStatus ? acc.add(cur.servicesStatus) : acc,
                new Set()
            )
        ),
    };

    const servicesRoleOptions = {
        selected: '',
        options: Array.from(
            services.reduce(
                (acc, cur) => (cur.role ? acc.add(cur.role) : acc),
                new Set()
            )
        ),
    };

    return {
        servicesOptions,
        servicesStatusOptions,
        servicesRoleOptions,
    };
};
