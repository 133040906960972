import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LOADING_STATUS } from '../../helpers/constants';
import { enqueueSnackbar } from '../pushNotifications/pushNotificationsSlice';
import { registerUser } from '../../api/user';

const initialState = {
    insertStatus: LOADING_STATUS.IDLE,
    error: null,
};

export const registerUserInfo = createAsyncThunk(
    'user/registerUserInfo',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await registerUser(body);
            dispatch(
                enqueueSnackbar({
                    message: `Usuario actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el usuario, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(registerUserInfo.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(registerUserInfo.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(registerUserInfo.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = registerSlice.actions;

export default registerSlice.reducer;
