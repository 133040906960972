export const formatSubjects = subjectsArray =>
    subjectsArray.map(subject => {
        return {
            id: subject.idSubject,
            subjectName: subject?.name ?? '',
            type: subject?.idSubjectType ?? '',
            subjectStatus: subject?.status === 1 ? 'Activo' : 'Inactivo',
        };
    });

export const getFilterData = subjects => {
    const subjectNameOptions = {
        selected: '',
        options: Array.from(
            subjects.reduce(
                (acc, cur) =>
                    cur.subjectName ? acc.add(cur.subjectName) : acc,
                new Set()
            )
        ),
    };

    const subjectTypeOptions = {
        selected: '',
        options: Array.from(
            subjects.reduce(
                (acc, cur) => (cur.type ? acc.add(cur.type) : acc),
                new Set()
            )
        ),
    };

    const subjectStatusOptions = {
        selected: '',
        options: Array.from(
            subjects.reduce(
                (acc, cur) =>
                    cur.subjectStatus ? acc.add(cur.subjectStatus) : acc,
                new Set()
            )
        ),
    };

    return {
        subjectNameOptions,
        subjectTypeOptions,
        subjectStatusOptions,
    };
};
