import {
    Button,
    Grid,
    Paper,
    IconButton,
    MenuItem,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FastField, FieldArray, Form, Formik, getIn } from 'formik';
import { useEffect, useRef, useState } from 'react';
import {
    AddNewElement,
    LabelledCheckbox,
    OutlinedTextField,
} from '../../../common/FormComponents/FormComponents';
import PageHeader from '../../../common/PageHeader/PageHeader';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    initialAddress,
    initialContactInfo,
    initialFiscalInfo,
    validationSchema,
} from './mainForm';
import { red } from '@material-ui/core/colors';
import UploadDialog from '../../../common/DocumentComponents/UploadDialog/UploadDialog';
import DocumentationList from '../../../common/DocumentComponents/DocumentationList/DocumentationList';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
    createSingleRole,
    fetchRoleInfo,
    updateSingleRole,
    resetValues,
    fetchAllPermissions,
    fetchPermissionsOfRole,
    updateSingleRoleInfo,
} from './singleRoleSlice';
import { fetchRoles } from '../main/RolesSlice';
import { fetchAllCatalogs } from '../../catalogs/catalogSlice';
import { formatInsertData, formatInsertDataPermissions } from './utils';
import { LOADING_STATUS } from '../../../helpers/constants';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useActionStyles = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const useStyles = makeStyles(theme => ({
    arraySubtitle: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: 700,
        marginTop: theme.spacing(-1.5),
        marginBottom: theme.spacing(-1.5),
        '$formSubSection + $formSubSection &': {
            marginTop: theme.spacing(2),
        },
        '& > span': {
            marginRight: theme.spacing(1),
        },
    },
    deleteIcon: {
        color: red[400],
        marginTop: theme.spacing(0.2),
    },
    formSection: {
        backgroundColor: theme.palette.grey[50],
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(4),
        },
    },
    formSectionHeading: {
        color: theme.palette.primary.main,
        marginBottom: 0,
        marginTop: 0,
    },
    formSubSection: {},
    noData: {
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 1,
        textAlign: 'center',
    },
    subtitleWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
    uploadGrid: {
        marginBottom: theme.spacing(1),
    },

    noInfo: {
        color: theme.palette.grey[400],
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'center',
    },

    tableFooter: {
        background: `linear-gradient(to top, white 0%, white calc(100% - 1px), ${theme.palette.grey[300]} calc(100% - 1px))`,
        position: 'sticky',
        bottom: 0,
    },

    tableHead: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        position: 'sticky',
        top: 0,
    },

    tableHeadCell: {
        color: 'white',
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase',
    },
    tableUser: {
        borderRadius: theme.shape.borderRadius,
        marginBottom: 30,
        maxWidth: 484,
        '& td, & th': {
            height: 40,
        },
        '& table': {
            borderCollapse: 'separate',
        },
    },
    tableInfo: {
        borderRadius: theme.shape.borderRadius,
        marginBottom: 30,
        maxHeight: 284,
        '& td, & th': {
            height: 40,
        },
        '& table': {
            borderCollapse: 'separate',
        },
    },
    tableRoles: {
        borderRadius: theme.shape.borderRadius,
        marginBottom: 30,
        '& td, & th': {
            height: 40,
        },
        '& table': {
            borderCollapse: 'separate',
        },
    },
    inputCellDescription: {},
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
    const classes = useActionStyles();

    const handleFirstPageButtonClick = event => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </div>
    );
};

const SingleRole = ({ create = false }) => {
    const classes = useStyles();

    const prevSubmitCount = useRef(0);
    const [page, setPage] = useState(0);
    const [rolesPerPage, setRolesPerPage] = useState(5);
    const [edit, setEdit] = useState(create);
    const [files, setFiles] = useState([]);
    const [roleName, setRoleName] = useState({});
    const [idCreated, setIdCreated] = useState();
    const [bodyPermissions, setBodyPermissions] = useState([]);
    const [roleAdded, setRoleAdded] = useState(false);
    // const [open, setOpen] = useState(false);
    // const [deletableContent, setDeletableContent] = useState({
    //     contactInfo: [],
    //     fiscalInfo: [],
    //     address: [],
    // });

    const { id } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();

    const allRoles = useSelector(state => state.roles.data.rows);
    const role = useSelector(state => state.singleRole.role);
    const insertStatus = useSelector(state => state.singleRole.insertStatus);
    const fetchStatus = useSelector(
        state => state.singleRole.fetchRoleInfoStatus
    );

    const loading = fetchStatus === LOADING_STATUS.LOADING;

    const insertingClient = insertStatus === LOADING_STATUS.LOADING;

    useEffect(() => {
        allRoles.find(role =>
            role.name === roleName ? setIdCreated(role.id) : null
        );
        if (idCreated) {
            const assignPermissionsToRole = (idRole, permissions) => {
                dispatch(updateSingleRole({ idRole, permissions }));
                setRoleAdded(true);
            };
            assignPermissionsToRole(idCreated, bodyPermissions);
        }
    }, [dispatch, allRoles, roleName, idCreated, bodyPermissions]);

    useEffect(() => {
        if (roleAdded) {
            history.push('/roles');
        }
    }, [history, roleAdded]);

    useEffect(() => {
        dispatch(fetchAllPermissions());
        return () => dispatch(resetValues());
    }, [dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(fetchPermissionsOfRole(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (id) {
            dispatch(fetchRoleInfo(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (insertStatus === LOADING_STATUS.SUCCEEDED) {
            dispatch(fetchRoles());
        }
    }, [dispatch, insertStatus]);

    // const handleFileChange = file => {
    //     setFiles(prev => [...prev, file]);
    //     setOpen(false);
    // };

    // const handleOpen = () => {
    //     setOpen(true);
    // };

    const handleButtonClick = e => {
        e.preventDefault();
        if (!edit) {
            setEdit(true);
        }
    };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handleDeleteDoc = fileName => {
        setFiles(prev => prev.filter(file => file.fileName !== fileName));
    };

    const handleSubmit = values => {
        const formattedValues = formatInsertData(values, id);
        const formattedValuesPermissions = formatInsertDataPermissions(values);
        const body = {
            ...formattedValues,
        };
        const idRole = id;
        const permissions = [...formattedValuesPermissions.filter(Boolean)];
        if (id) {
            dispatch(updateSingleRoleInfo({ id, body }));
            dispatch(updateSingleRole({ idRole, permissions }));
            setRoleAdded(true);
        } else {
            setRoleName(body.name);
            setBodyPermissions(permissions);
            dispatch(createSingleRole(body));
        }
    };

    const handleFieldUpdate = (nextProps, currentProps) => {
        if (
            nextProps.edit !== currentProps.edit ||
            nextProps.disabled !== currentProps.disabled ||
            nextProps.loading !== currentProps.loading ||
            nextProps.catalog !== currentProps.catalog ||
            nextProps.name !== currentProps.name ||
            getIn(nextProps.formik.values, currentProps.name) !==
                getIn(currentProps.formik.values, currentProps.name) ||
            getIn(nextProps.formik.errors, currentProps.name) !==
                getIn(currentProps.formik.errors, currentProps.name) ||
            getIn(nextProps.formik.touched, currentProps.name) !==
                getIn(currentProps.formik.touched, currentProps.name) ||
            Object.keys(currentProps).length !==
                Object.keys(nextProps).length ||
            nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRolesPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const permissionsForRole = [...role.allPermissions];

    role.allPermissions.forEach(permit =>
        role.permissionsOfRole.find(rolePermit =>
            rolePermit.id === permit.id
                ? (permissionsForRole[role.allPermissions.indexOf(permit)] = {
                      ...rolePermit,
                  })
                : null
        )
    );

    const newRole = { ...role, permissionsOfRole: permissionsForRole };

    return (
        <main>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={newRole}
                onSubmit={handleSubmit}
            >
                {props => {
                    const { errors, submitCount, values } = props;
                    if (submitCount !== prevSubmitCount.current) {
                        prevSubmitCount.current = submitCount;
                        if (Object.keys(errors).length > 0) {
                            dispatch(
                                enqueueSnackbar({
                                    message:
                                        'Por favor llene todos los campos correctamente.',
                                    options: {
                                        key:
                                            new Date().getTime() +
                                            Math.random(),
                                        variant: 'error',
                                    },
                                })
                            );
                        }
                    }

                    return (
                        <Form>
                            <PageHeader
                                title={
                                    create
                                        ? 'Nuevo rol'
                                        : edit
                                        ? 'Editar rol'
                                        : 'Consulta de rol'
                                }
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type={edit ? 'submit' : 'button'}
                                    onClick={
                                        !edit ? handleButtonClick : undefined
                                    }
                                    disabled={loading || insertingClient}
                                >
                                    {create
                                        ? 'Crear'
                                        : edit
                                        ? 'Aplicar cambios'
                                        : 'Editar'}
                                </Button>
                            </PageHeader>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Información General
                                    </h2>
                                </div>
                                <Grid container spacing={3}>
                                    <FastField
                                        component={OutlinedTextField}
                                        name="roleInfo.name"
                                        type="text"
                                        label={
                                            create || edit
                                                ? 'Nombre*'
                                                : 'Nombre'
                                        }
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingClient}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        className={classes.inputCellDescription}
                                        component={OutlinedTextField}
                                        name="roleInfo.description"
                                        type="text"
                                        label={
                                            create || edit
                                                ? 'Descripción*'
                                                : 'Descripción'
                                        }
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingClient}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                </Grid>
                            </section>
                            <TableContainer
                                mt={5}
                                className={classes.tableRoles}
                            >
                                <Table size="small" aria-label="a dense table">
                                    <TableHead className={classes.tableHead}>
                                        <TableRow>
                                            <TableCell
                                                className={
                                                    classes.tableHeadCell
                                                }
                                                component="th"
                                                scope="row"
                                                align="center"
                                            >
                                                Roles
                                            </TableCell>
                                            <TableCell
                                                className={
                                                    classes.tableHeadCell
                                                }
                                                component="th"
                                                scope="row"
                                                align="center"
                                            >
                                                Descripción
                                            </TableCell>
                                            <TableCell
                                                className={
                                                    classes.tableHeadCell
                                                }
                                                component="th"
                                                scope="row"
                                            >
                                                Asignado
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {values.permissionsOfRole.length > 0 ? (
                                            (rolesPerPage > 0
                                                ? values.permissionsOfRole.slice(
                                                      page * rolesPerPage,
                                                      page * rolesPerPage +
                                                          rolesPerPage
                                                  )
                                                : values.permissionsOfRole
                                            ).map(permit => (
                                                <TableRow key={permit.id}>
                                                    <TableCell align="center">
                                                        {permit.name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {permit.description}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <FastField
                                                            component={
                                                                LabelledCheckbox
                                                            }
                                                            type="checkbox"
                                                            name={`permissionsOfRole[${values.permissionsOfRole.indexOf(
                                                                permit
                                                            )}].state`}
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingClient
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow style={{ height: 106 }}>
                                                <TableCell
                                                    className={classes.noInfo}
                                                    colSpan={6}
                                                >
                                                    Sin información que mostrar
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter
                                        className={classes.tableFooter}
                                    >
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[
                                                    5,
                                                    10,
                                                    20,
                                                    100,
                                                ]}
                                                colSpan={6}
                                                count={
                                                    values.permissionsOfRole
                                                        .length
                                                }
                                                rowsPerPage={rolesPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        'aria-label':
                                                            'rows per page',
                                                    },
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={
                                                    handleChangeRowsPerPage
                                                }
                                                ActionsComponent={
                                                    TablePaginationActions
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Form>
                    );
                }}
            </Formik>
        </main>
    );
};

export default SingleRole;
