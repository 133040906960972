import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSubjects } from '../../../api/subjects';
import { LOADING_STATUS } from '../../../helpers/constants';
import { formatSubjects, getFilterData } from './utils';

const initialState = {
    data: {
        filters: {
            subjectNameOptions: {
                selected: '',
                options: [],
            },
            subjectTypeOptions: {
                selected: '',
                options: [],
            },
            subjectStatusOptions: {
                selected: '',
                options: [],
            },
        },
        rows: [],
    },
    status: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchSubjects = createAsyncThunk(
    'subjects/fetchSubjects',
    async () => {
        const data = await getSubjects();
        return data;
    }
);

export const subjectSlice = createSlice({
    name: 'subjects',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        changeOption: (state, { payload }) => {
            state.data.filters[payload.name].selected = payload.value;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSubjects.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchSubjects.fulfilled, (state, { payload }) => {
                const cleanSubjects = formatSubjects(payload);

                state.status = LOADING_STATUS.SUCCEEDED;
                state.data.rows = cleanSubjects;
                state.data.filters = getFilterData(cleanSubjects);
            })
            .addCase(fetchSubjects.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { changeOption } = subjectSlice.actions;

export default subjectSlice.reducer;
