export const videoMapper = video => {
    return {
        id: video.id,
        image: video.image ?? '',
        video: video.video ?? '',
        title: video.title ?? '',
    };
};

export const formatData = values => {
    const formatted = {
        title: values.videoInfo.title,
    };
    return formatted;
};

export const formatInsertFile = values => ({
    filename: values.file,
});
