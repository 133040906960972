import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getServiceInfo,
    createService,
    updateService,
    uploadImageService,
} from '../../../api/service';
import { getRolesList } from '../../../api/roles';
import { LOADING_STATUS } from '../../../helpers/constants';
import { initialValues } from './mainForm';
import { serviceMapper } from './utils';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';

const initialState = {
    service: initialValues,
    serviceAdded: false,
    fetchServiceInfoStatus: LOADING_STATUS.IDLE,
    fetchServiceRolesStatus: LOADING_STATUS.IDLE,
    fetchAllRolesStatus: LOADING_STATUS.IDLE,
    insertStatus: LOADING_STATUS.IDLE,
    insertRoles: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchServiceInfo = createAsyncThunk(
    'services/fetchServiceInfo',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getServiceInfo(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar el servicio con el id ${id}.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createSingleService = createAsyncThunk(
    'services/createSingleService',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createService(body);
            dispatch(
                enqueueSnackbar({
                    message: `Servicio creado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo crear el servicio, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleService = createAsyncThunk(
    'services/updateSingleService',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateService(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Servicio actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el servicio, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const uploadServicePicture = createAsyncThunk(
    'services/updateServicePicture',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await uploadImageService(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Imagen actualizada con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar la Imagen, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const singleServiceSlice = createSlice({
    name: 'singleService',
    initialState,
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchServiceInfo.pending, state => {
                state.fetchServiceInfoStatus = LOADING_STATUS.LOADING;
            })
            .addCase(fetchServiceInfo.fulfilled, (state, { payload }) => {
                state.fetchServiceInfoStatus = LOADING_STATUS.SUCCEEDED;
                state.service.serviceInfo = serviceMapper(payload);
            })
            .addCase(fetchServiceInfo.rejected, (state, { error }) => {
                state.fetchServiceInfoStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(createSingleService.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(createSingleService.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(createSingleService.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSingleService.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSingleService.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSingleService.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(uploadServicePicture.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(uploadServicePicture.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(uploadServicePicture.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = singleServiceSlice.actions;

export default singleServiceSlice.reducer;
