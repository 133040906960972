import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    createClient,
    createPet,
    createTrainerAssign,
    getSingleClient,
    updateClient,
    updateEthogram,
    updatePet,
    updateTrainerAssign,
    uploadPetImage,
} from '../../../api/clients';
import { LOADING_STATUS } from '../../../helpers/constants';
import { initialValues } from './mainForm';
import { clientMapper } from './utils';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { updateAddress, updateUser } from '../../../api/user';

const initialState = {
    client: initialValues,
    clientAdded: false,
    fetchStatus: LOADING_STATUS.IDLE,
    insertStatus: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchSingleClient = createAsyncThunk(
    'clients/fetchSingleClient',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getSingleClient(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar el usuario con el id ${id}.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createSingleClient = createAsyncThunk(
    'clients/createSingleClient',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createClient(body);
            dispatch(
                enqueueSnackbar({
                    message: `Cliente creado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo crear el cliente, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleClient = createAsyncThunk(
    'clients/updateSingleClient',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateClient(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Cliente actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el cliente, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createSinglePet = createAsyncThunk(
    'clients/createSinglePet',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createPet(body);
            dispatch(
                enqueueSnackbar({
                    message: `Mascota creada con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo agregar la mascota, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSinglePet = createAsyncThunk(
    'clients/updateSinglePet',
    async ({ petId, ethogramId, body }, { dispatch, rejectWithValue }) => {
        try {
            const petData = await updatePet(petId, body.pet);
            const ethogramData = await updateEthogram(
                ethogramId,
                body.ethogram
            );
            dispatch(
                enqueueSnackbar({
                    message: `Mascota actualizada con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return { ...petData, ethogram: { ...ethogramData } };
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar la mascota, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createSingleTrainerAssign = createAsyncThunk(
    'clients/createSingleTrainerAssign',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createTrainerAssign(body);
            dispatch(
                enqueueSnackbar({
                    message: `Adiestrador asignado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo asignar el adiestrador, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleTrainerAssign = createAsyncThunk(
    'clients/updateSingleTrainerAssign',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateTrainerAssign(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Adiestrador actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el adiestrador, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleUser = createAsyncThunk(
    'clients/updateSingleUser',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateUser(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Usuario actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el usuario, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleAddress = createAsyncThunk(
    'clients/updateSingleAddress',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateAddress(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Dirección actualizada con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar la dirección, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const uploadPetPicture = createAsyncThunk(
    'users/updatePetPicture',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await uploadPetImage(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Imagen actualizada con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar la Imagen, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const singleClientSlice = createSlice({
    name: 'singleClient',
    initialState,
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSingleClient.pending, state => {
                state.fetchStatus = LOADING_STATUS.LOADING;
            })
            .addCase(fetchSingleClient.fulfilled, (state, { payload }) => {
                state.fetchStatus = LOADING_STATUS.SUCCEEDED;
                state.client = clientMapper(payload);
            })
            .addCase(fetchSingleClient.rejected, (state, { error }) => {
                state.fetchStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(createSingleClient.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(
                createSingleClient.fulfilled,
                (state, { payload: newClient }) => {
                    state.insertStatus = LOADING_STATUS.SUCCEEDED;
                    state.clientAdded = Boolean(newClient.clientId);
                }
            )
            .addCase(createSingleClient.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSingleClient.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSingleClient.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSingleClient.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSinglePet.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSinglePet.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSinglePet.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(createSingleTrainerAssign.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(createSingleTrainerAssign.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(createSingleTrainerAssign.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSingleTrainerAssign.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSingleTrainerAssign.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSingleTrainerAssign.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSingleUser.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSingleUser.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSingleUser.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSingleAddress.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSingleAddress.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSingleAddress.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(uploadPetPicture.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(uploadPetPicture.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(uploadPetPicture.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = singleClientSlice.actions;

export default singleClientSlice.reducer;
