import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ContactMail from '@material-ui/icons/ContactMail';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import img1 from '../../assets/images/img1.jpg';
import img2 from '../../assets/images/img2.jpg';
import img3 from '../../assets/images/img3.jpg';
import img4 from '../../assets/images/img4.jpg';
import Alert from '@material-ui/lab/Alert';
import logo from '../../assets/images/holistica_logo.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from '../pushNotifications/pushNotificationsSlice';
import WelcomeDialog from '../landingPage/welcomeDialog';
import TopBarHome from '../../common/TopBarHome/TopBarHome';
import { validationSchema, initialValues } from './mainForm';
import { FastField, FieldArray, Form, Formik, getIn } from 'formik';
import {
    AddNewElement,
    LabelledCheckbox,
    OutlinedTextField,
} from '../../common/FormComponents/FormComponents';
import PageHeader from '../../common/PageHeader/PageHeader';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { formatInsertData } from './utils';
import { registerUserInfo } from './registerSlice';

// sent dialog
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

//menus
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { max } from 'lodash';
import { registerUser } from '../../api/user';
import { LOADING_STATUS } from '../../helpers/constants';

//styles menu
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const imgRandom = () => {
    let imgOptions = [img1, img2, img3, img4];
    let numRandom = Math.floor(Math.random() * 3);
    return imgOptions[numRandom];
};

// function Copyright() {
//     return (
//         <Typography variant="body2" color="textSecondary" align="center">
//             {`Copyright © Aptude México ${new Date().getFullYear()}.`}
//         </Typography>
//     );
// }

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    body: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'fixed',
    },
    image: {
        backgroundImage: `url(${imgRandom()})`,
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(5, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        height: '70px',
        width: '70px',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        width: '500px',
        margin: theme.spacing(3, 0, 2),
    },
    login: {
        backgroundColor: 'rgba(255,255,255,0.8)',
    },
    menus: {
        display: 'flex',
        marginRight: '30px',
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    logo: {
        height: '50px',
        margin: '0 10px 0 10px',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            marginRight: '20px',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    aButton: {
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'none',
    },
    aButtonW: {
        color: 'rgba(255, 255, 255, 1)',
        textDecoration: 'none',
    },
    checkAvatar: {
        height: '70px',
        width: '70px',
        color: 'green',
    },
    info: {
        textAlign: 'center',
    },
    arraySubtitle: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: 700,
        marginTop: theme.spacing(-1.5),
        marginBottom: theme.spacing(-1.5),
        '$formSubSection + $formSubSection &': {
            marginTop: theme.spacing(2),
        },
        '& > span': {
            marginRight: theme.spacing(1),
        },
    },
    formSection: {
        backgroundColor: theme.palette.grey[50],
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(4),
        },
    },
    formSectionHeading: {
        color: theme.palette.primary.main,
        marginBottom: 0,
        marginTop: 0,
    },
    noData: {
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 1,
        textAlign: 'center',
    },
    subtitleWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
    uploadGrid: {
        marginBottom: theme.spacing(1),
    },
    stylesMain: {
        margin: theme.spacing(2),
        width: '100%',
    },
    submitButton: {
        margin: '0 5px',
    },
    phoneForm: {
        padding: '12px',
        width: '23%',
    },
}));

export default function Register() {
    const history = useHistory();
    const id = useSelector(state => state.user.userData.id);
    const email = useSelector(state => state.user.userData.email);
    const insertStatus = useSelector(state => state.register.insertStatus);
    const [firstPhone, setFirstPhone] = useState(false);
    const [secondPhone, setSecondPhone] = useState(false);
    const prevSubmitCount = useRef(0);
    const [edit, setEdit] = useState(true);
    const { error, logout } = useAuth0();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = React.useState(false);

    useEffect(() => {
        if (insertStatus === LOADING_STATUS.SUCCEEDED) {
            window.location.reload();
        }
    }, [dispatch, history, insertStatus]);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    // Create state
    const [form, setForm] = useState({
        name: '',
        lastName: '',
        phone: '',
        email: '',
    });

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };
    const handleSubmit = values => {
        let body = { ...values, userInfo: { ...values.userInfo, id: id } };
        let formattedValues = formatInsertData(body);
        // axios
        //     .post(
        //         'https://centro-universitario-dev.herokuapp.com/api/interesados/send',
        //         form
        //     )
        //     .then(() => {
        //         setForm({ name: '', lastName: '', phone: '', email: '' });

        //         dispatch(handleClickOpen);
        //         // enqueueSnackbar({
        //         //     message: `Correo enviado.`,
        //         //     options: {
        //         //         key: new Date().getTime() + Math.random(),
        //         //         variant: 'success',
        //         //     },
        //         // })
        //     })
        //     .catch(() => {
        //         dispatch(
        //             enqueueSnackbar({
        //                 message: `No se pudo enviar el correo, intente más tarde.`,
        //                 options: {
        //                     key: new Date().getTime() + Math.random(),
        //                     variant: 'error',
        //                 },
        //             })
        //         );
        //     });
        dispatch(registerUserInfo(formattedValues));
    };

    const handleRememberMeChange = e => {
        setForm({
            ...form,
            rememberPassword: !form.rememberPassword,
        });
    };

    if (error) {
        logout();
        alert(`${error.message}`);
    }

    const handleFieldUpdate = (nextProps, currentProps) => {
        if (
            nextProps.edit !== currentProps.edit ||
            nextProps.disabled !== currentProps.disabled ||
            nextProps.loading !== currentProps.loading ||
            nextProps.catalog !== currentProps.catalog ||
            nextProps.name !== currentProps.name ||
            getIn(nextProps.formik.values, currentProps.name) !==
                getIn(currentProps.formik.values, currentProps.name) ||
            getIn(nextProps.formik.errors, currentProps.name) !==
                getIn(currentProps.formik.errors, currentProps.name) ||
            getIn(nextProps.formik.touched, currentProps.name) !==
                getIn(currentProps.formik.touched, currentProps.name) ||
            Object.keys(currentProps).length !==
                Object.keys(nextProps).length ||
            nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <main className={classes.stylesMain}>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={{
                    ...initialValues,
                    userInfo: { ...initialValues.userInfo, email: `${email}` },
                }}
                onSubmit={handleSubmit}
            >
                {props => {
                    const { errors, submitCount, values } = props;
                    if (submitCount !== prevSubmitCount.current) {
                        prevSubmitCount.current = submitCount;
                        if (Object.keys(errors).length > 0) {
                            dispatch(
                                enqueueSnackbar({
                                    message:
                                        'Por favor llene todos los campos correctamente.',
                                    options: {
                                        key:
                                            new Date().getTime() +
                                            Math.random(),
                                        variant: 'error',
                                    },
                                })
                            );
                        }
                    }
                    return (
                        <Form>
                            <PageHeader title={'Registro de usuario'}>
                                <Button
                                    className={classes.submitButton}
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => logout()}
                                    type="button"
                                >
                                    cancelar
                                </Button>
                                <Button
                                    className={classes.submitButton}
                                    color="primary"
                                    variant="contained"
                                    type={edit ? 'submit' : 'button'}
                                >
                                    guardar
                                </Button>
                            </PageHeader>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Información General
                                    </h2>
                                </div>
                                <Grid container spacing={3}>
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.name"
                                        type="text"
                                        label="Nombre(s)*"
                                        disabled={!insertStatus}
                                        edit={edit}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.surName"
                                        type="text"
                                        label="Apellidos*"
                                        disabled={!insertStatus}
                                        edit={edit}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.curp"
                                        type="text"
                                        disabled={!insertStatus}
                                        label="CURP"
                                        edit={edit}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.birthday"
                                        type="date"
                                        label="fecha de nacimiento"
                                        disabled={!insertStatus}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        edit={edit}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.nss"
                                        type="number"
                                        label="No Seguro Social"
                                        disabled={!insertStatus}
                                        edit={edit}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.email"
                                        type="text"
                                        disabled={!insertStatus}
                                        label="e-mail"
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.telephone1"
                                        type="text"
                                        disabled={!insertStatus}
                                        onChange={e => {
                                            setFirstPhone(
                                                e.target.value.length > 0
                                            );
                                        }}
                                        label="Teléfono 1*"
                                        edit={edit}
                                    />
                                    {firstPhone && (
                                        <FastField
                                            component={LabelledCheckbox}
                                            type="checkbox"
                                            disabled={!insertStatus}
                                            name="userInfo.whatsApp1"
                                            label="¿Mensajería?"
                                            edit={edit}
                                        />
                                    )}
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.telephone2"
                                        type="text"
                                        disabled={!insertStatus}
                                        onChange={e => {
                                            setSecondPhone(
                                                e.target.value.length > 0
                                            );
                                        }}
                                        label="Teléfono 2"
                                        edit={edit}
                                    />
                                    {secondPhone && (
                                        <FastField
                                            component={LabelledCheckbox}
                                            type="checkbox"
                                            disabled={!insertStatus}
                                            name="userInfo.whatsApp2"
                                            label="¿Mensajería?"
                                            edit={edit}
                                        />
                                    )}
                                </Grid>
                            </section>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Datos Demográficos
                                    </h2>
                                </div>
                                <Grid container spacing={3}>
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.address"
                                        type="text"
                                        disabled={!insertStatus}
                                        label="Dirección 1"
                                        edit={edit}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.city"
                                        type="text"
                                        disabled={!insertStatus}
                                        label="Ciudad"
                                        edit={edit}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        select
                                        name="userInfo.state"
                                        type="text"
                                        disabled={!insertStatus}
                                        label="Estado"
                                        edit={edit}
                                        // catalog={catalogs.businessarea}
                                    >
                                        <MenuItem value="">&nbsp;</MenuItem>
                                        <MenuItem value="CDMX">CDMX</MenuItem>
                                        <MenuItem value="MTY">MTY</MenuItem>
                                        <MenuItem value="GDL">GDL</MenuItem>
                                    </FastField>
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.municipality"
                                        type="text"
                                        disabled={!insertStatus}
                                        label="Municipalidad"
                                        edit={edit}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.cp"
                                        type="text"
                                        disabled={!insertStatus}
                                        label="Código postal"
                                        edit={edit}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.country"
                                        type="text"
                                        disabled={!insertStatus}
                                        label="País"
                                        edit={edit}
                                    />
                                </Grid>
                            </section>
                            <section>
                                <Grid container spacing={3}>
                                    <FastField
                                        component={LabelledCheckbox}
                                        type="checkbox"
                                        disabled={!insertStatus}
                                        color="secondary"
                                        name="userInfo.private"
                                        label="Acepto el aviso de privacidad*"
                                        edit={edit}
                                    />
                                </Grid>

                                {errors.userInfo?.private?.length > 0 &&
                                    submitCount > 0 && (
                                        <Grid container spacing={3}>
                                            <FormControl
                                                required
                                                error={
                                                    errors.userInfo?.private
                                                        ?.length > 0
                                                }
                                                component="fieldset"
                                            >
                                                <FormGroup>
                                                    <FormHelperText>
                                                        {`${errors.userInfo?.private}`}
                                                    </FormHelperText>
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    )}
                            </section>
                        </Form>
                    );
                }}
            </Formik>
        </main>
    );
}
