import axios from 'axios';
import { axiosConfig } from './axiosConfig';

export const getVideoList = async () => {
    const { data } = await axios.get('videos', axiosConfig('api/v1'));
    return data;
};

export const getVideoInfo = async id => {
    const { data } = await axios.get(`videos/${id}`, axiosConfig('api/v1'));
    return data;
};

export const createVideo = async body => {
    const { data } = await axios.post('videos', body, axiosConfig('api/v1'));
    return data;
};

export const updateVideo = async (id, body) => {
    const { data } = await axios.patch(
        `videos/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const uploadImageFile = async (id, body) => {
    const { data } = await axios.patch(
        `videos/images/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const uploadVideoFile = async (id, body) => {
    const { data } = await axios.patch(
        `videos/videos/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const deleteVideo = async id => {
    const { data } = await axios.delete(`videos/${id}`, axiosConfig('api/v1'));
    return data;
};
