import { Button, Grid, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FastField, Form, Formik, getIn } from 'formik';
import { useEffect, useRef, useState } from 'react';
import {
    AddNewContact,
    AddNewElement,
    OutlinedTextField,
} from '../../../common/FormComponents/FormComponents';
import PageHeader from '../../../common/PageHeader/PageHeader';
import { validationSchema } from './mainForm';
import { red } from '@material-ui/core/colors';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchContactInfo,
    createSingleOpportunity,
    fetchSingleOpportunity,
    updateSingleOpportunity,
    resetValues,
} from './singleOpportunitySlice';
import {
    fetchAllOpportunityType,
    fetchDropdownClients,
    fetchDropdownContacts,
} from './DropdownContacts/dropdownSlice';
import { fetchAllCatalogs } from '../../catalogs/catalogSlice';
import { contactInfoMapper, formatInsertData } from './utils';
import { LOADING_STATUS } from '../../../helpers/constants';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { renderDateValue } from '../../../helpers/formHelpers';
import UploadContact from './UploadContact/UploadContact';
import UploadDialog from '../../../common/DocumentComponents/UploadDialog/UploadDialog';
import DocumentationList from '../../../common/DocumentComponents/DocumentationList/DocumentationList';

const useStyles = makeStyles(theme => ({
    arraySubtitle: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: 700,
        marginTop: theme.spacing(-1.5),
        marginBottom: theme.spacing(-1.5),
        '$formSubSection + $formSubSection &': {
            marginTop: theme.spacing(2),
        },
        '& > span': {
            marginRight: theme.spacing(1),
        },
    },
    deleteIcon: {
        color: red[400],
        marginTop: theme.spacing(0.2),
    },
    formSection: {
        backgroundColor: theme.palette.grey[50],
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(4),
        },
    },
    formSectionHeading: {
        color: theme.palette.primary.main,
        marginBottom: 0,
        marginTop: 0,
    },
    formSubSection: {},
    noData: {
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 1,
        textAlign: 'center',
    },
    subtitleWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
    uploadGrid: {
        marginBottom: theme.spacing(1),
    },
}));

const SingleOpportunity = ({ create = false }) => {
    const classes = useStyles();

    const prevSubmitCount = useRef(0);

    const [edit, setEdit] = useState(create);
    const [files, setFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [openNewContact, setOpenNewContact] = useState(false);

    const { id } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();

    const opportunity = useSelector(
        state => state.singleOpportunity.opportunity
    );
    const dropdownClientsStatus = useSelector(state => state.dropdown.status);
    const dropdownClients = useSelector(
        state => state.dropdown.dropdownClients
    );
    const dropdownContacts = useSelector(
        state => state.dropdown.dropdownContacts
    );
    const allOpportunityType = useSelector(
        state => state.dropdown.opportunityType
    );
    const catalogs = useSelector(state => state.catalogs.catalogs);
    const insertStatus = useSelector(
        state => state.singleOpportunity.insertStatus
    );
    const fetchStatus = useSelector(
        state => state.singleOpportunity.fetchStatus
    );
    const dropdownStatus = useSelector(state => state.dropdown.status);
    const catalogStatus = useSelector(state => state.catalogs.status);

    const loadingClients = dropdownClientsStatus === LOADING_STATUS.LOADING;

    const loading =
        fetchStatus === LOADING_STATUS.LOADING ||
        catalogStatus === LOADING_STATUS.LOADING ||
        dropdownStatus === LOADING_STATUS.LOADING;

    const error =
        fetchStatus === LOADING_STATUS.FAILED ||
        catalogStatus === LOADING_STATUS.FAILED;

    const insertingOpportunity = insertStatus === LOADING_STATUS.LOADING;

    useEffect(() => {
        dispatch(fetchDropdownClients());
        return () => dispatch(resetValues());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchAllCatalogs());
        return () => dispatch(resetValues());
    }, [dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(fetchSingleOpportunity(id))
                .unwrap()
                .then(result =>
                    dispatch(fetchDropdownContacts(result[0].idClient))
                );
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (fetchStatus === LOADING_STATUS.SUCCEEDED) {
            setFiles(opportunity.documents);
        }
    }, [opportunity.documents, fetchStatus]);

    useEffect(() => {
        if (fetchStatus === LOADING_STATUS.SUCCEEDED) {
            setFiles(opportunity.documents);
        }
    }, [opportunity.documents, fetchStatus]);

    useEffect(() => {
        if (insertStatus === LOADING_STATUS.SUCCEEDED) {
            history.push('/opportunities');
        }
    }, [dispatch, history, insertStatus]);

    useEffect(() => {
        dispatch(fetchAllOpportunityType());
        return () => dispatch(resetValues());
    }, [dispatch]);

    const handleFileChange = file => {
        setFiles(prev => [...prev, file]);
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenNewContact = () => {
        setOpenNewContact(true);
    };

    const handleCloseNewContact = () => {
        setOpenNewContact(false);
    };

    const handleButtonClick = e => {
        e.preventDefault();
        if (!edit) {
            setEdit(true);
        }
    };

    const handleDeleteDoc = fileName => {
        setFiles(prev => prev.filter(file => file.fileName !== fileName));
    };

    const handleSubmit = values => {
        const opportunityContactId = opportunity.contactClientInfo
            .opportunityContactId
            ? opportunity.contactClientInfo.opportunityContactId
            : 0;
        const formattedValues = formatInsertData(
            values,
            id,
            opportunityContactId
        );
        const body = {
            ...formattedValues,
            opportunityDocuments: files.map(
                ({
                    documentId,
                    docNumber,
                    documentNotes,
                    file,
                    fileName,
                    idDocumentType,
                    effBeginDate,
                    effEndDate,
                }) => ({
                    ...(id ? { opportunityId: Number(id) } : {}),
                    ...(documentId
                        ? { opportunityDocumentId: documentId }
                        : {}),
                    file,
                    fileName,
                    docNumber,
                    documentNotes,
                    documentTypeId: idDocumentType,
                    effBeginDate: effBeginDate
                        ? effBeginDate
                        : new Date().toISOString(),
                    effEndDate: effEndDate
                        ? effEndDate
                        : new Date().toISOString(),
                })
            ),
        };
        if (id) {
            dispatch(updateSingleOpportunity({ id, body }));
        } else {
            dispatch(createSingleOpportunity(body));
        }
    };

    const handleFieldUpdate = (nextProps, currentProps) => {
        if (
            nextProps.edit !== currentProps.edit ||
            nextProps.disabled !== currentProps.disabled ||
            nextProps.catalog !== currentProps.catalog ||
            nextProps.loading !== currentProps.loading ||
            nextProps.name !== currentProps.name ||
            getIn(nextProps.formik.values, currentProps.name) !==
                getIn(currentProps.formik.values, currentProps.name) ||
            getIn(nextProps.formik.errors, currentProps.name) !==
                getIn(currentProps.formik.errors, currentProps.name) ||
            getIn(nextProps.formik.touched, currentProps.name) !==
                getIn(currentProps.formik.touched, currentProps.name) ||
            Object.keys(currentProps).length !==
                Object.keys(nextProps).length ||
            nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <main>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={opportunity}
                onSubmit={handleSubmit}
            >
                {props => {
                    const { errors, submitCount, values, setValues } = props;
                    if (submitCount !== prevSubmitCount.current) {
                        prevSubmitCount.current = submitCount;
                        if (Object.keys(errors).length > 0) {
                            dispatch(
                                enqueueSnackbar({
                                    message:
                                        'Por favor llene todos los campos correctamente.',
                                    options: {
                                        key:
                                            new Date().getTime() +
                                            Math.random(),
                                        variant: 'error',
                                    },
                                })
                            );
                        }
                    }
                    const errorEndDate = values.generalInfo.endDate
                        ? values.generalInfo.startDate >
                          values.generalInfo.endDate
                        : false;
                    const endDateDelta = values.generalInfo.endDate
                        ? values.generalInfo.endDate < '2000-01-01' ||
                          values.generalInfo.endDate > '2099-01-01'
                            ? true
                            : false
                        : prevSubmitCount.current > 0
                        ? true
                        : false;
                    const startDateDelta = values.generalInfo.startDate
                        ? values.generalInfo.startDate < '2000-01-01' ||
                          values.generalInfo.startDate > '2099-01-01'
                            ? true
                            : false
                        : prevSubmitCount.current > 0
                        ? true
                        : false;
                    return (
                        <Form>
                            <PageHeader
                                title={
                                    create
                                        ? 'Nueva oportunidad'
                                        : edit
                                        ? 'Editar oportunidad'
                                        : 'Consulta de oportunidad'
                                }
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type={edit ? 'submit' : 'button'}
                                    onClick={
                                        !edit ? handleButtonClick : undefined
                                    }
                                    disabled={
                                        loading ||
                                        errorEndDate ||
                                        startDateDelta ||
                                        endDateDelta
                                    }
                                >
                                    {create
                                        ? 'Crear'
                                        : edit
                                        ? 'Aplicar cambios'
                                        : 'Editar'}
                                </Button>
                            </PageHeader>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Información General
                                    </h2>
                                </div>
                                <Grid container spacing={3}>
                                    <FastField
                                        component={OutlinedTextField}
                                        name="generalInfo.name"
                                        type="text"
                                        label="Nombre*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingOpportunity}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="generalInfo.goesBy"
                                        type="text"
                                        label="Alias*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingOpportunity}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        select
                                        name="generalInfo.opportunityType"
                                        type="text"
                                        label="Tipo de oportunidad*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingOpportunity}
                                        shouldUpdate={handleFieldUpdate}
                                        catalog={allOpportunityType.map(
                                            ({
                                                opportunityTypeId,
                                                description,
                                            }) => ({
                                                id: opportunityTypeId,
                                                text: description,
                                            })
                                        )}
                                    >
                                        <MenuItem value="">&nbsp;</MenuItem>
                                        {allOpportunityType?.length > 0 &&
                                            allOpportunityType.map(
                                                ({
                                                    opportunityTypeId,
                                                    description,
                                                }) => (
                                                    <MenuItem
                                                        value={
                                                            opportunityTypeId
                                                        }
                                                        key={opportunityTypeId}
                                                    >
                                                        {description}
                                                    </MenuItem>
                                                )
                                            )}
                                    </FastField>
                                    <FastField
                                        component={OutlinedTextField}
                                        name="generalInfo.startDate"
                                        type="date"
                                        label="Fecha de inicio*"
                                        edit={edit}
                                        error={errorEndDate || startDateDelta}
                                        helperText={
                                            errorEndDate
                                                ? 'Fecha posterior a la de termino'
                                                : startDateDelta
                                                ? 'Fecha fuera del limite'
                                                : false
                                        }
                                        loading={loading}
                                        disabled={insertingOpportunity}
                                        renderValue={renderDateValue}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="generalInfo.endDate"
                                        type="date"
                                        label="Fecha de término*"
                                        edit={edit}
                                        error={errorEndDate || endDateDelta}
                                        helperText={
                                            errorEndDate
                                                ? 'Fecha anterior a la de inicio'
                                                : endDateDelta
                                                ? 'Fecha fuera del limite'
                                                : false
                                        }
                                        loading={loading}
                                        disabled={insertingOpportunity}
                                        renderValue={renderDateValue}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="generalInfo.notes"
                                        type="text"
                                        label="Notas*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingOpportunity}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                </Grid>
                            </section>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Información de contacto
                                    </h2>
                                    {edit &&
                                    values.headerContacts.client > 0 ? (
                                        <AddNewContact
                                            disabled={loading}
                                            onAddElement={handleOpenNewContact}
                                        />
                                    ) : null}
                                </div>
                                {edit && (
                                    <Grid container spacing={3}>
                                        <FastField
                                            component={OutlinedTextField}
                                            select
                                            name={'headerContacts.client'}
                                            type="text"
                                            label="Cliente*"
                                            edit={edit}
                                            loading={loadingClients}
                                            disabled={insertingOpportunity}
                                            catalog={dropdownClients}
                                            onChange={e => {
                                                let clientId = e.target.value;
                                                dispatch(
                                                    fetchDropdownContacts(
                                                        clientId
                                                    )
                                                );
                                            }}
                                            shouldUpdate={handleFieldUpdate}
                                        >
                                            <MenuItem value="">&nbsp;</MenuItem>
                                            {dropdownClients?.length > 0 &&
                                                dropdownClients.map(
                                                    ({ id, text }) => (
                                                        <MenuItem
                                                            value={id}
                                                            key={id}
                                                        >
                                                            {text}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </FastField>
                                        {values.headerContacts.client > 0 ? (
                                            <>
                                                <FastField
                                                    component={
                                                        OutlinedTextField
                                                    }
                                                    select
                                                    name={
                                                        'headerContacts.contact'
                                                    }
                                                    type="text"
                                                    label="Contacto*"
                                                    edit={edit}
                                                    loading={loading}
                                                    disabled={
                                                        insertingOpportunity
                                                    }
                                                    catalog={dropdownContacts}
                                                    onChange={e => {
                                                        let contactId =
                                                            e.target.value;
                                                        contactId &&
                                                            dispatch(
                                                                fetchContactInfo(
                                                                    contactId
                                                                )
                                                            )
                                                                .unwrap()
                                                                .then(result =>
                                                                    setValues({
                                                                        ...values,
                                                                        headerContacts: {
                                                                            ...values.headerContacts,
                                                                            contact: contactId,
                                                                        },
                                                                        contactClientInfo: contactInfoMapper(
                                                                            result
                                                                        ),
                                                                    })
                                                                );
                                                    }}
                                                    shouldUpdate={
                                                        handleFieldUpdate
                                                    }
                                                >
                                                    <MenuItem value="">
                                                        &nbsp;
                                                    </MenuItem>
                                                    {dropdownContacts?.length >
                                                        0 &&
                                                        dropdownContacts.map(
                                                            ({ id, text }) => (
                                                                <MenuItem
                                                                    value={id}
                                                                    key={id}
                                                                >
                                                                    {text}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                </FastField>
                                            </>
                                        ) : null}
                                        {/* <FastField
                                            component={OutlinedTextField}
                                            select
                                            name={
                                                'headerContacts.internalContact'
                                            }
                                            type="text"
                                            label="Contacto Aptude"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingOpportunity}
                                            shouldUpdate={handleFieldUpdate}
                                        >
                                            <MenuItem value="">&nbsp;</MenuItem>
                                        </FastField> */}
                                    </Grid>
                                )}
                                {(values.headerContacts.contact > 0 &&
                                    values.headerContacts.client > 0) ||
                                !edit ? (
                                    <Grid
                                        container
                                        style={{ marginTop: 4 }}
                                        spacing={3}
                                    >
                                        <FastField
                                            component={OutlinedTextField}
                                            name={`contactClientInfo.firstName`}
                                            type="text"
                                            label="Nombre*"
                                            loading={loading}
                                            disabled={insertingOpportunity}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name={`contactClientInfo.middleName`}
                                            type="text"
                                            label="Segundo nombre"
                                            loading={loading}
                                            disabled={insertingOpportunity}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name={`contactClientInfo.lastName`}
                                            type="text"
                                            label="Apellido*"
                                            loading={loading}
                                            disabled={insertingOpportunity}
                                            shouldUpdate={handleFieldUpdate}
                                        >
                                            {`{ContactInfo.firstName}`}
                                        </FastField>
                                        <FastField
                                            component={OutlinedTextField}
                                            name={`contactClientInfo.officePhoneNumber`}
                                            type="text"
                                            label="Teléfono de oficina*"
                                            loading={loading}
                                            disabled={insertingOpportunity}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name={`contactClientInfo.cellPhoneNumber`}
                                            type="text"
                                            label="Teléfono celular*"
                                            loading={loading}
                                            disabled={insertingOpportunity}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name={`contactClientInfo.email`}
                                            type="email"
                                            label="Email*"
                                            loading={loading}
                                            disabled={insertingOpportunity}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name={`contactClientInfo.goesBy`}
                                            type="text"
                                            label="Responsable*"
                                            loading={loading}
                                            disabled={insertingOpportunity}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name={`contactClientInfo.contactNotes`}
                                            type="text"
                                            label="Notas*"
                                            loading={loading}
                                            disabled={insertingOpportunity}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name={`contactClientInfo.role`}
                                            type="text"
                                            label="Rol*"
                                            loading={loading}
                                            disabled={insertingOpportunity}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name={`contactClientInfo.getInTouchWith`}
                                            type="text"
                                            label="Comunicarse con*"
                                            loading={loading}
                                            disabled={insertingOpportunity}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                    </Grid>
                                ) : null}
                                <UploadContact
                                    handleClose={handleCloseNewContact}
                                    clientId={values.headerContacts.client}
                                    open={openNewContact}
                                    edit={edit}
                                    influenceCatalog={catalogs.influency}
                                    setValues={(contactId, newValues) =>
                                        setValues({
                                            ...values,
                                            headerContacts: {
                                                ...values.headerContacts,
                                                contact: contactId,
                                            },
                                            contactClientInfo: contactInfoMapper(
                                                newValues
                                            ),
                                        })
                                    }
                                />
                            </section>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Documentación
                                    </h2>
                                    {edit && (
                                        <AddNewElement
                                            disabled={loading}
                                            onAddElement={handleOpen}
                                        >
                                            Cargar archivo
                                        </AddNewElement>
                                    )}
                                </div>
                                {files.length > 0 ? (
                                    <DocumentationList
                                        className={classes.uploadGrid}
                                        documentCatalog={catalogs.documenttype}
                                        files={files}
                                        onDeleteDoc={handleDeleteDoc}
                                        edit={edit}
                                    />
                                ) : (
                                    <Typography className={classes.noData}>
                                        Aún no hay documentos cargados.
                                    </Typography>
                                )}
                                <UploadDialog
                                    documentCatalog={catalogs.documenttype}
                                    files={files}
                                    handleClose={handleClose}
                                    handleFileChange={handleFileChange}
                                    open={open}
                                />
                            </section>
                        </Form>
                    );
                }}
            </Formik>
        </main>
    );
};

export default SingleOpportunity;
