import * as yup from 'yup';
import {
    maxCharMessage,
    requiredMessage,
} from '../../../helpers/validationSchemaHelpers';

export const initialValues = {
    generalInfo: {
        name: '',
        status: true,
    },
};

export const validationSchema = yup.object({
    generalInfo: yup.object({
        name: yup.string().max(50, maxCharMessage).required(requiredMessage),
        status: yup.boolean().required(requiredMessage),
    }),
});
