import PageHeader from '../../../common/PageHeader/PageHeader';
import { Button } from '@material-ui/core';
import ExportButton from '../../../common/ExportButton/ExportButton';
import { Link } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { fetchUsers } from './UsersSlice';
import UsersFilters from './usersFilters/UsersFilters';
import UsersTable from './usersTable/UsersTable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    downloadButton: {
        backgroundColor: green[600],
        color: 'white',
        '&:hover': {
            backgroundColor: green[800],
        },
    },
    topButton: {
        '&:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
}));

const csvHeaders = [
    { label: 'Usuarios', key: 'user' },
    { label: 'Email', key: 'email' },
    { label: 'Nombre', key: 'name' },
    { label: 'Apellido', key: 'lastName' },
    { label: 'Status del usuario', key: 'usersStatus' },
];

const Users = () => {
    const classes = useStyles();
    const status = useSelector(state => state.users.status);
    const filters = useSelector(state => state.users.data.filters);
    const rows = useSelector(state => state.users.data.rows);

    const filtersActive = Object.values(filters).some(
        ({ selected }) => selected
    );

    const filteredRows = ((filtersActive, rows) => {
        if (!filtersActive) return rows;
        let newRows = [];
        if (filters.usersOptions.selected) {
            const filterItems = query => {
                return rows.filter(
                    row =>
                        row.user.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
            };
            newRows = filterItems(filters.usersOptions.selected);
        }
        if (filters.usersRoleOptions.selected) {
            newRows = (newRows.length > 0 ? newRows : rows).filter(
                row => row.role === filters.usersRoleOptions.selected
            );
        }
        if (filters.usersStatusOptions.selected) {
            newRows = (newRows.length > 0 ? newRows : rows).filter(
                row => row.usersStatus === filters.usersStatusOptions.selected
            );
        }

        return newRows;
    })(filtersActive, rows);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    return (
        <div>
            <PageHeader title="Empleados">
                <Button
                    className={classes.topButton}
                    color="primary"
                    component={Link}
                    to="/users/create"
                    variant="contained"
                >
                    Adicionar
                </Button>
                <ExportButton
                    headers={csvHeaders}
                    inputData={rows}
                    fileName="Usuarios.csv"
                    className={`${classes.topButton} ${classes.downloadButton}`}
                >
                    Exportar a Excel
                </ExportButton>
            </PageHeader>

            <UsersFilters {...filters}></UsersFilters>
            <UsersTable rows={filteredRows} status={status}></UsersTable>
        </div>
    );
};

export default Users;
