import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { renderTimeValue } from '../../../helpers/formHelpers';

import {
    CircularProgress,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useTableStyles = makeStyles(theme => ({
    noInfo: {
        color: theme.palette.grey[400],
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'center',
    },
    fullHeight: {
        height: 484,
    },
    tableFooter: {
        background: `linear-gradient(to top, white 0%, white calc(100% - 1px), ${theme.palette.grey[300]} calc(100% - 1px))`,
        position: 'sticky',
        bottom: 0,
    },
    tableHead: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        position: 'sticky',
        top: 0,
    },
    tableHeadCell: {
        color: 'white',
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase',
    },
    tableLink: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    tableRoot: {
        borderRadius: theme.shape.borderRadius,
        maxHeight: 484,
        '& td, & th': {
            height: 40,
        },
        '& table': {
            borderCollapse: 'separate',
        },
    },
}));

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function Schedule({
    info = { subjectName: '', schedule: [] },
    open,
    handleClose,
}) {
    const classes = useTableStyles();

    const capitalLetter = string => {
        let lowStr = string.toLowerCase();
        let capStr = lowStr.charAt(0).toUpperCase() + lowStr.slice(1);
        return capStr;
    };

    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {capitalLetter(info.subjectName)}
                </DialogTitle>
                <DialogContent dividers>
                    <Table size="small" aria-label="a dense table">
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell className={classes.tableHeadCell}>
                                    Dia
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.tableHeadCell}
                                >
                                    Hora de inicio
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.tableHeadCell}
                                >
                                    Hora de termino
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.tableHeadCell}
                                >
                                    Salon
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {info.schedule.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {capitalLetter(row.day)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {renderTimeValue(row.startTime)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {renderTimeValue(row.endTime)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.classroom}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
