import PageHeader from '../../../common/PageHeader/PageHeader';
import { Button } from '@material-ui/core';
import ExportButton from '../../../common/ExportButton/ExportButton';
import { Link } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import ServicesFilters from './servicesFilters/ServicesFilters';
import ServicesTable from './servicesTable/ServicesTable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServices } from './ServicesSlice';

// cards
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
    downloadButton: {
        backgroundColor: green[600],
        color: 'white',
        '&:hover': {
            backgroundColor: green[800],
        },
    },
    topButton: {
        '&:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
    //
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: theme.spacing(8, 0, 6),
        placeItems: 'center',
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
        width: 400,
    },
    cardContent: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    root: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        flexGrow: 1,
    },
    cover: {
        width: '300px',
        height: '300px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '300px',
        },
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    rootMobile: {
        display: 'none',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    tableLink: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    description: {
        whiteSpace: 'pre-wrap',
    },
}));

const csvHeaders = [
    { label: 'Servicios', key: 'user' },
    { label: 'Email', key: 'email' },
    { label: 'Nombre', key: 'name' },
    { label: 'Apellido', key: 'lastName' },
    { label: 'Status del usuario', key: 'servicesStatus' },
];

const Services = () => {
    const classes = useStyles();
    const status = useSelector(state => state.services.status);
    const filters = useSelector(state => state.services.data.filters);
    const rows = useSelector(state => state.services.data.rows);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchServices());
    }, [dispatch]);

    return (
        <div>
            <PageHeader title="Servicios">
                <Button
                    className={classes.topButton}
                    color="primary"
                    component={Link}
                    to="/services/create"
                    variant="contained"
                >
                    Adicionar
                </Button>
            </PageHeader>

            <Container className={classes.cardGrid} maxWidth="lg">
                {/* End hero unit */}
                <Grid
                    container
                    spacing={4}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {rows.map(card => (
                        <Grid item key={card} xs={12} sm={12} md={12}>
                            <Card className={classes.root}>
                                {rows.indexOf(card) % 2 === 0 && (
                                    <Link
                                        className={classes.tableLink}
                                        to={`services/${card.id}`}
                                    >
                                        <CardMedia
                                            className={classes.cover}
                                            image={card.image}
                                            title="Image title"
                                        />
                                    </Link>
                                )}
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Grid>
                                            <Link
                                                className={classes.tableLink}
                                                to={`services/${card.id}`}
                                            >
                                                <Typography
                                                    gutterBottom
                                                    variant="h5"
                                                    component="h2"
                                                >
                                                    {card.name}
                                                </Typography>
                                            </Link>
                                            <Typography
                                                component="text"
                                                className={classes.description}
                                            >
                                                {card.description}
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                </div>
                                {rows.indexOf(card) % 2 === 1 && (
                                    <Link
                                        className={classes.tableLink}
                                        to={`services/${card.id}`}
                                    >
                                        <CardMedia
                                            className={classes.cover}
                                            image={card.image}
                                            title="Image title"
                                        />
                                    </Link>
                                )}
                            </Card>
                            <Card className={classes.rootMobile}>
                                <Link
                                    className={classes.tableLink}
                                    to={`services/${card.id}`}
                                >
                                    <CardMedia
                                        className={classes.cover}
                                        image={card.image}
                                        title="Image title"
                                    />
                                </Link>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Link
                                            className={classes.tableLink}
                                            to={`services/${card.id}`}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="h2"
                                            >
                                                {card.name}
                                            </Typography>
                                        </Link>
                                        <Typography
                                            className={classes.description}
                                        >
                                            {card.description}
                                        </Typography>
                                    </CardContent>
                                    <CardContent></CardContent>
                                </div>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            {/* <ServicesFilters {...filters}></ServicesFilters> */}
            {/* <ServicesTable rows={filteredRows} status={status}></ServicesTable> */}
        </div>
    );
};

export default Services;
