import * as yup from 'yup';
import {
    emailErrorMessage,
    maxCharMessage,
    minCharMessage,
    phoneErrorMessage,
    phoneRegExp,
    requiredMessage,
    onlyLetters,
    onlyNumbers,
    onlyLettersMessage,
} from '../../../helpers/validationSchemaHelpers';

export const studentInfo = {
    registrationNumber: '',
};

export const bankInfo = {
    bank: '',
    accountNumber: '',
    interbankKey: '',
    cardNumber: '',
};

export const addressInfo = {
    state: '',
    city: '',
    cp: '',
    suburb: '',
    address: '',
};

export const employeeInfo = {
    image: '',
    name: '',
    nickname: '',
    curp: '',
    birthday: '',
    nss: '',
    telephone1: '',
    description: '',
};

export const initialUserInfo = {
    userStatus: '',
    email: '',
    role: '',
};

export const initialValues = {
    userInfo: {
        ...initialUserInfo,
        employee: {
            ...employeeInfo,
        },
        address: {
            ...addressInfo,
        },
        bankInfo: {
            ...bankInfo,
        },
    },
};

export const validationSchema = yup.object({
    userInfo: yup.object({
        email: yup
            .string()
            .max(50, maxCharMessage)
            .email(emailErrorMessage)
            .matches(
                /^[a-zA-Z0-9._%+-]+@faithfuldog\.mx$/, // Expresión regular que valida el dominio
                'El correo debe pertenecer al dominio faithfuldog.mx'
            )
            .required(requiredMessage),
        role: yup.number().required(requiredMessage),
        userStatus: yup.number().required(requiredMessage),
        employee: yup.object({
            name: yup
                .string()
                .matches(onlyLetters, onlyLettersMessage)
                .min(3, minCharMessage)
                .max(50, maxCharMessage)
                .required(requiredMessage),
            nickname: yup
                .string()
                .matches(onlyLetters, onlyLettersMessage)
                .min(3, minCharMessage)
                .max(15, maxCharMessage)
                .required(requiredMessage),
            curp: yup
                .string()
                .required(requiredMessage)
                .min(18, 'Mínimo 18 dígitos')
                .max(18, 'Máximo 18 dígitos'),
            description: yup.string().max(500, maxCharMessage),
            telephone1: yup
                .string()
                .min(10, 'Mínimo 10 dígitos')
                .max(15, 'Máximo 15 dígitos'),
            nss: yup
                .string()
                .min(10, 'Mínimo 10 dígitos')
                .max(15, 'Máximo 15 dígitos'),
        }),
        address: yup.object({
            address: yup
                .string()
                .max(50, maxCharMessage)
                .required(requiredMessage),
            city: yup
                .string()
                .matches(onlyLetters, onlyLettersMessage)
                .min(3, minCharMessage)
                .max(10, maxCharMessage)
                .required(requiredMessage),
            state: yup
                .string()
                .matches(onlyLetters, onlyLettersMessage)
                .max(15, maxCharMessage)
                .required(requiredMessage),
            suburb: yup
                .string()
                .matches(onlyLetters, onlyLettersMessage)
                .max(20, maxCharMessage),
            cp: yup
                .string()
                .min(5, 'Mínimo 5 dígitos')
                .max(5, 'Máximo 5 dígitos')
                .required(requiredMessage),
        }),
        bankInfo: yup.object({
            bank: yup
                .string()
                .matches(onlyLetters, onlyLettersMessage)
                .min(3, 'Mínimo 3 dígitos')
                .max(18, 'Máximo 18 dígitos')
                .required(requiredMessage),
            accountNumber: yup
                .string()
                .min(10, 'Mínimo 10 dígitos')
                .max(10, 'Máximo 10 dígitos'),
            cardNumber: yup
                .string()
                .min(16, 'Mínimo 16 dígitos')
                .max(16, 'Máximo 16 dígitos'),
            interbankKey: yup
                .string()
                .required(requiredMessage)
                .min(18, 'Mínimo 18 dígitos')
                .max(18, 'Máximo 18 dígitos'),
        }),
    }),
});
