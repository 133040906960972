import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    requirePropFactory,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FastField, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderDateValue } from '../../../helpers/formHelpers';
import { OutlinedTextField } from '../../FormComponents/FormComponents';
import { initialValues, validationSchema } from './dialogForm';
import { resetValues } from '../../../features/paymentAdmin/userPayment/userPaymentInfoSlice';

const useStyles = makeStyles(theme => ({
    dialogActions: {
        marginBottom: theme.spacing(1),
    },
    dialogGrid: {
        marginBottom: theme.spacing(2),
    },
    dialogTitle: {
        '& > *': {
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    inputFile: {
        width: 0,
        height: 0,
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));

const convertBase64 = file => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = error => {
            reject(error);
        };
    });
};

const PaymentReview = ({ handleClose, handleFileChange, open }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [dialogFile, setDialogFile] = useState();
    const [base64File, setBase64File] = useState();
    const [paymentFile, setPaymentFile] = useState(null);
    const catalogs = useSelector(state => state.catalogs.catalogs);
    const paymentDocument = useSelector(
        state => state.paymentInfo.data.paymentInfo.base64File
    );
    const paymentNameDocument = useSelector(
        state => state.paymentInfo.data.paymentInfo.file
    );
    const paymentInfo = useSelector(
        state => state.paymentInfo.data.paymentInfo
    );

    const handleDialogFile = async e => {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setDialogFile(file);
        setBase64File(base64);
    };

    useEffect(() => {
        if (!open) setDialogFile(undefined);
    }, [open]);

    useEffect(() => {
        if (paymentDocument && paymentNameDocument)
            setPaymentFile({
                base64File: paymentDocument,
                file: paymentNameDocument,
            });
    }, [paymentDocument, paymentNameDocument, setPaymentFile]);

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={() => {
                handleClose();
                dispatch(resetValues());
            }}
        >
            <DialogTitle className={classes.dialogTitle}>
                Validación de documentos
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={paymentInfo}
                    onSubmit={({ authorizationStatus, authorizationComment }) =>
                        handleFileChange({
                            ...paymentInfo,
                            authorizationStatus,
                            authorizationComment,
                        })
                    }
                >
                    {props => {
                        const { setFieldValue, values } = props;
                        const { fileName } = values;
                        if (dialogFile && fileName !== dialogFile.name) {
                            setFieldValue('fileName', dialogFile.name);
                        }
                        const validExtensions = () => {
                            if (fileName) {
                                const extensions = [
                                    'xls',
                                    'xlsx',
                                    'pdf',
                                    'gif',
                                    'jpg',
                                    'png',
                                    'tiff',
                                ];
                                const split = fileName.split('.');
                                function getExtension(ext) {
                                    return ext === split[split.length - 1];
                                }
                                return !extensions.some(getExtension);
                            } else {
                                return false;
                            }
                        };
                        const maxSize = dialogFile
                            ? dialogFile.size > 5242880
                            : false;

                        return (
                            <Form>
                                <Grid
                                    alignItems="center"
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <FastField
                                        component={OutlinedTextField}
                                        name="file"
                                        type="text"
                                        label="Documento"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={false}
                                    />
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            download={paymentFile}
                                            disabled={Boolean(!paymentFile)}
                                            component="label"
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                        >
                                            <a
                                                className={classes.link}
                                                href={
                                                    paymentFile?.base64File ??
                                                    null
                                                }
                                                download={
                                                    paymentFile?.file ?? null
                                                }
                                            >
                                                Descargar
                                            </a>
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid
                                    alignItems="center"
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <FastField
                                        component={OutlinedTextField}
                                        name="amountPaid"
                                        type="number"
                                        label="Monto*"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={false}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="expiredDate"
                                        type="date"
                                        label="Fecha de vencimiento"
                                        renderValue={renderDateValue}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={false}
                                    />
                                </Grid>
                                <Grid
                                    alignItems="center"
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <FastField
                                        component={OutlinedTextField}
                                        name="paymentDate"
                                        renderValue={renderDateValue}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="date"
                                        label="Pago realizado"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={false}
                                    />

                                    <FastField
                                        component={OutlinedTextField}
                                        name="reference"
                                        type="number"
                                        label="Número de referencia"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={false}
                                    />
                                </Grid>
                                <Grid
                                    alignItems="center"
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <FastField
                                        component={OutlinedTextField}
                                        name="authorizedName"
                                        type="text"
                                        label="Autorizado por"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={false}
                                    />

                                    <FastField
                                        component={OutlinedTextField}
                                        select
                                        name="authorizationStatus"
                                        type="text"
                                        label="Status*"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        catalog={catalogs.paymentStatus}
                                        edit={true}
                                    >
                                        <MenuItem value="">&nbsp;</MenuItem>
                                        {catalogs.paymentStatus?.length > 0 &&
                                            catalogs.paymentStatus.map(
                                                ({ id, text }) => (
                                                    <MenuItem
                                                        value={id}
                                                        key={id}
                                                    >
                                                        {text}
                                                    </MenuItem>
                                                )
                                            )}
                                    </FastField>
                                </Grid>
                                <Grid
                                    alignItems="center"
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <FastField
                                        component={OutlinedTextField}
                                        name="authorizationComment"
                                        type="text"
                                        label="Comentario"
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        edit={true}
                                    />
                                </Grid>
                                <Grid
                                    className={classes.dialogActions}
                                    container
                                    spacing={3}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                handleClose();
                                                dispatch(resetValues());
                                            }}
                                            variant="outlined"
                                            type="button"
                                            fullWidth
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            disabled={
                                                validExtensions() || maxSize
                                            }
                                            fullWidth
                                        >
                                            Validar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default PaymentReview;
