import PageHeader from '../../../common/PageHeader/PageHeader';
import { Button } from '@material-ui/core';
import ExportButton from '../../../common/ExportButton/ExportButton';
import { Link } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { fetchRoles } from './RolesSlice';
import RolesFilters from './rolesFilters/RolesFilters';
import RolesTable from './rolesTable/RolesTable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    downloadButton: {
        backgroundColor: green[600],
        color: 'white',
        '&:hover': {
            backgroundColor: green[800],
        },
    },
    topButton: {
        '&:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
}));

const csvHeaders = [
    { label: 'Rol', key: 'role' },
    { label: 'Descripción', key: 'description' },
];

const Roles = () => {
    const classes = useStyles();
    const status = useSelector(state => state.roles.status);
    const filters = useSelector(state => state.roles.data.filters);
    const rows = useSelector(state => state.roles.data.rows);

    const filtersActive = Object.values(filters).some(
        ({ selected }) => selected
    );

    const filteredRows = ((filtersActive, rows) => {
        if (!filtersActive) return rows;
        let newRows = [];
        if (filters.rolesNameOptions.selected) {
            newRows = rows.filter(
                row => row.role === filters.rolesNameOptions.selected
            );
        }
        if (filters.rolesDescriptionOptions.selected) {
            newRows = (newRows.length > 0 ? newRows : rows).filter(
                row =>
                    row.description === filters.rolesDescriptionOptions.selected
            );
        }

        return newRows;
    })(filtersActive, rows);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchRoles());
    }, [dispatch]);

    return (
        <div>
            <PageHeader title="Roles">
                <Button
                    className={classes.topButton}
                    color="primary"
                    component={Link}
                    to="/roles/create"
                    variant="contained"
                >
                    Crear Rol
                </Button>
                <ExportButton
                    headers={csvHeaders}
                    inputData={rows}
                    fileName="Roles.csv"
                    className={`${classes.topButton} ${classes.downloadButton}`}
                >
                    Exportar a Excel
                </ExportButton>
            </PageHeader>

            <RolesFilters {...filters}></RolesFilters>
            <RolesTable rows={filteredRows} status={status}></RolesTable>
        </div>
    );
};

export default Roles;
