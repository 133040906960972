import * as yup from 'yup';
import {
    emailErrorMessage,
    maxCharMessage,
    requiredMessage,
    minCharMessage,
    confirmPasswordMessage,
} from '../../helpers/validationSchemaHelpers';

export const initialLoginValues = {
    email: '',
    password: '',
};
export const initialRecoveryValues = {
    email: '',
};
export const initialChangePasswordValues = {
    password: '',
    confirmPassword: '',
};

export const validationLoginSchema = yup.object({
    email: yup
        .string()
        .max(50, maxCharMessage)
        .email(emailErrorMessage)
        .required(requiredMessage),
    password: yup.string().min(8, minCharMessage).required(requiredMessage),
});

export const validationRecoverySchema = yup.object({
    email: yup
        .string()
        .max(50, maxCharMessage)
        .email(emailErrorMessage)
        .required(requiredMessage),
});

export const validationChangePasswordSchema = yup.object({
    password: yup.string().min(8, minCharMessage).required(requiredMessage),
    confirmPassword: yup
        .string()
        .min(8, minCharMessage)
        .required(requiredMessage)
        .oneOf([yup.ref('password')], confirmPasswordMessage),
});
