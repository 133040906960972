import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const pushNotificationsSlice = createSlice({
    name: 'pushNotifications',
    initialState,
    reducers: {
        enqueueSnackbar: {
            reducer: (state, { payload }) => {
                state.push(payload);
            },
            prepare: payload => {
                const key = payload.options && payload.options.key;

                return {
                    payload: {
                        ...payload,
                        key: key || new Date().getTime() + Math.random(),
                    },
                };
            },
        },
        closeSnackbar: {
            reducer: (state, { payload }) => {
                return state.map(notification =>
                    payload.dismissAll || notification.key === payload.key
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                );
            },
            prepare: key => {
                return {
                    payload: {
                        dismissAll: !key, // dismiss all if no key has been defined
                        key,
                    },
                };
            },
        },
        removeSnackbar: (state, { payload }) => {
            return state.filter(
                notification => notification.key !== payload.key
            );
        },
    },
});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } =
    pushNotificationsSlice.actions;

export default pushNotificationsSlice.reducer;
