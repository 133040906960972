import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { OutlinedTextField } from '../../../../common/FormComponents/FormComponents';
import { addClientContact } from '../DropdownContacts/dropdownSlice';
import {
    initialValues,
    validationSchema,
} from '../../../clientContact/contactForm';
import { createClientContact } from '../../../clientContact/clientContactSlice';
import { LOADING_STATUS } from '../../../../helpers/constants';
const useStyles = makeStyles(theme => ({
    dialogActions: {
        marginBottom: theme.spacing(1),
    },
    dialogGrid: {
        marginBottom: theme.spacing(2),
    },
    dialogTitle: {
        '& > *': {
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    inputFile: {
        width: 0,
        height: 0,
    },
}));
const UploadContact = ({
    edit,
    handleClose,
    influenceCatalog,
    open,
    clientId,
    setValues,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const insertStatus = useSelector(state => state.clientContact.insertStatus);
    const loading = insertStatus === LOADING_STATUS.LOADING;
    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle className={classes.dialogTitle}>
                Nuevo contacto
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        const body = { ...values, clientId };
                        dispatch(createClientContact(body))
                            .unwrap()
                            .then(result => {
                                const {
                                    id,
                                    goesBy,
                                    firstName,
                                    middleName,
                                    lastName,
                                } = result;
                                dispatch(
                                    addClientContact(
                                        id,
                                        goesBy,
                                        `${firstName}${
                                            middleName ? ' ' + middleName : ''
                                        } ${lastName}`
                                    )
                                );
                                setValues(id, result);
                                handleClose();
                            });
                    }}
                >
                    {props => {
                        return (
                            <Form>
                                <Grid
                                    alignItems="center"
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <Field
                                        component={OutlinedTextField}
                                        name="firstName"
                                        type="text"
                                        label="Nombre*"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={edit}
                                        disabled={loading}
                                    />
                                    <Field
                                        component={OutlinedTextField}
                                        name="middleName"
                                        type="text"
                                        label="Segundo nombre"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={edit}
                                        disabled={loading}
                                    />
                                </Grid>
                                <Grid
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <Field
                                        component={OutlinedTextField}
                                        name="lastName"
                                        type="text"
                                        label="Apellido*"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={edit}
                                        disabled={loading}
                                    />
                                    <Field
                                        component={OutlinedTextField}
                                        name="phoneNumber"
                                        type="text"
                                        label="Numero de telefono*"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={edit}
                                        disabled={loading}
                                    />
                                </Grid>
                                <Grid
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <Field
                                        component={OutlinedTextField}
                                        name="mobileNumber"
                                        type="text"
                                        label="Número de celular*"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={edit}
                                        disabled={loading}
                                    />
                                    <Field
                                        component={OutlinedTextField}
                                        name="email"
                                        type="text"
                                        label="Email*"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={edit}
                                        disabled={loading}
                                    />
                                </Grid>
                                <Grid
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <Field
                                        component={OutlinedTextField}
                                        name="goesBy"
                                        type="text"
                                        label="Encargado*"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={edit}
                                        disabled={loading}
                                    />
                                    <Field
                                        component={OutlinedTextField}
                                        name="contactNotes"
                                        type="text"
                                        label="Notas*"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={edit}
                                        disabled={loading}
                                    />
                                </Grid>
                                <Grid
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <Field
                                        component={OutlinedTextField}
                                        name="contactRole"
                                        type="text"
                                        label="Rol*"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={edit}
                                        disabled={loading}
                                    />
                                    <Field
                                        component={OutlinedTextField}
                                        select
                                        name="influencyId"
                                        type="text"
                                        label="Influencia*"
                                        catalog={influenceCatalog}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit={edit}
                                        disabled={loading}
                                    >
                                        <MenuItem value="">&nbsp;</MenuItem>
                                        {influenceCatalog?.length > 0 &&
                                            influenceCatalog.map(
                                                ({ id, text }) => (
                                                    <MenuItem
                                                        value={id}
                                                        key={id}
                                                    >
                                                        {text}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Field>
                                </Grid>
                                <Grid
                                    className={classes.dialogActions}
                                    container
                                    spacing={3}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            color="primary"
                                            disabled={loading}
                                            onClick={handleClose}
                                            variant="outlined"
                                            fullWidth
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            color="primary"
                                            disabled={loading}
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                        >
                                            Guardar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};
export default UploadContact;
