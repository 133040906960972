import {
    Button,
    Grid,
    IconButton,
    MenuItem,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FastField, FieldArray, Form, Formik, getIn } from 'formik';
import { useEffect, useRef, useState } from 'react';
import {
    AddNewElement,
    LabelledCheckbox,
    OutlinedTextField,
} from '../../../common/FormComponents/FormComponents';
import PageHeader from '../../../common/PageHeader/PageHeader';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    initialAddress,
    initialContactInfo,
    initialFiscalInfo,
    initialPetInfo,
    validationSchema,
} from './mainForm';
import { red } from '@material-ui/core/colors';
import UploadDialog from '../../../common/DocumentComponents/UploadDialog/UploadDialog';
import DocumentationList from '../../../common/DocumentComponents/DocumentationList/DocumentationList';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
    createSingleClient,
    fetchSingleClient,
    updateSingleClient,
    resetValues,
    updateSingleTrainerAssign,
    createSingleTrainerAssign,
    updateSingleUser,
    updateSingleAddress,
} from './singleClientSlice';
import { fetchAllCatalogs } from '../../catalogs/catalogSlice';
import {
    formatCreateClient,
    formatTrainerAssign,
    formatUpdateAddressInfo,
    formatUpdateClient,
    formatUpdateUser,
} from './utils';
import { LOADING_STATUS } from '../../../helpers/constants';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { renderDateValue } from '../../../helpers/formHelpers';
import PetsTabs from './coursesTabs/PetsTabs';

const useStyles = makeStyles(theme => ({
    arraySubtitle: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: 700,
        marginTop: theme.spacing(-1.5),
        marginBottom: theme.spacing(-1.5),
        '$formSubSection + $formSubSection &': {
            marginTop: theme.spacing(2),
        },
        '& > span': {
            marginRight: theme.spacing(1),
        },
    },
    deleteIcon: {
        color: red[400],
        marginTop: theme.spacing(0.2),
    },
    formSection: {
        backgroundColor: theme.palette.grey[50],
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),

        marginBottom: theme.spacing(4),
    },
    formSectionHeading: {
        color: theme.palette.primary.main,
        marginBottom: 0,
        marginTop: 0,
    },
    formSubSection: {},
    noData: {
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 1,
        textAlign: 'center',
    },
    subtitleWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
    uploadGrid: {
        marginBottom: theme.spacing(1),
    },
}));

const SingleClient = ({ create = false }) => {
    const classes = useStyles();

    const prevSubmitCount = useRef(0);

    const [edit, setEdit] = useState(create);
    const [files, setFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [deletableContent, setDeletableContent] = useState({
        contactInfo: [],
        fiscalInfo: [],
        address: [],
    });

    const { id } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();

    const userRole = useSelector(state => state.user.userData.userInfo.role);
    const client = useSelector(state => state.singleClient.client);
    const catalogs = useSelector(state => state.catalogs.catalogs);
    const insertStatus = useSelector(state => state.singleClient.insertStatus);
    const fetchStatus = useSelector(state => state.singleClient.fetchStatus);
    const catalogStatus = useSelector(state => state.catalogs.catalogs.status);

    const loading =
        fetchStatus === LOADING_STATUS.LOADING ||
        catalogStatus === LOADING_STATUS.LOADING;

    const insertingClient = insertStatus === LOADING_STATUS.LOADING;

    useEffect(() => {
        dispatch(fetchAllCatalogs());
        return () => dispatch(resetValues());
    }, [dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(fetchSingleClient(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (fetchStatus === LOADING_STATUS.SUCCEEDED) {
            setFiles(client.documents);
        }
    }, [client.documents, fetchStatus]);

    useEffect(() => {
        if (insertStatus === LOADING_STATUS.SUCCEEDED) {
            history.push('/clients');
        }
    }, [dispatch, history, insertStatus]);

    const handleFileChange = file => {
        setFiles(prev => [...prev, file]);
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleButtonClick = e => {
        e.preventDefault();
        if (!edit) {
            setEdit(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteDoc = fileName => {
        setFiles(prev => prev.filter(file => file.fileName !== fileName));
    };

    function getRole(text) {
        let role = catalogs?.roles?.find(role => role.text === text);
        return role.id;
    }
    const handleSubmit = values => {
        // const newValue = {
        //     ...values,
        //     contactInfo: [
        //         ...values.contactInfo,
        //         ...deletableContent.contactInfo,
        //     ],
        //     fiscalInfo: [...values.fiscalInfo, ...deletableContent.fiscalInfo],
        //     address: [...values.address, ...deletableContent.address],
        // };
        // const formattedValues = formatInsertData(newValue, id);
        // const body = {
        //     ...formattedValues,
        //     documents: files.map(
        //         ({
        //             documentId,
        //             docNumber,
        //             documentNotes,
        //             file,
        //             fileName,
        //             idDocumentType,
        //             effBeginDate,
        //             effEndDate,
        //         }) => ({
        //             ...(id ? { clientId: Number(id) } : {}),
        //             ...(documentId ? { id: documentId } : {}),
        //             file,
        //             fileName,
        //             docNumber,
        //             documentNotes,
        //             documentTypeId: idDocumentType,
        //             effBeginDate: effBeginDate
        //                 ? effBeginDate
        //                 : new Date().toISOString(),
        //             effEndDate: effEndDate
        //                 ? effEndDate
        //                 : new Date().toISOString(),
        //         })
        //     ),
        // };
        if (id) {
            if (
                values.userInfo.email !== client.userInfo.email ||
                values.generalInfo.membership !==
                    client.generalInfo.membership ||
                values.userInfo.userStatus !== client.userInfo.userStatus
            ) {
                let formattedUser = formatUpdateUser({
                    ...values,
                    userInfo: {
                        ...values.userInfo,
                        roleId: values.generalInfo.membership
                            ? getRole('Miembro')
                            : getRole('Cliente'),
                    },
                });
                dispatch(
                    updateSingleUser({
                        id: values.userInfo.id,
                        body: formattedUser,
                    })
                );
            }
            if (values.generalInfo !== client.generalInfo) {
                let formattedClient = formatUpdateClient(values);
                dispatch(
                    updateSingleClient({
                        id: id,
                        body: formattedClient,
                    })
                );
            }
            if (values.address !== client.address) {
                let formattedAddress = formatUpdateAddressInfo(values);
                dispatch(
                    updateSingleAddress({
                        id: values.address.addressId,
                        body: formattedAddress,
                    })
                );
            }
            values.trainers?.map(async (value, index) => {
                let body = formatTrainerAssign(value, Number(id));
                if (value.id) {
                    if (value.trainerId !== client.trainers[index].trainerId) {
                        await dispatch(
                            updateSingleTrainerAssign({ id: value.id, body })
                        );
                    }
                } else {
                    await dispatch(createSingleTrainerAssign(body));
                }
            });
            // dispatch(updateSingleClient({ id, body }));
        } else {
            let body = formatCreateClient({
                ...values,
                userInfo: {
                    ...values.userInfo,
                    roleId: values.generalInfo.membership
                        ? getRole('Miembro')
                        : getRole('Cliente'),
                },
            });
            dispatch(createSingleClient(body));
        }
    };

    const handleFieldUpdate = (nextProps, currentProps) => {
        if (
            nextProps.edit !== currentProps.edit ||
            nextProps.disabled !== currentProps.disabled ||
            nextProps.loading !== currentProps.loading ||
            nextProps.catalog !== currentProps.catalog ||
            nextProps.name !== currentProps.name ||
            getIn(nextProps.formik.values, currentProps.name) !==
                getIn(currentProps.formik.values, currentProps.name) ||
            getIn(nextProps.formik.errors, currentProps.name) !==
                getIn(currentProps.formik.errors, currentProps.name) ||
            getIn(nextProps.formik.touched, currentProps.name) !==
                getIn(currentProps.formik.touched, currentProps.name) ||
            Object.keys(currentProps).length !==
                Object.keys(nextProps).length ||
            nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <main>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={client}
                onSubmit={handleSubmit}
            >
                {props => {
                    const { errors, submitCount, values } = props;
                    if (submitCount !== prevSubmitCount.current) {
                        prevSubmitCount.current = submitCount;
                        if (Object.keys(errors).length > 0) {
                            dispatch(
                                enqueueSnackbar({
                                    message:
                                        'Por favor llene todos los campos correctamente.',
                                    options: {
                                        key:
                                            new Date().getTime() +
                                            Math.random(),
                                        variant: 'error',
                                    },
                                })
                            );
                        }
                    }
                    return (
                        <Form>
                            <PageHeader
                                title={
                                    create
                                        ? 'Nuevo cliente'
                                        : edit
                                        ? 'Editar cliente'
                                        : 'Consulta de cliente'
                                }
                            >
                                {(userRole === 'Administrador' ||
                                    userRole === 'Developer') && (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type={edit ? 'submit' : 'button'}
                                        onClick={
                                            !edit
                                                ? handleButtonClick
                                                : undefined
                                        }
                                        disabled={loading || insertingClient}
                                    >
                                        {create
                                            ? 'Crear'
                                            : edit
                                            ? 'Aplicar cambios'
                                            : 'Editar'}
                                    </Button>
                                )}
                            </PageHeader>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Información General
                                    </h2>
                                </div>
                                <Grid container spacing={3}>
                                    <FastField
                                        component={OutlinedTextField}
                                        name="generalInfo.fullName"
                                        type="text"
                                        label="Nombre*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingClient}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="generalInfo.birthday"
                                        type="date"
                                        label="Fecha de nacimiento*"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        edit={edit}
                                        renderValue={renderDateValue}
                                        loading={loading}
                                        disabled={insertingClient}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="generalInfo.phoneNumber"
                                        type="number"
                                        label="Teléfono*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingClient}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="userInfo.email"
                                        type="email"
                                        label="email*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingClient}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        select
                                        name="userInfo.userStatus"
                                        type="text"
                                        label="Estatus*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingClient}
                                        shouldUpdate={handleFieldUpdate}
                                        catalog={catalogs.user_status}
                                    >
                                        <MenuItem value="">&nbsp;</MenuItem>
                                        {catalogs.user_status?.length > 0 &&
                                            catalogs.user_status.map(
                                                ({ id, text }) => (
                                                    <MenuItem
                                                        value={id}
                                                        key={id}
                                                    >
                                                        {text}
                                                    </MenuItem>
                                                )
                                            )}
                                    </FastField>
                                    <FastField
                                        component={LabelledCheckbox}
                                        type="checkbox"
                                        name={`generalInfo.membership`}
                                        label="membresía"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingClient}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                </Grid>
                            </section>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Dirección
                                    </h2>
                                </div>
                                <Grid container spacing={3}>
                                    <FastField
                                        component={OutlinedTextField}
                                        name="address.state"
                                        type="text"
                                        label="estado*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingClient}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="address.city"
                                        type="text"
                                        label="ciudad*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingClient}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="address.postalCode"
                                        type="number"
                                        label="código postal*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingClient}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="address.address"
                                        type="text"
                                        label="dirección"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingClient}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="address.suburb"
                                        type="text"
                                        label="colonia*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingClient}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                    <FastField
                                        component={OutlinedTextField}
                                        name="address.mapLink"
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        type="text"
                                        label="link de ubicación*"
                                        edit={edit}
                                        loading={loading}
                                        disabled={insertingClient}
                                        shouldUpdate={handleFieldUpdate}
                                    />
                                </Grid>
                            </section>
                            {!create && (
                                <section className={classes.formSection}>
                                    <div className={classes.subtitleWrapper}>
                                        <h2
                                            className={
                                                classes.formSectionHeading
                                            }
                                        >
                                            Entrenadores
                                        </h2>
                                    </div>
                                    <Grid container spacing={3}>
                                        <FastField
                                            component={OutlinedTextField}
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            select
                                            name="trainers[0].trainerId"
                                            type="text"
                                            label="Entrenador 1"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingClient}
                                            shouldUpdate={handleFieldUpdate}
                                            catalog={catalogs.employees}
                                        >
                                            <MenuItem value="">&nbsp;</MenuItem>
                                            {catalogs.employees?.length > 0 &&
                                                catalogs.employees.map(
                                                    ({ id, text }) => (
                                                        <MenuItem
                                                            value={id}
                                                            key={id}
                                                        >
                                                            {text}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </FastField>
                                        <FastField
                                            component={OutlinedTextField}
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            select
                                            name="trainers[1].trainerId"
                                            type="text"
                                            label="Entrenador 2"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingClient}
                                            shouldUpdate={handleFieldUpdate}
                                            catalog={catalogs.employees}
                                        >
                                            <MenuItem value="">&nbsp;</MenuItem>
                                            {catalogs.employees?.length > 0 &&
                                                catalogs.employees.map(
                                                    ({ id, text }) => (
                                                        <MenuItem
                                                            value={id}
                                                            key={id}
                                                        >
                                                            {text}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </FastField>
                                    </Grid>
                                </section>
                            )}
                        </Form>
                    );
                }}
            </Formik>
            {!create && (
                <section className={classes.formSection}>
                    <PetsTabs
                        role={userRole}
                        pets={client.pets}
                        catalogs={catalogs}
                        loading={loading}
                        insertingClient={insertingClient}
                    />
                </section>
            )}
        </main>
    );
};

export default SingleClient;
