export const serviceMapper = service => {
    return {
        id: service.id,
        image: service.image ?? '',
        name: service.name ?? '',
        description: service.description ?? '',
        price: service.price ?? 0,
    };
};

export const formatData = values => {
    const formatted = {
        name: values.serviceInfo.name,
        description: values.serviceInfo.description
            ? values.serviceInfo.description
            : null,
        price: values.serviceInfo.price,
    };
    return formatted;
};

export const formatInsertImage = values => ({
    filename: values.file,
});
