import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Avatar, Button } from '@material-ui/core';
import wave from '../../assets/images/wave.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchAllUserData } from '../../auth/authSlice';
import { fetchAssignedSubjects } from './profileSlice';
import AssignedSubjectTable from './AssignedSubjectTable/AssignedSubjectTable';
import ClientSection from './clientSection/ClientTabs';

const useStyles = makeStyles(theme => ({
    background: {
        width: '100%',
        backgroundImage: `url(${wave})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no repeat',
    },

    large: {
        width: '10rem',
        height: '10rem',
        marginTop: '1%',
        margin: 'auto',
    },

    heading: {
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    table: {
        marginTop: '50px',
    },
}));

const Profile = () => {
    const classes = useStyles();
    const userData = useSelector(state => state.user.userData);
    const rows = useSelector(state => state.profile.data.rows);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     if (userData.userInfo.id) {
    //         dispatch(fetchAssignedSubjects(userData.userInfo.id));
    //     }
    // }, [dispatch, userData]);

    return (
        <div>
            {userData.userInfo.role === 'Entrenador' && (
                <div className={classes.background}>
                    <Grid container>
                        <Grid item xs>
                            <h1 className={classes.heading}>
                                {userData.userInfo.name}
                            </h1>
                            <h2 className={classes.heading}>
                                {userData.userInfo.role}
                            </h2>
                            <h4 className={classes.heading}>
                                {userData.userInfo.position}
                            </h4>
                        </Grid>
                        <Grid item xs>
                            <Avatar
                                src={userData.userInfo.image}
                                className={classes.large}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
            <div className={classes.table}>
                {console.log(userData)}
                <ClientSection
                    trainers={userData.trainers}
                    pets={userData.pets}
                    appointments={userData.appointments}
                />
            </div>
            {/* <div className={classes.table}>
                <AssignedSubjectTable rows={rows} />
            </div> */}
        </div>
    );
};

export default Profile;
