import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getVideoInfo,
    createVideo,
    updateVideo,
    uploadVideoFile,
    uploadImageFile,
} from '../../../api/video';
import { getRolesList } from '../../../api/roles';
import { LOADING_STATUS } from '../../../helpers/constants';
import { initialValues } from './mainForm';
import { videoMapper } from './utils';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';

const initialState = {
    video: initialValues,
    videoAdded: false,
    fetchVideoInfoStatus: LOADING_STATUS.IDLE,
    fetchVideoRolesStatus: LOADING_STATUS.IDLE,
    fetchAllRolesStatus: LOADING_STATUS.IDLE,
    insertStatus: LOADING_STATUS.IDLE,
    insertRoles: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchVideoInfo = createAsyncThunk(
    'videos/fetchVideoInfo',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getVideoInfo(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar el video con el id ${id}.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createSingleVideo = createAsyncThunk(
    'videos/createSingleVideo',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createVideo(body);
            dispatch(
                enqueueSnackbar({
                    message: `Video creado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo crear el video, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleVideo = createAsyncThunk(
    'videos/updateSingleVideo',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateVideo(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Video actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el video, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const uploadVideo = createAsyncThunk(
    'videos/uploadVideo',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await uploadVideoFile(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Video actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el video, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const uploadImage = createAsyncThunk(
    'videos/uploadImage',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await uploadImageFile(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Imagen actualizada con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar la imagen, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const singleVideoSlice = createSlice({
    name: 'singleVideo',
    initialState,
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchVideoInfo.pending, state => {
                state.fetchVideoInfoStatus = LOADING_STATUS.LOADING;
            })
            .addCase(fetchVideoInfo.fulfilled, (state, { payload }) => {
                state.fetchVideoInfoStatus = LOADING_STATUS.SUCCEEDED;
                state.video.videoInfo = videoMapper(payload);
            })
            .addCase(fetchVideoInfo.rejected, (state, { error }) => {
                state.fetchVideoInfoStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(createSingleVideo.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(createSingleVideo.fulfilled, state => {})
            .addCase(createSingleVideo.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSingleVideo.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSingleVideo.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSingleVideo.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(uploadVideo.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(uploadVideo.fulfilled, state => {})
            .addCase(uploadVideo.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(uploadImage.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(uploadImage.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(uploadImage.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = singleVideoSlice.actions;

export default singleVideoSlice.reducer;
