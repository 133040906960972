import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FastField, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { OutlinedTextField } from '../../../../common/FormComponents/FormComponents';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    arraySubtitle: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: 700,
        marginTop: theme.spacing(-1.5),
        marginBottom: theme.spacing(-1.5),
        '$formSubSection + $formSubSection &': {
            marginTop: theme.spacing(2),
        },
        '& > span': {
            marginRight: theme.spacing(1),
        },
    },
    deleteIcon: {
        color: red[400],
        marginTop: theme.spacing(0.2),
    },
    formSection: {
        backgroundColor: theme.palette.grey[50],
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(4),
        },
    },
    formSectionHeading: {
        color: theme.palette.primary.main,
        marginBottom: 0,
        marginTop: 0,
    },
    formSubSection: {},
    noData: {
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 1,
        textAlign: 'center',
    },
    subtitleWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
    uploadGrid: {
        marginBottom: theme.spacing(1),
    },
    container: {
        display: 'flex',
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        maxWidth: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
}));

const ClientInfo = () => {
    const classes = useStyles();
    const userInfo = useSelector(state => state.user.userData);

    return (
        <main>
            <Formik enableReinitialize initialValues={userInfo}>
                {() => {
                    return (
                        <Form>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Información General
                                    </h2>
                                </div>

                                <Container className={classes.container}>
                                    <Grid container spacing={3}>
                                        <FastField
                                            component={OutlinedTextField}
                                            name="userInfo.name"
                                            type="text"
                                            label="Nombre"
                                            edit={false}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name="userInfo.email"
                                            type="text"
                                            label="Correo"
                                            edit={false}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name="userInfo.phoneNumber"
                                            type="number"
                                            label="Numero de teléfono"
                                            edit={false}
                                        />
                                    </Grid>
                                </Container>
                            </section>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Información demográfica
                                    </h2>
                                </div>

                                <Container className={classes.container}>
                                    <Grid container spacing={3}>
                                        <FastField
                                            component={OutlinedTextField}
                                            name="address.address"
                                            type="text"
                                            label="Dirección"
                                            edit={false}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name="address.city"
                                            type="text"
                                            label="Ciudad"
                                            edit={false}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name="address.state"
                                            type="text"
                                            label="Estado"
                                            edit={false}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name="address.suburb"
                                            type="text"
                                            label="Colonia"
                                            edit={false}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name="address.cp"
                                            type="number"
                                            label="Código postal"
                                            edit={false}
                                        />
                                    </Grid>
                                </Container>
                            </section>
                        </Form>
                    );
                }}
            </Formik>
        </main>
    );
};

export default ClientInfo;
