import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    csvLink: {
        color: 'inherit',
        textDecoration: 'none',
    },
});

const ExportButton = ({ headers, inputData, fileName, ...props }) => {
    const classes = useStyles();
    return (
        <Button variant="contained" {...props}>
            <CSVLink
                className={classes.csvLink}
                data={inputData}
                headers={headers}
                filename={fileName}
            >
                Exportar a Excel
            </CSVLink>
        </Button>
    );
};

ExportButton.propTypes = {
    className: PropTypes.string,
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            key: PropTypes.string,
        })
    ),
    inputData: PropTypes.any.isRequired,
    fileName: PropTypes.string.isRequired,
};

export default ExportButton;
