import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router';
import Cookies from 'js-cookie';

import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

/** This component redirects the user to home if the user is already logged in */
const AuthenticationRoute = ({ children, ...rest }) => {
    // const { isAuthenticated } = useAuth0();
    const isAuthenticated = rest.isAuthenticated;

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated ? (
                    <>
                        <Redirect
                            to={{
                                pathname: '/profile',
                                state: { from: location },
                            }}
                        />
                    </>
                ) : (
                    children
                )
            }
        />
    );
};

export default AuthenticationRoute;
