import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
    CalendarToday,
    ChatBubbleOutline,
    MailOutline,
    NotificationsNone,
} from '@material-ui/icons';
import logo from '../../assets/images/faithful-dog-logo.png';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles(theme => ({
    rootTopBar: {
        backgroundColor: 'white !important',
        color: '#181818',
        borderRadius: theme.shape.borderRadius,
        margin: '24px auto',
        maxWidth: 1100,
        width: '90%',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    logo: {
        height: '42px',
        marginLeft: '30px',
    },
}));

const TopBar = props => {
    const classes = useStyles();
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const { menuToggleHandle } = props;
    const { user } = useAuth0();

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    aria-label="show 11 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
        </Menu>
    );

    return (
        <div>
            <AppBar className={classes.rootTopBar} position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={menuToggleHandle ? menuToggleHandle : null}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* <div className={classes.sectionDesktop}>
                        <IconButton
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <ChatBubbleOutline />
                        </IconButton>
                        <IconButton
                            aria-label="show 4 new mails"
                            color="inherit"
                        >
                            <Badge badgeContent={4} color="secondary">
                                <MailOutline />
                            </Badge>
                        </IconButton>
                        <IconButton
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <CalendarToday />
                        </IconButton>
                        <IconButton
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <Badge badgeContent={17} color="secondary">
                                <NotificationsNone />
                            </Badge>
                        </IconButton>
                    </div> */}
                    <div className={classes.grow} />
                    <div style={{ marginLeft: '30px', marginRight: '30px' }}>
                        <Typography>🇲🇽 Español</Typography>
                    </div>
                    {/* <Typography> {user.given_name}</Typography> */}
                    <Link to={'/'}>
                        <img src={logo} alt="" className={classes.logo} />
                    </Link>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
        </div>
    );
};

export default TopBar;
