import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import bgimage from '../../../../src/assets/images/bg_eventos.jpg';
import playa from '../../../../src/assets/images/playa.jpeg';
import halowen from '../../../../src/assets/images/HALOWEN.jpg';
import hipnoterapia from '../../../../src/assets/images/hipnosis.jpeg';
import acupuntura from '../../../../src/assets/images/acupuntura.jpg';
import logo from '../../../../src/assets/images/holistica_logo.png';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: theme.spacing(8, 0, 6),
        placeItems: 'center',
    },
    backgroundImagen: {
        backgroundImage: `url(${bgimage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
        width: 400,
    },
    cardContent: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: '500px',
        height: '300px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '300px',
        },
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

const cards = [
    {
        name: 'VIAJE A LA PLAYA',
        description:
            'Proximo 8 de octubre llevaremos a cabo un viaje a la playa donde realizaremos muchas dinámicas, paseos y concursos.',
        image: playa,
    },
    {
        name: 'PASARELA DE HALLOWEEN',
        description:
            'Proxima caminata del dia de Halloween realizada en paseo Montejo, asiste con toda la familia donde realizaremos un concurso de disfraces .',
        image: halowen,
    },
];

export default function TherapiesPage() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <main>
                {/* Hero unit */}
                <div className={classes.backgroundImagen}>
                    <div className={classes.heroContent}>
                        <Container maxWidth="sm">
                            <Typography
                                component="h1"
                                variant="h2"
                                align="center"
                                color="textPrimary"
                                gutterBottom
                            >
                                Eventos
                            </Typography>
                        </Container>
                    </div>
                </div>
                <Container className={classes.cardGrid} maxWidth="lg">
                    {/* End hero unit */}
                    <Grid
                        container
                        spacing={4}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {cards.map(card => (
                            <Grid item key={card} xs={12} sm={12} md={12}>
                                <Card className={classes.root}>
                                    {cards.indexOf(card) % 2 === 0 && (
                                        <CardMedia
                                            className={classes.cover}
                                            image={card.image}
                                            title="Image title"
                                            style={{
                                                width: '500px',
                                                height: '300px',
                                            }}
                                        />
                                    )}
                                    <div className={classes.details}>
                                        <CardContent
                                            className={classes.content}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="h2"
                                            >
                                                {card.name}
                                            </Typography>
                                            <Typography>
                                                {card.description}
                                            </Typography>
                                        </CardContent>
                                    </div>
                                    {cards.indexOf(card) % 2 === 1 && (
                                        <CardMedia
                                            className={classes.cover}
                                            image={card.image}
                                            title="Image title"
                                            style={{
                                                width: '500px',
                                                height: '300px',
                                            }}
                                        />
                                    )}
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
            {/* Footer */}
            <footer className={classes.footer}>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                    component="p"
                >
                    Avenida 13 número 166 Colonia Apolo 83100 Hermosillo,
                    Sonora, México
                    <br />
                    Teléfono: 01 662 284 4500
                    <br />
                    http://universidadholistica.com.mx/
                    <br />
                    Lunes a Viernes: 8:00am - 8:00pm Sábado: 8:00am a 2:00pm
                </Typography>
                <Copyright />
            </footer>
            {/* End footer */}
        </React.Fragment>
    );
}
