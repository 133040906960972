import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import ListSubheader from '@material-ui/core/ListSubheader';
import PersonIcon from '@material-ui/icons/Person';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import BarChartIcon from '@material-ui/icons/BarChart';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ListItemText from '@material-ui/core/ListItemText';
import GroupIcon from '@material-ui/icons/Group';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PaymentIcon from '@material-ui/icons/Payment';
import PetsIcon from '@material-ui/icons/Pets';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import StoreIcon from '@material-ui/icons/Store';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import logo from '../../assets/images/faithful-dog-logo.png';
import Cookies from 'js-cookie';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    nested: {
        paddingLeft: theme.spacing(2),
    },
    logo: {
        width: '100%',
    },
}));

function ResponsiveDrawer(props) {
    const permissions = useSelector(state => state.user.userData.userInfo.role);
    const { window, menuToggle, menuToggleHandle, isAuthenticated } = props;
    const classes = useStyles();
    const theme = useTheme();
    const { logout } = useAuth0();

    // const searchPermit = namePermit =>
    //     permissions.find(permit => permit.name === namePermit) ? true : false;

    const drawer = (
        <div>
            {isAuthenticated ? (
                <img src={logo} alt="logo" className={classes.logo} />
            ) : null}
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Menú
                    </ListSubheader>
                }
            >
                <ListItem component={Link} to="/profile" button>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Mi Perfil" />
                </ListItem>
                {(permissions === 'Administrador' ||
                    permissions === 'Developer') && (
                    <>
                        <ListItem component={Link} to="/carousel" button>
                            <ListItemIcon>
                                <ViewCarouselIcon />
                            </ListItemIcon>
                            <ListItemText primary="Carrusel" />
                        </ListItem>
                        <ListItem component={Link} to="/users" button>
                            <ListItemIcon>
                                <HowToRegIcon />
                            </ListItemIcon>
                            <ListItemText primary="Empleados" />
                        </ListItem>
                        <ListItem component={Link} to="/services" button>
                            <ListItemIcon>
                                <RoomServiceIcon />
                            </ListItemIcon>
                            <ListItemText primary="Servicios" />
                        </ListItem>
                    </>
                )}
                {(permissions === 'Miembro' ||
                    permissions === 'Administrador' ||
                    permissions === 'Entrenador' ||
                    permissions === 'Developer') && (
                    <>
                        <ListItem component={Link} to="/videos" button>
                            <ListItemIcon>
                                <VideoLibraryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Videos" />
                        </ListItem>
                    </>
                )}
                {(permissions === 'Administrador' ||
                    permissions === 'Entrenador' ||
                    permissions === 'Developer') && (
                    <>
                        <ListItem component={Link} to="/scheduler" button>
                            <ListItemIcon>
                                <ScheduleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Horarios" />
                        </ListItem>
                        <ListItem component={Link} to="/clients" button>
                            <ListItemIcon>
                                <PetsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Clientes" />
                        </ListItem>
                    </>
                )}
                {/* <ListItem component={Link} to="#" button>
                    <ListItemIcon>
                        <EventAvailableIcon />
                    </ListItemIcon>
                    <ListItemText primary="Eventos" />
                </ListItem>
                <ListItem component={Link} to="#" button>
                    <ListItemIcon>
                        <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Grupales" />
                </ListItem>
                <ListItem component={Link} to="#" button>
                    <ListItemIcon>
                        <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary="Productos" />
                </ListItem>
                <ListItem component={Link} to="#" button>
                    <ListItemIcon>
                        <MonetizationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary="Ganancias" />
                </ListItem> */}

                <ListItem
                    component={Link}
                    onClick={async () => {
                        await Cookies.remove('token');
                        await document.location.reload();
                    }}
                    to="/"
                    button
                >
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cerrar sesión" />
                </ListItem>
            </List>
        </div>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={menuToggle}
                        onClose={menuToggleHandle ? menuToggleHandle : null}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;
