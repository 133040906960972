import axios from 'axios';
import { axiosConfig, axiosConfigForm } from './axiosConfig';

export const login = async body => {
    const { data } = await axios.post(
        `auth/login`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const recovery = async body => {
    const { data } = await axios.post(
        `auth/recovery`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const changePassword = async body => {
    const { data } = await axios.post(
        `auth/changePassword`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};
