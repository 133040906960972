import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import loadingImage from '../../assets/images/dog-loader.gif';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

export default function Loading({ customHeight = '100vh', children }) {
    const classes = useStyles();

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    height: `${customHeight}`,
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div className={classes.root}>
                    <Typography component="h1" variant="h5">
                        {children}
                    </Typography>
                    <img src={loadingImage} alt="loading image" height={100} />
                    {/* <CircularProgress /> */}
                </div>
            </div>
        </>
    );
}
